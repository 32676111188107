import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { VersionsService } from '@app/shared/versions/state/versions.service';
import { UIModule } from '@bannerflow/ui';
import { Observable } from 'rxjs';
import { UserSettingsService } from '../../../shared/user-settings/state/user-settings.service';
import { TranslationPageService } from '../state/translation-page.service';
import { PanelContentWrapperComponent } from './panel-content-wrapper/panel-content-wrapper.component';
import { PanelContentComponent } from './panel-content/panel-content.component';
import { PanelStylingComponent } from './panel-styling/panel-styling.component';
import { PanelTabsComponent } from './panel-tabs/panel-tabs.component';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        UIModule,
        PanelTabsComponent,
        PanelContentComponent,
        PanelStylingComponent,
        PanelContentWrapperComponent
    ],
    selector: 'tp-panel',
    templateUrl: './translation-panel.component.html',
    styleUrls: ['./translation-panel.component.scss']
})
export class TranslationPanelComponent {
    private translationPageService = inject(TranslationPageService);
    private userSettingsService = inject(UserSettingsService);
    private versionsService = inject(VersionsService);

    hasTranslatableElements$: Observable<boolean>;
    selectedTab$: Observable<'content' | 'styling'>;
    versionsLoaded$ = this.versionsService.loaded$;

    constructor() {
        this.selectedTab$ = this.userSettingsService.translationPageTab$;
        this.hasTranslatableElements$ = this.translationPageService.hasTranslatableElements$;
    }
}
