import { BrandDto } from '@domain/api/generated/sapi';
import { IShowcaseBrand } from '@domain/showcase';

export function convertBrandDto(brand: BrandDto): IShowcaseBrand {
    return {
        id: brand.id,
        localizations: brand.localizations!.map(l => ({
            id: l.id,
            name: l.name,
            cultureCode: l.cultureCode,
            cultureName: l.cultureName,
            targetUrl: l.targetUrl ?? ''
        }))
    };
}
