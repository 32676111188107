import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Logger } from '@bannerflow/sentinel-logger';
import {
    UIConfirmDialogService,
    UIInputComponent,
    UIListComponent,
    UIListDataSource,
    UIModule,
    UINotificationService,
    UITabComponent,
    UITabsComponent
} from '@bannerflow/ui';
import { CreativeSize } from '@domain/creativeset/size';
import { IVersion } from '@domain/creativeset/version';
import {
    AllowedOperations,
    ICreativesetShowcase,
    ICreativesetShowcaseCreateData,
    IShowcaseTranslator,
    IShowcaseUser,
    IShowcaseUserMapped,
    IShowcaseUserOrTranslator
} from '@domain/showcase';
import { copyToClipboard } from '@studio/utils/clipboard';
import { Observable, Subject, combineLatest } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ItemListOverflowComponent } from '../../../shared/components/item-overflow/item-list-overflow.component';
import { OverflowItemComponent } from '../../../shared/components/item-overflow/overflow-item.component';
import { VersionFlagComponent } from '../../../shared/components/version-picker/version-flag/version-flag.component';
import { CreativeSetShowcaseService } from '../../../shared/creativeset-showcase/state/creativeset-showcase.service';
import { CreativesetDataService } from '../../../shared/creativeset/creativeset.data.service';
import { TruncateSpanComponent } from '../../../shared/directives/truncate-span.directive';
import { UserService } from '../../../shared/user/state/user.service';
import { VersionsService } from '../../../shared/versions/state/versions.service';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        UIModule,
        VersionFlagComponent,
        OverflowItemComponent,
        ItemListOverflowComponent,
        TruncateSpanComponent
    ],
    selector: 'showcase-dialog',
    templateUrl: './showcase-dialog.component.html',
    styleUrls: ['./showcase-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowcaseDialogComponent implements OnInit, OnDestroy {
    @ViewChild('input', { static: true }) input: UIInputComponent;
    @ViewChild('linkList', { static: false }) linkList: UIListComponent;
    @ViewChild('createLinkTab') createLinkTab: UITabComponent;
    @ViewChild('tabs') tabs: UITabsComponent;

    defaultVersion$: Observable<IVersion>;
    versions: IVersion[] = [];
    loading$: Observable<boolean>;
    sizes: CreativeSize[];
    allowComments = true;
    allowStatuses = true;
    allowTranslations = false;
    allowStyling = false;
    allowFullAccess = false;
    selectedVersions: IVersion[] = [];
    selectedSizes: CreativeSize[] = [];
    selectedRecipients: IShowcaseUserOrTranslator[] = [];
    sortedUsers: IShowcaseUserOrTranslator[] = [];
    users: IShowcaseUserMapped[] = [];
    translators: IShowcaseTranslator[] = [];
    createdShowcase$: Observable<ICreativesetShowcase | undefined>;
    showcaseList: UIListDataSource<ICreativesetShowcase> = new UIListDataSource<ICreativesetShowcase>();
    invitationMessage: string;
    private unsubscribe$ = new Subject<void>();
    private logger = new Logger('ShowcaseDialogComponent');

    constructor(
        private creativesetDataService: CreativesetDataService,
        private uiNotificationService: UINotificationService,
        private uiConfirmDialogService: UIConfirmDialogService,
        private changeDetectorRef: ChangeDetectorRef,
        private creativesetShowcaseService: CreativeSetShowcaseService,
        public userService: UserService,
        public versionsService: VersionsService
    ) {
        this.defaultVersion$ = this.versionsService.defaultVersion$;
        this.versionsService.versions$.pipe(takeUntil(this.unsubscribe$)).subscribe(versions => {
            this.versions = versions;
        });
        this.loading$ = this.creativesetShowcaseService.loading$;
        this.createdShowcase$ = this.creativesetShowcaseService.createdShowcase$;

        this.createdShowcase$.pipe(takeUntilDestroyed()).subscribe(createdShowcase => {
            if (createdShowcase?.showcaseLink) {
                this.copyLink(createdShowcase.showcaseLink);
            }
        });
    }

    async ngOnInit(): Promise<void> {
        this.sizes = this.creativesetDataService.creativeset.sizes.filter(size =>
            this.creativesetDataService.creativeset.creatives.find(
                creative => creative.size.id === size.id && !!creative.design
            )
        );

        combineLatest([
            this.creativesetShowcaseService.users$,
            this.creativesetShowcaseService.translators$
        ])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([users, translators]) => {
                this.users = users.map(
                    user =>
                        ({
                            id: user.id,
                            name: `${user.firstName} ${user.lastName}`,
                            email: user.email,
                            kind: 'user'
                        }) as IShowcaseUserMapped
                );

                this.translators = translators.map(
                    t =>
                        ({
                            id: t.id,
                            name: t.name,
                            email: t.email,
                            kind: 'translator',
                            localizations: t.localizations
                        }) as IShowcaseTranslator
                );

                this.sortUsers();
            });

        this.creativesetShowcaseService.showcases$
            .pipe(
                withLatestFrom(
                    this.creativesetShowcaseService.users$,
                    this.creativesetShowcaseService.translators$
                ),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(([showcases, users, translators]) => {
                const filteredShowcases = showcases.filter(showcase => !showcase.isTemporary);
                this.showcaseList.data = filteredShowcases.map(showcase =>
                    this.toShowcaseListItem(showcase, users, translators)
                );
                this.changeDetectorRef.markForCheck();
            });

        this.creativesetShowcaseService.showcaseDeleted$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                if (this.showcaseList.data.length === 0) {
                    this.tabs.selectTab(this.createLinkTab);
                    this.resetLink();
                }
            });

        this.creativesetShowcaseService.loadShowcasesOfCreativeset();
    }
    selectedTabChanged(): void {
        setTimeout(() => {
            this.linkList?.resizeTable();
        });
    }

    resetLink(): void {
        this.creativesetShowcaseService.resetCreatedShowcase();
    }

    goToLink(link: string): void {
        window.open(link, '_blank');
    }

    copyLink(link: string): void {
        const input = document.createElement('input');
        input.value = link;
        input.hidden = true;
        input.style.position = 'absolute';
        document.body.appendChild(input);
        input.select();
        copyToClipboard(input.value);
        input.remove();
        this.uiNotificationService.open('Shared link copied to clipboard.', {
            placement: 'top',
            autoCloseDelay: 5000
        });
    }

    createLinkWithRecipients(): void {
        const newShowcase = this.getCreationShowcase();

        for (const recipient of this.selectedRecipients) {
            newShowcase.invitations.push({
                id: recipient.id,
                kind: this.isTranslator(recipient) ? 'translator' : 'user',
                email: recipient.email
            });
        }

        this.createLink(newShowcase);
    }

    createLink(showcase?: ICreativesetShowcaseCreateData): void {
        const newShowcase = showcase ?? this.getCreationShowcase();
        this.creativesetShowcaseService.createShowcaseLink(newShowcase);
    }

    onCopyLink(link: string | undefined): void {
        if (!link) {
            this.logger.warn('Trying to copy link, but no showcase was created yet');
        } else {
            this.copyLink(link);
        }
    }

    onCopyMVLink(): void {
        this.copyLink(window.location.href);
    }

    async goToManageUsersView(): Promise<void> {
        const brandUrl = `${this.creativesetDataService.getAccountSlug()}/${this.creativesetDataService.getBrandSlug()}`;
        const manageUsersViewLink = `${environment.origins.bfc}/${brandUrl}/settings/account/users`;
        window.open(manageUsersViewLink, '_blank');
    }

    toggleEnabled(enabled: boolean, showcaseKey: string): void {
        this.creativesetShowcaseService.updateShowcaseLink(
            { disabled: !enabled, invitations: [] },
            showcaseKey
        );
    }

    async deleteShowcase(showcaseKey: string): Promise<void> {
        const dialogResponse = await this.uiConfirmDialogService.confirm({
            headerText: 'DELETE SHARED LINK',
            confirmText: 'DELETE',
            cancelText: 'CANCEL',
            text: 'Delete shared link? This action cannot be undone.'
        });

        if (dialogResponse === 'confirm') {
            this.creativesetShowcaseService.deleteShowcaseLink(showcaseKey);
        }
    }

    isTranslator(user: IShowcaseUserOrTranslator): user is IShowcaseTranslator {
        return !!(
            (user as IShowcaseTranslator).localizations ||
            (user as IShowcaseTranslator).kind === 'translator'
        );
    }

    sortUsers(): void {
        const translatorFilter = (user: IShowcaseUserOrTranslator): boolean =>
            this.isTranslator(user) &&
            !!user.localizations.find(
                loc => !!this.selectedVersions.find(version => version.localization.id === loc.id)
            );

        this.sortedUsers = this.allowFullAccess
            ? [...this.users]
            : [...this.users, ...this.translators];

        if (this.allowTranslations && this.selectedVersions.length) {
            this.sortedUsers = this.sortedUsers.filter(translatorFilter);
        }

        this.selectedRecipients = this.selectedRecipients.filter(sr =>
            this.sortedUsers.some(su => su.id === sr.id)
        );
        this.changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private toShowcaseListItem = (
        showcase: ICreativesetShowcase,
        users: IShowcaseUser[],
        translators: IShowcaseTranslator[]
    ): any => ({
        invitations: [
            ...users
                .filter(user => showcase.invitations.find(inv => inv.id === user.id))
                .map(u => ({
                    id: u.id,
                    name: `${u.firstName} ${u.lastName}`,
                    kind: 'user',
                    email: u.email
                })),
            ...translators.filter(translator =>
                showcase.invitations.find(inv => inv.id === translator.id)
            )
        ],
        allowedOperations: {
            fullAccess: showcase.allowedOperations.includes('fullAccess'),
            translations: showcase.allowedOperations.includes('updateVersions'),
            styling: showcase.allowedOperations.includes('styleTranslations'),
            comments: showcase.allowedOperations.includes('makeComments'),
            statuses: showcase.allowedOperations.includes('setApprovalStatusOnCreatives')
        },
        showcaseKey: showcase.showcaseKey,
        showcaseLink: showcase.showcaseLink,
        sizes: this.sizes.filter(size => !!showcase.sizeIds.find(id => id === size.id)),
        versions: this.versions.filter(version => !!showcase.versionIds.find(id => id === version.id)),
        disabled: showcase.disabled
    });

    private getLinkOperations(): AllowedOperations[] {
        const operations: AllowedOperations[] = [];
        if (this.allowFullAccess) {
            operations.push('fullAccess');
        }

        if (this.allowStyling) {
            operations.push('styleTranslations');
        }

        if (this.allowTranslations) {
            operations.push('updateVersions');
            operations.push('updateCreativesetShowcase');
        }
        if (this.allowStatuses) {
            operations.push('setApprovalStatusOnCreatives');
        }
        if (this.allowComments) {
            operations.push('makeComments');
        }
        return operations;
    }

    private getCreationShowcase(): ICreativesetShowcaseCreateData {
        return {
            versionIds: this.selectedVersions.map(v => v.id),
            sizeIds: this.selectedSizes.map(v => v.id),
            creativesetId: this.creativesetDataService.creativeset.id,
            allowedOperations: this.getLinkOperations(),
            invitations: [],
            invitationMessage: this.invitationMessage
        };
    }

    allowTextEditingChange(event: boolean): void {
        this.allowStyling = event === false ? false : this.allowStyling;
        this.allowTranslations = event;
        this.sortUsers();
        this.resetLink();
    }

    allowStylingChange(event: boolean): void {
        this.allowStyling = event;
        this.resetLink();
    }
}
