import { NgFor, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    input,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { UIDialogService, UIModule, UISelectComponent } from '@bannerflow/ui';
import { removeDeletedFonts } from '@creative/font-families.utils';
import { ISelectedFont } from '@domain/font';
import { IFontFamily, IFontFamilyStyle } from '@domain/font-families';
import { FONT_PLACEHOLDERS } from '@studio/domain/components/font-picker';
import { EventLoggerService, FontNotFoundError } from '@studio/monitoring/events';
import { injectFontFace } from '@studio/utils/dom-utils';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { CreativesetDataService } from '../../creativeset/creativeset.data.service';
import { FontFamiliesService } from '../../font-families/state/font-families.service';
import { SortArrayPipe } from '../../pipes/sort-array.pipe';
import { FontManagerComponent } from '../fonts/font-manager.component';
import { ToolbarButtonComponent } from '../toolbar-button/toolbar-button.component';

const MIXED_FONT = '$mixed';

@Component({
    standalone: true,
    imports: [UIModule, NgIf, NgFor, ToolbarButtonComponent, SortArrayPipe],
    selector: 'font-picker',
    templateUrl: 'font-picker.component.html',
    styleUrls: ['font-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'font-picker'
    }
})
export class FontPickerComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('fontFamilySelect', { static: true }) fontFamilySelect: UISelectComponent;
    @ViewChild('fontStyleSelect', { static: true }) fontStyleSelect: UISelectComponent;
    @Input() selectedFontFamilyId: string | undefined;
    @Input() selectedFontStyleId: string | undefined;
    @Input() labels = true;
    @HostBinding('class.labelVisible') labelVisible = this.labels;
    @Input() showAsPlaceholder = false;
    @Input() disabled = false;
    searchable = input(true);
    @Output() mousedown = new EventEmitter<MouseEvent>();
    @Output() onOpen = new EventEmitter<void>();
    @Output() selectedFontChange = new EventEmitter<ISelectedFont>();
    @Output() previewFontChange = new EventEmitter<Partial<ISelectedFont>>();
    @Output() onPreviewStop = new EventEmitter();

    selectedFontFamily?: IFontFamily;
    selectedFontStyle?: IFontFamilyStyle;
    filteredFontFamilies: IFontFamily[];
    allFontFamilies: IFontFamily[];
    fontFamilyPlaceholder: string;
    fontStylePlaceholder: string;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private creativesetDataService: CreativesetDataService,
        private uiDialogService: UIDialogService,
        private changeDetector: ChangeDetectorRef,
        private fontFamiliesService: FontFamiliesService,
        private eventLoggerService: EventLoggerService
    ) {}

    ngOnInit(): void {
        this.fontFamiliesService.fontFamilies$
            .pipe(
                takeUntil(this.unsubscribe$),
                tap(fontFamilies => (this.allFontFamilies = fontFamilies)),
                map(fontFamilies => removeDeletedFonts(fontFamilies))
            )
            .subscribe(fontFamilies => this.setFontFamilies(fontFamilies));
    }

    ngOnChanges(): void {
        if (!this.filteredFontFamilies) {
            return;
        }

        this.updateSelectedFont();

        this.changeDetector.detectChanges();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private setFontFamilies(fontFamilies: IFontFamily[]): void {
        const currentBrandId = this.creativesetDataService.brand.id;
        this.filteredFontFamilies = fontFamilies.filter(
            fontFamily =>
                !fontFamily.visibleBrandIds ||
                fontFamily.visibleBrandIds.find(brandId => currentBrandId === brandId)
        );
        this.updateSelectedFont();
        this.changeDetector.detectChanges();
    }

    private getFontStyle(
        fontFamilies: IFontFamily[],
        fontFamilyId: string,
        fontStyleId: string
    ): IFontFamilyStyle | undefined {
        if (!fontFamilies.length || !fontFamilyId) {
            return;
        }
        const fontFamily = fontFamilies.find(({ id }) => fontFamilyId === id);
        return fontFamily && fontFamily.fontStyles.find(({ id }) => id === fontStyleId);
    }

    private updateSelectedFont(): void {
        this.selectedFontFamily = this.selectedFontStyle = undefined;

        // Mixed font
        if (this.selectedFontFamilyId === MIXED_FONT) {
            this.fontFamilyPlaceholder = FONT_PLACEHOLDERS.MIXED;
            this.fontStylePlaceholder = FONT_PLACEHOLDERS.MIXED;
            return;
        }
        // No font at all
        if (!this.selectedFontFamilyId) {
            this.fontFamilyPlaceholder = FONT_PLACEHOLDERS.NO_FONT_SELECTED;
            this.fontStylePlaceholder = FONT_PLACEHOLDERS.NO_STYLE_SELECTED;
            return;
        }

        if (!this.allFontFamilies.length) {
            this.fontFamilyPlaceholder = FONT_PLACEHOLDERS.NO_FONTS;
            this.fontStylePlaceholder = FONT_PLACEHOLDERS.NO_STYLES;
            return;
        }

        const fontFamily = this.allFontFamilies.find(({ id }) => id === this.selectedFontFamilyId);

        if (!fontFamily) {
            this.fontFamilyPlaceholder = FONT_PLACEHOLDERS.NO_FONT_FOUND;
            this.fontStylePlaceholder = FONT_PLACEHOLDERS.NO_STYLE_FOUND;
            this.eventLoggerService.log(
                new FontNotFoundError({ fontFamilyId: this.selectedFontStyleId })
            );
            return;
        }

        this.fontFamilyPlaceholder = `[${fontFamily.name}]`;
        if (!fontFamily.deletedAt) {
            this.selectedFontFamily = this.filteredFontFamilies.find(
                ({ id }) => id === this.selectedFontFamilyId
            );
        }

        if (this.selectedFontStyleId === MIXED_FONT) {
            this.fontStylePlaceholder = FONT_PLACEHOLDERS.MIXED;
            return;
        }

        if (!this.selectedFontStyleId) {
            this.fontStylePlaceholder = FONT_PLACEHOLDERS.NO_STYLE_SELECTED;
            return;
        }

        const fontStyle = this.getFontStyle(
            this.filteredFontFamilies,
            this.selectedFontFamilyId,
            this.selectedFontStyleId
        );
        if (fontStyle) {
            this.selectedFontStyle = fontStyle;
            this.fontStylePlaceholder = `[${fontStyle.name}]`;
            return;
        }

        const deletedFontStyle = this.getFontStyle(
            this.allFontFamilies,
            this.selectedFontFamilyId,
            this.selectedFontStyleId
        );
        if (deletedFontStyle) {
            this.fontStylePlaceholder = `[${deletedFontStyle.name}]`;
            return;
        }

        this.fontStylePlaceholder = FONT_PLACEHOLDERS.NO_STYLE_FOUND;
        this.eventLoggerService.log(new FontNotFoundError({ fontStyleId: this.selectedFontStyleId }));
    }

    selectOpen(): void {
        this.onOpen.next();
    }

    onFontFamilyChanged(fontFamily: IFontFamily): void {
        this.selectedFontFamily = fontFamily;
        this.selectedFontStyle = this.getClosestFontStyle(
            fontFamily.id,
            this.selectedFontStyle ? this.selectedFontStyle.id : ''
        );
        this.selectedFontChange.emit({
            fontFamily: this.selectedFontFamily,
            fontStyle: this.selectedFontStyle
        });
    }

    onFontStyleChanged(fontStyle: IFontFamilyStyle): void {
        this.selectedFontChange.emit({ fontFamily: this.selectedFontFamily!, fontStyle: fontStyle });
    }

    getClosestFontStyle(fontFamilyId: string, fontStyleId: string): IFontFamilyStyle {
        const selectedFontFamily = this.filteredFontFamilies.find(family => family.id === fontFamilyId);
        let closestFontStyle: IFontFamilyStyle | undefined;

        if (selectedFontFamily) {
            for (const fontFamily of this.filteredFontFamilies) {
                const currentFontStyle = fontFamily.fontStyles.find(
                    fontStyle => fontStyle.id === fontStyleId
                );

                if (currentFontStyle) {
                    // Check if the new font family has a similar style
                    let newFontStyle = selectedFontFamily.fontStyles.find(
                        fontStyle =>
                            fontStyle.italic === currentFontStyle.italic &&
                            fontStyle.weight === currentFontStyle.weight
                    );

                    if (newFontStyle) {
                        closestFontStyle = newFontStyle;
                        break;
                    }
                    // If it doesn't match italic and weight, look for just the weight instead
                    else {
                        newFontStyle = selectedFontFamily.fontStyles.find(
                            fontStyle => fontStyle.weight === currentFontStyle.weight
                        );
                        closestFontStyle = newFontStyle;
                    }
                }
            }
        }

        if (!closestFontStyle) {
            closestFontStyle =
                selectedFontFamily!.fontStyles.find(fontStyle => fontStyle.weight === 400) ||
                selectedFontFamily!.fontStyles[0];
        }

        return closestFontStyle;
    }

    async onPreviewFontFamily(fontFamily: IFontFamily): Promise<void> {
        const fontStyle = this.getClosestFontStyle(
            fontFamily.id,
            (this.selectedFontStyle && this.selectedFontStyle.id) || this.selectedFontStyleId!
        );
        const font = await injectFontFace(fontStyle);
        if (!document.fonts.has(font)) {
            document.fonts.add(font);
        }
        this.previewFontChange.emit({ fontFamily, fontStyle });
    }

    async onPreviewFontStyle(fontStyle: IFontFamilyStyle): Promise<void> {
        await injectFontFace(fontStyle);
        this.previewFontChange.emit({ fontFamily: this.selectedFontFamily!, fontStyle });
    }

    stopCurrentPreview(): void {
        this.onPreviewStop.emit();
    }

    fontManagerClosed = (): void => {
        this.fontFamiliesService.loadFontFamilies();
        this.creativesetDataService.syncFonts();
    };

    openFontManager(): void {
        const dialog = this.uiDialogService.openComponent(FontManagerComponent, {
            padding: 0,
            headerText: 'Manage brand fonts',
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            panelClass: ['inlined-iframe', 'fullscreen'],
            data: {
                brandId: this.creativesetDataService.brand.id
            }
        });
        dialog.beforeClose().subscribe(() => this.fontManagerClosed());
    }
}
