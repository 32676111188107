import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BrandManagerBrandDto } from '@domain/brand';
import { catchError, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BrandDataService {
    private httpClient = inject(HttpClient);

    getBrand(brandId: string): Observable<BrandManagerBrandDto> {
        const url = `${environment.origins.brandManager}/brand/${brandId}?includeAllLocalizations=true`;

        return this.httpClient.get<BrandManagerBrandDto>(url).pipe(
            catchError(err => {
                if (err.error === 'Invalid brandId') {
                    err.status = 404;
                }
                throw err;
            })
        );
    }
}
