import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UIDialogComponent } from '@bannerflow/ui';
import { QRCodeService } from '../../services/qr-code.service';

@Component({
    selector: 'qr-code-modal',
    templateUrl: './qr-code-modal.component.html',
    styleUrls: ['./qr-code-modal.component.scss']
})
export class QRCodeModalComponent implements OnInit {
    @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLElement>;

    public targetMessage: string;

    constructor(
        private dialog: UIDialogComponent,
        private qrCodeService: QRCodeService
    ) {}

    async ngOnInit(): Promise<void> {
        const { link, message } = this.dialog.config.data;
        if (!link) {
            throw new Error('No temporary link found');
        }
        this.targetMessage = message;

        this.qrCodeService.generateQRCode(link, this.canvas.nativeElement);
    }

    close(): void {
        this.dialog.dialogRef.close();
    }
}
