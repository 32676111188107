import { BrandManagerBrandDto } from '@domain/brand';
import { createActionGroup, props } from '@ngrx/store';

export const loadActions = createActionGroup({
    source: 'Brand',
    events: {
        'load brand': props<{ brandId: string }>(),
        'load brand success': props<{ brand: BrandManagerBrandDto }>(),
        'load brand failure': props<{ error: unknown }>()
    }
});
