import { GridColor } from '@domain/grid';
import { LibraryKind } from '@domain/media-library';
import { createReducer, on } from '@ngrx/store';
import { GuidelineColor } from '../../../pages/design-view/workspace/guideline/guideline.models';
import * as MediaLibraryActions from '../../media-library/state/media-library.actions';
import * as UserActions from './user-settings.actions';
import {
    IDesignViewSettings,
    IIgnoredWarnings,
    IManageViewSettings,
    ISharedSettings,
    ITranslationPageSettings
} from './user-settings.model';

export const defaultDesignViewSettings: IDesignViewSettings = {
    isLibraryPinned: false,
    libraryKind: LibraryKind.Feeds,
    selectedFeedId: 'none',
    dimOutsideCanvas: false,
    snapping: true,
    outlineVisible: false,
    guidelineVisible: true,
    guidelineColor: GuidelineColor.Magenta,
    grid: { width: 20, height: 20 },
    gridColor: GridColor.BLACK,
    timelineHeight: 180,
    displayGrid: false,
    animation: {
        usePixelValues: {},
        useDefaultAnimations: true
    }
};

export const defaultManageViewSettings: IManageViewSettings = {
    isPlaying: true
};

export const defaultTranslationPageSettings: ITranslationPageSettings = {
    layout: 'left',
    showAllElements: false,
    tabs: {}
};

export const defaultIgnoredWarnings: IIgnoredWarnings = {
    fontValidationWarnings: [],
    videoValidationWarnings: []
};

export const defaultSharedSettings: ISharedSettings = {
    lastLocation: 'none'
};

export const USER_SETTINGS_FEATURE_KEY = 'userSettings';

export interface UserSettingsPartialState {
    readonly [USER_SETTINGS_FEATURE_KEY]: UserSettingsState;
}

export interface UserSettingsState {
    manageView: IManageViewSettings;
    designView: IDesignViewSettings;
    translationPage: ITranslationPageSettings;
    ignoredWarnings: IIgnoredWarnings;
    sharedSettings: ISharedSettings;
}

export const initialState: UserSettingsState = {
    manageView: defaultManageViewSettings,
    designView: defaultDesignViewSettings,
    translationPage: defaultTranslationPageSettings,
    ignoredWarnings: defaultIgnoredWarnings,
    sharedSettings: defaultSharedSettings
};

export const reducer = createReducer<UserSettingsState>(
    initialState,
    on(
        UserActions.setDesignViewSetting,
        (state, { key, value }): UserSettingsState => ({
            ...state,
            designView: {
                ...state.designView,
                [key]: value
            }
        })
    ),

    on(
        UserActions.setAnimationSetting,
        (state, { key, value }): UserSettingsState => ({
            ...state,
            designView: {
                ...state.designView,
                animation: {
                    ...state.designView.animation,
                    [key]: value
                }
            }
        })
    ),

    on(
        UserActions.setIgnoreWarnings,
        (state, { key, value }): UserSettingsState => ({
            ...state,
            ignoredWarnings: {
                ...state.ignoredWarnings,
                [key]: value
            }
        })
    ),

    on(
        UserActions.setSharedSetting,
        (state, { key, value }): UserSettingsState => ({
            ...state,
            sharedSettings: {
                ...state.sharedSettings,
                [key]: value
            }
        })
    ),
    on(
        UserActions.toggleTranslationPageLayout,
        (state, { direction }): UserSettingsState => ({
            ...state,
            translationPage: {
                ...state.translationPage,
                layout: direction || (state.translationPage.layout === 'left' ? 'right' : 'left')
            }
        })
    ),

    on(
        UserActions.toggleTranslationPageTab,
        (state, { tab, creativesetId }): UserSettingsState => ({
            ...state,
            translationPage: {
                ...state.translationPage,
                tabs: {
                    ...state.translationPage.tabs,
                    [creativesetId]: tab
                }
            }
        })
    ),

    on(
        UserActions.toggleTranslationPageShowAllElements,
        (state): UserSettingsState => ({
            ...state,
            translationPage: {
                ...state.translationPage,
                showAllElements: !state.translationPage.showAllElements
            }
        })
    ),

    // Media library actions
    on(
        MediaLibraryActions.pin,
        (state): UserSettingsState => ({
            ...state,
            designView: {
                ...state.designView,
                isLibraryPinned: true
            }
        })
    ),
    on(
        MediaLibraryActions.unpin,
        (state): UserSettingsState => ({
            ...state,
            designView: {
                ...state.designView,
                isLibraryPinned: false
            }
        })
    ),
    on(
        MediaLibraryActions.open,
        (state, { kind }): UserSettingsState => ({
            ...state,
            designView: {
                ...state.designView,
                libraryKind: kind
            }
        })
    ),
    on(
        MediaLibraryActions.setSelectedFeedId,
        (state, { selectedFeedId }): UserSettingsState => ({
            ...state,
            designView: {
                ...state.designView,
                selectedFeedId
            }
        })
    )
);
