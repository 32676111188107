import { Component, inject, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIModule, UIPopoverTargetDirective } from '@bannerflow/ui';
import { KeymapOptionsPopoverComponent } from '../../../shared/components';
import { IntercomService } from '../../../core/plugins/intercom.service';

@Component({
    standalone: true,
    imports: [UIModule, CommonModule, KeymapOptionsPopoverComponent],
    selector: 'help-menu',
    templateUrl: './help-menu.component.html'
})
export class HelpMenuComponent {
    private keymapOptionsPopover = viewChild<KeymapOptionsPopoverComponent>('keymapOptionsPopover');
    private keymapOptionsPopoverTarget = viewChild<UIPopoverTargetDirective>(
        'keymapOptionsPopoverTarget'
    );

    private intercomService = inject(IntercomService);

    onIntercom(): void {
        this.intercomService.showNewMessage();
    }

    onIntercomNews(): void {
        this.intercomService.showIntercomNews();
    }

    onKeyboardShortcuts(): void {
        const keymapPopover = this.keymapOptionsPopover();
        const keymapPopoverTarget = this.keymapOptionsPopoverTarget();

        if (keymapPopover && keymapPopoverTarget) {
            keymapPopover.open(keymapPopoverTarget);
        }
    }
}
