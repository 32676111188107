import { CommonModule } from '@angular/common';
import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    inject,
    signal,
    viewChildren
} from '@angular/core';
import { VersionedStylingTargetsService } from '../versioned-styling.targets.service';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { TranslationPageState } from '../../../state/translation-page.reducer';
import { UIModule, UIPopoverTargetDirective } from '@bannerflow/ui';
import { tryGetFontStyleById } from '@creative/font-families.utils';
import { ICreativeset, IDesign, IElement } from '@domain/creativeset';
import { ICreative } from '@domain/creativeset/creative/creative';
import { IVersion, IVersionProperty, IVersionedText } from '@domain/creativeset/version';
import { ElementKind } from '@domain/elements';
import { IFontFamily, IFontFamilyStyle } from '@domain/font-families';
import { ITextElementDataNode, OneOfTextDataNodes } from '@domain/nodes';
import { SpanType } from '@domain/text';
import {
    GroupedElements,
    IMatContentSpan,
    IMatInputHighlightedArea,
    IMatInputTextSelection,
    PresetsContent,
    VersionToDirtyProperties
} from '@studio/domain/components/translation-page';
import { deepEqual, omit } from '@studio/utils/utils';
import { skip } from 'rxjs';
import { v4 } from 'uuid';
import { MatInputComponent } from '../../../../../shared/components/mat-input/mat-input.component';
import { CreativesService } from '../../../../../shared/creatives/state/creatives.service';
import { CreativesetDataService } from '../../../../../shared/creativeset/creativeset.data.service';
import { FontFamiliesService } from '../../../../../shared/font-families/state/font-families.service';
import { IconForElementPipe, VersionToLabelPipe } from '../../../pipes/index';
import { TranslationPageService } from '../../../state/translation-page.service';
import { getHighlightedAreasFor } from '../../../utils/feed.utils';
import { convertPresetsToContentSpan } from '../../../utils/mat-input.utils';
import {
    getVersionPropertyAndDirtyProperty,
    groupHasDirtyPropertyChanged
} from '../../../utils/tp.utils';
import { GroupInputHeaderComponent } from '../../group-input-header/group-input-header.component';
import { GroupOptionNumberComponent } from '../../group-option-number/group-option-number.component';
import { isVersionedText } from '@creative/elements/rich-text/text-nodes';

interface NewProperty {
    id: string;
    value: StylesBeforeMapping[];
}

interface StylesBeforeMapping {
    content: string;
    variable: boolean;
    style: {
        [key: string]: string;
    };
}

type VersionedStylingValue = {
    renderId: string; // used for tracking in template
    text: string | undefined;
    styledContent: IMatContentSpan[];
    highlightedAreas: IMatInputHighlightedArea[];
};

@Component({
    standalone: true,
    imports: [
        CommonModule,
        UIModule,
        IconForElementPipe,
        VersionToLabelPipe,
        MatInputComponent,
        GroupInputHeaderComponent,
        GroupOptionNumberComponent
    ],
    selector: 'versioned-styling',
    templateUrl: './versioned-styling.component.html',
    styleUrls: ['./versioned-styling.component.scss']
})
export class VersionedStylingComponent implements OnChanges, OnInit {
    private creativesService = inject(CreativesService);
    private creativesetDataService = inject(CreativesetDataService);
    private fontFamiliesService = inject(FontFamiliesService);
    private translationPageService = inject(TranslationPageService);
    private versionedStylingTargetsService = inject(VersionedStylingTargetsService);

    @Input() defaultVersion: IVersion;
    @Input() group: GroupedElements;
    @Input() selectedElement: IElement | undefined;
    @Input() version: IVersion;

    stylingPopoverTargets = viewChildren<UIPopoverTargetDirective>('stylingPopoverTarget');

    expanded = false;
    focusedElementId = toSignal(this.creativesService.focusedElementId$);
    inputsData = signal<VersionedStylingValue[]>([]);

    private creativeset: ICreativeset;
    private dirtyProperties: VersionToDirtyProperties;
    private fontFamilies: IFontFamily[] = [];
    private selectedCreatives: ICreative[];
    private selectedText: TranslationPageState['selectedText'];

    constructor() {
        this.creativesService.filteredCreatives$
            .pipe(takeUntilDestroyed())
            .subscribe(selectedCreatives => {
                this.selectedCreatives = selectedCreatives;
            });

        this.creativesetDataService.creativeset$.pipe(takeUntilDestroyed()).subscribe(creativeset => {
            this.creativeset = creativeset;
        });

        this.fontFamiliesService.fontFamilies$.pipe(takeUntilDestroyed()).subscribe(fontFamilies => {
            this.fontFamilies = fontFamilies;
        });

        this.translationPageService.selectedText$
            .pipe(takeUntilDestroyed(), skip(1))
            .subscribe(selectedText => {
                this.selectedText = selectedText;
                this.init(this.expanded);
            });

        this.translationPageService.dirtyProperties$
            .pipe(takeUntilDestroyed())
            .subscribe(dirtyProperties => {
                this.dirtyProperties = dirtyProperties;
                if (
                    groupHasDirtyPropertyChanged(
                        this.group,
                        this.dirtyProperties,
                        this.version,
                        this.defaultVersion
                    )
                ) {
                    this.init(this.expanded);
                }
            });
    }

    ngOnInit(): void {
        this.init();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['group']) {
            this.init();
        }
    }

    toggleExpand(expanded: boolean): void {
        this.expanded = expanded;
        this.init(expanded);
    }

    onSelectedText(
        { renderId }: VersionedStylingValue,
        event: IMatInputTextSelection | undefined,
        elementId: string
    ): void {
        if (!event?.text.length) {
            this.creativesService.blurElement();
            return;
        }
        this.creativesService.focusElement(elementId, this.version.id);
        const targets = this.stylingPopoverTargets();
        const targetToFind = `target-${renderId}`;
        const popoverTarget = targets.find(({ host }) => host.nativeElement.id === targetToFind);
        if (!popoverTarget) {
            return;
        }
        this.versionedStylingTargetsService.currentTarget.set(popoverTarget);
        this.translationPageService.setSelectedText({
            selection: event,
            groupId: this.group.id,
            elementId,
            versionId: this.version.id,
            expanded: this.expanded
        });
    }

    highlightElement(elementId: string | undefined, versionId: string): void {
        if (!this.expanded || this.group.elements.length === 1) {
            return;
        }
        if (elementId) {
            this.creativesService.focusElement(elementId, versionId);
        }
    }

    private init(expanded?: boolean): void {
        if (typeof expanded === 'undefined') {
            expanded = this.shouldExpandGroupByDefault();
            this.expanded = expanded;
        }
        const selectedElement = this.selectedElement ? [this.selectedElement] : undefined;
        if (selectedElement || expanded) {
            this.setValues(selectedElement ?? this.group.elements);
        } else {
            this.setGroupValues();
        }
    }

    private getPresetsContent(versionProperty: IVersionProperty<IVersionedText>): PresetsContent[] {
        const newProperty = this.mapPropertyToNewProperty(versionProperty);
        const selectedDesigns = this.selectedCreatives
            .map(({ design }) => design)
            .filter((design): design is IDesign => !!design);
        const styledContent = this.mapStyleIdsToStyles(newProperty, selectedDesigns);

        return styledContent.value;
    }

    private getStyledContent(
        presetsContent: PresetsContent[],
        elementId: string | undefined
    ): IMatContentSpan[] {
        const isSameGroup = this.group.id === this.selectedText?.groupId;
        // Valid only when expanded is true
        const isSameElement =
            !this.selectedText?.expanded || (this.selectedText.elementId === elementId && elementId);
        const selection =
            this.selectedText && isSameGroup && isSameElement ? this.selectedText.selection : undefined;
        return convertPresetsToContentSpan(presetsContent, selection);
    }

    private mapPropertyToNewProperty(property: IVersionProperty<IVersionedText>): NewProperty {
        const { text, styles } = property.value;
        const newProperty: NewProperty = {
            id: property.id,
            value: []
        };

        for (const style of styles) {
            const { position, length, styleIds } = style;
            const content = text.slice(position, position + length);

            newProperty.value.push({
                content,
                variable: style.type === SpanType.Variable,
                style: styleIds
            });
        }

        return newProperty;
    }

    private mapStyleIdsToStyles(
        newProperty: NewProperty,
        designs: IDesign[]
    ): { id: string; value: PresetsContent[] } {
        const styledValue: PresetsContent[] = [];
        for (const item of newProperty.value) {
            const element = this.getElementWithVersionProperty(newProperty.id, designs);
            if (!element) {
                continue;
            }
            const fontStyleId = element.__fontStyleId;
            let font: IFontFamilyStyle | undefined;
            if (fontStyleId) {
                font = tryGetFontStyleById(this.fontFamilies, fontStyleId);
            }

            let newItem: PresetsContent = {
                content: item.content,
                style: {
                    userSelect: item.variable ? 'all' : 'text',
                    fontSize: element.fontSize,
                    fontFamilyStyle: font,
                    underline: element.underline,
                    strikethrough: element.strikethrough,
                    color: element.textColor.toString(),
                    uppercase: element.uppercase,
                    characterSpacing: element.characterSpacing
                }
            };

            newItem = this.mergeStylesFromAllDesigns(item, newItem);

            styledValue.push(newItem);
        }
        return {
            id: newProperty.id,
            value: styledValue
        };
    }
    private mergeStylesFromAllDesigns(
        item: StylesBeforeMapping,
        newItem: PresetsContent
    ): PresetsContent {
        const newStyledContentSpan = {
            ...newItem
        };
        const selectedDesigns = this.selectedCreatives.map(({ design }) => design);
        for (const [documentId, styleId] of Object.entries(item.style)) {
            const matchingDesign = selectedDesigns.find(design => design?.document?.id === documentId);
            if (!matchingDesign) {
                continue;
            }
            for (const designEl of matchingDesign.document?.elements ?? []) {
                const designElement = designEl as ITextElementDataNode;
                const characterStyles = designElement.characterStyles;
                const styleValue = characterStyles?.get(styleId);
                if (!styleValue) {
                    continue;
                }
                let fontFromCharacterStyle: IFontFamilyStyle | undefined;
                const fontStyleIdFromCharacterStyle = styleValue.font?.id;

                if (fontStyleIdFromCharacterStyle) {
                    fontFromCharacterStyle = tryGetFontStyleById(
                        this.fontFamilies,
                        fontStyleIdFromCharacterStyle
                    );
                }

                newStyledContentSpan.style = {
                    userSelect: item.variable ? 'all' : 'text',
                    fontSize: styleValue.fontSize
                        ? styleValue.fontSize * designElement.fontSize
                        : designElement.fontSize,
                    defaultFontSize: designElement.fontSize,
                    fontFamilyStyle: fontFromCharacterStyle ?? newItem.style.fontFamilyStyle,
                    underline: styleValue.underline ?? newItem.style.underline,
                    strikethrough: styleValue.strikethrough ?? newItem.style.strikethrough,
                    color: styleValue.textColor?.toString() ?? newItem.style.color,
                    uppercase: styleValue.uppercase ?? newItem.style.uppercase,
                    characterSpacing: styleValue.characterSpacing ?? newItem.style.characterSpacing
                };
            }
        }
        return newStyledContentSpan;
    }

    private getElementWithVersionProperty(
        versionPropertyId: string,
        designs: IDesign[]
    ): OneOfTextDataNodes | undefined {
        // Get the elementId
        const elementId = this.creativeset.elements.find(element =>
            element.properties.some(
                elementProperty => elementProperty.versionPropertyId === versionPropertyId
            )
        )?.id;
        if (!elementId) {
            return;
        }
        for (const design of designs) {
            for (const element of design.document?.elements ?? []) {
                if (element?.kind !== ElementKind.Text && element?.kind !== ElementKind.Button) {
                    continue;
                }
                if (element.id === elementId) {
                    return element;
                }
            }
        }
    }
    private setGroupValues(): void {
        let groupedStyledContent: IMatContentSpan[] = [];
        let groupedVersionProperty: IVersionProperty<IVersionedText> | undefined;
        for (const element of this.group.elements) {
            const { versionProperty, dirtyVersionProperty } = this.getVersionProperty(element);

            const presetsContent = this.getPresetsContent(dirtyVersionProperty ?? versionProperty);

            const styledContent = this.getStyledContent(presetsContent, undefined);

            if (groupedStyledContent.length < styledContent.length) {
                groupedStyledContent = styledContent;
                groupedVersionProperty = dirtyVersionProperty ?? versionProperty;
            }
        }
        if (groupedVersionProperty) {
            const highlightedAreas = getHighlightedAreasFor(groupedVersionProperty);
            this.inputsData.set([
                {
                    renderId: v4(),
                    text: this.group.value,
                    styledContent: groupedStyledContent,
                    highlightedAreas
                }
            ]);
        }
    }

    private setValues(elements: IElement[]): void {
        const values: VersionedStylingValue[] = [];
        for (const element of elements) {
            const { versionProperty, dirtyVersionProperty } = this.getVersionProperty(element);

            const highlightedAreas = getHighlightedAreasFor(dirtyVersionProperty ?? versionProperty);

            const textValue = (dirtyVersionProperty ?? versionProperty).value.text;
            const presetsContent = this.getPresetsContent(dirtyVersionProperty ?? versionProperty);
            const styledContent = this.getStyledContent(presetsContent, element.id);
            values.push({
                renderId: v4(),
                text: textValue,
                styledContent,
                highlightedAreas
            });
        }
        this.inputsData.set(values);
    }

    private getVersionProperty(element: IElement): {
        versionProperty: IVersionProperty<IVersionedText>;
        dirtyVersionProperty: IVersionProperty<IVersionedText> | undefined;
    } {
        const property = element.properties[0];
        if (!property.versionPropertyId) {
            throw new Error('Element has no versionPropertyId');
        }
        const { versionProperty, dirtyVersionProperty } = getVersionPropertyAndDirtyProperty(
            property.versionPropertyId,
            this.version,
            this.defaultVersion,
            this.dirtyProperties
        );
        if (!isVersionedText(versionProperty)) {
            throw new Error('Wrong version property');
        }
        if (dirtyVersionProperty && !isVersionedText(dirtyVersionProperty)) {
            throw new Error('Wrong dirty version property');
        }
        return {
            versionProperty,
            dirtyVersionProperty
        };
    }

    private shouldExpandGroupByDefault(): boolean {
        if (this.group.elements.length <= 1 || this.selectedElement) {
            return false;
        }
        // Need to skip FontSize values since they vary depending sizes
        const skipFontSize = (
            preset: PresetsContent
        ): {
            content: PresetsContent['content'];
            style: Omit<PresetsContent['style'], 'fontSize' | 'defaultFontSize'>;
        } => ({
            ...preset,
            style: omit(preset.style, 'fontSize', 'defaultFontSize')
        });
        const { versionProperty: baseVersionProperty } = this.getVersionProperty(
            this.group.elements[0]
        );
        const basePresetsContent = this.getPresetsContent(baseVersionProperty).map(skipFontSize);
        for (const element of this.group.elements.slice(1)) {
            const { versionProperty } = this.getVersionProperty(element);
            const presetsContent = this.getPresetsContent(versionProperty).map(skipFontSize);
            if (!deepEqual(basePresetsContent, presetsContent)) {
                return true;
            }
        }
        return false;
    }
}
