import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { provideCreativeConverterStore } from './pages/manage-view/creative-converter/state/creative-converter.provider';
import { provideDuplicateCreativesStore } from './pages/manage-view/duplicate-creatives/state/duplicate-creative.provider';
import { provideSizeAddStore } from './pages/manage-view/size-add-dialog/state/size-add.provider';
import { metaReducers } from './pages/state/local-storage';
import { provideTranslationPageStore } from './pages/translation-page/state/translation-page.provider';
import { provideGenAIStore } from './shared/ai-studio/state/gen-ai.provider';
import { provideAnimationControlStore } from './shared/animation-control/state/animation-control.provider';
import { provideBrandStore } from './shared/brand/state/brand.provider';
import { provideCreativesStore } from './shared/creatives/state/creatives.provider';
import { provideCreativeSetShowcaseStore } from './shared/creativeset-showcase/state/creativeset-showcase.provider';
import { provideCreativeSetStore } from './shared/creativeset/state/creativeset.provider';
import { provideDisplayCampaignStore } from './shared/display-campaign/state/display-campaign.provider';
import { provideFiltersStore } from './shared/filters/state/filters.provider';
import { provideFontFamiliesStore } from './shared/font-families/state/font-families.provider';
import { provideMediaLibraryStore } from './shared/media-library/state/media-library.provider';
import { provideUserSettingsStore } from './shared/user-settings/state/user-settings.provider';
import { provideUserStore } from './shared/user/state/user.provider';
import { provideVersionsStore } from './shared/versions/state/versions.provider';

export function provideNgRxStores(): EnvironmentProviders[] {
    return [
        importProvidersFrom([
            StoreModule.forRoot(
                {},
                {
                    metaReducers,
                    runtimeChecks: {
                        strictActionImmutability: true,
                        strictStateImmutability: true
                    }
                }
            ),
            EffectsModule.forRoot([]),
            StoreDevtoolsModule.instrument({
                logOnly: environment.production
            }),
            StoreRouterConnectingModule.forRoot()
        ]),
        provideAnimationControlStore(),
        provideBrandStore(),
        provideCreativeConverterStore(),
        provideCreativeSetShowcaseStore(),
        provideCreativeSetStore(),
        provideCreativesStore(),
        provideCreativesStore(),
        provideDisplayCampaignStore(),
        provideDuplicateCreativesStore(),
        provideFiltersStore(),
        provideFontFamiliesStore(),
        provideGenAIStore(),
        provideMediaLibraryStore(),
        provideSizeAddStore(),
        provideTranslationPageStore(),
        provideUserSettingsStore(),
        provideUserStore(),
        provideVersionsStore()
    ];
}
