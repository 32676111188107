import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    HostListener,
    OnDestroy,
    computed,
    inject,
    viewChild
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UIDialogComponent, UIModule, UINotificationService } from '@bannerflow/ui';
import { ISelectableSize, ISizeList } from '@studio/domain/components/size-list.types';
import { SelectedSizes } from '@studio/domain/state';
import { FeatureFlagsService, StudioFeatureFlags } from '@studio/feature-flags';
import { NodeCreatorService } from '../../design-view/services/data-node-creator.service';
import { CreativeConverterUploadService } from '../creative-converter/creative-converter-upload.service';
import { CreativeConverterService } from '../creative-converter/creative-converter.service';
import { PsdFixFontPanelComponent } from '../creative-converter/psd/psd-fix-font-panel/psd-fix-font-panel.component';
import { PsdImportPreviewComponent } from '../creative-converter/psd/psd-import-preview/psd-import-preview.component';
import { PsdImportService } from '../creative-converter/psd/psd-import.service';
import { PsdListComponent } from '../creative-converter/psd/psd-list/psd-list.component';
import { SizeAddCollectionListComponent } from './size-add-collection-list/size-add-collection-list.component';
import { SizeAddCollectionOverviewComponent } from './size-add-collection-overview/size-add-collection-overview.component';
import { SizeAddCollectionSelectComponent } from './size-add-collection-select/size-add-collection-select.component';
import { SizeAddCustomInputComponent } from './size-add-custom-input/size-add-custom-input.component';
import { SizeAddOverviewComponent } from './size-add-overview/size-add-overview.component';
import { SizeAddPreviewComponent } from './size-add-preview/size-add-preview.component';
import { SizeAddTabsComponent } from './size-add-tabs/size-add-tabs.component';
import { SizeAddService } from './size-add.service';
import { SizeListComponent } from './size-list/size-list.component';
import { SizeAddStateService } from './state/size-add.service';

enum Tabs {
    SIZE_ADD = 0,
    PSD_IMPORT = 1
}

@Component({
    standalone: true,
    imports: [
        CommonModule,
        PsdFixFontPanelComponent,
        PsdImportPreviewComponent,
        PsdListComponent,
        SizeAddCollectionListComponent,
        SizeAddCollectionOverviewComponent,
        SizeAddCollectionSelectComponent,
        SizeAddCustomInputComponent,
        SizeAddOverviewComponent,
        SizeAddPreviewComponent,
        SizeAddTabsComponent,
        SizeListComponent,
        UIModule
    ],
    selector: 'size-add-dialog',
    templateUrl: './size-add-dialog.component.html',
    styleUrls: ['./size-add-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[attr.data-test-id]': '"size-add-dialog"'
    },
    providers: [
        PsdImportService,
        CreativeConverterUploadService,
        CreativeConverterService,
        NodeCreatorService
    ]
})
export class SizeAddDialogComponent implements OnDestroy {
    private dialog = inject(UIDialogComponent);
    private featureFlagsService = inject(FeatureFlagsService);
    private sizeAddService = inject(SizeAddService);
    private sizeAddStateService = inject(SizeAddStateService);
    private uiNotificationService = inject(UINotificationService);

    private customSizeInputComp = viewChild.required(SizeAddCustomInputComponent);

    addedSizes = computed(() => this.computeAddedSizes());
    sizeCategories = computed(() => this.sizeAddService.sizeCategories());
    sizeCollectionEnabled = toSignal(
        this.featureFlagsService.isEnabled$(StudioFeatureFlags.SizeCollections),
        { initialValue: false }
    );

    Tabs = Tabs;
    selectedTab: Tabs = Tabs.SIZE_ADD;

    private sizes = toSignal(this.sizeAddStateService.sizes$, { initialValue: [] });
    private _selectedSizes = toSignal(this.sizeAddStateService.selectedSizes$, {
        initialValue: {} as SelectedSizes
    });

    @HostListener('document:keydown.escape') onEscape(): void {
        const isCustomInputFocused = this.customSizeInputComp().isFocused();
        if (!isCustomInputFocused) {
            this.close();
        }
    }

    ngOnDestroy(): void {
        this.sizeAddStateService.reset();
        this.uiNotificationService.close();
        this.deselectAllSizes();
    }

    close(): void {
        this.dialog.close();
    }

    selectTab(tab: Tabs): void {
        this.selectedTab = tab;
        this.deselectAllSizes();
    }

    deselectAllSizes(): void {
        this.sizeAddService.deselectAllSizes();
    }

    selectSize(size: ISelectableSize): void {
        this.sizeAddService.selectSize(size);
    }

    selectedSizes(selectedSizes: ISelectableSize[], category: ISizeList): void {
        this.sizeAddService.selectedSizes(selectedSizes, category);
    }

    toggleAllSizes(): void {
        this.sizeAddService.toggleAllSizes();
    }

    addCustomSize(customSize: ISelectableSize): void {
        this.sizeAddService.addCustomSize(customSize);
        this.customSizeInputComp().resetInputs();
    }

    addSizeToCustomSizesCollection(customSize: ISelectableSize): void {
        this.sizeAddStateService.addSizeToCustomSizesCollection(customSize);
    }

    private computeAddedSizes(): ISelectableSize[] {
        const sizeCollectionEnabled = this.sizeCollectionEnabled();
        if (!sizeCollectionEnabled) {
            return this.sizeAddService.addedSizes();
        }
        const sizes = this.sizes();
        const selectedSizes = this._selectedSizes();

        const addedSizes: ISelectableSize[] = [];

        for (const size of sizes) {
            const numberOfSizes = selectedSizes[size.id]?.numberOfSizes ?? 0;

            if (numberOfSizes) {
                addedSizes.push(...Array(numberOfSizes).fill(size));
            }
        }

        return addedSizes;
    }
}
