import { Pipe, PipeTransform } from '@angular/core';
import { isVersionedFeed } from '@creative/elements/feed/feeds.utils';
import { isVersionedText } from '@creative/elements/rich-text/text-nodes';
import { GroupedElements, IVersionedElementPropertyValuesByElement } from '@domain/creativeset/version';
import { IBfFeed, IFeed } from '@domain/feed';
import { SpanType } from '@domain/text';

@Pipe({ standalone: true, name: 'groupfeeds' })
export class GroupFeedsPipe implements PipeTransform {
    transform(
        elements: IVersionedElementPropertyValuesByElement[],
        feeds: IBfFeed[]
    ): GroupedElements[] {
        const groupedElements = new Map<string, GroupedElements>();
        for (const element of elements) {
            for (const property of element.properties) {
                if (isVersionedText(property) && property.value.styles) {
                    const feed = property.value.styles.find(
                        style => style.type === SpanType.Variable && style.variable?.id
                    )?.variable;
                    if (!feed) {
                        continue;
                    }
                    this.setElements(groupedElements, element, feed, feeds);
                } else if (isVersionedFeed(property)) {
                    this.setElements(groupedElements, element, property.value, feeds);
                }
            }
        }
        return Array.from(groupedElements.values());
    }

    private setElements(
        elementsMap: Map<string, GroupedElements>,
        element: IVersionedElementPropertyValuesByElement,
        feed: IFeed,
        feeds: IBfFeed[]
    ): void {
        const feedHash = this.feedToHash(feed);

        const elementInMap = elementsMap.get(feedHash);
        if (elementInMap) {
            element.feedName = elementInMap.elements[0].feedName;
            element.feedElement = elementInMap.elements[0].feedElement;
            elementInMap.elements.push(element);
            return;
        }

        const feedId = feed.id;
        element.feedName = findFeed(feeds, feedId)?.name ?? '(Feed not selected)';
        element.feedElement = feed;

        elementsMap.set(feedHash, {
            elements: [element],
            isExpanded: false
        });
    }

    private feedToHash(feed: IFeed): string {
        return `${feed.id}`;
    }
}

function findFeed(feeds: IBfFeed[], feedId: string): IBfFeed | undefined {
    for (const feed of feeds) {
        if (feed.id === feedId) {
            return feed;
        }
        if (feed.children?.length) {
            const foundFeed = findFeed(feed.children, feedId);
            if (foundFeed) {
                return foundFeed;
            }
        }
    }
}
