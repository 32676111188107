import {
    createRichTextFromSegments,
    createRichTextFromString,
    isVersionedText
} from '@creative/elements/rich-text/text-nodes';
import {
    createVersionedTextFromText,
    CreativeDataNode,
    isCustomFeedPropertyDto,
    isCustomFeedPropertyOverrideDto,
    isCustomTextPropertyDto,
    isCustomTextPropertyOverrideDto,
    isDapiEllipseNodeDto,
    isDapiGroupNodeDto,
    isDapiImageNodeDto,
    isDapiRectangleNodeDto,
    isDapiTextLikeNodeDto,
    isDapiVideoNodeDto,
    isDapiWidgetNodeDto,
    isDapiWidgetWithAssetNodeDto,
    isTextNode
} from '@creative/nodes';
import {
    deserializeDesignDocument,
    deserializeGuidelines,
    deserializeSocialGuide
} from '@creative/serialization';
import { deserializeWidgetText } from '@creative/serialization/text-serializer';
import {
    DesignApiOverrideElement,
    OneOfCustomPropertyDtos,
    OneOfElementDtos
} from '@domain/api/design-api.interop';
import {
    CreativeDto,
    CreativeSetDto,
    DesignDto,
    ElementOverrideDto,
    ImageElementDto,
    SizeDto,
    TextLikeElementDto,
    TextLikeElementOverrideDto,
    VersionDto,
    VideoElementDto,
    WidgetElementDto,
    WidgetElementOverrideDto
} from '@domain/api/generated/design-api';
import {
    CharacterStyleDto,
    DocumentDto,
    ImageAssetDataDto,
    CreativeDto as SapiCreativeDto,
    WidgetElementDto as SapiWidgetElementDto,
    VideoAssetDataDto
} from '@domain/api/generated/sapi';
import { ImageLibraryAsset } from '@domain/brand/brand-library/image-asset';
import { VideoLibraryAsset } from '@domain/brand/brand-library/video-asset';
import { IWidgetLibraryAsset } from '@domain/brand/brand-library/widget-asset';
import {
    CreativeSize,
    ElementPropertyUnit,
    IDesign,
    IElement,
    IElementProperty
} from '@domain/creativeset';
import { ApprovalStatus, ICreative } from '@domain/creativeset/creative/creative';
import { AssetReference } from '@domain/creativeset/element-asset';
import { IVersion, IVersionProperty, IWidgetText } from '@domain/creativeset/version';
import { ElementKind } from '@domain/elements';
import { OneOfNodesDto } from '@domain/serialization';
import { ITextVariable, SpanType } from '@domain/text';
import { WidgetUnitValues } from '@domain/widget';
import { distinctArrayById } from '@studio/utils/array';
import { createElement, createElementProperty } from '@studio/utils/element.utils';
import { uuidv4 } from '@studio/utils/id';
import { omit } from '@studio/utils/utils';
import {
    CompileElementOptions,
    FlatElementOverride,
    InferredCustomPropertyOverride,
    InferredElementOverride,
    OverrideOptions,
    RemovablePropertyKeys,
    RemovablePropertyValues
} from './design-api.types';
import { IFeed } from '@domain/feed';

export function convertPoolElementToElement(element: OneOfElementDtos): IElement {
    return createElement({
        id: element.id,
        name: element.name,
        type: getKindFromDesignApiElementForCreativesetElement(element),
        properties: getPropertiesFromDapiElementDto(element)
    });
}

export function getConvertedCreativesAndAssets(
    creativeSetDto: CreativeSetDto,
    versions: IVersion[],
    elements: IElement[],
    designs: IDesign[],
    creativeSizes: CreativeSize[]
): {
    creatives: ICreative[];
    imageAssets: ImageLibraryAsset[];
    videoAssets: VideoLibraryAsset[];
    widgetAssets: IWidgetLibraryAsset[];
} {
    const imageAssets: ImageLibraryAsset[] = getImageLibraryAssetFromElementDtos(
        creativeSetDto.elementsPool
    );
    const videoAssets: VideoLibraryAsset[] = getVideoLibraryAssetFromElementDtos(
        creativeSetDto.elementsPool
    );

    // sometimes we can have widget element in design, but widget asset is missing in widgets array.
    // In this case we have decided to skip this items as it was originally (647956, 648039, 648041)
    const widgetAssets = creativeSetDto.elementsPool
        .filter(isDapiWidgetWithAssetNodeDto)
        .map(element => element.widgetAsset);

    const creatives = creativeSetDto.creatives.map((creative): ICreative => {
        const creativeSize = creativeSizes.find(({ id }) => id === `${creative.sizeId}`)!;
        const size = creativeSetDto.sizes.find(({ id }) => id === creative.sizeId)!;
        const version = versions.find(({ id }) => id === `${creative.versionId}`)!;
        const apiElements = compileDesignApiElements(creativeSetDto, creative);
        const designDto = creative.design;

        imageAssets.push(...getImageLibraryAssetFromElementDtos(apiElements));
        videoAssets.push(...getVideoLibraryAssetFromElementDtos(apiElements));

        const SAPIDesignDocumentDto = designDto
            ? createSapiDocument(apiElements, designDto, creative, size, version)
            : undefined;

        const creativeDataNode = SAPIDesignDocumentDto
            ? deserializeDesignDocument(SAPIDesignDocumentDto)
            : undefined;

        let design: IDesign | undefined;

        if (creativeDataNode && designDto?.legacy_DesignId) {
            design = designs.find(({ id }) => id === designDto.legacy_DesignId);
            if (design) {
                mergeDesignElementCharacterStyles(creativeDataNode, design);
            } else {
                design = {
                    id: `${designDto.legacy_DesignId}`,
                    elements: elements.filter(element => size.elements[element.id]),
                    hasHeavyVideo: designDto.hasHeavyVideo,
                    name: designDto.name,
                    document: creativeDataNode
                };
                designs.push(design);
            }
        }

        return {
            id: `${creative.id}`,
            checksum: creative.checksum,
            approvalStatus: creative.approvalStatus as ApprovalStatus,
            connectedCampaigns: [],
            size: creativeSize,
            design,
            version,
            targetUrl: creative.targetUrl
        };
    });

    return {
        creatives,
        imageAssets: distinctArrayById(imageAssets),
        videoAssets: distinctArrayById(videoAssets),
        widgetAssets: distinctArrayById(widgetAssets)
    };
}

export function convertVersion(
    version: VersionDto,
    elements: IElement[],
    creativesetDto: CreativeSetDto
): IVersion {
    const { id: versionId, name, targetUrl, elements: versionElements } = version;
    const migratedVersion: IVersion = {
        id: `${versionId}`,
        localization: {
            id: version.localizationId
        },
        name,
        targetUrl,
        properties: []
    };

    for (const elementId of Object.keys(versionElements)) {
        const poolElement = getPoolElement(creativesetDto, elementId);
        if (!poolElement) {
            continue;
        }

        const element = elements.find(({ id }) => id === poolElement.id)!;

        if (isDapiTextLikeNodeDto(poolElement)) {
            const versionElement = getElementOverride(poolElement, { version });
            const versionProperty = getTextLikeVersionProperty(versionElement, element);
            migratedVersion.properties.push(versionProperty);
            const existingElementProperty = element.properties.find(
                p => p.versionPropertyId === versionProperty.id
            );
            if (!existingElementProperty) {
                element.properties.push(
                    createElementProperty({
                        id: poolElement.legacy_TextElementPropertyId,
                        name: 'content',
                        versionPropertyId: versionProperty.id,
                        value: versionProperty.id ? undefined : '' // Comparison tool expects empty string due to that being persisted for SAPI, but we don't need to set value if version exists
                    })
                );
            }
        } else if (isDapiWidgetNodeDto(poolElement)) {
            const versionElement = getElementOverride(poolElement, { version });

            if (!versionElement.customProperties) {
                continue;
            }

            const widgetVersionProperties = getWidgetVersionProperties(versionElement);
            widgetVersionProperties.forEach(vp => migratedVersion.properties.push(vp));
        }
    }

    return migratedVersion;
}

export function getPropertiesFromDapiElementDto(element: OneOfElementDtos): IElementProperty[] {
    if (isDapiWidgetNodeDto(element)) {
        return getWidgetElementProperties(element);
    }

    if (isDapiVideoNodeDto(element)) {
        return getVideoElementProperties(element);
    }

    if (isDapiImageNodeDto(element)) {
        return getImageElementProperties(element);
    }

    return [];
}

export function getWidgetElementProperties(element: WidgetElementDto): IElementProperty[] {
    return (
        element.legacy_WidgetProperties?.map(property =>
            createElementProperty({
                clientId: uuidv4(),
                id: property.id,
                name: property.name,
                unit: property.unit?.toLowerCase() as ElementPropertyUnit,
                value: property.value,
                versionPropertyId: property.versionPropertyId,
                label: property.label
            })
        ) || []
    );
}

export function getVideoElementProperties(element: VideoElementDto): IElementProperty[] {
    if (element.legacy_VideoElementPropertyId) {
        return [
            createElementProperty({
                clientId: uuidv4(),
                id: element.legacy_VideoElementPropertyId,
                unit: 'id',
                name: AssetReference.Video,
                value: element.videoAsset?.id.toString()
            })
        ];
    }

    return [];
}

export function getImageElementProperties(element: ImageElementDto): IElementProperty[] {
    if (element.legacy_ImageElementPropertyId) {
        return [
            createElementProperty({
                clientId: uuidv4(),
                id: element.legacy_ImageElementPropertyId,
                unit: 'id',
                name: AssetReference.Image,
                value: element.imageAsset?.id.toString()
            })
        ];
    }

    return [];
}

export function getTextLikeVersionProperty(
    textLikeElement: TextLikeElementDto | TextLikeElementOverrideDto,
    element: IElement
): IVersionProperty {
    const existingProperty = element?.properties.find(({ name }) => name === 'content');

    const versionPropertyId =
        existingProperty?.versionPropertyId ?? textLikeElement.legacy_VersionPropertyId;

    if (!versionPropertyId) {
        throw new Error('VersionPropertyId not found');
    }

    const versionProperty: IVersionProperty = {
        id: versionPropertyId,
        name: 'content',
        value: createVersionedTextFromText(
            createRichTextFromSegments(textLikeElement.textSegments ?? [])
        )
    };

    return versionProperty;
}

export function getWidgetVersionProperties(
    widgetElement: WidgetElementOverrideDto
): IVersionProperty[] {
    const versionProperties: IVersionProperty[] = [];
    const customProperties = widgetElement.customProperties || [];

    for (const customProperty of customProperties) {
        if (customProperty.value === undefined) {
            continue;
        }

        if (isCustomTextPropertyOverrideDto(customProperty)) {
            const versionPropertyId = customProperty.legacy_VersionPropertyId;
            if (!versionPropertyId) {
                throw new Error('VersionPropertyId not found');
            }

            let propertyName: string;
            let textValue: IWidgetText;
            if (customProperty.legacy_VersionPropertyName === 'widgetText') {
                propertyName = 'widgetText';
                textValue = deserializeWidgetText(customProperty.value);
                // looks like we still have corrupted data for widgetText, COBE is aware of it, and they will fix it soon
                // for now let's have this dirty fix, we styles exists then property type will be content
                if (textValue['styles'] !== undefined) {
                    delete textValue['styles'];
                }
            } else {
                propertyName = 'content';
                textValue = createVersionedTextFromText(createRichTextFromString(customProperty.value));
            }

            const versionProperty: IVersionProperty = {
                id: versionPropertyId,
                name: propertyName,
                value: textValue
            };

            versionProperties.push(versionProperty);
        } else if (isCustomFeedPropertyOverrideDto(customProperty)) {
            const versionPropertyId = customProperty.legacy_VersionPropertyId;
            if (!versionPropertyId) {
                throw new Error('VersionPropertyId not found');
            }
            const feedValue: IFeed = {
                id: customProperty.value.id,
                path: customProperty.value.path,
                step: customProperty.value.step,
                fallback: customProperty.value.fallback,
                type: customProperty.value.type
            };
            const versionProperty: IVersionProperty = {
                id: versionPropertyId,
                name: 'feed',
                value: feedValue
            };

            versionProperties.push(versionProperty);
        }
    }
    return versionProperties;
}

export function populateCharacterStylesByTextSegment(
    textElement: TextLikeElementDto,
    version: IVersion,
    documentId: string
): CharacterStyleDto[] {
    const segments = textElement.textSegments;
    const versionProperty = version.properties.find(
        ({ id }) => id === textElement.legacy_VersionPropertyId
    );

    const characterStyles: CharacterStyleDto[] = [];

    if (!versionProperty || !isVersionedText(versionProperty)) {
        return [];
    }

    for (let i = 0; i < segments.length; i++) {
        // We have no other way to match a segment to a span on vp property than by index.
        // Assuming that all spans are present in vp prop & as segment no matter if it has a style or not
        const versionedStyle = versionProperty.value.styles[i];
        if (versionedStyle) {
            const segment = segments[i];

            // Apply character styles to node
            const styleId = segment.legacy_StyleId;

            if (styleId) {
                versionedStyle.styleIds[`${documentId}`] = styleId;
                const characterStyle: CharacterStyleDto = { id: styleId, value: segment.value };
                characterStyle.value.variable = segment.variable;
                characterStyles.push(characterStyle);
            }

            versionedStyle.variable = segment.variable as ITextVariable;
            if (segment.type === SpanType.Variable) {
                versionedStyle.type = SpanType.Variable;
            }
        }
    }

    return characterStyles;
}

export function createSapiDocument(
    elementDtos: OneOfElementDtos[],
    designDto: DesignDto,
    creative: CreativeDto,
    size: SizeDto,
    version: IVersion
): DocumentDto {
    const imageAssets = getImageAssetsDataFromElementDtos(elementDtos);
    const videoAssets = getVideoAssetsDataFromElementDtos(elementDtos);

    const elements = elementDtos.map(element => convertToStudioElement(element, creative, version));

    const creativeDto: SapiCreativeDto = {
        id: `${creative.design?.legacy_DocumentId}`,
        width: size.width,
        height: size.height,
        fill: designDto.fill,
        startTime: designDto.startTime,
        stopTime: designDto.stopTime,
        gifExport: designDto.gifExport,
        guidelines: deserializeGuidelines(designDto.guidelines),
        socialGuide: deserializeSocialGuide(designDto.socialGuide),
        loops: designDto.loops,
        preloadImage: designDto.preloadImage,
        elements
    };

    return {
        head: {
            asset: {
                images: imageAssets,
                videos: videoAssets
            },
            elements: elementDtos.map(element => ({
                id: element.id,
                type: getKindFromDesignApiElement(element)
            }))
        },
        body: {
            creative: creativeDto
        }
    };
}

export function getVideoAssetsDataFromElementDtos(
    elementDtos: OneOfElementDtos[]
): VideoAssetDataDto[] {
    const videoAssets: VideoAssetDataDto[] = [];

    for (const element of elementDtos) {
        if (isDapiVideoNodeDto(element) && element.videoAsset) {
            const videoAsset = element.videoAsset;
            videoAssets.push({
                id: videoAsset.id.toString(),
                name: videoAsset.name,
                fileSize: videoAsset.fileSize,
                height: videoAsset.height,
                url: videoAsset.url,
                width: videoAsset.width
            });
        }
    }

    return videoAssets;
}

export function getImageAssetsDataFromElementDtos(
    elementDtos: OneOfElementDtos[]
): ImageAssetDataDto[] {
    const imageAssets: ImageAssetDataDto[] = [];

    for (const element of elementDtos) {
        if (isDapiImageNodeDto(element) && element.imageAsset) {
            const imageAsset = element.imageAsset;
            imageAssets.push({
                id: imageAsset.id.toString(),
                height: imageAsset.original.height,
                src: imageAsset.original.url,
                width: imageAsset.original.width,
                name: imageAsset.name
            });
        }
    }

    return imageAssets;
}

export function getImageLibraryAssetFromElementDtos(
    elementDtos: OneOfElementDtos[]
): ImageLibraryAsset[] {
    const imageAssets: ImageLibraryAsset[] = [];

    for (const element of elementDtos) {
        if (isDapiImageNodeDto(element) && element.imageAsset) {
            const imageAsset = element.imageAsset;
            imageAssets.push({
                ...imageAsset,
                id: `${imageAsset.id}`,
                height: imageAsset.original.height,
                url: imageAsset.original.url,
                width: imageAsset.original.width
            });
        }
    }

    return imageAssets;
}

export function getVideoLibraryAssetFromElementDtos(
    elementDtos: OneOfElementDtos[]
): VideoLibraryAsset[] {
    const videoAssets: VideoLibraryAsset[] = [];

    for (const element of elementDtos) {
        if (isDapiVideoNodeDto(element) && element.videoAsset) {
            const videoAsset = element.videoAsset;
            videoAssets.push({
                ...videoAsset,
                id: `${videoAsset.id}`
            });
        }
    }

    return videoAssets;
}

export function getPoolElement<ElementDto extends OneOfElementDtos>(
    creativeSetDto: CreativeSetDto,
    elementId: string
): ElementDto {
    const poolElement = creativeSetDto.elementsPool.find(({ id }) => id === elementId);

    if (!poolElement) {
        throw new Error(`Element with id ${elementId} does not exist in Creativeset.ElementPool.`);
    }

    return poolElement as ElementDto;
}

export function compileDesignApiElements(
    creativeSetDto: CreativeSetDto,
    creative: CreativeDto
): OneOfElementDtos[] {
    const size = creativeSetDto.sizes.find(({ id }) => id === creative.sizeId)!;

    const sortedElementIds = Object.keys(size.elements).sort((a, b) => {
        const sortIndexA = size.elements[a]?.sortIndex ?? 0;
        const sortIndexB = size.elements[b]?.sortIndex ?? 0;
        return sortIndexA - sortIndexB;
    });

    return sortedElementIds.map(elementId =>
        compileDesignApiElement(creativeSetDto, { elementId, creative, size })
    );
}

function compileDesignApiElement(
    creativeSetDto: CreativeSetDto,
    { elementId, creative, size }: CompileElementOptions
): OneOfElementDtos {
    const versionId = creative.versionId;
    const version = creativeSetDto.versions.find(({ id }) => id === versionId);

    if (!version) {
        throw new Error();
    }

    const poolElement = getPoolElement(creativeSetDto, elementId);

    if (isDapiWidgetNodeDto(poolElement)) {
        return getCompiledWidgetElement(poolElement, { size, creative, version });
    }

    const override = getElementOverride(poolElement, { size, creative, version });

    return mergePoolElementWithOverride(poolElement, override);
}

function getCompiledWidgetElement(
    poolWidgetElement: WidgetElementDto,
    { creative, size, version }: OverrideOptions
): WidgetElementDto {
    const override = getElementOverride(poolWidgetElement, { size, creative, version });
    override.customProperties = poolWidgetElement.customProperties.map(customProperty => {
        return getCustomPropertyOverride(customProperty, override);
    });

    return mergePoolElementWithOverride(poolWidgetElement, override);
}

export function getKindFromDesignApiElementForCreativesetElement(
    element: OneOfElementDtos
): ElementKind {
    if (isDapiWidgetNodeDto(element)) {
        return element.type as ElementKind;
    }

    return getKindFromDesignApiElement(element);
}

export function getKindFromDesignApiElement(element: OneOfElementDtos): ElementKind {
    if (isDapiWidgetNodeDto(element)) {
        return ElementKind.Widget;
    }

    if (isDapiTextLikeNodeDto(element)) {
        return element.isButton ? ElementKind.Button : ElementKind.Text;
    }

    if (isDapiEllipseNodeDto(element)) {
        return ElementKind.Ellipse;
    }

    if (isDapiGroupNodeDto(element)) {
        return ElementKind.Group;
    }

    if (isDapiRectangleNodeDto(element)) {
        return ElementKind.Rectangle;
    }

    if (isDapiImageNodeDto(element)) {
        return ElementKind.Image;
    }

    if (isDapiVideoNodeDto(element)) {
        return ElementKind.Video;
    }

    throw new Error(`Unknown element type: ${element.$type}`);
}

export function getUnitFromDesignApiCustomProperty(
    customProperty: WidgetElementDto['customProperties'][number]
): WidgetUnitValues {
    switch (customProperty.$type) {
        case 'CustomPropertyBooleanDto':
            return WidgetUnitValues.Boolean;
        case 'CustomPropertyColorDto':
            return WidgetUnitValues.Color;
        case 'CustomPropertyFeedDto':
            return WidgetUnitValues.Feed;
        case 'CustomPropertyFontDto':
            return WidgetUnitValues.Font;
        case 'CustomPropertyImageDto':
            return WidgetUnitValues.Image;
        case 'CustomPropertyNumberDto':
            return WidgetUnitValues.Number;
        case 'CustomPropertySelectDto':
            return WidgetUnitValues.Select;
        case 'CustomPropertyTextDto':
            return WidgetUnitValues.Text;
        default:
            throw new Error(`Unknown custom property type: ${customProperty}`);
    }
}

export function convertToStudioElement(
    elementDto: OneOfElementDtos,
    creative: CreativeDto,
    version: IVersion
): OneOfNodesDto {
    if (isDapiImageNodeDto(elementDto)) {
        return {
            ...omit(elementDto, '$type'),
            imageAssetId: elementDto.imageAsset?.id.toString(),
            __imageKind: true
        };
    }

    if (isDapiVideoNodeDto(elementDto)) {
        return {
            ...omit(elementDto, '$type'),
            videoAssetId: elementDto.videoAsset?.id.toString(),
            __videoKind: true
        };
    }

    if (isDapiTextLikeNodeDto(elementDto)) {
        const characterStyles = populateCharacterStylesByTextSegment(
            elementDto,
            version,
            `${creative.design?.legacy_DocumentId}`
        );
        const isButton = elementDto.isButton;

        if (isButton) {
            return { ...omit(elementDto, '$type'), __buttonKind: true, characterStyles };
        }

        return { ...omit(elementDto, '$type'), __textKind: true, characterStyles };
    }

    if (isDapiWidgetNodeDto(elementDto)) {
        const customProperties = elementDto.customProperties.map(
            (customProperty): SapiWidgetElementDto['customProperties'][number] => {
                const versionPropertyId =
                    isCustomTextPropertyDto(customProperty) || isCustomFeedPropertyDto(customProperty)
                        ? customProperty.legacy_VersionPropertyId
                        : undefined;

                return {
                    name: customProperty.name,
                    label: customProperty.label,
                    unit: getUnitFromDesignApiCustomProperty(customProperty),
                    value: customProperty.value,
                    versionPropertyId: versionPropertyId
                } as SapiWidgetElementDto['customProperties'][number];
            }
        );

        return { ...omit(elementDto, '$type'), customProperties, __widgetKind: true };
    }

    if (isDapiGroupNodeDto(elementDto)) {
        return { ...omit(elementDto, '$type'), __groupKind: true };
    }

    if (isDapiRectangleNodeDto(elementDto)) {
        return { ...omit(elementDto, '$type'), __rectangleKind: true };
    }

    if (isDapiEllipseNodeDto(elementDto)) {
        return { ...omit(elementDto, '$type'), __ellipseKind: true };
    }

    throw new Error('Unknown element type');
}

export function getElementOverride<Element extends OneOfElementDtos>(
    poolElement: Element,
    { creative, size, version }: OverrideOptions
): InferredElementOverride<Element> {
    const sizeElement: undefined | DesignApiOverrideElement = size?.elements[poolElement.id];
    const versionElement: undefined | DesignApiOverrideElement = version?.elements[poolElement.id];
    const creativeElement: undefined | DesignApiOverrideElement = creative?.elements[poolElement.id];

    return {
        ...versionElement,
        ...sizeElement,
        ...creativeElement
    } as InferredElementOverride<Element>;
}

export function getCustomPropertyOverride<CustomProperty extends OneOfCustomPropertyDtos>(
    customProperty: CustomProperty,
    override: WidgetElementOverrideDto
): InferredCustomPropertyOverride<CustomProperty> {
    const propertyOverride = override.customProperties?.find(
        ({ name }) => name === customProperty.name
    );

    return {
        ...customProperty,
        value: propertyOverride?.value ?? customProperty.value
    } as InferredCustomPropertyOverride<CustomProperty>;
}

export function mergePoolElementWithOverride<ElementDto extends OneOfElementDtos>(
    poolElement: ElementDto,
    override: DesignApiOverrideElement
): ElementDto {
    const { flatOverride, removedProperties } = getFlatOverride(override);

    return {
        /**
         * GroupNodeDto has a subset of removable properties, hence
         * casting for the sake of simplicity
         */
        ...omit(poolElement, ...(removedProperties as (keyof ElementDto)[])),
        ...flatOverride
    } as ElementDto;
}

function isRemovableProperty(
    property: DesignApiOverrideElement[keyof DesignApiOverrideElement]
): property is RemovablePropertyValues {
    if (typeof property === 'object' && ('isRemoved' in property || 'value' in property)) {
        return true;
    }

    return false;
}

function isRemovedProperty(
    override: DesignApiOverrideElement,
    key: keyof ElementOverrideDto
): key is RemovablePropertyKeys {
    if (isRemovableProperty(override[key])) {
        return !!override[key]?.isRemoved;
    }

    return false;
}

function getFlatOverride(override: DesignApiOverrideElement): {
    removedProperties: RemovablePropertyKeys[];
    flatOverride: FlatElementOverride;
} {
    const removedProperties: RemovablePropertyKeys[] = [];
    const flatOverride: FlatElementOverride = {};

    for (const field in override) {
        const key = field as keyof DesignApiOverrideElement;

        if (key === '$type') {
            continue;
        }

        if (isRemovedProperty(override, key)) {
            removedProperties.push(key);
            continue;
        }

        const value = isRemovableProperty(override[key]) ? override[key].value : override[key];
        flatOverride[key] = value;
    }

    return {
        removedProperties,
        flatOverride
    };
}

function mergeDesignElementCharacterStyles(
    creativeDataNode: CreativeDataNode,
    existingDesign: IDesign
): void {
    for (const creativeElement of creativeDataNode.elements) {
        if (!isTextNode(creativeElement)) {
            continue;
        }
        const existingElement = existingDesign.document.findNodeById_m(creativeElement.id);
        if (isTextNode(existingElement)) {
            existingElement.characterStyles = new Map([
                ...existingElement.characterStyles,
                ...creativeElement.characterStyles
            ]);
        }
    }
}
