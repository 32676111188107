@if (sizes$ | async; as sizes) {
    <ui-select
        #sizePicker
        id="size-picker"
        [selected]="selectedSizes$ | async"
        [allowUnselected]="true"
        [multiSelect]="true"
        [searchable]="true"
        [useTargetWidth]="true"
        [disabled]="!sizes.length"
        [placeholder]="(placeholder$ | async) ?? ''"
        (selectedChange)="selectedChange($event)">
        <div class="size-picker-content">
            @for (size of sizes | sortByGroupWidth; track size.id) {
                <ui-option [value]="size">
                    <div class="size">
                        <span>
                            {{ size.width }} × {{ size.height }}
                            @if (size.name) {
                                <span class="size-name">{{ size.name }}</span>
                            }
                        </span>
                    </div>
                </ui-option>
            }
        </div>
    </ui-select>
}
