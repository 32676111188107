import { NgIf } from '@angular/common';
import { Directive, inject, Input } from '@angular/core';
import { FeatureFlagsService } from './feature-flags.service';
import { StudioFeatureFlags } from './feature-flags.types';

/**
 * Usage: *featureFlag="StudioFeatureFlags.SizeCollection"
 */
@Directive({
    selector: '[featureFlag]',
    standalone: true
})
export class FeatureFlagsDirective extends NgIf<boolean> {
    private featureFlagsService = inject(FeatureFlagsService);

    @Input('featureFlag') set toggleName(flag: StudioFeatureFlags | string) {
        if (flag.startsWith('!')) {
            flag = flag.substring(1);
            this.ngIf = this.featureFlagsService.isDisabled(flag);
            return;
        }

        this.ngIf = this.featureFlagsService.isEnabled(flag);
    }
}
