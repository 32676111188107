import { IInlineStyledText, IVersionedText, IWidgetText } from '@domain/creativeset/version';
import {
    CharacterPropertyKeys,
    ICharacterProperties,
    IStyleIdMap,
    ITextVariable,
    SpanType
} from '@domain/text';
import {
    deserializeElementPropertyStringValue,
    deserializeTextStyle,
    serializeTextStyle
} from './property-value-serializer';

interface ISerializedStyleMap {
    documentId: string;
    styleId: string;
}

interface ISerializedTextSpan {
    type: SpanType;
    position: number;
    length: number;
    styleIds: ISerializedStyleMap[];
    variable?: ITextVariable;
}

interface ISerializedInlineStyledTextSpan {
    type: SpanType;
    position: number;
    length: number;
    style: string;
}

interface ISerializedInlineStyledText {
    text: string;
    styles: ISerializedInlineStyledTextSpan[];
}

interface ISerializedVersionedText {
    text: string;
    styles: ISerializedTextSpan[];
}

export function deserializeWidgetText(text: string): IWidgetText {
    try {
        const value = JSON.parse(text) as IWidgetText;
        if (typeof value === 'number') {
            return { text: text.toString() };
        }
        return value;
    } catch {
        if (typeof text !== 'string') {
            throw new Error(`Could not deserialize widgetText: '${text}'`);
        }

        return { text: text };
    }
}

export function deserializeVersionedText(text: string): IVersionedText {
    let versionedText: ISerializedVersionedText;

    // Try to serialize
    try {
        versionedText = JSON.parse(text) as ISerializedVersionedText;
    } catch {
        const msg = `Could not deserialize text: '${text}'`;

        // JSON is provided but could not parse anyway
        if ((text !== undefined && text.charAt(0) === '{') || text.charAt(0) === '<') {
            throw new Error(msg);
        }
        versionedText = { text: text, styles: [] };
    }

    // JSON.parse on a number is valid and does not go into the catch
    // Happens for widget custom text properties with a numeric value in a string e.g "0.85"
    if (typeof versionedText === 'number') {
        versionedText = { text: text, styles: [] };
    }

    if (!versionedText.styles) {
        // unmigrated widgetText
        return {
            text: versionedText.text,
            styles: []
        };
    }
    const remappedSpans = versionedText.styles.map(span => {
        const styleIdMap: IStyleIdMap = {};
        for (const style of span.styleIds) {
            styleIdMap[style.documentId] = style.styleId;
        }
        return {
            ...span,
            styleIds: styleIdMap
        };
    });
    return {
        text: versionedText.text,
        styles: remappedSpans
    };
}

export function serializeStyle(style: Partial<ICharacterProperties>): string {
    const styleText: `${CharacterPropertyKeys}:${string}`[] = [];
    for (const prop in style) {
        const property = prop as CharacterPropertyKeys;
        if (property === '__fontFamilyId') {
            continue;
        }
        const value = style[property as CharacterPropertyKeys];
        if (value !== undefined) {
            styleText.push(`${property}:${serializeTextStyle(property, value)}`);
        }
    }
    return styleText.sort().join(';');
}

export function deserializeStyle(text: string): Partial<ICharacterProperties> {
    const properties: [CharacterPropertyKeys, string][] = text
        .split(';')
        .filter(p => p.includes(':'))
        .map(p => {
            // Handle links which includes 'http://'.
            const propertyLastIndex = p.indexOf(':');
            return [
                p.substring(0, propertyLastIndex) as CharacterPropertyKeys,
                p.substring(propertyLastIndex + 1)
            ];
        });

    const style: Partial<ICharacterProperties> = {};

    for (const property of properties) {
        const [key, value] = property;
        style[key as string] = deserializeTextStyle(key, value);
    }

    return style;
}

export function deserializeHTMLElementTextStyles(element: HTMLElement): Partial<ICharacterProperties> {
    const propertiesToUse = element.dataset.studioValues ? element.dataset.studioValues.split(' ') : [];

    const style: Partial<ICharacterProperties> = {};
    for (const property of propertiesToUse) {
        switch (property as CharacterPropertyKeys) {
            case 'font':
                style.font = deserializeElementPropertyStringValue('font', element.dataset.font);
                break;
            case 'textColor':
                style.textColor = deserializeElementPropertyStringValue(
                    'textColor',
                    element.style.color
                );
                break;
            case 'fontSize':
                style.fontSize = deserializeElementPropertyStringValue(
                    'fontSize',
                    element.style.fontSize
                );
                break;
            case 'strikethrough':
            case 'underline':
                if (element.style.textDecoration.indexOf('line-through') > -1) {
                    style.strikethrough = true;
                }
                if (element.style.textDecoration.indexOf('underline') > -1) {
                    style.underline = true;
                }
                break;
            case 'uppercase':
                style.uppercase = element.style.textTransform.indexOf('uppercase') > -1;
                break;
            case 'characterSpacing':
                style.characterSpacing = deserializeElementPropertyStringValue(
                    'characterSpacing',
                    element.style.letterSpacing
                );
                break;
            case 'lineHeight':
                style.lineHeight = deserializeElementPropertyStringValue(
                    'lineHeight',
                    element.style.lineHeight
                );
                break;
            case 'textShadows':
                style.textShadows = deserializeElementPropertyStringValue(
                    'textShadows',
                    element.style.textShadow
                );
                break;
            case 'variable':
                style.variable = deserializeElementPropertyStringValue(
                    'feed',
                    element.dataset.variable
                );
        }
    }
    return style;
}

export function serializeInlineStyledText(text: IInlineStyledText): string {
    const spans: ISerializedInlineStyledTextSpan[] = [];
    for (const span of text.styles) {
        if (span.type === SpanType.End) {
            break;
        }
        spans.push({
            type: span.type,
            length: span.length,
            position: span.position,
            style: serializeStyle(span.style)
        });
    }
    return JSON.stringify({ text: text.text, styles: spans } as ISerializedInlineStyledText);
}

export function serializeVersionedText(text: IVersionedText): string {
    const spans: ISerializedTextSpan[] = [];
    for (const span of text.styles) {
        if (span.type === SpanType.End) {
            break;
        }
        if (span.type === SpanType.Composition) {
            continue;
        }
        const styleIds: ISerializedStyleMap[] = [];
        for (const [documentId, styleId] of Object.entries(span.styleIds)) {
            styleIds.push({ documentId, styleId });
        }

        let variable: ITextVariable | undefined;
        if (span.type === SpanType.Variable && span.variable) {
            variable = { ...span.variable, type: 'text' };
        }
        spans.push({
            type: span.type,
            length: span.length,
            position: span.position,
            styleIds: styleIds.sort((a, b) => {
                if (a.documentId > b.documentId) {
                    return 1;
                } else if (a.documentId < b.documentId) {
                    return -1;
                } else if (a.styleId > b.styleId) {
                    return 1;
                } else if (a.styleId < b.styleId) {
                    return -1;
                }
                throw new Error('StyleId is not unique');
            }),
            variable
        });
    }
    return JSON.stringify({ text: text.text, styles: spans });
}

export function deserializeInlineStyledText(text: string): IInlineStyledText {
    const inlineStyledText = JSON.parse(text) as ISerializedInlineStyledText;
    const remappedSpans = inlineStyledText.styles.map(span => ({
        ...span,
        style: deserializeStyle(span.style)
    }));
    return {
        text: inlineStyledText.text,
        styles: remappedSpans
    };
}
