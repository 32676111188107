import { Component, inject, OnDestroy } from '@angular/core';
import { Logger } from '@bannerflow/sentinel-logger';
import { AppComponent } from '../../app.component';

@Component({
    selector: 'creativeset-component',
    templateUrl: './creativeset.component.html'
})
export class CreativesetComponent implements OnDestroy {
    private app = inject(AppComponent);
    private logger = new Logger('CreativesetComponent');

    constructor() {
        this.app.loaded = true;
    }

    ngOnDestroy(): void {
        this.logger.verbose('ngOnDestroy');
    }
}
