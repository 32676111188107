import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ISelectableSize } from '@studio/domain/components/size-list.types';
import { map } from 'rxjs';
import * as BrandActions from '../../../../shared/brand/state/brand.actions';
import { SizeAddInitActions } from './size-add.actions';

@Injectable()
export class SizeAddEffects {
    private _actions$ = inject(Actions);

    initialize$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(BrandActions.loadActions.loadBrandSuccess),
            map(({ brand }) => {
                const selectableSizes: ISelectableSize[] = brand.sizeFormats.map(size => ({
                    id: size.id,
                    sizeFormatId: size.id,
                    width: size.width,
                    height: size.height,
                    name: size.name,
                    amount: 0,
                    selected: false
                }));
                const collections = [...brand.sizeFormatGroups].sort((a, b) =>
                    a.name > b.name ? 1 : -1
                );

                return SizeAddInitActions.initialize({ sizes: selectableSizes, collections });
            })
        );
    });
}
