@if (initialized$ | async) {
    <div
        class="translation-page"
        [ngClass]="{
            'translation-page--sidebar-left-controls-hidden': !isLayoutDirectionRight,
            'translation-page--sidebar-right-controls-hidden': isLayoutDirectionRight
        }">
        @if (creativeset) {
            <translation-topbar
                [creativesetName]="creativeset.name"
                [creativesetId]="creativeset.id"
                (layoutChange)="changeLayoutDirection($event)"></translation-topbar>
        }

        <!-- Translation Panel -->
        <tp-panel class="sidebar"></tp-panel>

        <!-- CREATIVE LIST -->
        <div
            cdk-scrollable
            class="ui-scrollbar creatives-list"
            (scroll)="onScroll()">
            @if ((hasActiveFilters$ | async) && isViewEmpty()) {
                <div class="no-search-results">No creatives matching your filters</div>
            }

            <creative-list
                [creativeItemHeaderTemplate]="headerTemplate"
                (contextmenu)="$event.preventDefault()"
                (scroll)="onScroll()">
                <ng-template
                    #headerTemplate
                    let-creative
                    let-creativeIndex="creativeIndex"
                    let-isCreativeGroup="isCreativeGroup">
                    <creative-list-item-title
                        [index]="creativeIndex"
                        [isCreativeGroup]="isCreativeGroup"
                        [creative]="creative">
                    </creative-list-item-title>
                </ng-template>
            </creative-list>
        </div>
    </div>
} @else {
    <ui-loader></ui-loader>
}

<!-- ANIMATION CONTROLS  -->
<div
    class="hover-area"
    [ngClass]="{
        'hover-area--left': isLayoutDirectionRight,
        'hover-area--visible': (isAnimationControlsVisible$ | async) === false
    }"></div>
<div
    class="animation-controls"
    [ngClass]="{
        'animation-controls--left': isLayoutDirectionRight,
        'animation-controls--visible': (isAnimationControlsVisible$ | async)
    }">
    <animation-control id="interaction-animation-control"></animation-control>
</div>
