import {
    AllowedOperations,
    ICreativesetShowcase,
    IShowcaseTranslator,
    IShowcaseUser
} from '@domain/showcase';
import { IAsyncState } from '@domain/store/async';
import { createReducer, on } from '@ngrx/store';
import { creativeSetShowcaseActions } from './creativeset-showcase.actions';

export const CREATIVESET_SHOWCASE_FEATURE_KEY = 'creativesetShowcase';

export interface CreativesetShowcasePartialState {
    readonly [CREATIVESET_SHOWCASE_FEATURE_KEY]: ShowcaseState;
}

export interface ShowcaseState extends IAsyncState {
    showcases: ICreativesetShowcase[];
    translators: IShowcaseTranslator[];
    users: IShowcaseUser[];
    createdShowcase?: ICreativesetShowcase;
    temporaryLink?: string;
    loadingTemporaryLink: boolean;
    showcaseToken?: string;
    allowedOperations: AllowedOperations[];
}

export const initialState: ShowcaseState = {
    loaded: false,
    showcases: [],
    translators: [],
    users: [],
    loadingTemporaryLink: false,
    showcaseToken: '',
    allowedOperations: []
};

export const reducer = createReducer<ShowcaseState>(
    initialState,
    on(creativeSetShowcaseActions.loadCreativeSetShowcases, state => {
        return {
            ...state,
            loaded: false,
            error: undefined
        };
    }),
    on(
        creativeSetShowcaseActions.loadShowcaseCreativeSetSuccess,
        (state, { showcaseToken, allowedOperations }) => {
            return {
                ...state,
                showcaseToken,
                allowedOperations
            };
        }
    ),
    on(creativeSetShowcaseActions.loadCreativeSetShowcasesSuccess, (state, { response }) => {
        return {
            ...state,
            showcases: state.showcases.length ? state.showcases : response.showcases,
            translators: response.translators,
            users: response.users,
            loaded: true
        };
    }),

    on(
        creativeSetShowcaseActions.createCreativeSetShowcase,
        (state, { showcase }): ShowcaseState => ({
            ...state,
            temporaryLink: undefined,
            loadingTemporaryLink: showcase.isTemporary ? true : state.loadingTemporaryLink
        })
    ),
    on(
        creativeSetShowcaseActions.createCreativeSetShowcaseSuccess,
        (state, { showcase }): ShowcaseState => ({
            ...state,
            showcases: [...state.showcases, showcase],
            createdShowcase: showcase,
            temporaryLink: showcase.isTemporary ? showcase.showcaseLink : state.temporaryLink,
            loadingTemporaryLink: showcase.isTemporary ? false : state.loadingTemporaryLink
        })
    ),
    on(
        creativeSetShowcaseActions.createCreativeSetShowcaseFailure,
        (state, { error }): ShowcaseState => ({
            ...state,
            error,
            loadingTemporaryLink: false
        })
    ),

    on(
        creativeSetShowcaseActions.updateCreativeSetShowcaseSuccess,
        (state, { showcase }): ShowcaseState => ({
            ...state,
            showcases: state.showcases.map(s => (s.showcaseKey === showcase.showcaseKey ? showcase : s))
        })
    ),

    on(
        creativeSetShowcaseActions.deleteCreativeSetShowcaseSuccess,
        (state, { showcaseKey }): ShowcaseState => ({
            ...state,
            showcases: state.showcases.filter(s => s.showcaseKey !== showcaseKey)
        })
    ),
    on(
        creativeSetShowcaseActions.deleteCreativeSetShowcaseFailure,
        creativeSetShowcaseActions.loadShowcaseCreativeSetFailure,
        creativeSetShowcaseActions.updateCreativeSetShowcaseFailure,
        creativeSetShowcaseActions.loadCreativeSetShowcasesFailure,
        (state, { error }) => {
            return { ...state, error };
        }
    ),

    on(creativeSetShowcaseActions.resetCreatedShowcase, state => {
        return { ...state, createdShowcase: undefined };
    })
);
