import { Injectable } from '@angular/core';
import { Logger } from '@bannerflow/sentinel-logger';
import { IFeedStore } from '@domain/creative/feed/feed-store.header';
import { IFontStyle } from '@domain/font';
import { IFontFamily } from '@domain/font-families';
import { OneOfTextDataNodes } from '@domain/nodes';
import { OneOfEditableSpans, SpanType } from '@domain/text';
import { EventLoggerService, FontValidationFailEvent } from '@studio/monitoring/events';
import { Subject, firstValueFrom, lastValueFrom } from 'rxjs';
import { CreativesetDataService } from '../creativeset/creativeset.data.service';
import { FontFamiliesDataService } from '../font-families/font-families.data.service';
import { UserSettingsService } from '../user-settings/state/user-settings.service';

@Injectable({ providedIn: 'root' })
export class FontValidationService {
    private _fontValidation$ = new Subject<IFontValidationState[]>();
    fontValidationChange$ = this._fontValidation$.asObservable();

    private logger = new Logger('FontValidationService');

    private cachedFonts: IFontFamily[] = [];

    constructor(
        private fontFamiliesDataService: FontFamiliesDataService,
        private eventLoggerService: EventLoggerService,
        private userSettingsService: UserSettingsService,
        private creativesetDataService: CreativesetDataService
    ) {}

    async validateElements(
        elements: OneOfTextDataNodes | OneOfTextDataNodes[],
        feedStore: IFeedStore,
        selectedFont?: IFontStyle
    ): Promise<void> {
        if (!Array.isArray(elements)) {
            elements = [elements];
        }

        const fonts: ValidateFontParams[] = [];

        for (const element of elements) {
            const textFontMap = this.spansToMap(
                element.content.spans,
                (selectedFont || element.font)!,
                feedStore
            );
            for (const textFontValue of Object.values(textFontMap)) {
                const { font, text } = textFontValue;
                fonts.push({
                    elementId: element.id,
                    elementName: element.name || '',
                    fontStyle: font,
                    text
                });
            }
        }

        this.validateFonts(fonts);
    }

    spansToMap(spans: OneOfEditableSpans[], font: IFontStyle, feedStore?: IFeedStore): TextFontMap {
        const textFontMap: TextFontMap = {};

        for (const span of spans) {
            if (!(span.type === SpanType.Variable || span.type === SpanType.Word)) {
                continue;
            }

            let text = '';
            if (span.type === SpanType.Variable && span.style.variable?.id) {
                // Get all feeded characters
                const feedData = feedStore?.getFeed(span.style.variable.id);
                text =
                    feedData?.data
                        .map(feedElement => feedElement[span.style.variable?.path || '']?.value || '')
                        .join('') || '';
            } else if (span.type === SpanType.Word) {
                text = span.content;
            }
            const spanFont = span.style.font || font;
            if (!spanFont) {
                continue;
            }
            textFontMap[spanFont.id] = {
                text: (textFontMap[spanFont.id]?.text || '') + text,
                font: spanFont
            };
        }
        return textFontMap;
    }

    async validateFonts(fontParams: ValidateFontParams[]): Promise<void> {
        await this.loadFonts(fontParams);

        const results = fontParams.map(fontParam => this.validateFontParam(fontParam));

        this._fontValidation$.next(results);
    }

    private async loadFonts(fontParams: ValidateFontParams[]): Promise<void> {
        const noCachedFontIds = fontParams
            .filter(
                ({ fontStyle }) => !this.cachedFonts.find(({ id }) => id === fontStyle?.fontFamilyId)
            ) // Remove cached fonts
            .map(({ fontStyle }) => fontStyle?.id || '')
            .filter(Boolean);

        if (noCachedFontIds.length) {
            const result = await lastValueFrom(
                this.fontFamiliesDataService.getFontFamiliesByStyleIds(noCachedFontIds, true)
            );
            this.cachedFonts = [...this.cachedFonts, ...result];
        }
    }

    private validateFontParam({
        elementId,
        elementName,
        fontStyle,
        text
    }: ValidateFontParams): IFontValidationState {
        const uniqueCharacters = this.getUniqueCharacters(text);

        if (!uniqueCharacters.size) {
            this.logger.debug(`No characters provided`);
            return {
                valid: true,
                missingCharacters: [],
                elementName,
                elementId,
                fontStyleId: ''
            };
        }

        if (!fontStyle) {
            this.logger.warn(`${elementName} doesn't have a font!`);
            return {
                valid: false,
                missingCharacters: Array.from(uniqueCharacters),
                elementName,
                elementId,
                fontStyleId: ''
            };
        }

        const result = this.doValidation(fontStyle.id, uniqueCharacters);

        if (!result.valid) {
            this.eventLoggerService.log(
                new FontValidationFailEvent({
                    ...result,
                    elementName,
                    elementId
                }),
                this.logger
            );
        }

        return {
            ...result,
            fontStyleId: fontStyle.id,
            elementName,
            elementId
        };
    }

    private doValidation(
        fontStyleId: string,
        uniqueCharacters: Set<string>
    ): {
        valid: IFontValidationState['valid'];
        missingCharacters: IFontValidationState['missingCharacters'];
    } {
        const unicodeGlyphs = this.getUnicodeGlyphsFor(fontStyleId);

        if (!unicodeGlyphs?.length) {
            throw new Error('Font not found');
        }

        const uniqueCharactersNotFound = Array.from(uniqueCharacters).filter(
            c => !unicodeGlyphs.includes(c.charCodeAt(0))
        );

        return {
            valid: uniqueCharactersNotFound.length === 0,
            missingCharacters: uniqueCharactersNotFound
        };
    }

    private getUnicodeGlyphsFor(fontStyleId: string): number[] | undefined {
        for (const fmItem of this.cachedFonts) {
            const fs = fmItem.fontStyles.find(({ id }) => id === fontStyleId);
            if (fs) {
                return fs.unicodeGlyphs;
            }
        }
    }

    private getUniqueCharacters(text: string): Set<string> {
        // These are the most common white spaces characters. Feel free to add more
        const charactersToIgnore = [
            '\t',
            '\n',
            '\v',
            '\f',
            '\r',
            's',
            '\u00a0',
            '\u2000',
            '\u2001',
            '\u2002',
            '\u2003',
            '\u2004',
            '\u2005',
            '\u2006',
            '\u2007',
            '\u2008',
            '\u2009',
            '\u200a',
            '\u200b',
            '\u2028',
            '\u2029',
            '\u3000'
        ];
        const combinedRegExp = new RegExp(`(${charactersToIgnore.join('|')})`, 'gu');
        return new Set(text.replace(combinedRegExp, '').split(''));
    }

    async filterIgnoredResults(
        fontValidationResults: IFontValidationState[]
    ): Promise<IFontValidationState[]> {
        const ignoredFontValidationResults: IFontValidationState[] =
            await this.getIgnoredFontValidationResults();

        if (ignoredFontValidationResults.length === 0) {
            return fontValidationResults;
        }

        return fontValidationResults.filter(
            validationResult =>
                !ignoredFontValidationResults.find(
                    ignoredValidationResult =>
                        validationResult.fontStyleId === ignoredValidationResult.fontStyleId &&
                        validationResult.missingCharacters?.every(character =>
                            ignoredValidationResult.missingCharacters?.includes(character)
                        )
                )
        );
    }

    async updateIgnoredResults(fontValidationResults: IFontValidationState[]): Promise<void> {
        const ignoredFontValidationResults = (await this.getIgnoredFontValidationResults()).map(
            result => ({
                fontStyleId: result.fontStyleId,
                missingCharacters: result.missingCharacters,
                creativesetId: result.creativesetId
            })
        );

        for (const result of fontValidationResults) {
            const alreadyIgnored = ignoredFontValidationResults.find(
                ignoreResult =>
                    ignoreResult.creativesetId === this.creativesetDataService.creativeset.id &&
                    ignoreResult.fontStyleId === result.fontStyleId
            );

            if (alreadyIgnored) {
                alreadyIgnored.missingCharacters = Array.from(
                    new Set([
                        ...(alreadyIgnored?.missingCharacters ?? []),
                        ...(result?.missingCharacters ?? [])
                    ])
                );
            } else {
                ignoredFontValidationResults.push({
                    creativesetId: this.creativesetDataService.creativeset.id,
                    fontStyleId: result.fontStyleId,
                    missingCharacters: result.missingCharacters
                });
            }
        }

        this.userSettingsService.setIgnoreWarnings(
            'fontValidationWarnings',
            ignoredFontValidationResults
        );
    }

    async clearSuppressedWarnings(): Promise<void> {
        const ignoredFontValidationResults: IFontValidationState[] = (
            await this.getIgnoredFontValidationResults()
        ).filter(
            ignoreResult =>
                !!ignoreResult.creativesetId &&
                ignoreResult.creativesetId !== this.creativesetDataService.creativeset.id
        );
        this.userSettingsService.setIgnoreWarnings(
            'fontValidationWarnings',
            ignoredFontValidationResults
        );
    }

    private async getIgnoredFontValidationResults(): Promise<IFontValidationState[]> {
        return firstValueFrom(this.userSettingsService.fontValidationWarnings$);
    }
}

export interface ValidateFontParams {
    elementId: string;
    elementName: string;
    fontStyle: IFontStyle | undefined;
    text: string;
}

export interface IFontValidationState {
    fontStyleId: string;
    valid?: boolean;
    elementId?: string;
    elementName?: string;
    creativesetId?: string;
    missingCharacters?: string[];
}

interface TextFontMap {
    [key: string]: {
        text: string;
        font: IFontStyle;
    };
}
