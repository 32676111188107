import { isVersionedFeed } from '@creative/elements/feed/feeds.utils';
import { isVersionedText, isVersionedWidgetText } from '@creative/elements/rich-text/text-nodes';
import { serializeVersionProperty } from '@creative/serialization/versions/version-serializer';
import { updateChecksumOfCreatives } from '@creative/utils';
import { IAdDataCreativeVersion } from '@domain/ad/ad-data-creative';
import { IBrandLocalization } from '@domain/brand';
import { IElementProperty } from '@domain/creativeset';
import { CreativeUpdate } from '@domain/creativeset/creative';
import { ICreativeset } from '@domain/creativeset/creativeset';
import {
    ILocalization,
    ITextSpan,
    IVersion,
    IVersionedText,
    IVersionProperty,
    IWidgetText,
    OneOfVersionableProperties,
    VersionedPropertyUnit
} from '@domain/creativeset/version';
import { IFeed } from '@domain/feed';
import { IWidgetCustomProperty, IWidgetElementProperty } from '@domain/widget';
import { cloneDeep } from '@studio/utils/clone';
import { deepEqual, pick, removeDuplicates } from '@studio/utils/utils';

export enum VersionValidationErrors {
    NO_NAME,
    DUPLICATED_NAME
}

export function isVersionedProperty(
    property: IVersionProperty | IWidgetElementProperty | IWidgetCustomProperty
): property is IVersionProperty<IVersionedText | IFeed | IWidgetText> & {
    name: VersionedPropertyUnit;
} {
    return isVersionedFeed(property) || isVersionedText(property) || isVersionedWidgetText(property);
}

export function isVersionedElementProperty(
    property: IElementProperty
): property is IElementProperty & Required<Pick<IElementProperty, 'versionPropertyId'>> {
    return !!property.versionPropertyId;
}

export function validateNewVersion(
    newVersionName: string,
    existingVersions: IVersion[],
    versionId?: string
): [boolean, string?, VersionValidationErrors?] {
    newVersionName = newVersionName.trim();
    if (!newVersionName) {
        return [false, 'You have not provided a version name.', VersionValidationErrors.NO_NAME];
    }
    const versionResult = existingVersions.find(
        ({ name, id }) => name.trim() === newVersionName && (!versionId || versionId !== id)
    );
    if (versionResult) {
        return [
            false,
            `Duplicate version '${newVersionName}'. Versions need to have a unique name.`,
            VersionValidationErrors.DUPLICATED_NAME
        ];
    }

    return [true];
}

export function copyCharacterStylesToNewDesign(
    versions: IVersion[],
    { sourceDocumentId, targetDocumentId }: { targetDocumentId: string; sourceDocumentId: string }
): { versionId: string; properties: IVersionProperty[] }[] {
    const output: { versionId: string; properties: IVersionProperty[] }[] = [];
    for (const version of versions) {
        const properties: IVersionProperty[] = [];
        for (const property of version.properties) {
            if (!isVersionedText(property)) {
                continue;
            }
            for (const span of property.value.styles) {
                const styleId = span.styleIds[sourceDocumentId];
                if (styleId) {
                    span.styleIds[targetDocumentId] = styleId;
                }
            }
            properties.push(property);
        }
        output.push({
            versionId: version.id,
            properties
        });
    }
    return output;
}

export function sortVersions(
    versionA: IVersion,
    versionB: IVersion,
    defaultVersionId: string,
    localizations: ILocalization[]
): number {
    // Move original version to the very top
    if (versionA.id === defaultVersionId) {
        return -1;
    }

    if (versionB.id === defaultVersionId) {
        return 1;
    }
    const localizationA = localizations.find(({ id }) => id === versionA.localization.id);
    const localizationAName = localizationA?.name.toLowerCase() ?? '';
    const localizationB = localizations.find(({ id }) => id === versionB.localization.id);
    const localizationBName = localizationB?.name.toLowerCase() ?? '';

    const result = localizationAName.localeCompare(localizationBName) ?? 0;

    // If localization name is same, version name is tie-breaker
    if (result === 0) {
        const versionAName = versionA.name.toLowerCase();
        const versionBName = versionB.name.toLowerCase();
        return versionAName.localeCompare(versionBName);
    }

    return result;
}

export function getVersionProperty(
    currentProperties: IVersionProperty[],
    defaultVersion: IVersion,
    versionPropertyId: string
): IVersionProperty {
    const versionProperty = currentProperties.find(v => v.id === versionPropertyId);
    if (versionProperty) {
        return versionProperty;
    }
    return defaultVersion.properties.find(v => v.id === versionPropertyId)!;
}

export function getVersionPropertyByIdFromVersions(
    versionPropertyId: string,
    version: IVersion,
    defaultVersion: IVersion
): IVersionProperty | undefined {
    return getVersionProperty(version.properties, defaultVersion, versionPropertyId);
}

export function findVersionPropertyValue(
    version: IVersion,
    versionPropertyId: string
): OneOfVersionableProperties | undefined {
    return version.properties.find(p => p.id === versionPropertyId)?.value;
}

export function getVersionPropertyById(
    versions: IVersion[],
    versionPropertyId: string
): IVersionProperty | undefined {
    for (const version of versions) {
        const property = version.properties.find(p => p.id === versionPropertyId);
        if (property) {
            return property;
        }
    }
    return undefined;
}

export function compareVersionProperty(a: IVersionProperty, b: IVersionProperty): boolean {
    try {
        return deepEqual(serializeVersionProperty(a), serializeVersionProperty(b));
    } catch {
        // a new version may not have an id yet. So serializing it will trigger an exception
        return false;
    }
}

export function cleanStyleIdsFromProperties(
    properties: IVersionProperty[],
    validDocumentIds: string[]
): IVersionProperty[] {
    return properties.map(property => {
        if (!isVersionedText(property)) {
            return property;
        }

        const newStyles: ITextSpan[] = [];

        for (const style of property.value.styles) {
            const newStyle = cloneDeep(style);

            for (const [documentId, _styleId] of Object.entries(style.styleIds)) {
                if (validDocumentIds.includes(documentId)) {
                    continue;
                }

                delete newStyle.styleIds[documentId];
            }

            newStyles.push(newStyle);
        }

        return {
            ...property,
            value: {
                ...property.value,
                styles: newStyles
            }
        };
    });
}

export function mergeVersionsProperties(
    prevVersion: IVersion,
    newVersion: IVersion
): IVersionProperty[] {
    const output: IVersionProperty[] = [];
    for (const prevProperty of prevVersion.properties) {
        const newValue = newVersion.properties.find(({ id }) => id === prevProperty.id);
        if (!newValue) {
            output.push(prevProperty);
            continue;
        }
        output.push(newValue);
    }

    return [
        ...output,
        ...newVersion.properties.filter(
            ({ id }) => !prevVersion.properties.find(prevProp => prevProp.id === id)
        )
    ];
}

export function mergeVersionProperties(
    sourceProperties: IVersionProperty[],
    changedProperties: IVersionProperty[]
): IVersionProperty[] {
    const mergedVersionProperties: IVersionProperty[] = cloneDeep(sourceProperties);
    for (const targetProperty of changedProperties) {
        const versionProperty = mergedVersionProperties.find(({ id }) => id === targetProperty.id);
        if (!versionProperty) {
            mergedVersionProperties.push(cloneDeep(targetProperty));
            continue;
        }
        // If property already exists, update it's value
        versionProperty.value = targetProperty.value;
    }
    return mergedVersionProperties;
}

export function mergeVersions(originalVersions: IVersion[], newVersions: IVersion[]): IVersion[] {
    const fullResponse = !!newVersions[0].name;
    if (!fullResponse) {
        // BE didn't return the whole Version entity
        return originalVersions.map(originalVersion => {
            const newVersion = newVersions.find(newV => newV.id === originalVersion.id);
            return {
                ...originalVersion,
                properties: newVersion?.properties ?? originalVersion.properties
            };
        });
    }
    return [
        ...originalVersions.filter(({ id }) => !newVersions.find(newVersion => newVersion.id === id)),
        ...newVersions
    ];
}

export function sanitizeVersionIdsFromURL(
    selectedVersionIds: string[],
    versions: IVersion[],
    defaultVersion: IVersion,
    versionParamProvided: boolean
): string[] {
    selectedVersionIds = removeDuplicates(selectedVersionIds);

    if (!versionParamProvided || selectedVersionIds.length === 0) {
        if (versions.find(({ id }) => id === defaultVersion.id)) {
            return [defaultVersion.id];
        } else {
            return versions.length > 1 ? ['all'] : [versions[0].id];
        }
    }

    const filteredVersionIds = selectedVersionIds.filter(
        selectedVersionId => !!versions.find(({ id }) => id === selectedVersionId)
    );

    // Cannot find any, or none was selected
    if (!filteredVersionIds.length) {
        if (versions.length > 1) {
            return ['all'];
        } else {
            return [versions[0].id];
        }
    }

    if (filteredVersionIds.length === versions.length && versions.length > 1) {
        return ['all'];
    }
    return filteredVersionIds;
}

type VersionsUpdateDto = {
    versions: IVersion[];
    dirtyCreatives: CreativeUpdate[];
};

export function prepareVersionsForUpdate(
    versions: IVersion[],
    creativeset: ICreativeset
): VersionsUpdateDto {
    const versionUpdateDto: VersionsUpdateDto = {
        versions,
        dirtyCreatives: []
    };

    for (const version of versions) {
        const creativesForVersion = creativeset.creatives.filter(
            creative => creative.version.id === version.id
        );

        const updatedChecksumsCreatives: CreativeUpdate[] = updateChecksumOfCreatives(
            creativesForVersion,
            [version],
            creativeset.stateId
        ).map(dirtyCreative => pick(dirtyCreative, 'id', 'checksum', 'targetUrl'));

        versionUpdateDto.dirtyCreatives.push(...updatedChecksumsCreatives);
    }

    return versionUpdateDto;
}

export function createAdDataCreativeVersion(
    version: IVersion,
    brandLocalizations: IBrandLocalization[]
): IAdDataCreativeVersion {
    const localization = brandLocalizations.find(loc => loc.id === version.localization.id);
    if (!localization) {
        throw new Error(
            `Localization with ID: "${version.localization.id}" not found in brand localizations`
        );
    }

    return {
        ...version,
        localization: {
            id: localization.id,
            cultureCode: localization?.cultureCode,
            cultureName: localization?.cultureName
        }
    };
}

export const unitToVersionedUnitMap: Record<'text' | 'feed', VersionedPropertyUnit> = {
    text: 'content',
    feed: 'feed'
};
