import { fromRGBAstring } from '@creative/color.utils';
import { convertTextShadowsToDtos } from '@creative/serialization/property-serializer';
import { CharacterStyleDto, CharacterStyleValueDto } from '@domain/api/generated/sapi';
import { IFontStyle } from '@domain/font';
import { ITextShadow } from '@domain/style';
import {
    ICharacterProperties,
    ICharacterStylesMap,
    IContentSpan,
    IText,
    ITextVariable
} from '@domain/text';
import { clamp, decimal } from '@studio/utils/utils';
import { copyStyle, getHashFromStyle, isContentSpan } from '../elements/rich-text/text-nodes';

export function convertCharacterStyleToDto(
    style: Partial<ICharacterProperties>
): CharacterStyleValueDto {
    const characterStyleDto: CharacterStyleValueDto = {};
    for (const property in style) {
        const value = style[property];
        switch (property) {
            case '__fontFamilyId':
                continue;
            case 'font':
                if (typeof value === 'string') {
                    characterStyleDto.font = value;
                } else {
                    characterStyleDto.font = (value as IFontStyle).id;
                }
                break;
            case 'textColor':
                characterStyleDto[property] = value.toString();
                break;
            case 'textShadows':
                const shadow = (value || []) as ITextShadow[];
                characterStyleDto[property] = convertTextShadowsToDtos(shadow);
                break;
            case 'variable':
                const variable = value as ITextVariable | undefined;
                if (variable) {
                    variable.type = 'text';
                }
                characterStyleDto.variable = variable;
                break;
            case 'fontSize':
                characterStyleDto.fontSize = decimal(clamp(value, 0.01, 2500));
                break;
            default:
                characterStyleDto[property] = value;
                break;
        }
    }
    return characterStyleDto;
}

export function deserializeCharacterStyles(characterStylesDto: CharacterStyleDto[]): {
    characterStyles: ICharacterStylesMap;
    styleHashMap: Map<string, string>;
} {
    const characterStyles: ICharacterStylesMap = new Map<string, Partial<ICharacterProperties>>();
    const styleHashMap = new Map</* styleHash */ string, /* styleId */ string>();
    for (const characterStyle of characterStylesDto || []) {
        const id = characterStyle.id;
        const style = deserializeCharacterProperties(characterStyle.value);
        styleHashMap.set(getHashFromStyle(style), id);
        characterStyles.set(id, style);
    }

    return { characterStyles, styleHashMap };
}

export function deserializeCharacterProperties(
    characterStyleDto: CharacterStyleValueDto
): Partial<ICharacterProperties> {
    const style = {} as Partial<ICharacterProperties>;
    for (const property in characterStyleDto) {
        switch (property) {
            case 'textColor':
                style[property] = fromRGBAstring(characterStyleDto[property]!);
                break;
            case 'textShadows':
                style[property] = characterStyleDto[property]!.map(s => ({
                    offsetX: s.offsetX,
                    offsetY: s.offsetY,
                    blur: s.blur,
                    color: fromRGBAstring(s.color)
                }));
                break;
            case 'variable':
                const variable = characterStyleDto[property]!;
                if (!variable) {
                    break;
                }

                style[property] = {
                    ...variable,
                    type: 'text'
                };
                break;
            default:
                style[property] = characterStyleDto[property];
                break;
        }
    }
    return style;
}

export function serializeRichText(text: IText): IText {
    return {
        spans: text.spans.map(span => {
            const result = { ...span };
            if (isContentSpan(span)) {
                const r = result as IContentSpan;
                r.styleId = span.styleId;
                r.styleIds = { ...span.styleIds };
                if (span.__previousStyleIdToHistoryIndexMap) {
                    r.__previousStyleIdToHistoryIndexMap = new Map(
                        span.__previousStyleIdToHistoryIndexMap
                    );
                }
            }
            return result;
        }),
        style: copyStyle(text.style)
    };
}
