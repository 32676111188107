import { inject, Injectable } from '@angular/core';
import { distinctUntilChanged, fromEvent, map, Observable, of, shareReplay, startWith } from 'rxjs';
import { EVENTS, UnleashClient } from 'unleash-proxy-client';
import { FEATURE_FLAGS_CONFIG, FeatureFlagsConfig, StudioFeatureFlags } from './feature-flags.types';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagsService {
    private configs = inject<FeatureFlagsConfig>(FEATURE_FLAGS_CONFIG);

    initialized$: Observable<void>;
    update$: Observable<void>;

    private unleashClient: UnleashClient;
    private readonly defaultValues = {
        [StudioFeatureFlags.SizeCollections]: false,
        [StudioFeatureFlags.SizeCollectionsDisabled]: true
    };

    constructor() {
        if (!this.configs.enabled) {
            this.initialized$ = of();
            this.update$ = of();
            return;
        }

        this.unleashClient = new UnleashClient(this.configs);
        this.initialized$ = fromEvent<void>(this.unleashClient, EVENTS.INIT).pipe(
            shareReplay({ bufferSize: 1, refCount: true })
        );
        this.update$ = fromEvent<void>(this.unleashClient, EVENTS.UPDATE).pipe(
            shareReplay({ bufferSize: 1, refCount: true })
        );
    }

    start(): void {
        if (!this.configs.enabled) {
            return;
        }
        this.unleashClient.start();
    }

    isEnabled(featureFlag: StudioFeatureFlags | string): boolean {
        if (!this.configs.enabled) {
            return !!this.defaultValues[featureFlag];
        }
        return this.unleashClient.isEnabled(featureFlag);
    }

    isDisabled(featureFlag: StudioFeatureFlags | string): boolean {
        return !this.isEnabled(featureFlag);
    }

    isEnabled$(featureFlag: StudioFeatureFlags | string): Observable<boolean> {
        return this.update$.pipe(
            startWith(this.defaultValues[featureFlag] ?? false),
            map(() => this.isEnabled(featureFlag)),
            distinctUntilChanged()
        );
    }

    isDisabled$(featureFlag: StudioFeatureFlags | string): Observable<boolean> {
        return this.isEnabled$(featureFlag).pipe(map(flagState => !flagState));
    }
}
