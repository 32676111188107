import { MediaLibraryService } from '../../shared/media-library/state/media-library.service';
import { ColorService } from '../../shared/services/color.service';
import { SocialGuideService } from '../../shared/services/social-guide.service';
import {
    BrandLibraryElementService,
    ElementRenderingService,
    LibraryElementRenderingService
} from './media-library';
import { MutatorService } from './services/mutator.service';
import { PropertiesService } from './properties-panel';
import { HeavyVideoService } from './video';
import {
    AssetUploadService,
    CopyPasteService,
    ElementReplaceService,
    EditorEventService,
    ElementHighlightService,
    ElementSelectionBoundingBoxService,
    ElementSelectionService,
    HistoryService,
    SaveErrorHandlerService,
    SelectionNetService,
    ValidationService,
    EditorSaveStateService,
    EditorStateService
} from './services';
import {
    AnimationRecorderService,
    AnimationService,
    KeyframeService,
    TimelineScrollService,
    TimelineTransformService,
    TimelineZoomService
} from './timeline';
import { WorkspaceGradientHelperService } from './workspace/workspace-gradient-helper.service';
import { WorkspaceTransformService } from './workspace/workspace-transform.service';
import { ElementCreatorService } from './services/element-creator.service';
import { NodeCreatorService } from './services/data-node-creator.service';
import { AssetPickerService } from './asset-picker/asset-picker.service';
import { AssetPickerUploadService } from './asset-picker/asset-picker-upload.service';
import { StudioWorkspaceService } from './workspace/services/studio-workspace.service';

/**
 * Services that should be scoped to Design View and also respect
 * the regular lifecycle (i.e ngOnDestroy).
 * Services that depend on EditorStateService has to be provided here
 */
export const SERVICES = [
    MutatorService,
    PropertiesService,
    HistoryService,
    CopyPasteService,
    SaveErrorHandlerService,
    TimelineScrollService,
    TimelineZoomService,
    TimelineTransformService,
    KeyframeService,
    WorkspaceTransformService,
    AnimationRecorderService,
    AnimationService,
    ValidationService,
    ColorService,
    SocialGuideService,
    ElementHighlightService,
    ElementSelectionService,
    SelectionNetService,
    ElementSelectionBoundingBoxService,
    WorkspaceGradientHelperService,
    EditorEventService,
    MediaLibraryService,
    EditorStateService,
    BrandLibraryElementService,
    LibraryElementRenderingService,
    ElementRenderingService,
    AssetUploadService,
    HeavyVideoService,
    ElementCreatorService,
    NodeCreatorService,
    ElementReplaceService,
    AssetPickerService,
    AssetPickerUploadService,
    EditorSaveStateService,
    StudioWorkspaceService
];
