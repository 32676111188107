import { CreativeMode, ICreativeEnvironment } from '@domain/creative/environment';
import { IFeedState, IFeedStore } from '@domain/creative/feed/feed-store.header';
import { IFontStyle } from '@domain/font';
import { IFontFamily, IFontFamilyStyle } from '@domain/font-families';
import { ICreativeDataNode, OneOfTextDataNodes, OneOfTextViewElements } from '@domain/nodes';
import { characterProperties } from '@domain/property';
import { IContentSpanSplit } from '@domain/rich-text';
import { IRichTextEditorService } from '@domain/rich-text/rich-text.editor.header';
import { IRichText, RichTextEvents } from '@domain/rich-text/rich-text.header';
import { ITextSelection } from '@domain/rich-text/rich-text.selection.header';
import { IEditorState } from '@domain/rich-text/rich-text.types';
import { ITextShadow } from '@domain/style';
import {
    HorizontalAlignment,
    ICharacterProperties,
    ICompositionSpan,
    IContentLine,
    IEllipsesSpan,
    IEndSpan,
    INewlineSpan,
    ISpaceSpan,
    IText,
    ITextElementProperties,
    IVariableSpan,
    IWordSpan,
    JoinType,
    OneOfContentSpans,
    OneOfEditableSpans,
    OneOfRenderedSpans,
    SpanType,
    TextDirection,
    TextElementAndCharacterStyleProperties,
    TextStyle,
    VARIABLE_PREFIX
} from '@domain/text';
import { isSafari } from '@studio/utils/ad/browser';
import { isIframe } from '@studio/utils/ad/dom';
import { cloneDeep } from '@studio/utils/clone';
import { __inject, inject } from '@studio/utils/di.decorators';
import { injectFontFace } from '@studio/utils/dom-utils';
import { handleError } from '@studio/utils/errors';
import { EventEmitter } from '@studio/utils/event-emitter';
import { isBannerflow } from '@studio/utils/url';
import { encodeHtml, exclude, pick } from '@studio/utils/utils';
import { hasActionPreventClickthrough, hasActionTargetUrl } from '../../actions/actions.utils';
import { Color } from '../../color';
import { parseColor, toRGBA } from '../../color.utils';
import { T as S } from '../../creative.container';
import { L_BIDI_CHAR_RANGE_MAP, RTL_MARK, R_AND_AL_BIDI_CHAR_RANGE_MAP } from './rich-text.bidi-chars';
import { isSpanMergeable_m } from './rich-text.span.utils';
import {
    CharacterCode,
    calculateTop,
    copySpan,
    copySpans,
    copyStyle,
    createSpansFromString,
    forEachSpan,
    getHorizontalFlexAlignment,
    getVerticalFlexAlignment,
    hasSameStyleProperty,
    isContentSpan,
    isVariableSpan
} from './text-nodes';

export const STYLE_INDEX_MARGIN_RIGHT = 4;
export const FALLBACK_FONT_FAMILY =
    'Helvetica, Arial, Tahoma, "Microsoft Yahei", 微软雅黑, STXihei, 华文细黑, sans-serif';

interface StyleParameters {
    style: Partial<ICharacterProperties>;
    styleAttribute: CSSStyleDeclaration;
    element: HTMLElement;
    useRelativeFontSize: boolean;
}
interface ISize {
    width: number;
    height: number;
}

export interface ITextRange {
    startNode: Node;
    startOffset: number;
    endNode: Node;
    endOffset: number;
}

interface ISpanDistributionAbortSignal {
    shouldAbort: boolean;
}

export interface IRichTextRenderOption {
    renderUnderscoreLayer?: boolean;
    element?: OneOfTextDataNodes;
    viewElement?: OneOfTextViewElements;
    feedStore?: IFeedStore;
    skipFontLoad?: boolean;
    inDesignView?: boolean;
    document?: ICreativeDataNode;
    renderOverflowedText?: boolean;
}

export type IStyle = Partial<ICharacterProperties>;

export class RichText extends EventEmitter<RichTextEvents> implements IRichText {
    style: TextStyle;
    textElement_m: HTMLDivElement;
    textLines_m: IContentLine[];
    lineToTextLineElementMap_m: Map<number, HTMLDivElement>;
    textLineElementToLineMap_m: WeakMap<HTMLDivElement, number>;
    selectionSpanElements_m: HTMLSpanElement[] = [];
    lineToSpanDecorationLineElementMap_m = new Map<number, HTMLDivElement>();
    lineToSpanDecorationBackgroundLineElementMap_m = new Map<number, HTMLDivElement>();
    fontSizeDiff_m: number;
    reachedTruncationLine_m = false;
    spans_m: OneOfEditableSpans[] = [];
    lastVerticalMargin_m = 0;
    wordWidth_m = 0;
    variableSpanMap_m = new Map<
        string,
        { span: OneOfContentSpans; style: Partial<ICharacterProperties> }
    >();
    rows_m = 0;
    renderUnderscoreLayer_m?: boolean;
    element_m?: OneOfTextDataNodes;
    viewElement_m?: OneOfTextViewElements;
    initTask_m: Promise<void>;
    feedStore_m: IFeedStore;
    blurSuspensionListeners_m: { element: HTMLElement; callback: (...args: unknown[]) => void }[] = [];
    centerElement_m: HTMLDivElement;

    /**
     * Resized element font size is the scaled element font size and not the original. When we resize to fit
     * text inside a box, we want to store the resized(scaled) element font size.
     */
    resizedElementFontSize_m: number;

    private _centerElements: HTMLDivElement[] = [];
    private _lastBreakIndex?: number;
    private _previousSpanType?: SpanType;
    private _scrollHeight: number;
    private _currentContentLine?: IContentLine;
    private _previousContentLine?: IContentLine;
    private _scale = 1;
    private _widestWord = 0;
    private _longestLine: number;
    private _currentLineWidth = 0;
    private _skipFontLoad: boolean;
    private _elementStyle: TextStyle;
    private _calibratorElement: HTMLDivElement;
    private _textIntrospecter?: CanvasRenderingContext2D;

    constructor(
        public text_m: IText,
        public rootElement_m: HTMLDivElement,
        private _option: IRichTextRenderOption,
        @inject(S.ENVIRONMENT)
        private _env: ICreativeEnvironment,
        @inject(S.EDITOR_STATE, { optional: true })
        public editorState_m?: IEditorState,
        @inject(S.RICH_TEXT_EDITOR, { optional: true })
        public editor_m?: IRichTextEditorService,
        @inject(S.FONT_FAMILIES, { optional: true })
        private _fontFamilies?: IFontFamily[]
    ) {
        super();

        this.spans_m = copySpans(this.text_m.spans);
        this.style = this._elementStyle = { ...this.text_m.style };
        this._syncProperties();
        this._renderTextContainers();

        this.renderUnderscoreLayer_m = _option.renderUnderscoreLayer;
        this.element_m = _option.element;
        this.viewElement_m = _option.viewElement;
        this.feedStore_m = _option.feedStore!;
        this._skipFontLoad = !!_option.skipFontLoad;
    }

    get textSelection_m(): ITextSelection | undefined {
        return this.editor_m?.selection.selection;
    }
    set textSelection_m(selection: ITextSelection | undefined) {
        if (this.editor_m) {
            this.editor_m.selection.selection = selection;
        }
    }

    get zoom_m(): number {
        return this.editorState_m?.zoom || 1;
    }

    /**
     * Returns all the selected characters. Or, if no selection, empty string.
     * Without spaces.
     */
    get selectedCharacters(): string {
        const selection = this.textSelection_m;
        if (!selection) {
            return '';
        }
        let text = '';
        for (const span of this.spans_m) {
            if (span.type === SpanType.Variable && span.style.variable) {
                text += this._getTextFromFeedByVariableSpan(span);
                continue;
            }
            if (span.type !== SpanType.Word) {
                continue;
            }
            // Selected text will be inside spans withoud a line/column defined
            if (typeof span.line === 'undefined' || typeof span.column === 'undefined') {
                text += span.content;
            }
        }
        return text;
    }

    // Space at the end of the TP input
    private get _translationPanelDelta(): number {
        // When in translation panel, the width should be reduced by 20px to avoid horizontal overflow
        /** @@remove STUDIO:START */
        if (this._env.MODE === CreativeMode.TranslationPanel) {
            return 20;
        }
        /** @@remove STUDIO:END */
        return 0;
    }

    private _getTextFromFeedByVariableSpan(span: IVariableSpan): string {
        // Get all feeded characters
        const feedData = this.feedStore_m.getFeed(span.style.variable!.id);
        const allTextsFromFeed = feedData?.data
            ?.map(feedElement => feedElement[span.style.variable!.path || '']?.value ?? '')
            .join('');
        return allTextsFromFeed ?? '';
    }

    init_m(): void {
        this.renderTextIntrospecter_m();
        this.initTask_m = new Promise<void>(async resolve => {
            await this._applyFontInRootElement();
            this.runTextPipeline_m(/* normalize */ false);
            resolve();
        });
    }

    private _renderTextContainers(): void {
        this.rootElement_m.style.backgroundColor = 'transparent';

        this.centerElement_m = document.createElement('div');
        this.centerElement_m.classList.add('center');
        const style = this.centerElement_m.style;
        style.width = '100%';
        style.height = '100%';
        style.display = 'flex';
        style.alignItems = getVerticalFlexAlignment(this.style.verticalAlignment);
        style.justifyContent = getHorizontalFlexAlignment(this.style.horizontalAlignment);
        style.zIndex = '1';

        this.textElement_m = document.createElement('div');
        this.textElement_m.classList.add('text-content');
        this.textElement_m.style.outline = 'none';
        this.textElement_m.style.position = 'relative';
        this.textElement_m.style.width = '100%';

        // For better sharpness
        this.textElement_m.spellcheck = false;

        this.centerElement_m.appendChild(this.textElement_m);
        this.rootElement_m.appendChild(this.centerElement_m);
    }

    resetDirtyContent(element: OneOfTextDataNodes): void {
        element.__dirtyContent = undefined;
    }

    setText(text: IText, keepSelection?: boolean, reinitialize?: boolean, keepStyle?: boolean): void {
        /** @@remove STUDIO:START */
        if (!keepSelection && this.editor_m) {
            this.editor_m.selection.clearSelection();
            this.textSelection_m = undefined;
        }
        /** @@remove STUDIO:END */
        this.text_m = text;
        this.spans_m = text.spans;
        if (reinitialize) {
            this.variableSpanMap_m = new Map();
        } else {
            this.normalizeTextLineSpans_m();
        }

        if (!keepStyle) {
            this.style = text.style;
        }
        this._syncProperties();
        this.runTextPipeline_m(/* normalize */ !reinitialize);
        this.emit('change', this.getText_m());
    }

    // TODO: This is not fired when swapping language, potential memory leak.
    destroy_m(): void {
        if (this.editor_m?.inEditMode) {
            this.editor_m.stopEdit(true);
        }
        this.blurSuspensionListeners_m = [];
        for (const blurSuspension of this.blurSuspensionListeners_m) {
            blurSuspension.element.removeEventListener('mousedown', blurSuspension.callback);
        }
        this._removeTextIntrospecter();
        if (this.editor_m) {
            window.removeEventListener('mouseup', this.editor_m.mouseBindings.onMouseUp);
            window.removeEventListener('mousemove', this.editor_m.mouseBindings.onMouseMove);
        }
        this.emit('destroyed');
        this.clearEvents();
    }

    private _syncProperties(): void {
        const computedStyle = getComputedStyle(this.rootElement_m);
        this._syncFontSize(computedStyle);
        this._syncTextColor(computedStyle);
        this._syncLineHeight(computedStyle);
        this._syncPadding(computedStyle);
        this._syncDimensions();
        this._syncHorizontalAlignment();
    }

    private _syncFontSize(computedStyle: CSSStyleDeclaration): void {
        if (!this.style.fontSize) {
            this.style.fontSize = parseInt(computedStyle.fontSize, 10);
        }
    }

    private _syncTextColor(computedStyle: CSSStyleDeclaration): void {
        if (!this.style.textColor) {
            this.style.textColor = parseColor(computedStyle.color);
        }
    }

    private _syncLineHeight(computedStyle: CSSStyleDeclaration): void {
        if (!this.style.lineHeight) {
            this.style.lineHeight = parseFloat(computedStyle.lineHeight);
        }
    }

    private _syncPadding(computedStyle: CSSStyleDeclaration): void {
        if (!this.style.padding) {
            this.style.padding = {
                top: parseInt(computedStyle.paddingTop, 10) || 0,
                left: parseInt(computedStyle.paddingLeft, 10) || 0,
                bottom: parseInt(computedStyle.paddingBottom, 10) || 0,
                right: parseInt(computedStyle.paddingRight, 10) || 0
            };
        }
    }

    private _syncDimensions(): void {
        if (!this.style.width || !this.style.height) {
            const boundingRect = this.rootElement_m.getBoundingClientRect();
            this.style.width = boundingRect.width;
            this.style.height = boundingRect.height;
        }
    }

    // Should default to the first character direction.
    private _syncHorizontalAlignment(): void {
        if (!this.style.horizontalAlignment) {
            const firstChar = this.spans_m[0]?.content.charCodeAt(0);
            const dir = RichText.getBidiDirection_m(firstChar);

            this.style.horizontalAlignment = dir === TextDirection.Rtl ? 'right' : 'left';
        }
    }

    private _recalibrateScale(): void {
        if (isIframe() && isBannerflow(window.self.location.origin)) {
            /**
             * Some strange race-condition when ads are loaded in our iframe tag cauess height to be 0
             * resulting in that scale becomes 0 which means texts are barely visible.
             */
            this._scale = this._calibratorElement.getBoundingClientRect().height / 10 || 1;
        } else {
            this._scale = this._calibratorElement.getBoundingClientRect().height / 10;
        }
    }

    async rerender(applyFontInRootElement = false, element?: OneOfTextViewElements): Promise<void> {
        this._recalibrateScale();
        await this.initTask_m;

        if (applyFontInRootElement) {
            await this._applyFontInRootElement();
        }

        if (element) {
            this.style.width = element.width;
            this.style.height = element.height;
        }

        this._removeClickListeners();

        this._storeSelectionCharacterPositions();

        this.runTextPipeline_m(/* normalize */ false);

        this._reselectText();
    }

    private _removeClickListeners(): void {
        if (this.textLineElementToLineMap_m) {
            this.lineToTextLineElementMap_m.forEach(line => {
                line.removeEventListener('click', this._onClick);
                (Array.from(line.children) as HTMLElement[]).forEach(child => {
                    child.removeEventListener('click', this._onClick);
                });
            });
        }
    }

    private _storeSelectionCharacterPositions(): void {
        if (this.textSelection_m && this.editor_m?.inEditMode) {
            this.editor_m.selection.storeSelectionCharacterPositions();
        }
    }

    private _reselectText(): void {
        if (this.textSelection_m && this.editor_m?.inEditMode) {
            this.editor_m.selection.reselectText();
        }
    }

    runTextPipeline_m(normalize = true): void {
        if (normalize) {
            this.normalizeTextLineSpans_m();
        }
        let expandedSpans = this.spans_m;
        if (!this.editor_m?.inEditMode && !this.renderUnderscoreLayer_m) {
            expandedSpans = this.expandVariableSpans_m(expandedSpans);
        }
        expandedSpans = this._segmentBidiCharacters(expandedSpans);
        this._applyJointCharacters(expandedSpans);
        this.distributeAllSpansAcrossTextLines_m(expandedSpans);
        if (this.style.textOverflow === 'shrink') {
            this.resizeText(expandedSpans);
        }
        this._applyInferredTextDirections();
        this._applyLeftToSpans();
        this.renderTextWithDecorations_m();
    }

    private _applyLeftToSpans(): void {
        const isLeftAligned = this.style.horizontalAlignment === 'left';
        for (const textLine of this.textLines_m) {
            let left = isLeftAligned
                ? 0
                : (this.style.width ?? 0) - this.style.padding.left - this.style.padding.right;
            let currentRtlSpans: OneOfRenderedSpans[] = [];
            let currentLtrSpans: OneOfRenderedSpans[] = [];
            for (const span of textLine.spans) {
                if (span.dir === TextDirection.Rtl) {
                    if (isLeftAligned) {
                        span.left = left;
                        let width = span.width;
                        for (const s of currentRtlSpans) {
                            s.left = left + width;
                            width += s.width;
                        }
                        currentRtlSpans.unshift(span);
                    } else {
                        if (currentLtrSpans.length > 0) {
                            const lastLtrSpan = currentLtrSpans.pop()!;
                            left -= lastLtrSpan.left + lastLtrSpan.width;
                        }
                        left -= span.width;
                        span.left = left;
                        currentLtrSpans = [];
                    }
                } else {
                    if (isLeftAligned) {
                        if (currentRtlSpans.length > 0) {
                            const lastRtlSpan = currentRtlSpans.pop()!;
                            left += lastRtlSpan.left + lastRtlSpan.width;
                        }
                        span.left = left;
                        left += span.width;
                        currentRtlSpans = [];
                    } else {
                        span.left = left;
                        let width = span.width;
                        for (const s of currentLtrSpans) {
                            s.left = left - width;
                            width -= s.width;
                        }
                        currentLtrSpans.unshift(span);
                    }
                }
            }
        }
    }

    private _applyInferredTextDirections(): void {
        const isForcedRTL = this.isForcedRTL_m();
        for (const textLine of this.textLines_m) {
            let prevDir = TextDirection.Ltr;
            let nextDir: TextDirection | undefined = TextDirection.Ltr;

            for (let i = 0; i < textLine.spans.length; i++) {
                nextDir = i + 1 < textLine.spans.length ? textLine.spans[i + 1].dir : undefined;
                const span = textLine.spans[i];
                const isFirstSpan = i === 0;
                const isLastSpan = i === textLine.spans.length - (textLine.isLastLine ? 2 : 1);
                if (
                    span.type === SpanType.Variable &&
                    span.dir === TextDirection.InferFromContext &&
                    isForcedRTL
                ) {
                    span.dir = TextDirection.Rtl;
                    if (isFirstSpan) {
                        textLine.dir = TextDirection.Rtl;
                    }
                }

                if (
                    span.dir === TextDirection.InferFromContext ||
                    span.dir === TextDirection.Previous
                ) {
                    if (isFirstSpan) {
                        span.dir = TextDirection.Ltr;
                        textLine.dir = TextDirection.Ltr;
                    } else if (isLastSpan) {
                        // Note, trailing space in LTR text are rendered like this 'wefصثب '. So it has to be LTR.
                        // But, trailing space in RTL context is rendered like this ' صثب'.
                        span.dir = textLine.dir;
                    } else {
                        span.dir = this._determineSpaceSpanDirection({
                            isSpaceSpan: span.type === SpanType.Space,
                            prevDir,
                            nextDir,
                            textLineDir: textLine.dir
                        });
                    }
                } else {
                    prevDir = span.dir!;
                }
            }
        }
    }

    private _determineSpaceSpanDirection({
        isSpaceSpan,
        prevDir,
        nextDir,
        textLineDir
    }: {
        isSpaceSpan: boolean;
        prevDir: TextDirection;
        nextDir: TextDirection | undefined;
        textLineDir: TextDirection;
    }): TextDirection {
        if (isSpaceSpan) {
            if (
                this._isRtlContinuation(prevDir, nextDir, textLineDir) ||
                this._isRtlSwitchFromLtr(prevDir, nextDir, textLineDir)
            ) {
                return TextDirection.Rtl;
            } else {
                return TextDirection.Ltr;
            }
        } else {
            return prevDir;
        }
    }

    private _isRtlContinuation(
        prevDir: TextDirection,
        nextDir: TextDirection | undefined,
        textLineDir: TextDirection
    ): boolean {
        return (
            prevDir === TextDirection.Rtl &&
            (nextDir === TextDirection.Rtl ||
                (nextDir === TextDirection.Ltr && textLineDir === TextDirection.Rtl))
        );
    }

    private _isRtlSwitchFromLtr(
        prevDir: TextDirection,
        nextDir: TextDirection | undefined,
        textLineDir: TextDirection
    ): boolean {
        return (
            prevDir === TextDirection.Ltr &&
            nextDir === TextDirection.Rtl &&
            textLineDir === TextDirection.Rtl
        );
    }

    getSpanLength_m(span: OneOfRenderedSpans): number {
        if (isContentSpan(span)) {
            return span.content.length + (span.startJoint ? 1 : 0) + (span.endJoint ? 1 : 0);
        }
        return 0;
    }

    private _applyJointCharacters(spans: OneOfEditableSpans[]): void {
        for (let i = 0; i < spans.length - 1; i++) {
            const curSpan = spans[i];
            const nextSpan = spans[i + 1];
            if (
                curSpan.type === SpanType.Word &&
                nextSpan.type === SpanType.Word &&
                curSpan.dir === TextDirection.Rtl &&
                nextSpan.dir === TextDirection.Rtl
            ) {
                curSpan.endJoint = JoinType.ZeroWidthJoint;
                nextSpan.startJoint = JoinType.ZeroWidthJoint;
            } else {
                curSpan.endJoint = JoinType.ZeroWidthNoJoint;
            }
        }
    }

    private _segmentBidiCharacters(spans: OneOfEditableSpans[]): OneOfEditableSpans[] {
        const result: OneOfEditableSpans[] = [];
        for (const span of spans) {
            if (isContentSpan(span)) {
                if (span.content.length === 0) {
                    result.push(span);
                    continue;
                }
                let currentSpan = span.type === SpanType.Variable ? span : copySpan(span);
                const charAtZero = span.content.charCodeAt(0);
                let currentDirection = RichText.getBidiDirection_m(charAtZero);

                if (span.type === SpanType.Word) {
                    currentSpan.content = String.fromCharCode(charAtZero);
                    currentSpan.dir = currentDirection;
                    for (let i = 1; i < span.content.length; i++) {
                        const char = span.content.charCodeAt(i);
                        const direction = RichText.getBidiDirection_m(char);
                        if (currentDirection !== direction && direction !== TextDirection.Previous) {
                            result.push(currentSpan);
                            currentSpan = copySpan(span);
                            currentSpan.content = String.fromCharCode(char);
                            currentSpan.dir = currentDirection = direction;
                        } else {
                            currentSpan.content += String.fromCharCode(char);
                        }
                    }
                } else if (isVariableSpan(span)) {
                    currentSpan.dir = currentDirection;
                } else {
                    currentSpan.dir = TextDirection.InferFromContext;
                }
                result.push(currentSpan);
                continue;
            } else {
                span.dir = TextDirection.Ltr;
            }
            result.push(span);
        }
        return result;
    }

    private _getSpansFromTextLines(): OneOfEditableSpans[] {
        const spans: OneOfEditableSpans[] = [];
        for (const textLine of this.textLines_m) {
            for (const span of textLine.spans) {
                if (span.type === SpanType.Ellipsis) {
                    throw new Error('Ellipsis cannot exist in this stage.');
                }
                spans.push(span);
            }
        }
        return spans;
    }

    private _linearlyScaleText(width: number, height: number): void {
        const widthOccupation =
            this._longestLine !== 0 && this.style.width !== 0 ? this._longestLine / width : 0;
        const heightOccuptation =
            this._scrollHeight !== 0 && this.style.height !== 0 ? this._scrollHeight / height : 0;
        if (heightOccuptation > widthOccupation) {
            this.resizedElementFontSize_m = this.resizedElementFontSize_m / heightOccuptation;
            this._scaleTextLineMetadata(1 / heightOccuptation);
        } else {
            this.resizedElementFontSize_m = this.resizedElementFontSize_m / widthOccupation;
            this._scaleTextLineMetadata(1 / widthOccupation);
        }
    }

    private _scaleTextLineMetadata(scale: number): void {
        for (const textLine of this.textLines_m) {
            textLine.lineHeight *= scale;
            textLine.lineWidth *= scale;
            textLine.trailingSpaceWidth *= scale;
            for (const span of textLine.spans) {
                span.width *= scale;
                span.lineHeight *= scale;
            }
        }
    }

    private _getInlineContentArea(): number {
        let area = 0;
        for (const textLine of this.textLines_m) {
            area += textLine.lineWidth * textLine.lineHeight;
        }
        return area;
    }

    private _calculateElementDimensions(): {
        elementHeight: number;
        elementWidth: number;
        elementArea: number;
    } {
        const elementHeight = Math.max(
            (this.style.height ?? 0) - this.style.padding.top - this.style.padding.bottom,
            0
        );
        const elementWidth = Math.max(
            (this.style.width ?? 0) - this.style.padding.left - this.style.padding.right,
            0
        );
        const elementArea = elementWidth * elementHeight;
        return { elementHeight, elementWidth, elementArea };
    }

    resizeText(spans: OneOfEditableSpans[]): void {
        const { elementHeight, elementWidth, elementArea } = this._calculateElementDimensions();

        // Text resize doesn't work with too small element size.
        if (elementHeight < 3 || elementWidth < 3) {
            this.resizedElementFontSize_m = 0;
            return;
        }

        let inFirstLoop = true;
        // Decreased ratio increment for MV to achieve more stable scaling.
        // It makes getting the correct font size less performant by a factor of 10
        const RATIO_INCREASE = this._env.MODE === CreativeMode.ManageView ? 0.01 : 0.1;

        let bestOccupation = 0;
        let bestFontSize = this.resizedElementFontSize_m;

        do {
            const contentWidth = this._longestLine;
            const contentHeight = this._scrollHeight || 0;
            const widthOccupation = contentWidth / elementWidth;
            const heightOccupation = contentHeight / elementHeight;
            const contentArea = this._getInlineContentArea();
            const noOverflow = contentWidth <= elementWidth && contentHeight <= elementHeight;

            // Cancel resize when we hit a tolerance of 1% diff area. Or if it is in the first loop, because then it does not need resize to fit
            if (noOverflow && inFirstLoop) {
                this._resetResizeMetadata();
                return;
            }
            if (inFirstLoop) {
                const newResettedFontSize = 40;
                this.resizedElementFontSize_m = newResettedFontSize;
                this._resetResizeMetadata();
                this.distributeAllSpansAcrossTextLines_m(spans, this.resizedElementFontSize_m);

                this.fontSizeDiff_m = 1 / this.resizedElementFontSize_m;
                this.resizedElementFontSize_m = 1;
                this._redistributeAllSpansAcrossLines(spans, false);
                inFirstLoop = false;
                continue;
            }

            // We are taking 0.1(different value when in MV) steps incrementally to find the optimal font size. Note, we tried to increase it faster
            // in different tiers before, but the fallback we saw with that solution is that it often hits "pockets", where
            // it cannot come out from.
            const fontSizeDiffFactor =
                (this.resizedElementFontSize_m + RATIO_INCREASE) / this.resizedElementFontSize_m;

            // Occupation factor defines how "good" a particular font size occupies the text. We use the ratio of areas and ratio of ratios
            // as a measure. Since, having just one of these generates positive falses, and we want to filter them.
            const areaRatio = contentArea / elementArea;
            const ratioRatio = contentWidth / contentHeight / (elementWidth / elementHeight);

            // We want to construct an occupation factor which where the area ratio is weighted higher than ratio of ratio.
            // We divide one by abs(1- area/ratio) to get an inverse proportion relation of the proximity of 1.
            const areaRatioWeight = 0.9;
            const ratioRatioWeight = RATIO_INCREASE;
            const occupationFactor =
                (1 / Math.abs(1 - areaRatio)) * areaRatioWeight +
                (1 / Math.abs(1 - ratioRatio)) * ratioRatioWeight;
            if (noOverflow) {
                if (occupationFactor >= bestOccupation) {
                    bestOccupation = occupationFactor;
                    bestFontSize = this.resizedElementFontSize_m;
                }
            }
            if (widthOccupation !== heightOccupation) {
                this.fontSizeDiff_m = fontSizeDiffFactor;
                this.resizedElementFontSize_m = this.resizedElementFontSize_m * fontSizeDiffFactor;
                this._redistributeAllSpansAcrossLines(spans, false);
            } else {
                this._resetResizeMetadata();
                return;
            }
        } while (this.resizedElementFontSize_m < this.style.fontSize);

        this.fontSizeDiff_m = bestFontSize / this.resizedElementFontSize_m;
        this.resizedElementFontSize_m = bestFontSize;

        // Hack for Safari https://github.com/GoogleForCreators/web-stories-wp/issues/6323
        if (isSafari) {
            this.resizedElementFontSize_m = Math.floor(this.resizedElementFontSize_m);
        }
        this._redistributeAllSpansAcrossLines(spans, false);

        this._linearlyScaleText(elementWidth, elementHeight);
        this._resetResizeMetadata();
    }

    private _resetResizeMetadata(): void {
        this.lastVerticalMargin_m = 0;
    }

    setStyle_m<K extends keyof TextElementAndCharacterStyleProperties>(
        property: K,
        value: TextElementAndCharacterStyleProperties[K],
        style: Partial<TextElementAndCharacterStyleProperties>
    ): void {
        if (hasSameStyleProperty(property, { [property]: value }, this.style)) {
            if (style !== this.style) {
                delete style[property];
            }
        } else {
            if (value === '$mixed') {
                style[property] = value;
                return;
            }

            switch (property) {
                case 'textColor':
                    style.textColor = (value as Color).copy();
                    break;
                case 'font':
                case 'padding':
                    style[property] = Object.assign({}, value);
                    break;
                case 'textShadows':
                    style.textShadows = value && (value as ITextShadow[]).map(item => ({ ...item }));
                    break;
                case 'strikethrough':
                case 'underline':
                case 'uppercase':
                    if (typeof value !== 'boolean') {
                        delete style[property];
                    } else {
                        style[property] = value;
                    }
                    break;
                default:
                    style[property] = value;
                    break;
            }
        }
    }

    getText_m(): IText {
        return {
            style: this.style,
            spans: copySpans(this.spans_m)
        };
    }

    splitSpan_m(span: OneOfContentSpans, column: number): IContentSpanSplit {
        const preContent = span.type === SpanType.Variable ? '' : span.content.slice(0, column);
        const postContent = span.type === SpanType.Variable ? span.content : span.content.slice(column);

        const preContentSpan = this._createContentSpan(span, preContent);
        const postContentSpan = this._createContentSpan(span, postContent);

        return { preContentSpan, postContentSpan };
    }

    private _createContentSpan(span: OneOfContentSpans, content: string): IWordSpan | ISpaceSpan {
        return {
            type: span.type,
            dir: span.dir,
            content: content,
            style: copyStyle(span.style),
            attributes: this._getContentSpanAttributes(span),
            styleIds: cloneDeep(span.styleIds),
            styleId: span.styleId,
            __previousStyleIds: span.__previousStyleIds && [...span.__previousStyleIds],
            __previousStyleIdToHistoryIndexMap:
                span.__previousStyleIdToHistoryIndexMap &&
                new Map(span.__previousStyleIdToHistoryIndexMap)
        } as IWordSpan | ISpaceSpan;
    }

    private _getContentSpanAttributes(span: OneOfContentSpans): {
        styleIndex?: number;
        shouldRenderNumber: boolean;
    } {
        return span.attributes.styleIndex !== undefined
            ? { styleIndex: span.attributes.styleIndex, shouldRenderNumber: false }
            : { shouldRenderNumber: false };
    }

    removeDecorationLayers_m(): void {
        let centerElement: HTMLDivElement | undefined;
        while ((centerElement = this._centerElements.pop())) {
            if (this.rootElement_m.contains(centerElement)) {
                this.rootElement_m.removeChild(centerElement);
            }
        }
    }

    private async _applyFontInRootElement(): Promise<void> {
        this.textLines_m = [];

        // Don't try to preload fonts if there are no uploaded fonts
        if (this._shouldPreloadFonts()) {
            try {
                /** @@remove STUDIO:START */
                await this._preloadFonts();
                /** @@remove STUDIO:END */
            } catch {
                handleError('Could not load font face', {
                    enabled: !!this._env.STUDIO_JS,
                    contexts: { fontFaceId: this.style.font!.id }
                });
            }
        }

        this._updateRootElementFontSize();
    }

    private _shouldPreloadFonts(): boolean {
        return !this._skipFontLoad && !!this.style.font;
    }
    /** @@remove STUDIO:START */
    private async _preloadFonts(): Promise<void> {
        if (this._env.STUDIO_JS) {
            const fontStylesInSpans = this._getAllFontsInSpans().add(this.style.font!.id);
            const fontFaces = await this._loadFontFaces(fontStylesInSpans);
            this._addFontFacesToDocument(fontFaces);
        }
    }
    /** @@remove STUDIO:END */

    /** @@remove STUDIO:START */
    private async _loadFontFaces(fontStylesInSpans: Set<string>): Promise<FontFace[]> {
        const fontLoads: Promise<FontFace>[] = [];

        for (const fontStyleId of fontStylesInSpans.values()) {
            const fontStyle = this._findFontStyleById(fontStyleId);

            if (!fontStyle) {
                throw new Error(`Did not find font style '${fontStyleId}'.`);
            }

            fontLoads.push(injectFontFace(fontStyle));
        }

        return Promise.all(fontLoads);
    }
    /** @@remove STUDIO:END */

    /** @@remove STUDIO:START */
    private _findFontStyleById(fontStyleId: string): IFontFamilyStyle | undefined {
        for (const fontFamily of this._fontFamilies!) {
            const fontStyle = fontFamily.fontStyles.find(({ id }) => id === fontStyleId);

            if (fontStyle) {
                return fontStyle;
            }
        }

        return undefined;
    }
    /** @@remove STUDIO:END */

    /** @@remove STUDIO:START */
    private _addFontFacesToDocument(fontFaces: FontFace[]): void {
        for (const fontFace of fontFaces) {
            document.fonts.add(fontFace);
        }
    }
    /** @@remove STUDIO:END */

    private _updateRootElementFontSize(): void {
        this.rootElement_m.style.fontSize = `${this.style.fontSize}px`;
    }

    private _getAllFontsInSpans(): Set<string /* fontStyleId */> {
        const fontStyleIds = new Set<string>();

        const documentId = this.editorState_m?.document.id ?? this._option.document!.id;
        for (const span of this.text_m.spans) {
            if (isContentSpan(span)) {
                const styleId = span.styleIds[documentId];
                const style = this._option.element!.characterStyles.get(styleId)!;
                if (style?.font) {
                    fontStyleIds.add(style.font.id);
                }
            }
        }
        return fontStyleIds;
    }

    renderTextIntrospecter_m(): void {
        if (!this._textIntrospecter) {
            this._calibratorElement = document.createElement('div');
            this._calibratorElement.style.height = '10px';
            this._calibratorElement.style.top = '-10000px';
            this._calibratorElement.style.position = 'absolute';
            this.textElement_m.ownerDocument.body.appendChild(this._calibratorElement);

            const canvas = this.textElement_m.ownerDocument.createElement('canvas');
            this._textIntrospecter = canvas.getContext('2d')!;
        }
    }

    private _removeTextIntrospecter(): void {
        this._textIntrospecter = undefined;
        this._calibratorElement.remove();
    }

    private _emptyTextLines(textLines: IContentLine[]): void {
        this._currentContentLine = {
            spans: [
                {
                    type: SpanType.End,
                    content: 'END',
                    dir: TextDirection.Ltr,
                    top: 0,
                    left: 0,
                    width: 0,
                    height: 0,
                    lineHeight: 0,
                    attributes: {},
                    startJoint: JoinType.None,
                    endJoint: JoinType.None
                }
            ],
            dir: TextDirection.InferFromContext,
            lineHeight: 0,
            maxFontSize: 1,
            lineWidth: 0,
            characterWidth: 0,
            trailingSpaceWidth: 0,
            endsWithNewline: false,
            isLastLine: false
        };
        textLines.splice(0, Infinity, this._currentContentLine);
    }

    private _truncateCurrentContentLine(textLines: IContentLine[]): void {
        let lineWidth = 0;
        const spans: OneOfRenderedSpans[] = [];
        outer: for (let i = 0; i < this._currentContentLine!.spans.length; i++) {
            const span = this._currentContentLine!.spans[i] as OneOfContentSpans | IEndSpan;
            if (span.type === SpanType.End) {
                break;
            }
            if (span.type === SpanType.Newline) {
                spans.push({
                    type: SpanType.Ellipsis,
                    content: '...',
                    style: {},
                    top: 0,
                    left: 0,
                    width: 0,
                    height: 0,
                    lineHeight: 0,
                    attributes: {},
                    startJoint: JoinType.None,
                    endJoint: JoinType.None
                });
                break;
            }
            const spanSize = this._introspectSpan(
                {
                    type: SpanType.Word,
                    content: '...',
                    lineHeight: 0,
                    dir: TextDirection.Ltr,
                    style: span.style,
                    top: 0,
                    left: 0,
                    width: 0,
                    height: 0,
                    attributes: {},
                    styleIds: {},
                    __previousStyleIds: this.editorState_m ? [] : undefined,
                    __previousStyleIdToHistoryIndexMap: this.editorState_m
                        ? new Map<string, number>()
                        : undefined
                },
                /* useDom */ true
            );
            const relativeFontSize = this.resizedElementFontSize_m;
            const characterSpacing = this.style.characterSpacing * relativeFontSize;
            const ellipsisWidth = spanSize.width - characterSpacing;

            const copyOfSpan = copySpan(span);
            for (let offset = 0; offset <= span.content.length; offset++) {
                copyOfSpan.content = span.content.substring(0, offset);
                const introspectSpanSize = this._introspectSpan(copyOfSpan, true);
                const spanWidth = introspectSpanSize.width;
                if (
                    introspectSpanSize &&
                    lineWidth + spanWidth + ellipsisWidth >
                        (this.style.width || 0) - (this.style.padding.top + this.style.padding.bottom)
                ) {
                    if (offset === 0) {
                        // If ellipses is wider than the element width. Don't show anything.
                        this._emptyTextLines(textLines);
                        return;
                    }
                    const { preContentSpan } = this.splitSpan_m(span, offset - 1);
                    preContentSpan.width = spanWidth;
                    preContentSpan.lineHeight = introspectSpanSize.height;
                    spans.push(preContentSpan, {
                        type: SpanType.Ellipsis,
                        content: '...',
                        top: 0,
                        style: span.style,
                        left: 0,
                        width: 0,
                        height: 0,
                        lineHeight: 0,
                        attributes: {},
                        startJoint: JoinType.None,
                        endJoint: JoinType.None
                    });
                    break outer;
                }
            }
            spans.push(span);
            lineWidth += span.width;
        }
        this._currentContentLine!.spans = spans;
        this._calculateCurrentContentLineDimensions();
    }

    /**
     * Distribute one span across text lines.
     *
     * @param editableSpan Span to be distributed.
     * @param textLines Targeted text lines to receive the span.
     * @param abortSignal Abort signal that is used in distributeAllSpansAcrossTextLines to abort distribution when needed.
     * @param useDomIntrospection Introspect spans using the DOM (expensive)
     * @param restOfSpans Should only be provided to calculate the truncation of current line.
     * @param forceAppendToCurrentContentLine Force append to current contentLine. Should only be called when we already split words.
     * @param forcedFontSize Force to apply a certain font size. The span font size is relative to this font size.
     */
    private _distributeOneSpanAcrossTextLines = (
        editableSpan: OneOfEditableSpans,
        textLines: IContentLine[],
        abortSignal: ISpanDistributionAbortSignal,
        useDomIntrospection = true,
        restOfSpans: OneOfEditableSpans[],
        forceAppendToCurrentContentLine = false,
        forcedFontSize?: number
    ): void => {
        const self = this;
        if (!this._currentContentLine) {
            this._currentContentLine = self._createContentLine();
            textLines.push(this._currentContentLine);
            this._currentLineWidth = 0;
        }

        if (isVariableSpan(editableSpan)) {
            editableSpan.content = editableSpan.content.replace(/%20/gi, ' ');
        }

        const spanSize = this._introspectSpan(editableSpan, useDomIntrospection, forcedFontSize);
        const fontSize =
            (editableSpan as OneOfContentSpans).style?.fontSize || this.resizedElementFontSize_m;
        const lineHeight =
            (editableSpan as OneOfContentSpans).style?.lineHeight || this.style.lineHeight;

        editableSpan.top = ((lineHeight - 1) * fontSize) / 2;
        editableSpan.width = spanSize.width;
        editableSpan.height = spanSize.height - (lineHeight - 1) * fontSize;
        editableSpan.lineHeight = spanSize.height;

        if (editableSpan.type === SpanType.End) {
            appendEndSpan(editableSpan);
            return;
        }
        if (editableSpan.type === SpanType.Newline) {
            appendNewlineSpan(editableSpan);
            return;
        }

        const relativeFontSize = editableSpan.style.fontSize
            ? this.resizedElementFontSize_m * editableSpan.style.fontSize
            : this.resizedElementFontSize_m;
        const characterSpacing =
            (editableSpan.style.characterSpacing ?? this.style.characterSpacing) * relativeFontSize;
        const potentialWordWidth =
            this.wordWidth_m +
            editableSpan.width +
            (editableSpan.attributes.shouldRenderNumber ? STYLE_INDEX_MARGIN_RIGHT : 0) -
            characterSpacing;
        const currentWidth =
            (this.style.width ?? 0) -
            this.style.padding.left -
            this.style.padding.right -
            this._translationPanelDelta;

        const isWordBiggerThanElement =
            !forceAppendToCurrentContentLine &&
            (editableSpan.type === SpanType.Word || editableSpan.type === SpanType.Variable) &&
            potentialWordWidth > currentWidth;

        const shouldHandleWordBiggerThanElement =
            isWordBiggerThanElement &&
            (this._env.MODE === CreativeMode.TranslationPanel ||
                this.style.textOverflow === 'truncate');
        if (shouldHandleWordBiggerThanElement) {
            handleWordsBiggerThanWidthOfElement(editableSpan);
            return;
        }

        const isBreakable =
            this._previousSpanType === SpanType.Space &&
            (editableSpan.type === SpanType.Word || editableSpan.type === SpanType.Variable);
        if (isBreakable) {
            this._lastBreakIndex = this._currentContentLine.spans.length;
        }

        /* ending char spacing should not be breakable */
        const potentialLineWidth = this._currentLineWidth + editableSpan.width - characterSpacing;
        const exceedsCurrentLineWidth = potentialLineWidth >= currentWidth;
        const useNewLine =
            !forceAppendToCurrentContentLine &&
            this._lastBreakIndex !== undefined &&
            editableSpan.type !== SpanType.Space &&
            editableSpan.type !== SpanType.Composition &&
            exceedsCurrentLineWidth &&
            this._currentContentLine.spans.length !== 0;
        if (useNewLine) {
            appendSpansThatExceedsCurrentContentLine(editableSpan);
        } else {
            appendSpanToCurrentContentLine(editableSpan);
        }

        function appendSpanToCurrentContentLine(span: OneOfContentSpans): void {
            self._currentContentLine!.lineWidth += span.width;
            self._currentContentLine!.spans.push(span);
            if (span.type === SpanType.Word || span.type === SpanType.Variable) {
                self.wordWidth_m +=
                    span.width + (span.attributes.shouldRenderNumber ? STYLE_INDEX_MARGIN_RIGHT : 0);
                if (self.wordWidth_m > self._widestWord) {
                    self._widestWord = self.wordWidth_m;
                }
            } else {
                self.wordWidth_m = 0;
            }
            saveSpanPositionLeftAndSpanType();
        }

        function saveSpanPositionLeftAndSpanType(): void {
            self._currentLineWidth += editableSpan.width;
            if (editableSpan.attributes?.shouldRenderNumber) {
                self._currentLineWidth += STYLE_INDEX_MARGIN_RIGHT;
            }
            self._previousSpanType = editableSpan.type;
        }

        function appendSpansThatExceedsCurrentContentLine(span: OneOfContentSpans): void {
            const belowMaxRows = !self.style.maxRows || self.rows_m < self.style.maxRows;
            if ((self.editor_m?.inEditMode && self.style.textOverflow === 'truncate') || belowMaxRows) {
                const spinOffSpans = self._currentContentLine!.spans.splice(
                    self._lastBreakIndex!,
                    Infinity
                );
                self._calculateCurrentContentLineDimensions();
                const nextLineSpans = spinOffSpans.concat([span]);
                self._addScrollHeight(
                    textLines,
                    abortSignal,
                    (nextLineSpans as OneOfEditableSpans[]).concat(restOfSpans)
                );
                if (self.reachedTruncationLine_m) {
                    return;
                }
                self._currentContentLine = self._createContentLine();
                textLines.push(self._currentContentLine);
                self.rows_m++;
                if (!self.editor_m?.inEditMode && self.style.textOverflow === 'truncate') {
                    self.wordWidth_m = 0; // All spans are redacted and redistributed. So word width should be zero.
                    for (let i = 0; i < nextLineSpans.length; i++) {
                        if (self.reachedTruncationLine_m) {
                            return;
                        }
                        self._distributeOneSpanAcrossTextLines(
                            nextLineSpans[i] as OneOfEditableSpans,
                            textLines,
                            abortSignal,
                            useDomIntrospection,
                            (nextLineSpans.slice(i + 1) as OneOfEditableSpans[]).concat(restOfSpans),
                            undefined,
                            forcedFontSize
                        );
                    }
                } else {
                    self.wordWidth_m +=
                        span.width +
                        (span.attributes.shouldRenderNumber ? STYLE_INDEX_MARGIN_RIGHT : 0); // The previous span widths are already added, so just add the last one.
                    if (self.wordWidth_m > self._widestWord) {
                        self._widestWord = self.wordWidth_m;
                    }
                    self._currentContentLine.spans.push(...nextLineSpans);
                    let left = 0;
                    for (const lineSpan of nextLineSpans) {
                        left += lineSpan.width;
                    }
                    self._currentLineWidth = left;
                }
            } else {
                self._currentContentLine!.spans.push(span);
                self._calculateCurrentContentLineDimensions();
                if (!self.editor_m?.inEditMode && self.style.textOverflow === 'truncate') {
                    self._addSpansAndTruncateCurrentContentLine(textLines, restOfSpans, abortSignal);
                }
                saveSpanPositionLeftAndSpanType();
            }
            if (span.attributes?.shouldRenderNumber) {
                self._currentLineWidth += STYLE_INDEX_MARGIN_RIGHT;
            }
            self._previousSpanType = span.type;
            self._lastBreakIndex = undefined;
        }

        function handleWordsBiggerThanWidthOfElement(span: OneOfContentSpans): void {
            const { preContentSpan, postContentSpan } = splitWordOverflowedSpan(span);
            const newRestOfSpans = [postContentSpan as OneOfEditableSpans].concat(restOfSpans);
            if (preContentSpan.content) {
                // Split word overflow spans needs to be redistributed because they are being split by word width and not
                // the current left span position. Also truncation can be reached!. Note, for arbic glyphs they can change
                // when words are separated. For simplicity, we add one option to force append it to current line.
                self._distributeOneSpanAcrossTextLines(
                    preContentSpan,
                    textLines,
                    abortSignal,
                    /* useDom */ true,
                    newRestOfSpans,
                    false,
                    forcedFontSize
                );
                if (self.reachedTruncationLine_m) {
                    return;
                }
            }

            // if empty pre-content and word width is zero, we cannot fit the first character of span. Thus, we
            // should just show empty text element.
            else if (self.wordWidth_m === 0) {
                self.reachedTruncationLine_m = true;
                abortSignal.shouldAbort = true;
                return self._emptyTextLines(textLines);
            }
            self._calculateCurrentContentLineDimensions();
            self._addScrollHeight(textLines, abortSignal, newRestOfSpans);
            if (self.reachedTruncationLine_m) {
                return;
            }
            const belowMaxRows = !self.style.maxRows || self.rows_m < self.style.maxRows;
            if (self.editor_m?.inEditMode || belowMaxRows) {
                self._currentContentLine = self._createContentLine();
                self.rows_m++;
                self.wordWidth_m = 0;
                self._currentLineWidth = 0;
                textLines.push(self._currentContentLine);
                self._distributeOneSpanAcrossTextLines(
                    postContentSpan,
                    textLines,
                    abortSignal,
                    /* useDom */ true,
                    restOfSpans,
                    false,
                    forcedFontSize
                );
            } else {
                self._addSpansAndTruncateCurrentContentLine(
                    textLines,
                    [postContentSpan as OneOfEditableSpans].concat(restOfSpans),
                    abortSignal
                );
            }
        }

        function splitWordOverflowedSpan(span: OneOfContentSpans): IContentSpanSplit {
            if (!self._currentContentLine) {
                throw new Error('Current content line is not set.');
            }
            const lastOffset = span.content.length;
            const copyOfSpan = copySpan(span);
            for (let offset = 1; offset <= lastOffset; offset++) {
                copyOfSpan.content = span.content.substring(0, offset);
                const introspectSpan = self._introspectSpan(copyOfSpan, true);
                const marginRight =
                    offset === lastOffset && span.attributes.shouldRenderNumber
                        ? STYLE_INDEX_MARGIN_RIGHT
                        : 0;

                const widthWithoutMargins =
                    self.style.width! -
                    self.style.padding.left -
                    self.style.padding.right -
                    self._translationPanelDelta;

                if (
                    self.wordWidth_m + introspectSpan.width + marginRight - characterSpacing >
                    widthWithoutMargins
                ) {
                    return self.splitSpan_m(span, offset - 1);
                }
            }
            throw new Error('Could not split span.');
        }

        function appendNewlineSpan(span: INewlineSpan): void {
            self._previousSpanType = SpanType.Newline;
            if (!self.style.maxRows || self.rows_m < self.style.maxRows) {
                self._currentContentLine!.spans.push(span);
                self._calculateCurrentContentLineDimensions();
                self._currentContentLine!.endsWithNewline = true;
                self._addScrollHeight(textLines, abortSignal, restOfSpans);
                if (self.reachedTruncationLine_m) {
                    return;
                }
                if (!self.style.maxRows || self.rows_m < self.style.maxRows) {
                    self._currentContentLine = self._createContentLine();
                    self.wordWidth_m = 0;
                    textLines.push(self._currentContentLine);
                    self.rows_m++;
                }
                self._lastBreakIndex = undefined;
            } else {
                self._currentContentLine!.spans.push({
                    type: SpanType.Space,
                    attributes: {},
                    dir: TextDirection.InferFromContext,
                    top: 0,
                    left: 0,
                    width: span.width,
                    height: 0,
                    lineHeight: 0,
                    content: ' ',
                    style: cloneDeep(span.style),
                    styleIds: {},
                    __previousStyleIds: [],
                    __previousStyleIdToHistoryIndexMap: new Map<string, number>()
                } as ISpaceSpan);
                self._calculateCurrentContentLineDimensions();
            }
        }

        function appendEndSpan(span: IEndSpan): void {
            self._currentContentLine!.spans.push(span);
            self._calculateCurrentContentLineDimensions();
            self._currentContentLine!.isLastLine = true;
            self._addScrollHeight(textLines, abortSignal, restOfSpans);
            if (self.reachedTruncationLine_m) {
                return;
            }
            self._lastBreakIndex = undefined;
        }
    };

    private _createContentLine(): IContentLine {
        return {
            spans: [],
            dir: TextDirection.InferFromContext,
            lineHeight: 0,
            maxFontSize: 1,
            lineWidth: 0,
            trailingSpaceWidth: 0,
            characterWidth: 0,
            endsWithNewline: false,
            isLastLine: false
        };
    }

    private _addSpansAndTruncateCurrentContentLine(
        textLines: IContentLine[],
        restOfSpans: OneOfEditableSpans[],
        metadata: ISpanDistributionAbortSignal
    ): void {
        this._currentContentLine!.spans.push(...restOfSpans);
        this._calculateCurrentContentLineDimensions();
        this._truncateCurrentContentLine(textLines);
        this.reachedTruncationLine_m = true;
        metadata.shouldAbort = true;
    }

    private _calculateCurrentContentLineDimensions(): void {
        if (!this._currentContentLine) {
            return;
        }
        let lineHeight = 0;
        let maxFontSize = 0; // Use relative font size
        let lineWidth = 0;
        let characterWidth = 0;
        let trailingSpaceWidth = 0;
        let left = 0;
        let isFirstSpanInLine = true;
        let dir = TextDirection.InferFromContext;

        for (const span of this._currentContentLine.spans) {
            if (dir === TextDirection.InferFromContext && isContentSpan(span) && span.dir) {
                dir = span.dir;
            }

            if (span.type === SpanType.End) {
                lineHeight = this._getLineHeightOfEndSpan(lineHeight);
                continue;
            }

            // Newline should not contribute to line height when it is not the first
            if (span.type !== SpanType.Newline) {
                if (span.lineHeight > lineHeight) {
                    lineHeight = span.lineHeight;
                }
            } else {
                // Only contribute to line height when the newline is first(or only span in line, which is the same).
                if (isFirstSpanInLine && span.lineHeight > lineHeight) {
                    lineHeight = span.lineHeight;
                }
                lineWidth += span.width;
                trailingSpaceWidth += span.width;
                characterWidth += 1;
                continue;
            }
            const characterSpacing = span.style.characterSpacing ?? this.style.characterSpacing;
            const fontSize = span.style.fontSize
                ? span.style.fontSize * this.resizedElementFontSize_m
                : this.resizedElementFontSize_m;
            if (span.type === SpanType.Ellipsis) {
                trailingSpaceWidth = characterSpacing * fontSize;
                continue;
            }
            span.left = left;
            if (span.type === SpanType.Word || span.type === SpanType.Variable) {
                trailingSpaceWidth = characterSpacing * fontSize;
            }
            if (span.type === SpanType.Space) {
                trailingSpaceWidth += span.width;
            }
            lineWidth += span.width;
            if (span.style.fontSize && span.style.fontSize > maxFontSize) {
                maxFontSize = span.style.fontSize; // Is relative to element font size
            }
            characterWidth += span.content.length;
            left += span.width;
            if (span.attributes.shouldRenderNumber) {
                left += STYLE_INDEX_MARGIN_RIGHT;
            }
            isFirstSpanInLine = false;
        }

        this._updateCurrentContentLine({
            dir,
            lineWidth,
            trailingSpaceWidth,
            lineHeight,
            maxFontSize,
            characterWidth
        });
    }

    private _getLineHeightOfEndSpan(lineHeight: number): number {
        if (this._currentContentLine!.spans.length === 1) {
            lineHeight = this._calculateNewLineHeight();
        }
        return lineHeight;
    }

    private _calculateNewLineHeight(): number {
        let newLineHeight = this.style.lineHeight * this.resizedElementFontSize_m;

        if (this.editor_m?.currentStyle.lineHeight) {
            newLineHeight = this.editor_m.currentStyle.lineHeight * this.resizedElementFontSize_m;
        } else if (this._previousContentLine?.lineHeight) {
            newLineHeight = this._previousContentLine.lineHeight;
        }

        return newLineHeight;
    }

    private _updateCurrentContentLine({
        dir,
        lineWidth,
        trailingSpaceWidth,
        lineHeight,
        maxFontSize,
        characterWidth
    }: {
        dir: TextDirection;
        lineWidth: number;
        trailingSpaceWidth: number;
        lineHeight: number;
        maxFontSize: number;
        characterWidth: number;
    }): void {
        this._currentContentLine!.dir = dir;
        this._currentContentLine!.lineWidth = lineWidth;
        this._currentContentLine!.trailingSpaceWidth = trailingSpaceWidth;
        this._currentContentLine!.lineHeight = lineHeight;
        this._currentContentLine!.maxFontSize =
            maxFontSize || /* Happens only when there is only one newline */ 1;
        this._currentContentLine!.characterWidth = characterWidth;

        const newLongestLine = lineWidth - trailingSpaceWidth;
        if (newLongestLine > this._longestLine) {
            this._longestLine = Math.round(newLongestLine * 100) / 100;
        }

        this._currentLineWidth = 0;
        this._previousContentLine = this._currentContentLine;
    }

    private _addScrollHeight(
        textLines: IContentLine[],
        abortSignal: ISpanDistributionAbortSignal,
        restOfSpans: OneOfEditableSpans[]
    ): void {
        this._scrollHeight += this._currentContentLine!.lineHeight;

        // Add truncation margin for output, due to difference in browsers/OS:es
        const truncationMargin = this._env.STUDIO_JS ? 0 : 2;
        if (
            this.style.textOverflow === 'truncate' &&
            !this.editor_m?.inEditMode &&
            this._scrollHeight >
                (this.style.height || 0) -
                    (this.style.padding.top + this.style.padding.bottom) +
                    truncationMargin
        ) {
            this.reachedTruncationLine_m = true;
            const lastLine = textLines.pop()!;
            this._currentContentLine = textLines.pop()!;
            if (!this._currentContentLine) {
                this._currentContentLine = {
                    dir: TextDirection.InferFromContext,
                    spans: [
                        {
                            type: SpanType.End,
                            content: 'END',
                            dir: TextDirection.Ltr,
                            top: 0,
                            left: 0,
                            width: 0,
                            height: 0,
                            lineHeight: 0,
                            attributes: {},
                            startJoint: JoinType.None,
                            endJoint: JoinType.None
                        }
                    ],
                    lineHeight: 0,
                    maxFontSize: 0,
                    trailingSpaceWidth: 0,
                    lineWidth: 0,
                    characterWidth: 0,
                    endsWithNewline: false,
                    isLastLine: false
                };
                textLines.splice(0, Infinity, this._currentContentLine);
                return;
            }
            this._currentContentLine.spans.push(...lastLine.spans, ...restOfSpans);
            textLines.push(this._currentContentLine);
            this._truncateCurrentContentLine(textLines);
            abortSignal.shouldAbort = true;
        }
    }

    private _introspectSpan(span: OneOfEditableSpans, useDom: boolean, fontSize?: number): ISize {
        if (this._textIntrospecter && useDom) {
            const introspecterFontSize =
                (isContentSpan(span) &&
                    span.style.fontSize &&
                    span.style.fontSize * (fontSize ?? this.style.fontSize)) ||
                fontSize ||
                this.style.fontSize;
            const introspecterFontFamily =
                isVariableSpan(span) && this.editor_m?.inEditMode
                    ? `"Open Sans", ${FALLBACK_FONT_FAMILY}`
                    : this._resolveCssFontFamily(
                          (isContentSpan(span) && span.style.font) || this.style.font
                      );

            this._textIntrospecter.font = `${introspecterFontSize}px ${introspecterFontFamily}`;
            const isCapitalized = !!(
                (isContentSpan(span) && span.style.uppercase) ||
                this.style.uppercase
            );
            const content =
                (span.startJoint ?? '') + span.content.replace(/\u00A0/g, ' ') + (span.endJoint ?? '');
            const text = isCapitalized ? content.toLocaleUpperCase() : content;
            const metrics = this._textIntrospecter.measureText(text);

            const length = span.content.length;
            const characterSpacing =
                (isContentSpan(span) && span.style.characterSpacing) || this.style.characterSpacing;
            const charSpacePixels = introspecterFontSize * characterSpacing * length;
            const width = metrics.width + charSpacePixels;
            const lineHeight =
                introspecterFontSize *
                ((isContentSpan(span) && span.style.lineHeight) || this.style.lineHeight);

            // We just take content length for now, and don't filter out potential zero width space characters, like control characters etc.
            // The user have to take this into consideration for now.
            return {
                width: width / this._scale,
                height: lineHeight / this._scale
            };
        } else {
            return {
                width: span.width * this.fontSizeDiff_m,
                height: span.lineHeight * this.fontSizeDiff_m
            };
        }
    }

    renderTextWithDecorations_m(): void {
        this.renderTextLines_m();
        this.removeDecorationLayers_m();

        /** @@remove STUDIO:START */
        if (this.renderUnderscoreLayer_m) {
            this._renderDecorationLayer(
                'decoration-background-line',
                this.lineToSpanDecorationBackgroundLineElementMap_m,
                '-2'
            );
            for (let line = 0; line < this.textLines_m.length; line++) {
                const textLine = this.textLines_m[line];
                const lineElement = this.lineToSpanDecorationBackgroundLineElementMap_m.get(line)!;
                this._renderSpans(textLine, lineElement);
            }

            this._renderDecorationLayer('decoration-line', this.lineToSpanDecorationLineElementMap_m);
            for (let line = 0; line < this.textLines_m.length; line++) {
                const textLine = this.textLines_m[line];
                const lineElement = this.lineToSpanDecorationLineElementMap_m.get(line)!;
                lineElement.style.display = 'block';
                this._renderUnderscores(textLine, lineElement);
            }
        }

        if (this.editor_m && (this.renderUnderscoreLayer_m || this._option.inDesignView)) {
            this._renderDecorationLayer(
                'selection-line',
                this.editor_m.selection.lineToSelectionLineElementMap
            );
        }
        /** @@remove STUDIO:END */
    }

    /** @@remove STUDIO:START */
    private _createStyledDiv(style: Partial<CSSStyleDeclaration>): HTMLDivElement {
        const element = document.createElement('div');
        Object.assign(element.style, style);
        return element;
    }
    /** @@remove STUDIO:END */

    /** @@remove STUDIO:START */
    private _renderSpans(textLine: IContentLine, lineElement: HTMLDivElement): void {
        for (let spanIndex = 0; spanIndex < textLine.spans.length; spanIndex++) {
            const span = textLine.spans[spanIndex];
            if (span.attributes.styleIndex === undefined || span.type === SpanType.Newline) {
                continue;
            }

            const backgroundElement = this._createStyledDiv({
                position: 'absolute',
                top: '0',
                left: `${Math.round(span.left)}px`,
                height: '100%',
                width: `${span.width}px`,
                backgroundColor: '#f6f6f6'
            });
            lineElement.appendChild(backgroundElement);
        }
    }
    /** @@remove STUDIO:END */

    /** @@remove STUDIO:START */
    private _renderUnderscores(textLine: IContentLine, lineElement: HTMLDivElement): void {
        for (let spanIndex = 0; spanIndex < textLine.spans.length; spanIndex++) {
            const span = textLine.spans[spanIndex];
            if (span.attributes.styleIndex === undefined || span.type === SpanType.Newline) {
                continue;
            }

            const underscoreElement = this._createStyledDiv({
                position: 'absolute',
                top: '0',
                left: `${span.left}px`,
                height: '100%',
                width: `${span.width}px`,
                borderBottom: '1px solid #c3cbf7'
            });

            if (span.attributes.shouldRenderNumber) {
                const styleIndex = span.attributes.styleIndex;
                const numberElement = this._createStyledDiv({
                    position: 'absolute',
                    right: '-5px',
                    top: '-4px',
                    fontSize: '8px',
                    fontStyle: 'italic',
                    color: 'var(--studio-color-primary)',
                    display: this.editor_m!.hasFocus ? 'block' : 'none'
                });
                numberElement.className = 'number';
                numberElement.innerHTML = styleIndex && styleIndex > 0 ? `${styleIndex}` : '?';
                underscoreElement.appendChild(numberElement);
            }

            lineElement.appendChild(underscoreElement);
        }
    }
    /** @@remove STUDIO:END */

    normalizeTextLineSpans_m(): void {
        const currentTextLine: IContentLine = {
            spans: [],
            dir: TextDirection.InferFromContext,
            lineHeight: 0,
            maxFontSize: 0,
            lineWidth: 0,
            trailingSpaceWidth: 0,
            characterWidth: 0,
            endsWithNewline: false,
            isLastLine: false
        };

        for (const textLine of this.textLines_m) {
            this._normalizeSpansInLine(textLine, currentTextLine);
        }

        // Set current shouldRenderNumber flag
        if (this._env.STUDIO_JS && this.renderUnderscoreLayer_m) {
            this._setShouldRenderNumberFlag(currentTextLine);
        }

        this.textLines_m = [currentTextLine];
        this.spans_m = this._getSpansFromTextLines();
    }

    private _normalizeSpansInLine(textLine: IContentLine, currentTextLine: IContentLine): void {
        let lastSpan: OneOfRenderedSpans | undefined;
        for (const span of textLine.spans) {
            if (span.type === SpanType.Ellipsis || span.type === SpanType.Composition) {
                lastSpan = span;
                currentTextLine.spans.push(span);
                continue;
            }
            if (span.content === '') {
                continue;
            }
            if (lastSpan && isSpanMergeable_m(lastSpan, span)) {
                lastSpan.content += span.content;
            } else {
                lastSpan = span;
                currentTextLine.spans.push(span);
                if (isContentSpan(span)) {
                    this._removeDuplicatedStyleProperties(span);
                }
            }
        }
    }

    private _removeDuplicatedStyleProperties(span: OneOfContentSpans): void {
        const properties = characterProperties;
        for (const property of properties) {
            if (hasSameStyleProperty(property, span.style, this.style)) {
                delete span.style[property];
            }
        }
    }

    private _setShouldRenderNumberFlag(currentTextLine: IContentLine): void {
        /** @@remove STUDIO:START */
        if (this._env.MODE === CreativeMode.TranslationPanel && this._isEditable()) {
            // Avoid rendering numbers when span is being edited in TP
            return;
        }
        /** @@remove STUDIO:END */
        let previousSpan: OneOfRenderedSpans | undefined;
        const singlyIndexed = currentTextLine.spans.every(
            span => span.attributes.styleIndex === undefined || span.attributes.styleIndex === 1
        );

        // If the only index is "1" don't show anything
        if (singlyIndexed) {
            currentTextLine.spans.forEach(span => (span.attributes.shouldRenderNumber = false));
        }
        // Make sure only last span with same index renders number
        else {
            for (const span of currentTextLine.spans) {
                const index = span.attributes.styleIndex;
                if (previousSpan) {
                    const previousIndex = previousSpan.attributes.styleIndex;
                    if (previousIndex === index) {
                        previousSpan.attributes.shouldRenderNumber = false;
                    } else if (previousIndex !== undefined) {
                        previousSpan.attributes.shouldRenderNumber = true;
                    }
                }
                previousSpan = span;
            }
            previousSpan = undefined;
        }
    }

    expandVariableSpans_m(spans: OneOfEditableSpans[]): OneOfEditableSpans[] {
        const expandedSpans: OneOfEditableSpans[] = [];
        for (let i = 0; i < spans.length; i++) {
            if (spans[i].type === SpanType.Variable) {
                const span = spans[i] as IVariableSpan;

                /**
                 * Store the span and give it a temporary ID.
                 * We then resolve the variable to the correct feed data so that the
                 * text resize the actual value rather than the variable.
                 * The correct ID is then set in renderTextLines.
                 */
                this._assignSpanIdIfNotExists(span);

                const variableSpan = this.variableSpanMap_m.get(span.style.variable!.spanId!)!;
                if (!this.editor_m?.inEditMode && !this.renderUnderscoreLayer_m) {
                    const feedSpans = this._createFeedSpans(span);
                    expandedSpans.push(...feedSpans);
                } else {
                    span.content =
                        VARIABLE_PREFIX + decodeURIComponent(variableSpan.style.variable!.path);
                    expandedSpans.push(span);
                }
                continue;
            }
            expandedSpans.push(spans[i]);
        }
        return expandedSpans;
    }

    private _assignSpanIdIfNotExists(span: IVariableSpan): void {
        if (!span.style.variable!.spanId || !this.variableSpanMap_m.get(span.style.variable!.spanId)) {
            span.style.variable!.spanId = span.style.variable!.spanId || `${Date.now()}`;
            this.variableSpanMap_m.set(span.style.variable!.spanId, {
                style: span.style,
                span: span
            });
        }
    }

    private _createFeedSpans(span: IVariableSpan): OneOfEditableSpans[] {
        const feedValue = this.feedStore_m.getFeedValue(
            span.style.variable!,
            span.style.variable!.spanId!,
            this._option.element
        ).value as string;

        return createSpansFromString(feedValue, {}, span.style, copyStyle);
    }

    private _getElementTextProperties(): Partial<ITextElementProperties> {
        const resolvedText = this._option.inDesignView
            ? (this.editor_m!.styleResolver.getResolvedText()?.style ?? {})
            : {};
        return Object.assign(
            pick(
                this.style,
                'font',
                'characterSpacing',
                'lineHeight',
                'textColor',
                // 'uppercase',
                // 'strikethrough',
                // 'underline',
                'textOverflow',
                'textShadows',
                'padding',
                'maxRows',
                'horizontalAlignment',
                'verticalAlignment'
            ),
            { fontSize: this.resizedElementFontSize_m },
            resolvedText
        );
    }

    distributeAllSpansAcrossTextLines_m(spans: OneOfEditableSpans[], fontSize?: number): void {
        this.resizedElementFontSize_m = fontSize || this.style.fontSize;
        const textLines: IContentLine[] = [];
        this._resetTextMetadata();
        const option: ISpanDistributionAbortSignal = {
            shouldAbort: false
        };
        for (let i = 0; i < spans.length; i++) {
            if (option.shouldAbort) {
                break;
            }
            this._distributeOneSpanAcrossTextLines(
                spans[i],
                textLines,
                option,
                /* useDom */ true,
                spans.slice(i + 1),
                false,
                fontSize
            );
        }
        this.textLines_m = textLines;
    }

    private _resetTextMetadata(): void {
        this._scrollHeight = 0;
        this._longestLine = 0;
        this.wordWidth_m = 0;
        this._widestWord = 0;
        this.rows_m = 1;
        this.reachedTruncationLine_m = false;
        this._currentContentLine = undefined;
        this._previousContentLine = undefined;
    }

    private _redistributeAllSpansAcrossLines(spans: OneOfEditableSpans[], useDom?: boolean): void {
        const textLines: IContentLine[] = [];
        this._resetTextMetadata();
        const metadata: ISpanDistributionAbortSignal = {
            shouldAbort: false
        };
        for (let i = 0; i < spans.length; i++) {
            if (metadata.shouldAbort) {
                break;
            }
            this._distributeOneSpanAcrossTextLines(
                spans[i],
                textLines,
                metadata,
                useDom,
                spans.slice(i + 1),
                false
            );
        }
        this.textLines_m = textLines;
    }

    private _getNegativeLineHeightMargin(textLine: IContentLine): number {
        if (textLine.maxFontSize < 1) {
            textLine.maxFontSize = 1;
        }
        return -(textLine.lineHeight - textLine.maxFontSize * this.resizedElementFontSize_m) / 2;
    }

    renderTextLines_m(): void {
        this._applyTextPropertiesToRootElement();
        this._setupTextAndRootElement();
        this.textLineElementToLineMap_m = new WeakMap<HTMLDivElement, number>();
        this.lineToTextLineElementMap_m = new Map<number, HTMLDivElement>();
        this.variableSpanMap_m = new Map<
            string,
            { span: OneOfContentSpans; style: Partial<ICharacterProperties> }
        >();

        this._handleOverflowStyleOfRootElement();

        this.centerElement_m.style.alignItems = getVerticalFlexAlignment(this.style.verticalAlignment);

        const horizontalAlignment = this._adjustHorizontalAlignment();

        this.textElement_m.style.lineHeight = `${this.style.lineHeight}`;

        for (let line = 0; line < this.textLines_m.length; line++) {
            const textLine = this.textLines_m[line];
            const isLastLine = this.textLines_m.length - 1 === line;
            const lineFragment = document.createDocumentFragment();
            const lineElement = document.createElement('div');
            const currentDir = (lineElement.dir = isContentSpan(textLine.spans[0])
                ? textLine.spans[0].dir!
                : TextDirection.Ltr);

            this._applyStyleToLineElement(lineElement, textLine, line, horizontalAlignment, currentDir);

            this.textLineElementToLineMap_m.set(lineElement, line);
            this.lineToTextLineElementMap_m.set(line, lineElement);
            lineFragment.appendChild(lineElement);
            const spanId = `t-${line}`;
            let column = 0;

            let lastDir = isContentSpan(textLine.spans[0]) ? textLine.spans[0].dir : TextDirection.Ltr;
            let bidiDirectionContainer = this._buildBidiDirectionContainer(lastDir!);
            lineElement.appendChild(bidiDirectionContainer);
            forEachSpan(textLine, (span, spanIndex) => {
                if (textLine.spans.length !== 1 && span.type === SpanType.End) {
                    return;
                }
                const isLastSpan =
                    spanIndex === (isLastLine ? textLine.spans.length - 2 : textLine.spans.length - 1);
                if (isContentSpan(span) && lastDir !== span.dir!) {
                    bidiDirectionContainer = this._buildBidiDirectionContainer(span.dir!);
                    lineElement.appendChild(bidiDirectionContainer);
                    lastDir = span.dir!;
                }
                const spanElement = (span.__viewElement = document.createElement('span'));

                if (this.renderUnderscoreLayer_m) {
                    if (span.attributes.shouldRenderNumber) {
                        spanElement.style.marginRight = `${STYLE_INDEX_MARGIN_RIGHT}px`;
                    }
                }
                let shouldSkipRenderDecoration = false;
                if (span.type === SpanType.Space) {
                    if (
                        spanIndex === textLine.spans.length - 1 ||
                        (spanIndex === textLine.spans.length - 2 &&
                            textLine.spans[textLine.spans.length - 1].type === SpanType.Newline)
                    ) {
                        shouldSkipRenderDecoration = true;
                    }
                } else if (span.type === SpanType.Newline) {
                    shouldSkipRenderDecoration = true;
                }

                // We need 'inline-block' here, otherwise a parent's element's width is not the
                // same as the sum of its children. Though there is a problem in Chrome for getting
                // the caret position sometimes on inline-block. Right now we only experienced it on
                // the translation panel. So we are just removing inline-block there.
                spanElement.style.display = 'inline-block';

                // Prevents collapsing of spaces
                spanElement.style.whiteSpace = 'nowrap';

                spanElement.setAttribute('data-type', this._getStringSpanType(span.type));
                spanElement.setAttribute(
                    'data-test-id',
                    `rich-text-span-${this._getStringSpanType(span.type)}`
                );

                const rootId = this.rootElement_m.id || this.editor_m?.keyboardBindings.inputId;

                const viewElementId = this.viewElement_m ? `${this.viewElement_m.id}-` : '';

                spanElement.id = `${viewElementId}${rootId}-${spanId}-${spanIndex}`;

                spanElement.setAttribute('data-cy', `${rootId}-${spanId}-${spanIndex}`);

                if (isLastSpan) {
                    this._handleLastSpan(
                        bidiDirectionContainer,
                        textLine,
                        horizontalAlignment,
                        currentDir
                    );
                }

                if (isContentSpan(span)) {
                    spanElement.dir = span.dir!;
                }
                if (span.type === SpanType.Variable) {
                    this._handleVariableSpan(span, spanElement);
                }

                spanElement.addEventListener('click', this._onClick);
                if (span.type === SpanType.End) {
                    if (this.editor_m?.currentStyle.lineHeight) {
                        spanElement.style.height = `${
                            this.editor_m.currentStyle.lineHeight * this.resizedElementFontSize_m
                        }px`;
                    }
                    spanElement.innerHTML = '&#8203;'; // Zero-width-space
                } else {
                    this._handleSpanType(span, spanElement);
                    const spanStyle = this._handleSpanStyle(span, textLine, shouldSkipRenderDecoration);
                    const textColor = spanStyle.textColor ?? this.style.textColor;
                    this.applyStyleOnElement_m(
                        {
                            fontSize: 1,
                            characterSpacing: this.style.characterSpacing,
                            textColor,
                            ...spanStyle
                        },
                        spanElement
                    );
                }
                if (isContentSpan(span)) {
                    span.line = line;
                    span.column = column;
                    column += span.content.length;
                }
                bidiDirectionContainer.appendChild(spanElement);
            });

            this.textElement_m.appendChild(lineFragment);
        }
    }

    private _applyTextPropertiesToRootElement(): void {
        const textProperties = this._getElementTextProperties();
        this.applyStyleOnElement_m(textProperties, this.rootElement_m, /* isRootElement */ false);
    }

    private _setupTextAndRootElement(): void {
        this.textElement_m.innerHTML = '';
        this.rootElement_m.style.fontSize = `${this.resizedElementFontSize_m}px`;
        this.textElement_m.classList.add('invisible-selection');
    }

    private _handleOverflowStyleOfRootElement(): void {
        if (!this.editor_m?.inEditMode && this.style.textOverflow === 'scroll') {
            this.rootElement_m.style.overflow = 'auto';
        } else {
            this.rootElement_m.style.overflow = 'visible';
        }
    }

    private _adjustHorizontalAlignment(): HorizontalAlignment {
        let horizontalAlignment = this.style.horizontalAlignment;
        horizontalAlignment = this._adjustAlignmentForRTLText(horizontalAlignment);
        horizontalAlignment = this._adjustAlignmentForUnderscoreLayer(horizontalAlignment);
        this._setAlignmentOnElements(horizontalAlignment);
        return horizontalAlignment;
    }

    private _adjustAlignmentForRTLText(horizontalAlignment: HorizontalAlignment): HorizontalAlignment {
        // For RTL text, we change to right align if it was left align.
        if (
            horizontalAlignment === 'left' &&
            isContentSpan(this.textLines_m[0].spans[0]) &&
            this.textLines_m[0].spans[0].dir === TextDirection.Rtl
        ) {
            return 'right';
        }
        return horizontalAlignment;
    }

    private _adjustAlignmentForUnderscoreLayer(
        horizontalAlignment: HorizontalAlignment
    ): HorizontalAlignment {
        if (!this.renderUnderscoreLayer_m) {
            return horizontalAlignment;
        }
        const isRTL =
            isContentSpan(this.textLines_m[0].spans[0]) &&
            this.textLines_m[0].spans[0].dir === TextDirection.Rtl;

        return isRTL ? 'right' : 'left';
    }

    private _setAlignmentOnElements(horizontalAlignment: HorizontalAlignment): void {
        this.centerElement_m.style.justifyContent = getHorizontalFlexAlignment(horizontalAlignment);
        this.textElement_m.style.textAlign = horizontalAlignment;
    }

    private _applyStyleToLineElement(
        lineElement: HTMLDivElement,
        textLine: IContentLine,
        line: number,
        horizontalAlignment: HorizontalAlignment,
        currentDir: TextDirection
    ): void {
        lineElement.className = 'text-line';
        lineElement.setAttribute('data-test-id', 'rich-text-text-line');

        // Use block and explicit height to prevent white space underneath it when children(spans)
        // being wider than block width. This becomes apparant when font size becomes smaller and
        // there is a lot of characters.
        switch (horizontalAlignment) {
            case 'left':
                if (currentDir === TextDirection.Ltr) {
                    lineElement.style.justifyContent = 'flex-start';
                } else {
                    lineElement.style.justifyContent = 'flex-end';
                }
                break;
            case 'center':
                lineElement.style.justifyContent = 'center';
                break;
            case 'right':
                if (currentDir === TextDirection.Ltr) {
                    lineElement.style.justifyContent = 'flex-end';
                } else {
                    lineElement.style.justifyContent = 'flex-start';
                }
                break;
        }
        lineElement.style.left = '0';
        lineElement.style.width = '100%';
        lineElement.style.height = '100%';
        lineElement.style.display = 'flex';
        lineElement.style.alignItems = 'baseline';
        lineElement.style.height = `${Math.max(1, textLine.lineHeight)}px`;
        lineElement.style.whiteSpace = 'nowrap';
        lineElement.style.fontSize = `${textLine.maxFontSize * this.resizedElementFontSize_m}px`;
        lineElement.style.letterSpacing = `${this.style.characterSpacing}em`;

        if (this.style.verticalAlignment === 'top' && line === 0) {
            lineElement.style.marginTop = `${this._getNegativeLineHeightMargin(textLine)}px`;
        } else if (this.style.verticalAlignment === 'bottom' && textLine.isLastLine) {
            lineElement.style.marginBottom = `${this._getNegativeLineHeightMargin(textLine)}px`;
        }
    }

    private _buildBidiDirectionContainer(dir: TextDirection): HTMLDivElement {
        const bidiDirectionContainer = document.createElement('div');
        bidiDirectionContainer.dir = dir;
        bidiDirectionContainer.style.unicodeBidi = 'bidi-override';
        return bidiDirectionContainer;
    }

    private _handleLastSpan(
        bidiDirectionContainer: HTMLDivElement,
        textLine: IContentLine,
        horizontalAlignment: HorizontalAlignment,
        currentDir: TextDirection
    ): void {
        if (textLine.trailingSpaceWidth) {
            switch (horizontalAlignment) {
                case 'left':
                    if (currentDir === TextDirection.Rtl) {
                        bidiDirectionContainer.style.marginLeft = `${-textLine.trailingSpaceWidth}px`;
                    }
                    break;
                case 'right':
                case 'center':
                    if (currentDir === TextDirection.Rtl) {
                        bidiDirectionContainer.style.marginLeft = `${-textLine.trailingSpaceWidth}px`;
                    } else {
                        bidiDirectionContainer.style.marginRight = `${-textLine.trailingSpaceWidth}px`;
                    }
                    break;
            }
        }
    }

    private _handleVariableSpan(span: IVariableSpan, spanElement: HTMLSpanElement): void {
        spanElement.setAttribute('data-variable', span.style.variable!.path);
        if (span.style.variable!.spanId !== spanElement.id) {
            this.variableSpanMap_m.delete(span.style.variable!.spanId!);
            span.style.variable!.spanId = spanElement.id;
            this.variableSpanMap_m.set(span.style.variable!.spanId, {
                style: span.style,
                span
            });
        } else if (!this.variableSpanMap_m.get(spanElement.id)) {
            span.style.variable!.spanId = spanElement.id;
            this.variableSpanMap_m.set(spanElement.id, {
                style: span.style,
                span
            });
        }

        span.style.variable!.spanId = spanElement.id;

        if (!this.renderUnderscoreLayer_m) {
            this.feedStore_m.addFeedElement(
                spanElement.id,
                cloneDeep(span.style.variable!),
                this._option.viewElement?.__data
            );
        }
    }

    private _handleSpanType(
        span: IWordSpan | ISpaceSpan | IVariableSpan | INewlineSpan | ICompositionSpan | IEllipsesSpan,
        spanElement: HTMLElement
    ): void {
        if (span.type === SpanType.Newline) {
            this._setSpanElementNewline(span, spanElement);
        } else if (span.type === SpanType.Ellipsis) {
            this._setSpanElementEllipsis(spanElement);
        } else if (span.type === SpanType.Space) {
            this._setSpanElementSpace(span, spanElement);
        } else if (span.type === SpanType.Composition) {
            this._setSpanElementComposition(span, spanElement);
        } else {
            this._setSpanElementDefault(span, spanElement);
        }
    }

    private _setSpanElementNewline(span: INewlineSpan, spanElement: HTMLElement): void {
        spanElement.innerHTML = ' ';
        spanElement.style.whiteSpace = 'pre';
        spanElement.style.width = `${span.width}px`;
    }

    private _setSpanElementEllipsis(spanElement: HTMLElement): void {
        spanElement.innerHTML = '...';
    }

    private _setSpanElementSpace(span: ISpaceSpan, spanElement: HTMLElement): void {
        spanElement.style.whiteSpace = 'pre';
        spanElement.innerHTML = `${span.content}&zwnj;`;
    }

    private _setSpanElementComposition(span: ICompositionSpan, spanElement: HTMLElement): void {
        spanElement.style.width = `${span.width}px`;
        spanElement.innerHTML = '&#8203;';
        this.editor_m!.keyboardBindings.compositionSpanElement = spanElement;
    }

    private _setSpanElementDefault(span: IWordSpan | IVariableSpan, spanElement: HTMLElement): void {
        const content = span.content.normalize('NFC');
        spanElement.innerHTML = encodeHtml(
            (span.startJoint ?? '') + content.replace(/\u00A0/g, ' ') + (span.endJoint ?? '')
        );
    }

    private _handleSpanStyle(
        span: IWordSpan | ISpaceSpan | IVariableSpan | INewlineSpan | ICompositionSpan | IEllipsesSpan,
        textLine: IContentLine,
        shouldSkipRenderDecoration: boolean
    ): {
        underline: boolean | undefined;
        strikethrough: boolean | undefined;
        uppercase: boolean | undefined;
    } & Partial<ICharacterProperties> {
        const spanStyle = {
            underline: this.style.underline,
            strikethrough: this.style.strikethrough,
            uppercase: this.style.uppercase,
            ...span.style
        };
        if (span.type === SpanType.Newline) {
            // Using a big font size that exceeds max font size will pressure the rest of the text down a bit.
            // Using a small font size, will make the selection line and caret being small.
            // So we think using the max font size for the line makes most sense.
            spanStyle.fontSize = textLine.maxFontSize;
        }
        // Don't decorate spaces if it's in the end of the line
        if (shouldSkipRenderDecoration) {
            spanStyle.underline = false;
            spanStyle.strikethrough = false;
        }

        return spanStyle;
    }

    applyStyleOnElement_m(
        style: Partial<ICharacterProperties>,
        element: HTMLElement,
        useRelativeFontSize = true,
        animated = false
    ): void {
        const styleAttribute = element.style;
        let handledTextDecorationProperty = false;
        const characterStyleProperties = animated
            ? ['textColor']
            : [...exclude(characterProperties, '__fontFamilyId'), 'padding'];
        const styleParameters: StyleParameters = {
            style,
            styleAttribute,
            element,
            useRelativeFontSize
        };

        for (const property of characterStyleProperties) {
            switch (property) {
                case 'font':
                    this._applyFontStyle(styleParameters);
                    break;
                case 'textColor':
                    this._applyTextColorStyle(styleParameters);
                    break;
                case 'fontSize':
                    this._applyFontSizeStyle(styleParameters);
                    break;
                case 'underline':
                case 'strikethrough':
                    if (handledTextDecorationProperty) {
                        break;
                    }
                    this._applyTextDecorationStyle(styleParameters);
                    handledTextDecorationProperty = true;
                    break;
                case 'uppercase':
                    this._applyUppercaseStyle(styleParameters);
                    break;
                case 'characterSpacing':
                    this._applyCharacterSpacingStyle(styleParameters);
                    break;
                case 'lineHeight':
                    this._applyLineHeightStyle(styleParameters);
                    break;
                case 'textShadows':
                    this._applyTextShadowsStyle(styleParameters);
                    break;
                case 'variable':
                    this._applyVariableStyle(styleParameters);
                    break;
                case 'padding':
                    this._applyPaddingStyle(styleParameters);
                    break;
                default:
                    styleAttribute[property] = style[property];
                    break;
            }
        }
    }

    private _applyFontStyle({ style, styleAttribute, element }: StyleParameters): void {
        if (style.font) {
            styleAttribute.fontFamily = this._resolveCssFontFamily(style.font, element);
        } else {
            styleAttribute.fontFamily = '';
        }
    }

    private _applyTextColorStyle({ style, styleAttribute }: StyleParameters): void {
        styleAttribute.color = style.textColor ? toRGBA(style.textColor) : '';
    }

    private _applyFontSizeStyle({ style, styleAttribute, useRelativeFontSize }: StyleParameters): void {
        let fontSize = '';
        if (style.fontSize !== undefined) {
            fontSize = useRelativeFontSize
                ? `${this.resizedElementFontSize_m * style.fontSize}px`
                : `${this.resizedElementFontSize_m}px`;
        }
        styleAttribute.fontSize = fontSize;
    }

    private _applyTextDecorationStyle({ style, styleAttribute }: StyleParameters): void {
        const cssTextDecoration: string[] = [];
        if (style.underline) {
            cssTextDecoration.push('underline');
        }
        if (style.strikethrough) {
            cssTextDecoration.push('line-through');
        }
        styleAttribute.textDecoration = cssTextDecoration.length > 0 ? cssTextDecoration.join(' ') : '';
    }

    private _applyUppercaseStyle({ style, styleAttribute }: StyleParameters): void {
        styleAttribute.textTransform = style.uppercase ? 'uppercase' : '';
    }

    private _applyCharacterSpacingStyle({ style, styleAttribute }: StyleParameters): void {
        styleAttribute.letterSpacing =
            style.characterSpacing !== undefined ? `${style.characterSpacing}em` : '';
    }

    private _applyLineHeightStyle({
        style,
        styleAttribute,
        useRelativeFontSize
    }: StyleParameters): void {
        let lineHeight = 'none';
        if (style.lineHeight !== undefined) {
            lineHeight = useRelativeFontSize ? `${style.lineHeight}em` : `${style.lineHeight}`;
        }

        styleAttribute.lineHeight = lineHeight;
    }

    private _applyTextShadowsStyle({ style, styleAttribute }: StyleParameters): void {
        styleAttribute.textShadow = style.textShadows ? textShadowsCSSValue(style.textShadows) : '';
    }

    private _applyVariableStyle({ style, styleAttribute }: StyleParameters): void {
        if (style.variable) {
            if (this.editor_m?.inEditMode || this.renderUnderscoreLayer_m) {
                styleAttribute.color = 'var(--variable-artifact-color)';
                styleAttribute.backgroundColor = this.renderUnderscoreLayer_m
                    ? 'rgba(235, 235, 235, 0.7)'
                    : '';
                styleAttribute.cursor = 'var(--variable-artifact-cursor)';
                styleAttribute.fontFamily = `'Open Sans', ${FALLBACK_FONT_FAMILY}`;
            } else {
                styleAttribute.color = styleAttribute.color || '';
                styleAttribute.cursor = '';
                styleAttribute.fontFamily = styleAttribute.fontFamily || '';
            }
        }
    }

    private _applyPaddingStyle({ style, styleAttribute }: StyleParameters): void {
        if (style['padding']) {
            styleAttribute.paddingTop = `${style['padding'].top}px`;
            styleAttribute.paddingLeft = `${style['padding'].left}px`;
            styleAttribute.paddingRight = `${style['padding'].right}px`;
            styleAttribute.paddingBottom = `${style['padding'].bottom}px`;
        } else {
            styleAttribute.paddingTop = '';
            styleAttribute.paddingLeft = '';
            styleAttribute.paddingRight = '';
            styleAttribute.paddingBottom = '';
        }
    }

    /**
     * Applies the only the styles that can be animated, does not recreate the span elements,
     * only suitable for the styling that doesn't require text pipeline
     *
     * @param style Currently only supports `textColor`
     */
    applyOnlyAnimatedStyleOnElement_m(style: Pick<ICharacterProperties, 'textColor'>): void {
        this.textLines_m.forEach(line => {
            line.spans.forEach(span => {
                if (isContentSpan(span) && span.__viewElement) {
                    // if spans have additional styles, those override the text element styles
                    const mergedStyle = {
                        ...style,
                        ...span.style
                    };

                    this.applyStyleOnElement_m(mergedStyle, span.__viewElement, true, true);
                }
            });
        });
    }

    private _getStringSpanType(type: SpanType): string {
        switch (type) {
            case SpanType.Newline:
                return 'newline';
            case SpanType.Space:
                return 'space';
            case SpanType.Word:
                return 'text';
            case SpanType.Ellipsis:
                return 'ellipsis';
            case SpanType.End:
                return 'end';
            case SpanType.Variable:
                return 'variable';
            case SpanType.Composition:
                return 'composition';
        }
    }

    /** @@remove STUDIO:START */
    private _renderDecorationLayer(
        className: string,
        store: Map<number, HTMLDivElement>,
        zIndex = '-1'
    ): void {
        const boundingRect = this.textElement_m.getBoundingClientRect();
        store.clear();
        for (let line = 0; line < this.textLines_m.length; line++) {
            const textLineElement = this.lineToTextLineElementMap_m.get(line)!;
            const lineFragment = document.createDocumentFragment();
            const top = calculateTop(textLineElement, boundingRect, this.viewElement_m?.rotationZ ?? 0);
            const height = this.textLines_m[line].lineHeight;
            const lineElement = this._createLineElement(className, zIndex, top, height);
            this._setHorizontalAlignment(lineElement);

            store.set(line, lineElement);
            lineFragment.appendChild(lineElement);
            this.textElement_m.appendChild(lineFragment);
        }
    }
    private _createLineElement(
        className: string,
        zIndex: string,
        top: number,
        height: number
    ): HTMLDivElement {
        const lineElement = document.createElement('div');
        lineElement.className = className;
        lineElement.style.top = `${top / this.zoom_m}px`;
        lineElement.style.height = `${height}px`;
        lineElement.style.width = '100%';
        lineElement.style.position = 'absolute';
        lineElement.style.zIndex = zIndex;
        lineElement.style.left = '0px';

        return lineElement;
    }

    private _setHorizontalAlignment(lineElement: HTMLDivElement): void {
        if (this.style.horizontalAlignment === 'center') {
            lineElement.style.display = 'center';
        }
    }
    /** @@remove STUDIO:END */

    private _onClick = (event: MouseEvent): void => {
        if (this._isEditable() || !this.feedStore_m) {
            return;
        }

        const id = (event.currentTarget as HTMLElement).id;
        const feededElement = this._getFeededElement(id);

        if (feededElement && this._shouldEmitClick(feededElement)) {
            const feedValue = this.feedStore_m.getFeedValue(
                feededElement.feed,
                id,
                feededElement.element
            );
            this._emitClickEvent(event, feedValue.targetUrl);
        }
    };

    private _getFeededElement(id: string): IFeedState | undefined {
        let feededElement = this.feedStore_m.elements.get(id);

        if (!feededElement) {
            const variableSpans = this._findVariableSpans();
            const isSingleVariable = variableSpans.length === 1;

            if (isSingleVariable) {
                feededElement = this.feedStore_m.elements.get(variableSpans[0].style.variable!.spanId!);
            }
        }

        return feededElement;
    }

    private _shouldEmitClick(feededElement: IFeedState): boolean {
        const element = feededElement.element;
        return !(hasActionPreventClickthrough(element) || hasActionTargetUrl(element));
    }

    private _emitClickEvent(event: MouseEvent, targetUrl?: string): void {
        event.preventDefault();
        event.stopPropagation();
        this.emit('spanClicked', { event, deepLinkUrl: targetUrl });
    }

    private _isEditable(): boolean {
        const isEditorInEditMode = this.editor_m?.inEditMode;
        const isInShowcaseOrPreviewMode =
            window.bfstudio &&
            (window.bfstudio.inShowcaseMode ||
                (window.bfstudio.inPreviewMode && window.bfstudio.activePage === 'DV'));
        return !!isEditorInEditMode || !!isInShowcaseOrPreviewMode;
    }

    private _findVariableSpans(): IVariableSpan[] {
        return this.spans_m.filter(isVariableSpan).slice(0, 2);
    }

    isOfNodeSpanType_m(node: Node | HTMLElement, type: SpanType): boolean {
        if (node.nodeType === 3 && node.parentNode) {
            node = node.parentNode as HTMLElement;
        } else {
            return false;
        }
        if ((node as HTMLElement).dataset) {
            return this.getSpanTypeString_m((node as HTMLElement).dataset.type!) === type;
        } else {
            return false;
        }
    }

    getSpanTypeString_m(type: string): SpanType {
        switch (type) {
            case 'newline':
                return SpanType.Newline;
            case 'space':
                return SpanType.Space;
            case 'text':
                return SpanType.Word;
            case 'end':
                return SpanType.End;
            case 'variable':
                return SpanType.Variable;
            case 'composition':
                return SpanType.Composition;
        }
        throw new Error('Unknown span type.');
    }

    setStateOrElementStyle_m(stateStyle?: Partial<ITextElementProperties & ISize>): void {
        if (stateStyle) {
            this.style = { ...this._elementStyle, ...stateStyle };
        } else {
            this.style = this._elementStyle;
        }
    }

    private _resolveCssFontFamily(fontStyle?: IFontStyle, element?: HTMLElement): string {
        let fontFamily = fontStyle && `"f-${fontStyle.id}"`;

        // When no font family is set try to calculate which one to use
        if (!fontFamily && element) {
            const computedStyle = window.getComputedStyle(element).fontFamily;
            if (computedStyle) {
                fontFamily = computedStyle;
            }
        }

        // Add fallback font if only one fontFamily is provided.
        if (fontFamily && fontFamily.indexOf(',') === -1) {
            fontFamily += `, ${FALLBACK_FONT_FAMILY}`;
        }

        return fontFamily ?? '';
    }

    static isInCharacterRange_m(char: number, rangeMap: number[][]): boolean {
        return rangeMap.some(range => {
            const monoCharRange = range.length === 1 && range[0] === char;
            const multiCharRange = range.length > 1 && char >= range[0] && char <= range[1];
            return monoCharRange || multiCharRange;
        });
    }

    static getBidiDirection_m(
        char: number
    ): TextDirection.InferFromContext | TextDirection.Rtl | TextDirection.Ltr | TextDirection.Previous {
        if (char === CharacterCode.Space) {
            return TextDirection.InferFromContext;
        }

        // These are strictly RTL characters
        if (this.isInCharacterRange_m(char, R_AND_AL_BIDI_CHAR_RANGE_MAP)) {
            return TextDirection.Rtl;
        }

        // These are strictly LTR characters
        if (this.isInCharacterRange_m(char, L_BIDI_CHAR_RANGE_MAP)) {
            return TextDirection.Ltr;
        }

        return TextDirection.Previous;
    }

    isForcedRTL_m(): boolean {
        const firstChar = this.spans_m[0]?.content.charCodeAt(0) ?? '';
        return RichText.isInCharacterRange_m(firstChar, RTL_MARK);
    }
}

export function textShadowsCSSValue(shadow: ITextShadow[]): string {
    return shadow.map(s => `${s.offsetX}px ${s.offsetY}px ${s.blur}px ${s.color}`).join(',');
}

__inject(S.ENVIRONMENT, {}, RichText, '_env', 3);
__inject(S.EDITOR_STATE, { optional: true }, RichText, 'editorState', 4);
__inject(S.RICH_TEXT_EDITOR, { optional: true }, RichText, 'editor', 5);
