import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { EnvironmentService } from '../../shared/services/environment.service';
import { Logger } from '@bannerflow/sentinel-logger';

@Injectable({ providedIn: 'root' })
export class IntercomService {
    private logger = new Logger('IntercomService');

    constructor(private environmentService: EnvironmentService) {
        if (!environment.production) {
            return;
        }

        if (!window.Intercom) {
            this.logger.verbose('Intercom not detected');
            return;
        }

        this.environmentService.isMobile$.subscribe(data => {
            if (data) {
                window.Intercom('update', { hide_default_launcher: true });
            } else {
                window.Intercom('update', { hide_default_launcher: false });
            }
        });

        this.logger.verbose('Booting Intercom');

        window.Intercom('boot', {
            app_id: environment.intercomId,
            alignment: 'right',
            horizontal_padding: 20,
            vertical_padding: 20
        });
    }

    public showNewMessage(message?: string): void {
        if (window['Intercom']) {
            this.logger.verbose('showing message');
            window.Intercom('showNewMessage', message);
        }
    }

    public showIntercomNews(): void {
        if (window['Intercom']) {
            this.logger.verbose('showing news');
            window.Intercom('showSpace', 'news');
        }
    }
}
