import { createAction, props } from '@ngrx/store';
import {
    IDesignViewAnimationSettings,
    IDesignViewSettingValue,
    IDesignViewSettings,
    IIgnoredWarningValue,
    IIgnoredWarnings,
    ISharedSettingValue,
    ISharedSettings,
    KeyOfAnimationSettings
} from './user-settings.model';

export const setDesignViewSetting = createAction(
    '[User Settings] Set Design View Setting',
    props<{ key: keyof IDesignViewSettings; value: IDesignViewSettingValue }>()
);

export const setIgnoreWarnings = createAction(
    '[User Settings] Set Ignore Warnings',
    props<{ key: keyof IIgnoredWarnings; value: IIgnoredWarningValue }>()
);

export const toggleTranslationPageLayout = createAction(
    '[TranslationPage] Toggle TranslationPage Layout',
    props<{ direction?: 'left' | 'right' }>()
);

export const toggleTranslationPageTab = createAction(
    '[TranslationPage] Toggle TranslationPage tab',
    props<{ tab: 'content' | 'styling'; creativesetId: string }>()
);

export const toggleTranslationPageShowAllElements = createAction(
    '[TranslationPage] Toggle TranslationPage showAllElements'
);

export const setAnimationSetting = createAction(
    '[User Settings] Set Animation Setting',
    props<{ key: keyof IDesignViewAnimationSettings; value: KeyOfAnimationSettings }>()
);

export const setSharedSetting = createAction(
    '[User Settings] Set Other Setting',
    props<{ key: keyof ISharedSettings; value: ISharedSettingValue }>()
);
