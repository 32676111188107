import { LibraryKind, LibraryViewMode } from '@domain/media-library';
import { OneOfElementDataNodes } from '@domain/nodes';
import { createAction, props } from '@ngrx/store';
import { MediaLibraryState } from './media-library.reducer';

export const setSelectedFeedId = createAction(
    '[MediaLibrary] Set selected feed id',
    props<{ selectedFeedId: string | 'none' }>()
);

export const togglePinned = createAction('[MediaLibrary] Toggle media library pinned state');

export const filterLibraryByElement = createAction(
    '[MediaLibrary] Filter media library by element',
    props<{ node: OneOfElementDataNodes }>()
);

export const search = createAction('[MediaLibrary] Search library', props<{ searchQuery?: string }>());

export const pin = createAction('[MediaLibrary] Pin media library');

export const unpin = createAction('[MediaLibrary] Unpin the media library');

export const open = createAction('[MediaLibrary] Open media library', props<{ kind: LibraryKind }>());

export const toggle = createAction(
    '[MediaLibrary] Toggle media library',
    props<{ kind: LibraryKind; isEditingElement: boolean }>()
);

export const close = createAction(
    '[MediaLibrary] Close media library',
    props<{ force: boolean; isEditingElement: boolean }>()
);

export const closeSuccess = createAction(
    '[MediaLibrary] Close media library success',
    props<{
        force: boolean;
        isEditingBrandLibraryElementName: boolean;
    }>()
);

export const setKind = createAction(
    '[MediaLibrary] Set media library kind',
    props<{ kind: LibraryKind }>()
);

export const setViewMode = createAction(
    '[MediaLibrary] Set media library view mode',
    props<{ viewMode: LibraryViewMode }>()
);

export const setIsEditingElement = createAction(
    '[MediaLibrary] Set isEditingElement',
    props<{ isEditingElement: boolean }>()
);

export const openDialog = createAction('[MediaLibrary] Opens the dialog');

export const closeDialog = createAction('[MediaLibrary] Closes the dialog');

export const init = createAction('[MediaLibrary] Init media library');

export const initSuccess = createAction(
    '[MediaLibrary] Init media library success',
    props<{
        isPinned: boolean;
        kind: LibraryKind;
        selectedFeedId: MediaLibraryState['selectedFeedId'];
    }>()
);
