import {
    initialState,
    UserSettingsState,
    USER_SETTINGS_FEATURE_KEY
} from '../../shared/user-settings/state/user-settings.reducer';
import { ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [
            {
                [USER_SETTINGS_FEATURE_KEY]: { serialize: serializeToMergedState }
            }
        ],
        rehydrate: true,
        storageKeySerializer: serializeToKebabCase
    })(reducer);
}

const serializeToKebabCase = (key: string): string => {
    return key.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
};

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

// Merge current state with initial state in case of missing values in localstorage
function serializeToMergedState(state: UserSettingsState): UserSettingsState {
    return {
        designView: {
            ...initialState.designView,
            ...state.designView,
            animation: {
                ...initialState.designView.animation,
                ...state.designView.animation
            }
        },
        manageView: {
            ...initialState.manageView,
            ...state.manageView
        },
        translationPage: {
            ...initialState.translationPage,
            ...state.translationPage
        },
        ignoredWarnings: {
            ...initialState.ignoredWarnings,
            ...state.ignoredWarnings
        },
        sharedSettings: {
            ...initialState.sharedSettings,
            ...state.sharedSettings
        }
    };
}
