import { IColor } from './color';
import { ITextSpan } from './creativeset/version';
import { ISize } from './dimension';
import { IFeed } from './feed';
import { IFontStyle } from './font';
import { IPadding, ITextShadow } from './style';

export type HorizontalAlignment = 'left' | 'center' | 'right' | 'justify';

export type VerticalAlignment = 'top' | 'middle' | 'bottom';

export type TextOverflow = 'shrink' | 'truncate' | 'expand' | 'scroll';

export const enum TextNodeKind {
    Root,
    TextSpan
}

export const enum SpanType {
    Word = 'word',
    Space = 'space',
    Newline = 'newline',
    Ellipsis = 'ellipsis',
    End = 'end',
    Variable = 'variable',
    Composition = 'composition' // Used for showing whats being written while composing a text (i.e. writting a Chinese character using Pinyin)
}

export const enum RenderedLineKind {
    AutoLine, // Overflowed line
    ExplicitLine // User typed newline
}

export interface IContentLine {
    dir: TextDirection;
    lineHeight: number;
    maxFontSize: number;
    lineWidth: number;
    // Trailing spaces are trailing character spacing, spaces, newlines
    trailingSpaceWidth: number;
    characterWidth: number;
    endsWithNewline: boolean;
    isLastLine: boolean;
    spans: OneOfRenderedSpans[];
}

export type TextStyle = Omit<ITextElementProperties, 'content'> & Partial<ISize>;

export interface IText {
    style: TextStyle;
    spans: OneOfEditableSpans[];
}

export interface ISpans {
    spans: OneOfEditableSpans[];
}

export interface IRenderedSpans {
    spans: OneOfRenderedSpans[];
}

export const enum JoinType {
    None = 0,
    ZeroWidthNoJoint = '\u200C',
    ZeroWidthJoint = '\u200D'
}

export interface ISpan {
    top: number;
    left: number;
    type: SpanType;
    content: string;
    width: number;
    height: number;
    lineHeight: number;
    attributes: ISpanAttributes;

    // JoinType are needed in arabic and hindi languages, where a join span can alter the glyph of a character.
    startJoint?: JoinType;
    endJoint?: JoinType;
    __viewElement?: HTMLSpanElement;
}

export interface ISpanProperties {
    attributes?: ISpanAttributes;
    styleIds?: IStyleIdMap;
}

export interface ISpanAttributes {
    styleIndex?: number;
    shouldRenderNumber?: boolean;
    [attribute: string]: any;
}

export type StyleProperty = 'textColor';

export interface IStyle {
    [property: string]: any;
}

/** `{ documentId: styleId }` */
export interface IStyleIdMap {
    [documentId: string]: /* styleId */ string;
}
// export type IStyleIdMap = Map</* documentId */string, /* styleId */string>;

/**
 * `{ id: ICharacterProperties }`
 */
export type ICharacterStylesMap = Map<string, Partial<ICharacterProperties>>;

export interface IElementIdToCharacterStylesMap {
    [elementId: string]: ICharacterStylesMap;
}

export const enum PreviousStyleIdType {
    Undefined,
    Unchanged
}

export type PreviousStyleId = string | PreviousStyleIdType;

export const enum TextDirection {
    // Infer from context, if it is first or last it is LTR, otherwise it infers it from the next sibling.
    InferFromContext = 'infer',
    // Only for special chars.
    Previous = 'previous',
    Ltr = 'ltr',
    Rtl = 'rtl',
    Both = 'both'
}

export type ResolvedTextDirection = TextDirection.Ltr | TextDirection.Rtl;

export interface IContentSpan {
    line?: number;
    column?: number;
    dir?: TextDirection;
    style: Partial<ICharacterProperties>;
    styleIds: IStyleIdMap;
    styleId?: string;

    // We resolve style changes on edit end. We have to keep track of the style before the text edit
    // in situations where you clear styles and then reapply a different style, which should be the
    // same as reapplying the styles directly.
    __previousStyleIds?: PreviousStyleId[];
    __previousStyleIdToHistoryIndexMap?: Map<string, number>;
}

export interface ISpaceSpan extends IContentSpan, ISpan {
    type: SpanType.Space;
}

export interface IWordSpan extends IContentSpan, ISpan {
    type: SpanType.Word;
}

export interface ICompositionSpan extends IContentSpan, ISpan {
    type: SpanType.Composition;
    width: number;
}

export interface IVariableSpan extends IContentSpan, ISpan {
    type: SpanType.Variable;
}

export interface INewlineSpan extends IContentSpan, ISpan {
    type: SpanType.Newline;
}

export interface IEndSpan extends ISpan {
    type: SpanType.End;
    dir: TextDirection;
}

export interface IEllipsesSpan extends ISpan {
    type: SpanType.Ellipsis;
    style: Partial<ICharacterProperties>; // Takes the last span style before truncation
    dir?: TextDirection;
}

export type OneOfContentSpans =
    | IWordSpan
    | ISpaceSpan
    | IVariableSpan
    | INewlineSpan
    | ICompositionSpan;
export type OneOfEditableSpans = OneOfContentSpans | IEndSpan;
export type OneOfRenderedSpans = OneOfEditableSpans | IEllipsesSpan;

/**
 * Properties that can be changed by character styling
 *
 * NOTE: fontSize is relative to `ITextElementProperties.fontSize`
 */
export interface ICharacterProperties
    extends Partial<
        Omit<
            ITextElementProperties,
            | 'content'
            | 'horizontalAlignment'
            | 'verticalAlignment'
            | 'maxRows'
            | 'textOverflow'
            | 'padding'
        >
    > {
    variable?: ITextVariable;
}

export type CharacterPropertyKeys = keyof ICharacterProperties;

export interface ITextElementProperties {
    content: IText;
    horizontalAlignment: HorizontalAlignment;
    verticalAlignment: VerticalAlignment;
    font?: IFontStyle;
    fontSize: number;
    maxRows: number;
    padding: IPadding;
    textOverflow: TextOverflow;
    textColor: IColor;
    uppercase: boolean;
    underline: boolean;
    strikethrough: boolean;
    characterSpacing: number;
    lineHeight: number;
    textShadows?: ITextShadow[];
    __fontFamilyId?: string;
}

export interface IMixedCharacterProperties
    extends Omit<ITextElementProperties, 'textColor' | 'textShadows' | 'fontSize' | 'font'> {
    textColor?: IColor;
    fontSize?: number;
    font?: IFontStyle | '$mixed';
    textShadows?: ITextShadow[];
    variable?: IFeed;
    __fontFamilyId: string | '$mixed';
}

export interface ITextElementCharacterProperties extends ICharacterProperties {}
export type TextElementAndCharacterStyleProperties = ITextElementProperties &
    ICharacterProperties &
    IMixedCharacterProperties;

type RequiredExceptFrom<T, F extends keyof T> = Required<Omit<T, F>> & Partial<Pick<T, F>>;
export type IRequiredTextProperties = RequiredExceptFrom<
    ITextElementProperties,
    | 'content'
    | 'verticalAlignment'
    | 'horizontalAlignment'
    | 'strikethrough'
    | 'underline'
    | 'uppercase'
    | 'textShadows'
    | 'textColor'
    | 'fontSize'
    | 'font'
>;

export interface ITextVariable extends IFeed {
    spanId?: string;
    type: 'text';
}
export const VARIABLE_PREFIX = '@';

export interface IStyleIndexMaps {
    styleHashToIndexMap: Map<string, number>;
    indexToStyleIdsMap: Map<number, IStyleIdMap>;
}

export type SplitText = { text: string; styles: ITextSpan[] };
export interface IFeedInfo {
    [key: string]: ITextSpan[];
}
