@if (sizes.length) {
    <div class="format-list">
        <filter-list-sizes
            *featureFlag="StudioFeatureFlags.SizeCollectionsDisabled"
            [sizes]="sizes" />

        <ng-container *featureFlag="StudioFeatureFlags.SizeCollections">
            <quick-filters />
            <!-- TODO: Refactor this to use signals internally-->
            <filter-list-sizes [sizes]="sizes" />
        </ng-container>

        <ng-container *media="Breakpoint.DesktopUp">
            <filter-list-status *permissions="'Status'" />
        </ng-container>
    </div>
}
