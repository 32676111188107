import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MIN_ELEMENT_DURATION } from '@creative/animation.utils';
import { IAnimator } from '@creative/animator.header';
import { Color } from '@creative/color';
import { IGifExport, IPreloadImage } from '@domain/creativeset/creative';
import { ICreativeDataNode } from '@domain/nodes';
import { ISocialPlacement } from '@domain/social';
import { GuidelineType, IGuideline } from '@domain/workspace';
import { SOCIAL_PLACEMENTS } from '@studio/domain/constants/social';
import { ISocialGuide, ISocialNetwork } from '@studio/domain/social';
import { uuidv4 } from '@studio/utils/id';
import { Observable, Subject, combineLatest, merge } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { ColorService } from '../../../../shared/services/color.service';
import { SocialGuideService } from '../../../../shared/services/social-guide.service';
import { UserSettingsService } from '../../../../shared/user-settings/state/user-settings.service';
import { DesignViewComponent } from '../../../index';
import { EditorEventService, EditorStateService } from '../../services';
import { ElementChangeType } from '../../services/editor-event';
import { HistoryService } from '../../services/history.service';
import { MutatorService } from '../../services/mutator.service';
import { MAX_TIMELINE_DURATION } from '../../timeline/timeline.constants';

@Component({
    selector: 'creative-properties',
    templateUrl: './creative-properties.component.html',
    styleUrls: ['./creative-properties.component.scss', '../common.scss']
})
export class CreativePropertiesComponent implements OnInit, OnDestroy {
    @Input() animator: IAnimator;
    @Input() editor: DesignViewComponent;

    fill: Color = new Color();
    duration = 0;
    minTimelineDuration = MIN_ELEMENT_DURATION;
    maxTimelineDuration = MAX_TIMELINE_DURATION;
    loops = 0;
    startTime: number;
    stopTime: number | undefined;
    preloadImage: IPreloadImage;
    gifExport: IGifExport;
    readonly = false;
    creativeDocument: ICreativeDataNode;
    ElementChangeType = ElementChangeType;

    socialNetworks$: Observable<ISocialNetwork[]>;
    selectedNetwork$: Observable<ISocialNetwork>;
    selectedPlacement$: Observable<ISocialPlacement>;
    selectedPlacement: ISocialPlacement;
    showOverlay$: Observable<boolean>;
    showGuidelines$: Observable<boolean>;
    isSocialCreative$: Observable<boolean>;
    private unsubscribe$ = new Subject<void>();

    constructor(
        private historyService: HistoryService,
        public colorService: ColorService,
        private socialGuideService: SocialGuideService,
        public userSettingsService: UserSettingsService,
        private mutatorService: MutatorService,
        private editorEvent: EditorEventService,
        private editorStateService: EditorStateService
    ) {
        this.creativeDocument = this.mutatorService.creativeDocument;
        this.isSocialCreative$ = this.socialGuideService.isSocialCreative$;
        this.socialNetworks$ = this.socialGuideService.socialNetworks$;
        this.selectedNetwork$ = this.socialGuideService.selectedNetwork$;
        this.selectedPlacement$ = this.socialGuideService.selectedPlacement$;
        this.showOverlay$ = this.socialGuideService.showOverlay$;
        this.showGuidelines$ = this.socialGuideService.showGuidelines$;

        merge(
            this.historyService.snapshotApply$,
            this.editorEvent.creative.change$,
            this.editorStateService.renderer$
        )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.creativeDocument = this.mutatorService.creativeDocument;
                this.updateProperties();
            });

        this.isSocialCreative$
            .pipe(
                filter(isSocial => isSocial),
                switchMap(() =>
                    combineLatest({
                        network: this.selectedNetwork$,
                        placement: this.selectedPlacement$,
                        guidelines: this.showGuidelines$,
                        overlay: this.showOverlay$
                    })
                ),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((socialGuide: ISocialGuide) => {
                this.setSocialGuide(socialGuide);
                this.renderSocialGuidelines();
            });
    }

    ngOnInit(): void {
        this.updateProperties();

        this.socialGuideService.initSocialCreative(this.mutatorService.creativeDocument);
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    setFill(fill: Color, eventType?: ElementChangeType): void {
        this.fill = fill;
        this.updateFill(eventType);
    }

    setFillAlpha(alpha: number, eventType?: ElementChangeType): void {
        const newColor = new Color(this.fill);
        newColor.alpha = alpha;
        this.setFill(newColor, eventType);
    }

    updateFill(eventType?: ElementChangeType): void {
        this.mutatorService.setCreativeAppearance('fill', this.fill, eventType);
    }

    previewFill(color: Color): void {
        this.mutatorService.setCreativeAppearance('fill', color);
    }

    previewStop(color: Color): void {
        this.fill = color;
        this.updateFill();
    }

    updateLoops(): void {
        this.mutatorService.setCreativeLoops(this.loops);

        if (this.loops === 0) {
            this.stopTime = undefined;
        } else {
            this.stopTime = this.mutatorService.creativeDocument.getStopTime_m();
        }

        this.updateStopTime(false);
    }

    showManualGifFrames(show: boolean): void {
        this.mutatorService.showManualGifFrames(show);
    }

    updateStopTime(emitChanges = true): void {
        this.mutatorService.setCreativeStopTime(this.stopTime || undefined, emitChanges);
        this.stopTime = Math.max(0, this.stopTime!);
    }

    updateStartTime(emitChanges = true): void {
        this.mutatorService.setAnimationStartTime(this.startTime, emitChanges);
        this.startTime = Math.max(0, this.startTime);
    }

    updateDuration(): void {
        this.mutatorService.setCreativeDuration(this.duration);
    }

    updatePreloadImage(): void {
        this.mutatorService.setPreloadImage(this.preloadImage);
    }

    undo(): void {
        this.historyService.undo$.next();
    }

    redo(): void {
        this.historyService.redo$.next();
    }

    updateProperties = (): void => {
        if (this.mutatorService.creativeDocument.fill) {
            this.fill = this.mutatorService.creativeDocument.fill;
        }
        this.loops = this.mutatorService.creativeDocument.loops;
        this.stopTime = this.mutatorService.creativeDocument.getStopTime_m();
        this.startTime = this.mutatorService.creativeDocument?.startTime || 0;
        if (this.mutatorService.creativeDocument.preloadImage) {
            this.preloadImage = { ...this.mutatorService.creativeDocument.preloadImage };
        }
        if (this.creativeDocument.gifExport) {
            this.gifExport = this.creativeDocument.gifExport;
        }

        if (!this.preloadImage.frames || this.preloadImage.frames.length === 0) {
            this.preloadImage.frames = [
                this.mutatorService.creativeDocument.getFirstPreloadImageFrame()
            ];
        }

        if (this.animator) {
            this.duration = this.mutatorService.creativeDocument.duration;
        }
    };

    updateNetwork(network: ISocialNetwork): void {
        this.socialGuideService.selectNetwork(network);
    }

    updatePlacement(placement: ISocialPlacement): void {
        this.socialGuideService.selectPlacement(placement);
    }

    showSocialOverlay(show: boolean): void {
        this.socialGuideService.setShowOverlay(show);
    }

    showSocialGuidelines(show: boolean): void {
        this.socialGuideService.setShowGuidelines(show);
    }

    private setSocialGuide(socialGuide: ISocialGuide): void {
        this.mutatorService.setCreativeSocialGuide(socialGuide);
    }

    renderSocialGuidelines(): void {
        const creativeDocument = this.mutatorService.creativeDocument;
        const socialGuide = creativeDocument.socialGuide;
        if (socialGuide?.guidelines) {
            const creativeHeight = creativeDocument.height;
            const creativeWidth = creativeDocument.width;
            const guidelinePosition = SOCIAL_PLACEMENTS.find(
                placement => placement.type === socialGuide.placement
            )?.position;

            if (!guidelinePosition) {
                throw new Error('Could not get guideline position');
            }

            const guideLineTopYPos = Math.floor(creativeHeight * guidelinePosition.top);
            const guideLineBottomYPos = Math.floor(creativeHeight * guidelinePosition.bottom);

            const socialGuideLines: IGuideline[] = [
                {
                    id: uuidv4(),
                    position: { x: 0, y: guideLineTopYPos },
                    preview: false,
                    type: GuidelineType.Horizontal,
                    social: true
                },
                {
                    id: uuidv4(),
                    position: { x: 0, y: creativeHeight - guideLineBottomYPos },
                    preview: false,
                    type: GuidelineType.Horizontal,
                    social: true
                }
            ];

            if (guidelinePosition.left && guidelinePosition.right) {
                const guideLineLeftXPos = Math.floor(creativeWidth * guidelinePosition.left);
                const guideLineRightXPos = Math.floor(creativeWidth * guidelinePosition.right);

                socialGuideLines.push(
                    {
                        id: uuidv4(),
                        position: { x: guideLineLeftXPos, y: 0 },
                        preview: false,
                        type: GuidelineType.Vertical,
                        social: true
                    },
                    {
                        id: uuidv4(),
                        position: { x: creativeWidth - guideLineRightXPos, y: 0 },
                        preview: false,
                        type: GuidelineType.Vertical,
                        social: true
                    }
                );
            }

            creativeDocument.guidelines = creativeDocument.guidelines.filter(
                guideline => !guideline.social
            );

            this.mutatorService.creativeDocument.guidelines.push(...socialGuideLines);
        }

        this.editor?.workspace.gizmoDrawer.draw();
    }
}
