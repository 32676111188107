import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { UIModule, UIPopoverDirective, UIPopoverTargetDirective } from '@bannerflow/ui';
import { StudioKeymapsComponent } from '../studio-keymaps/studio-keymaps.component';

@Component({
    standalone: true,
    imports: [UIModule, CommonModule, StudioKeymapsComponent],
    selector: 'keymap-options-popover',
    templateUrl: './keymap-options-popover.component.html',
    styleUrls: ['./keymap-options-popover.component.scss']
})
export class KeymapOptionsPopoverComponent {
    @ViewChild('popover') popover: UIPopoverDirective;

    open(target: UIPopoverTargetDirective): void {
        this.popover.open(target);
    }
}
