@let _collapsed = collapsed();
<div
    id="interaction-quick-filters"
    class="quick-filters">
    <div
        class="header"
        [class.collapsed]="_collapsed">
        <ui-svg-icon
            (click)="toggleAll()"
            id="interaction-sizes-filter"
            data-test-id="size-filter-reset"
            class="filter-icon"
            [uiTooltip]="headerTooltip()"
            [uiTooltipHideArrow]="true"
            [class.all-filtered]="isAllFiltered()"
            [class.some-filtered]="!isAllFiltered() && isSomeFiltered()"
            icon="visibility-visible" />
        <span class="title">Quick filters</span>
        <div class="temporary-container-waiting-for-icon"></div>
        <ui-svg-icon
            (click)="collapse()"
            class="icon collapse"
            [icon]="_collapsed ? 'arrow-up' : 'arrow-down'" />
    </div>
    @if (!_collapsed) {
        @let _collections = collections();
        <div class="filters-section ui-scrollbar">
            @for (collection of _collections; track collection.id) {
                <div
                    class="collection"
                    (click)="toggleCollection(collection)"
                    [class.unfiltered]="!collection.selected"
                    data-test-id="quick-filters-interaction-item">
                    <div class="collection-copy">
                        <ui-svg-icon
                            class="visibility-icon"
                            icon="visibility-visible"
                            [class.selected]="collection.selected" />
                        <div class="collection-name">
                            <span>{{ collection.name }}</span>
                        </div>
                    </div>
                </div>
            }
        </div>
    }
</div>
