import { Component } from '@angular/core';
import { AppComponent } from '../../app.component';
import { CreativesetDataService } from './creativeset.data.service';

@Component({
    selector: 'creativeset-showcase-component',
    templateUrl: './creativeset.component.html'
})
export class CreativesetShowcaseComponent {
    constructor(
        private app: AppComponent,
        private creativesetDataService: CreativesetDataService
    ) {
        this.app.loaded = true;
    }
}
