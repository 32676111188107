import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Renderer2,
    ViewChild,
    ViewRef
} from '@angular/core';
import { FeedService } from '@app/shared/components';
import { Logger } from '@bannerflow/sentinel-logger';
import {
    IUIListDataNode,
    UIConfirmDialogResult,
    UIConfirmDialogService,
    UIDebounce,
    UIDialogComponent,
    UIDropdownComponent,
    UIInputComponent,
    UIListComponent,
    UIListDataSource,
    UINotificationService,
    UITooltipService
} from '@bannerflow/ui';
import { createFeed, createVariable, normalizeFeedPath } from '@creative/elements/feed/feeds.utils';
import { createRichTextVariableFromString } from '@creative/elements/rich-text/text-nodes';
import { fetchWidgetFromResource } from '@creative/elements/widget/utils';
import {
    getKindAsString,
    getKindMessageAsString,
    getKindTitleAsString,
    getLibraryKindFromElementKind,
    getSearchPlaceholderAsString,
    isBannerFlowLibraryWidget,
    isImageElement,
    isImageOrVideoElement,
    isTextLikeElement,
    isTextNode,
    isVideoElement,
    isWidgetElement
} from '@creative/nodes/helpers';
import { deserializeFeedString, INLINE_STYLED_TEXT, serializeFeedValue } from '@creative/serialization';
import { deserializeInlineStyledText } from '@creative/serialization/text-serializer';
import {
    Folder,
    IBrandLibraryElement,
    INewBrandLibraryElement,
    LibraryWidget,
    LibraryWidgetState,
    NewFolder,
    OneOfLibraryAssets
} from '@domain/brand/brand-library';
import { FeedEvents } from '@domain/creative/feed/feed-store.header';
import { IElement } from '@domain/creativeset';
import { AssetReference } from '@domain/creativeset/element-asset';
import { IBounds, ISize } from '@domain/dimension';
import { ElementKind } from '@domain/elements';
import { IBfFeed, IFeed } from '@domain/feed';
import { IFeedFieldListItem, LibraryKind, LibraryViewMode } from '@domain/media-library';
import {
    IElementDataNode,
    INewBaseNode,
    OneOfElementDataNodes,
    OneOfTextViewElements
} from '@domain/nodes';
import { SpanType, VARIABLE_PREFIX } from '@domain/text';
import { ActivityLoggerService } from '@studio/monitoring/activity-logger.service';
import { AssetUploadEvent, EventLoggerService } from '@studio/monitoring/events';
import {
    isElementDescendantOfElement,
    isElementDescendantOfElementWithClass
} from '@studio/utils/dom-utils';
import {
    createBrandlibraryElement,
    createElementProperty,
    getLibraryWidgetReferenceOfElement,
    getWidgetContentUrlOfElement
} from '@studio/utils/element.utils';
import { handleError } from '@studio/utils/errors';
import { eventToPosition, getBoundingboxWithinBoundary } from '@studio/utils/geom';
import { uuidv4 } from '@studio/utils/id';
import { getImageDimensions } from '@studio/utils/media';
import { isUrl } from '@studio/utils/url';
import {
    decodeXML,
    generateUniqueName,
    generateUniqueNameInList,
    getAspectRatioFit,
    omit
} from '@studio/utils/utils';
import { combineLatest, firstValueFrom, merge, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CreativesetDataService } from '../../../shared/creativeset/creativeset.data.service';
import { BrandLibraryDataService } from '../../../shared/media-library/brand-library.data.service';
import { MediaLibraryState } from '../../../shared/media-library/state/media-library.reducer';
import { MediaLibraryService } from '../../../shared/media-library/state/media-library.service';
import { GainsightEvent, GainsightService } from '../../../shared/services/gainsight.service';
import { HotkeyBetterService } from '../../../shared/services/hotkeys/hotkey.better.service';
import { LOCAL_STORAGE } from '../../../shared/services/storage/storage.injection';
import { IStorageService } from '../../../shared/services/storage/storage.model';
import { WidgetService } from '../../../shared/services/widget.service';
import { UserService } from '../../../shared/user/state/user.service';
import { DesignViewComponent } from '../design-view.component';
import { AssetPropertyContext } from '../properties-panel/asset-property/asset-property';
import { ElementSelectionService } from '../services';
import {
    AssetUploadService,
    IAssetUploadAfterProgressState,
    IAssetUploadCompleteState,
    IAssetUploadLoadedState,
    IAssetUploadState
} from '../services/asset-upload.service';
import { NodeCreatorService } from '../services/data-node-creator.service';
import { EditorStateService } from '../services/editor-state.service';
import { ElementCreatorService } from '../services/element-creator.service';
import { MutatorService } from '../services/mutator.service';
import { HeavyVideoService } from '../video';
import { BrandLibraryElementDeletionService } from './brandlibrary-element-deletion.service';
import { BrandLibraryElementEditService } from './brandlibrary-element-edit.service';
import { BrandLibraryElementService } from './brandlibrary-element.service';
import { BrandLibraryFolderService } from './brandlibrary-folder.service';
import { DraggableElementComponent } from './draggable-element/draggable-element.component';
import { ElementRenderingService } from './element-renderering-service';
import {
    createNewBrandLibraryElementFromWidget,
    FeedFieldComponent,
    isEffectLibraryElement,
    isEffectWidget,
    isElementTypeMatchLibraryType,
    LibraryElementRenderingService
} from './index';
import { AIStudioDialogService } from '../../../shared/ai-studio/ai-studio-dialog.service';
import { isImageLibraryAsset, isVideoLibraryAsset } from '@studio/domain/brand-library/assets';

@Component({
    selector: 'media-library',
    templateUrl: 'media-library.component.html',
    styleUrls: ['media-library.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ElementRenderingService, NodeCreatorService],
    host: {
        '[style.width.px]': 'width',
        '[class.hidden]': '(!this.inDialog && !this.isOpen)',
        '[class.isEditingName]': 'isEditingName',
        '[class.inDialog]': 'inDialog'
    }
})
export class MediaLibraryComponent implements AfterViewInit, OnDestroy, OnInit {
    @Input() width: number;
    @Output() clearFilter: EventEmitter<void> = new EventEmitter();

    @ViewChild('addMenuDropdown') addMenuDropdown: UIDropdownComponent;
    @ViewChild('assetList') assetList: ElementRef<HTMLElement>;
    @ViewChild('feedView') feedView: ElementRef<HTMLElement>;
    @ViewChild('fileDropZone') fileDropZone: ElementRef;
    @ViewChild('fileInput') fileInput: ElementRef;
    @ViewChild('folders', { static: false }) foldersElement: ElementRef<HTMLElement>;
    @ViewChild('foldersList', { static: false }) foldersList: UIListComponent;
    @ViewChild('list') list: UIListComponent;
    @ViewChild('searchInput') searchInput: UIInputComponent;
    @ViewChild('sortDropdown') sortDropdown: UIDropdownComponent;

    readonly THUMBNAIL_SIZE = 82;
    showFileDropZone: boolean;
    dataSource: UIListDataSource<IElement | IBfFeed> = new UIListDataSource();
    foldersSource: UIListDataSource<Folder> = new UIListDataSource();
    showGridView = true;
    private brandLibraryElements: IBrandLibraryElement[] | INewBrandLibraryElement[] | IBfFeed[] = [];
    brandLibraryFolderElements: IBrandLibraryElement[] | IBfFeed[];
    brandLibraryFolders: Folder[] = [];
    allBrandLibraryFolders: Folder[] = [];
    selectedFolder?: Folder;
    menuOpened = false;
    isHovered = false;
    scrollInterval: number | undefined;
    searchQuery = '';
    replaceAllOfAssetType = true;
    showReplaceSizes = false;
    context: 'medialibrary' | AssetPropertyContext = 'medialibrary';
    AssetPropertyContext = AssetPropertyContext;
    inDialog = false;
    selection: IElement[] | IBfFeed[] = [];
    bannerflowLibraryWidgets: LibraryWidget[] = [];
    bannerflowLibraryEffects: LibraryWidget[] = [];
    bannerflowLibraryEffectElements: INewBrandLibraryElement[] = [];
    feedData?: IFeedFieldListItem[];
    selectedFeedItem: IFeedFieldListItem;
    currentFolder?: IBfFeed;
    currentFeed?: IBfFeed;
    private currentFeedFieldComponent?: FeedFieldComponent;
    inFeedPicker = false;
    itemsIsLoading = false;
    cachedImages = new Map<string, { width: number; height: number }>();
    mouseDownOnHost = false;
    isEditingName = false;
    wasFiltered = false;
    newFolder?: NewFolder;
    oldViewMode: LibraryViewMode;
    viewMode: LibraryViewMode = LibraryViewMode.Grid;
    isFromFeeds: boolean;
    listLayout: 'list' | 'grid' = 'list';
    searchRegex = /\s/g;

    sortingStrategy: SortingStrategy = {
        direction: 'desc',
        sortBy: 'name'
    };

    LibraryViewMode = LibraryViewMode;

    isOpen = false;
    isPinned = false;
    LibraryKind = LibraryKind;
    kind: LibraryKind; // what type of library the user has open
    filteredAssetType?: ElementKind.Image | ElementKind.Video;
    selectedFeedId?: string;
    bannerflowLibraryWidgetsLoaded: boolean;
    public libraryKindTitle = '';
    public libraryKindToMessage = '';
    public libraryKindToName = '';
    public libraryKindToSearchPlaceholder = '';
    public isElementsLibrary = false;
    private currentDragElements: { component: DraggableElementComponent; viewRef: ViewRef }[] = [];
    private startMousePosition = { x: 0, y: 0 };
    private prospectiveDragElements: IBrandLibraryElement[] = [];
    private draggingRefs: ComponentRef<DraggableElementComponent>[] = [];
    isDraggingElements: boolean;
    private hotkeyContext: { name: string; input: Window; excludeInputFields: boolean };
    selectedElements: IElement[];
    private assetUploadProcessId: string;
    private unsubscribe$ = new Subject<void>();
    private feedPollTimeout?: number;

    ElementKind = ElementKind;
    viewInitialized = false;
    private hasVideoLibraryAccess: boolean;

    private get panelIncludeMedia(): boolean {
        return (
            this.kind === LibraryKind.Image ||
            this.kind === LibraryKind.Video ||
            this.kind === LibraryKind.Any
        );
    }

    @HostListener('mouseenter') onEnter(): void {
        this.isHovered = true;
    }

    @HostListener('mouseleave') onLeave(): void {
        this.isHovered = false;
    }

    @HostListener('click') onClick(): void {
        if (this.editor) {
            this.mutatorService.workspaceFocused = false;
        }
    }

    @HostListener('dragenter', ['$event']) onDragEnter(): void {
        this.showFileDropZone = true;
        this.detectChanges();
        setTimeout(() =>
            this.fileDropZone.nativeElement.addEventListener('dragleave', this.onDragFileLeave)
        );
    }

    @HostListener('dragover', ['$event']) onDragOver(event: Event): void {
        // Prevent file from opening in the browser
        event.preventDefault();
    }

    @HostListener('drop', ['$event']) onDropFile(event: Event): void {
        event.preventDefault();
        const files: File[] = [];
        const dataTransfer = (event as DragEvent).dataTransfer;

        if (this.editor) {
            this.editor.workspace.stopCreateNewElement();
        }

        if (dataTransfer?.items) {
            for (const i in dataTransfer.items) {
                const item = dataTransfer.items[i];
                if (item.kind === 'file') {
                    const file = dataTransfer.files[i];
                    files.push(file);
                }
            }
        }
        this.uploadFiles(files, this.parentFolderId);
        this.fileDropZone.nativeElement.removeEventListener('dragleave', this.onDragFileLeave);
        setTimeout(() => {
            this.showFileDropZone = false;
            this.detectChanges();
        });
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: Event): void {
        if (!this.editor) {
            return;
        }
        const target = event.target as HTMLElement;
        const targetIsOverlay = target.classList.contains('cdk-overlay-backdrop');

        if (
            !isElementDescendantOfElement(this.host.nativeElement, target) &&
            !targetIsOverlay &&
            !this.isDraggingElements &&
            !this.brandLibraryElementDeletionService.deleteConfirmDialogOpen &&
            !isElementDescendantOfElementWithClass(target, 'prevent-ml-close') &&
            !isElementDescendantOfElementWithClass(target, 'ui-dropdown-list') &&
            !isElementDescendantOfElementWithClass(target, 'cell')
        ) {
            // clicked outside media library and nothing prevents us from closing it
            // Avoid dispatching actions every click, it bloats the logs
            if (this.isMediaLibraryOpen || this.isEditingName) {
                this.mediaLibraryService.closeMediaLibrary(this.isEditingName);
            }
        }

        this.editor.toolbar.deselectToolbarItem();

        this.mouseDownOnHost = false;
    }

    private logger = new Logger('MediaLibraryComponent');
    private isMediaLibraryOpen: boolean;

    constructor(
        public editor: DesignViewComponent,
        private editorStateService: EditorStateService,
        // Should be changed to optional type (?:)
        @Optional()
        public uiDialogComponent: UIDialogComponent,
        public host: ElementRef,
        private uiNotificationService: UINotificationService,
        private hotkeyBetterService: HotkeyBetterService,
        private uiConfirmDialogService: UIConfirmDialogService,
        private uiTooltipService: UITooltipService,
        private changeDetector: ChangeDetectorRef,
        public creativesetDataService: CreativesetDataService,
        private widgetService: WidgetService,
        private userService: UserService,
        private feedService: FeedService,
        private renderer: Renderer2,
        private elementRenderingService: ElementRenderingService,
        private brandlibraryFolderService: BrandLibraryFolderService,
        public mediaLibraryService: MediaLibraryService,
        public assetUploadService: AssetUploadService,
        private mutatorService: MutatorService,
        private eventLoggerService: EventLoggerService,
        private activityLoggerService: ActivityLoggerService,
        private elementSelectionService: ElementSelectionService,
        private brandLibraryElementService: BrandLibraryElementService,
        private heavyVideoService: HeavyVideoService,
        private elementCreatorService: ElementCreatorService,
        private nodeCreatorService: NodeCreatorService,
        @Inject(LOCAL_STORAGE) private storage: IStorageService,
        private brandLibraryDataService: BrandLibraryDataService,
        private gainsightService: GainsightService,
        private libraryElementRenderingService: LibraryElementRenderingService,
        private brandLibraryElementDeletionService: BrandLibraryElementDeletionService,
        private brandLibraryElementEditService: BrandLibraryElementEditService,
        private aiStudioDialogService: AIStudioDialogService
    ) {
        if (this.uiDialogComponent) {
            this.inDialog = true;
            this.mediaLibraryService.openDialog();
        }

        this.assetUploadService.uploadProgress$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((assetUploadState: IAssetUploadState) =>
                this.onAssetUploadStateChange(assetUploadState)
            );

        this.initState();

        if (this.isPinned) {
            this.itemsIsLoading = true;
        }

        this.mediaLibraryService.search$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(searchQuery => void this.onSearch(searchQuery));
    }

    async ngOnInit(): Promise<void> {
        this.mediaLibraryService.isOpen$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(isMediaLibraryOpen => {
                this.isMediaLibraryOpen = isMediaLibraryOpen;
            });

        this.brandLibraryElementEditService.isEditingName$$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(isEditingElementName => {
                this.isEditingName = isEditingElementName;
                this.detectChanges();
            });

        this.hasVideoLibraryAccess = await this.userService.hasPermission('StudioVideoLibrary');
        this.setupHotkeyListeners();
    }

    get parentFolderId(): string | undefined {
        return this.selectedFolder ? this.selectedFolder.id : undefined;
    }

    get acceptedUploadFormats(): string {
        return `.jpg, .jpeg, .png, .svg, .gif ${this.hasVideoLibraryAccess ? ', .mp4' : ''}`;
    }

    initState(): void {
        combineLatest([
            this.mediaLibraryService.isOpen$,
            this.mediaLibraryService.isPinned$,
            this.mediaLibraryService.viewMode$
        ])
            .pipe(take(1))
            .subscribe(([isOpen, isPinned, viewMode]) => {
                this.isOpen = isOpen;
                this.isPinned = isPinned;
                this.viewMode = viewMode;
                this.initSorting();
            });
    }

    async ngAfterViewInit(): Promise<void> {
        this.viewInitialized = true;

        this.mediaLibraryService.mediaLibraryState$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((mediaLibraryState: MediaLibraryState) => {
                this.updateMediaLibraryState(mediaLibraryState);
            });

        this.brandlibraryFolderService.selectedFolder$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(folder => {
                this.selectedFolder = folder;
                this.mediaLibraryService.search();
                this.updateBrandLibrary(this.kind, true);
            });

        this.brandLibraryElementService.selectedElements$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(elements => {
                this.selectedElements = elements;
            });

        this.initView();
    }

    async initView(): Promise<void> {
        this.inDialog = !!this.uiDialogComponent;
        if (this.inDialog) {
            await this.initMediaLibraryDialog();
        }

        if (this.kind) {
            this.updateBrandLibrary(this.kind, true);
        }

        this.list.currentNodeChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(listNode => this.onListNodeChange(listNode as IBfFeed));

        this.renderer.listen(this.searchInput.valueContainer.nativeElement, 'focus', () => {
            this.mouseDownOnHost = true;
        });

        this.renderer.listen(this.searchInput.valueContainer.nativeElement, 'blur', () => {
            this.mouseDownOnHost = false;
        });

        merge(
            this.brandLibraryElementDeletionService.brandLibraryUpdated$,
            this.brandLibraryDataService.brandLibrary$
        )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.updateBrandLibrary(this.kind, true);
            });

        this.showReplaceSizes = this.setShowCheckBoxReplaceSizes();

        this.setViewMode(this.kind);
    }

    setViewMode(kind: LibraryKind): void {
        if (kind === LibraryKind.Feeds && this.viewMode !== LibraryViewMode.List) {
            this.isFromFeeds = true;
            this.oldViewMode = this.viewMode;
            this.mediaLibraryService.setViewMode(LibraryViewMode.List);
        }
        if (kind !== LibraryKind.Feeds && this.isFromFeeds) {
            this.isFromFeeds = false;
            this.mediaLibraryService.setViewMode(this.oldViewMode);
        }
    }

    private async initMediaLibraryDialog(): Promise<void> {
        let initFilter: LibraryKind = LibraryKind.Any;
        window.addEventListener('keypress', this.insertSelected);
        const configData = this.uiDialogComponent.config.data;

        this.context = configData.context;
        this.showReplaceSizes = configData.showReplaceAll;

        if (configData.multiselect === false) {
            this.list.multiSelect = false;
        }

        if (configData.filter) {
            initFilter = configData.filter as LibraryKind;
            this.mediaLibraryService.setLibraryKind(initFilter);
        }

        if (initFilter === LibraryKind.Feeds) {
            this.inFeedPicker = true;
            this.renderer.addClass(this.host.nativeElement, 'feeds');
        } else {
            this.inFeedPicker = false;
            this.renderer.addClass(this.host.nativeElement, getKindAsString(initFilter));
        }

        this.filteredAssetType = configData.assetType;

        await this.updateBrandLibrary(initFilter);

        if (configData.feed && initFilter === LibraryKind.Feeds) {
            const feed = configData.feed as IFeed;
            const elementFeed = await this.feedService.getNestedFeed(feed.id);
            const feedName = elementFeed?.name ?? '[Deleted feed]';
            this.currentFeed = elementFeed;

            if (this.currentFeed) {
                this.currentFeed.name = feedName;
                this.setSelectedFeed({
                    id: this.currentFeed.id,
                    feedData: this.editor.renderer.feedStore!.getFeed(this.currentFeed.id)!
                });
                this.list.select(this.currentFeed);
                const parent = this.list.getParent(this.list.selection.selected[0]);
                if (parent) {
                    this.currentFolder = parent.data as IBfFeed;
                } else {
                    this.currentFolder = undefined;
                }
                this.list.browseNode(this.list.getParent(this.list.selection.selected[0]));

                const feedItem = this.feedData?.find(f => f.name === feed.path);
                if (feedItem) {
                    this.selectFeedItem(feedItem);
                }
            }
        }

        window.addEventListener('resize', this.setFeedViewHeight);
    }

    private updateMediaLibraryState({
        kind,
        isOpen,
        isPinned,
        viewMode,
        selectedFeedId
    }: MediaLibraryState): void {
        // this component is both dialog and the toolbars. This statemanegement is same for both which is issue
        const initKind = this.uiDialogComponent?.config?.data?.filter as LibraryKind;
        const actualKind = this.inDialog ? initKind : kind;
        const sameKind = this.kind === kind;

        this.selectedFeedId = selectedFeedId;
        this.isOpen = isOpen;
        this.viewMode = viewMode;
        if (actualKind !== LibraryKind.Feeds && (this.currentFolder || this.currentFeed)) {
            this.resetFeeds();
        }

        if (isPinned && this.isPinned !== isPinned) {
            this.isPinned = isPinned;
            return; // otherwhise reloads assets / flicker
        } else {
            this.isPinned = isPinned;
        }

        if (sameKind) {
            // dont rerender
            this.detectChanges();
            return;
        }

        if (
            this.viewInitialized &&
            actualKind !== LibraryKind.Rectangle &&
            actualKind !== LibraryKind.Ellipse &&
            isOpen
        ) {
            this.itemsIsLoading = true;
            this.detectChanges();
        }

        if (actualKind === LibraryKind.Feeds) {
            this.host.nativeElement.classList.add('prevent-text-blur');
        } else {
            this.host.nativeElement.classList.remove('prevent-text-blur');
        }

        this.libraryKindTitle = getKindTitleAsString(actualKind);
        this.libraryKindToMessage = getKindMessageAsString(actualKind);
        this.libraryKindToSearchPlaceholder = getSearchPlaceholderAsString(actualKind);
        this.libraryKindToName = getKindAsString(actualKind);

        this.isElementsLibrary =
            !this.inDialog &&
            actualKind !== LibraryKind.Image &&
            actualKind !== LibraryKind.Widget &&
            actualKind !== LibraryKind.Feeds;

        if (this.viewInitialized && isOpen && !this.inDialog) {
            this.open(actualKind).then(() => {
                this.itemsIsLoading = false;
                this.changeDetector.detectChanges();
            });
        } else {
            this.kind = actualKind;
        }

        if (this.isPinned) {
            this.kind = actualKind;
        }

        if (this.viewInitialized) {
            this.changeDetector.detectChanges();
        }
    }

    private async onSearch(searchQuery?: string): Promise<void> {
        if (typeof searchQuery === 'undefined') {
            return;
        }

        this.selectedFolder = undefined;
        this.searchQuery = searchQuery;

        await this.updateBrandLibrary(this.kind, true);
        this.emitUIListChanges([this.list, this.foldersList]);

        setTimeout(() => this.detectChanges());
    }

    openFeedURL(event: MouseEvent, feed: IBfFeed): void {
        event.stopPropagation();
        this.feedService.openFeed(feed.id);
    }

    setShowCheckBoxReplaceSizes(): boolean {
        const selectedElement = this.elementSelectionService.currentSelection.element;
        if (!selectedElement) {
            return false;
        }

        const selectedImageId = selectedElement.id;
        const creatives = this.creativesetDataService.creativeset.creatives;

        return (
            creatives.filter(creative =>
                creative.design?.elements.find(el => el.id === selectedImageId)
            ).length > 1
        );
    }

    uploadFiles(file: File[] | FileList | null, parentFolderId?: string): void {
        this.eventLoggerService.log(new AssetUploadEvent());

        const files = Array.prototype.slice.call(file) as File[];
        this.assetUploadService.uploadAssets({ files, parentFolderId, shouldUpdateMediaLibrary: true });
    }

    uploadFilesAndClearFileInput(fileInput: HTMLInputElement, parentFolderId?: string): void {
        this.eventLoggerService.log(new AssetUploadEvent());

        this.uploadFiles(fileInput.files, parentFolderId);

        if (fileInput?.value) {
            fileInput.value = '';
        }
    }

    shouldHandleAssetUploadProcess(id: string): boolean {
        return this.assetUploadProcessId !== id;
    }

    onAssetUploadStateChange(state: IAssetUploadState): void {
        this.logger.verbose(`Upload status changed: ${state.status}`);

        if (!state.shouldUpdateMediaLibrary || !this.panelIncludeMedia) {
            return;
        }

        switch (state.status) {
            case 'LOAD_ASSET_FILE_STARTED': {
                this.onUploadLoadAssetStarted(state);
                break;
            }
            case 'AFTER_PROGRESS': {
                this.onAfterUploadProgress(state);
                break;
            }
            case 'COMPLETE': {
                this.onUploadComplete(state);
                break;
            }
            case 'FAIL':
            default: {
                if (this.shouldHandleAssetUploadProcess(state.uploadProcessId)) {
                    return;
                }
                this.dataSource.remove(state.element);
                break;
            }
        }
    }

    private onUploadLoadAssetStarted(state: IAssetUploadLoadedState): void {
        if (!this.inDialog && !this.panelIncludeMedia) {
            this.mediaLibraryService.openMediaLibrary(
                getLibraryKindFromElementKind(state.element.type)
            );
        }

        this.assetUploadProcessId = state.uploadProcessId;
        state.asset.thumbnail.url = state.url;

        this.dataSource.insert(state.element);
        this.updateSorting();
    }

    private onAfterUploadProgress(state: IAssetUploadAfterProgressState): void {
        if (this.shouldHandleAssetUploadProcess(state.uploadProcessId)) {
            return;
        }

        this.brandLibraryElements.push(state.element as IBrandLibraryElement);
        // when media library is instancieated in a modal then there is no mediaLibraryDropdown and it cant be closed
        if (this.addMenuDropdown) {
            this.addMenuDropdown.closePopover();
        }

        this.changeDetector.detectChanges();

        // Trigger change event and show proper thumbnail instead of base64
        state.asset.thumbnail.url = state.newAsset.thumbnail.url;
    }

    private async onUploadComplete(state: IAssetUploadCompleteState): Promise<void> {
        this.activityLoggerService.log(`Uploaded image to brand-library`);

        if (
            this.shouldHandleAssetUploadProcess(state.uploadProcessId) ||
            !this.brandLibraryDataService.brandLibrary
        ) {
            return;
        }

        state.placeholder.progress = undefined;
        const elements = this.brandLibraryDataService.brandLibrary.elements;
        const elementsLength = elements.length - 1;
        this.brandLibraryElements[elementsLength] = elements[elementsLength];
        (this.brandLibraryElements[elementsLength] as IElement).properties.push(
            createElementProperty({
                name: 'shouldAnimateInLibrary',
                unit: 'boolean',
                value: true
            })
        );
        this.dataSource.replace(state.placeholder, this.brandLibraryElements[elementsLength]);
        this.updateSorting();
        this.detectChanges();
        this.updateBrandLibrary(this.kind, true);
    }

    private async navigateToUsersLastPositionInLibrary(kind: LibraryKind): Promise<void> {
        if (!!this.selectedFeedId && this.selectedFeedId !== 'none' && kind === LibraryKind.Feeds) {
            const feedSelection = (this.brandLibraryElements as IBfFeed[]).find(
                ({ id }: IBfFeed) => id === this.selectedFeedId
            );
            if (feedSelection) {
                await this.onSelectionChange([feedSelection]);
                const feedItemToSelect: IFeedFieldListItem | undefined = this.feedData?.find(
                    ({ id }: IFeedFieldListItem) => id === this.selectedFeedId
                );
                if (feedItemToSelect) {
                    this.selectFeedItem(feedItemToSelect);
                }
            }
        }
    }

    switchViewMode(mode: LibraryViewMode): void {
        if (this.viewMode === mode) {
            return;
        }
        this.mediaLibraryService.setViewMode(mode);
        this.listLayout = mode === LibraryViewMode.Preview ? LibraryViewMode.Grid : mode;
        this.setViewMode(this.kind);
        this.brandLibraryElementDeletionService.brandLibraryUpdated$.next();
    }

    setFeedViewHeight = (): void => {
        if (this.feedView) {
            this.feedView.nativeElement.style.setProperty(
                '--feed-view-height',
                `${this.feedView.nativeElement.scrollHeight}px`
            );
        }
    };

    onSelectionChange = async (selection: IBfFeed[]): Promise<void> => {
        this.selection = selection;
        if (this.kind === LibraryKind.Feeds && selection.length) {
            if (selection[0].children) {
                return;
            }

            const currFeedId: string = selection[0].id;
            try {
                this.itemsIsLoading = true;
                const currentFeedData = this.editor.renderer.feedStore?.feeds.get(currFeedId);
                const feedData = await this.editor.renderer.feedStore!.add(currFeedId, true);
                this.currentFeed = selection[0];

                this.setSelectedFeed({ id: currFeedId, feedData });
                this.itemsIsLoading = false;

                // We need to re-set the feed data if it's overridden by a widget
                if (currentFeedData) {
                    this.editor.renderer.feedStore!.feeds.set(currFeedId, currentFeedData);
                }
            } catch {
                const poll = async (): Promise<void> => {
                    try {
                        await this.editor.renderer.feedStore!.updateFeedData(currFeedId);
                        selection[0].isBroken = false;
                        selection[0].errorMessage = '';
                    } catch {
                        this.logger.error(
                            `Could not fetch data from feed with id ${currFeedId}. Perhaps it's not yet generated.`
                        );
                        clearInterval(this.feedPollTimeout);
                        this.feedPollTimeout = window.setTimeout(poll, 5000);
                        this.detectChanges();
                    }
                };
                this.mediaLibraryService.unselectFeed();
                selection[0].isBroken = true;
                selection[0].errorMessage = 'It was not possible to load any data from your feed.';
                clearInterval(this.feedPollTimeout);
                this.feedPollTimeout = window.setTimeout(poll, 5000);
            }
        }

        this.detectChanges();
    };

    onListNodeChange = (node: IBfFeed): void => {
        // Is this still executed?
        this.currentFolder = node;
    };

    private updateBrandLibrary = async (
        kind: LibraryKind = LibraryKind.Any,
        forceUpdate?: boolean
    ): Promise<void> => {
        await this.brandLibraryDataService.brandLibraryLoaded;
        if (!this.brandLibraryDataService.brandLibrary) {
            return;
        }

        const isSearching = !!this.searchQuery;

        const isSameLibraryType = this.kind === kind && this.wasFiltered && !forceUpdate;

        if (!isSameLibraryType) {
            this.feedData = this.currentFeed = this.currentFolder = undefined;
            this.kind = kind;
        }

        if (!this.list) {
            return;
        }

        if (this.editor && this.isOpen && !isSameLibraryType) {
            this.itemsIsLoading = true;
            this.brandLibraryElements = [];
            this.list.currentNode = undefined;
        }

        this.kind = kind;

        if (this.kind === LibraryKind.Feeds) {
            this.brandLibraryElements = await this.feedService.getFeeds();
            this.brandLibraryFolders = [];
            this.showGridView = false;
        } else {
            this.showGridView = true;
            const elements = [
                ...this.brandLibraryDataService.brandLibrary.elements,
                ...this.bannerflowLibraryEffectElements
            ];

            this.brandLibraryElements = elements.filter(element =>
                isElementTypeMatchLibraryType(element, this.kind)
            );

            this.brandLibraryFolderElements = [
                ...(this.brandLibraryElements as IBrandLibraryElement[])
            ].filter(element => {
                return this.selectedFolder
                    ? element.parentFolderId === this.selectedFolder.id
                    : !element.parentFolderId;
            });

            this.allBrandLibraryFolders = [...this.brandLibraryDataService.brandLibrary.folders];

            this.brandLibraryFolders = [...this.allBrandLibraryFolders].filter(folder =>
                this.selectedFolder
                    ? folder.parentFolderId === this.selectedFolder.id
                    : !folder.parentFolderId
            );

            for (const element of this.brandLibraryElements as IBrandLibraryElement[]) {
                const inlineText = element.properties.find(
                    property => property.name === INLINE_STYLED_TEXT
                );

                if (inlineText && typeof inlineText.value === 'string') {
                    const deserializedText = deserializeInlineStyledText(inlineText.value);
                    for (const style of deserializedText.styles) {
                        if (style.type === SpanType.Variable) {
                            await this.editor.renderer.feedStore!.preloadFeeds([
                                style.style.variable!.id
                            ]);
                        }
                    }
                }
            }
        }

        const elements =
            !isSearching && this.kind !== LibraryKind.Feeds
                ? [...this.brandLibraryFolderElements]
                : [...this.brandLibraryElements];

        this.dataSource = new UIListDataSource<IElement[]>(elements);
        this.dataSource.customFilterFunction = this.filterFunction;

        const folders = !isSearching ? [...this.brandLibraryFolders] : [...this.allBrandLibraryFolders];
        const folderData = this.kind !== LibraryKind.Effects ? folders : [];
        this.foldersSource = new UIListDataSource<Folder[]>(folderData);
        this.foldersSource.customFilterFunction = this.folderFilterFunction;

        this.itemsIsLoading = false;
        this.wasFiltered = true;
        this.updateSorting();
        this.detectChanges();
    };

    private filterFunction = (node: IUIListDataNode): boolean => {
        const stringToSearch = node.name.toLowerCase();
        if (!this.searchQuery.length) {
            return true;
        }
        if (!stringToSearch && this.kind === LibraryKind.Image) {
            const imageReference = node.properties.find(
                (p: { name: string }) => p.name === AssetReference.Image
            );
            if (imageReference) {
                const image = this.brandLibraryDataService.brandLibrary?.images.find(
                    i => i.id === imageReference.value
                );
                if (image) {
                    return image.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1;
                }
            }
        }
        return stringToSearch.indexOf(this.searchQuery.toLowerCase()) > -1;
    };

    private folderFilterFunction = (node: IUIListDataNode): boolean => {
        const stringToSearch = node.name.toLowerCase();
        return stringToSearch.indexOf(this.searchQuery.toLowerCase()) > -1;
    };

    startCreatingFolder(): void {
        this.newFolder = {
            name: generateUniqueName('New folder', this.brandLibraryFolders),
            parentFolderId: this.selectedFolder?.id
        };
    }

    async createFolder(name: string): Promise<void> {
        if (name) {
            const folderId = this.selectedFolder ? this.selectedFolder.id : undefined;
            await this.brandlibraryFolderService.addFolder(name, folderId);
            this.newFolder = undefined;
            await this.updateBrandLibrary(this.kind, true);
        } else {
            this.newFolder = undefined;
        }
    }

    async deleteFolder(folderId: string): Promise<void> {
        const folderEmpty = this.brandlibraryFolderService.isEmptyFolder(folderId);
        const dialogText = folderEmpty
            ? 'Are you sure you want to delete this folder?'
            : `Are you sure you want to delete this folder and all elements inside it from the brand library?`;
        const result: UIConfirmDialogResult = await this.uiConfirmDialogService.confirm({
            headerText: 'Delete folder',
            text: dialogText,
            confirmText: 'Delete',
            cancelText: 'Cancel'
        });

        if (result === 'confirm') {
            await this.brandlibraryFolderService.deleteFolder(folderId);
            this.uiNotificationService.open(`Folder removed successfully`, {
                autoCloseDelay: 5000,
                placement: 'top',
                type: 'success'
            });
            this.updateBrandLibrary(this.kind, true);
        }
    }

    openParentFolder(): void {
        this.brandlibraryFolderService.selectFolder(this.selectedFolder?.parentFolderId);
    }

    toggleBrandLibrary = (): void => this.toggleLibrary(LibraryKind.Any);
    toggleImage = (): void => this.toggleLibrary(LibraryKind.Image);
    toggleVideo = (): void => this.toggleLibrary(LibraryKind.Video);
    toggleWidget = (): void => this.toggleLibrary(LibraryKind.Widget);
    toggleFeeds = (): void => this.toggleLibrary(LibraryKind.Feeds);
    toggleEffects = (): void => this.toggleLibrary(LibraryKind.Effects);

    private setupHotkeyListeners(): void {
        this.hotkeyContext = { name: 'BrandLibrary', input: window, excludeInputFields: true };
        this.hotkeyBetterService.pushContext(this.hotkeyContext);
        this.hotkeyBetterService.on('OpenBrandLibrary', this.toggleBrandLibrary);
        this.hotkeyBetterService.on('OpenImageLibrary', this.toggleImage);
        if (this.hasVideoLibraryAccess) {
            this.hotkeyBetterService.on('OpenVideoLibrary', this.toggleVideo);
        }
        this.hotkeyBetterService.on('OpenWidgetLibrary', this.toggleWidget);
        this.hotkeyBetterService.on('OpenFeedsLibrary', this.toggleFeeds);
        this.hotkeyBetterService.on('OpenEffectsLibrary', this.toggleEffects);
        this.hotkeyBetterService.on('DeleteElement', this.deleteElement);
    }

    detectChanges(): void {
        if (!(<ViewRef>this.changeDetector).destroyed) {
            this.changeDetector.detectChanges();
        }
    }

    private onDragFileLeave = (): void => {
        this.fileDropZone.nativeElement.removeEventListener('dragleave', this.onDragFileLeave);
        this.showFileDropZone = false;
        this.detectChanges();
    };

    onStartDragFeedItem(
        event: MouseEvent,
        feedItem: IFeedFieldListItem,
        feedField?: FeedFieldComponent
    ): void {
        this.currentFeedFieldComponent = feedField;
        const element = this.feedDataToElement(feedItem);
        this.onStartDragAsset(event, element);
    }

    onStartDragAsset(event: MouseEvent, item: IBrandLibraryElement): void {
        if (event.button !== 0 || !this.editor || this.inDialog) {
            return;
        }
        this.editor.workspace.isElementDragging$.next(true);

        const selections =
            this.kind === LibraryKind.Feeds
                ? []
                : (this.list.selection.selected.map(
                      selected => selected.data
                  ) as IBrandLibraryElement[]);

        this.prospectiveDragElements = [...selections, item].reduce<IBrandLibraryElement[]>(
            (acc, el) => (acc.find(element => element.id === el.id) ? acc : [...acc, el]),
            []
        );

        // Eagerly load the widget resource
        for (const element of this.prospectiveDragElements) {
            if (isWidgetElement(element)) {
                const resourceUrl = getWidgetContentUrlOfElement(element);
                if (resourceUrl) {
                    fetchWidgetFromResource(resourceUrl);
                }
            }
        }

        this.startMousePosition.x = event.pageX;
        this.startMousePosition.y = event.pageY;
        this.editor.viewContainerRef.element.nativeElement.style.cursor = 'pointer';
        document.addEventListener('mousemove', this.onDragAssets);
        document.addEventListener('mouseup', this.onStopDragAssets);
        event.preventDefault();
    }

    removeFolderHighlighting(): void {
        const hoveredElement = this.foldersElement?.nativeElement?.querySelector('.isDraggingOn');
        if (hoveredElement) {
            hoveredElement.classList.remove('isDraggingOn');
        }
    }

    scrollOnDrag(event: MouseEvent): void {
        const target = event.target as HTMLElement;
        const hostBoundingClientRect = this.host.nativeElement.getBoundingClientRect();
        const topOffset = hostBoundingClientRect.top + 27;
        const bottomOffset = hostBoundingClientRect.bottom - 32;
        const autoScrollArea = 16;
        const assetListScrollTop = this.assetList.nativeElement.scrollTop;
        if (
            isElementDescendantOfElement(this.host.nativeElement, target) &&
            (assetListScrollTop > 0 || assetListScrollTop < hostBoundingClientRect.bottom)
        ) {
            if (event.clientY >= topOffset && event.clientY < topOffset + autoScrollArea) {
                if (!this.scrollInterval) {
                    this.scrollInterval = window.setInterval(() => {
                        this.assetList.nativeElement.scrollTo(
                            0,
                            this.assetList.nativeElement.scrollTop - 1
                        );
                    }, 0);
                }
            } else if (event.clientY >= bottomOffset && event.clientY > bottomOffset - autoScrollArea) {
                if (!this.scrollInterval) {
                    this.scrollInterval = window.setInterval(() => {
                        this.assetList.nativeElement.scrollTo(
                            0,
                            this.assetList.nativeElement.scrollTop + 1
                        );
                    }, 0);
                }
            } else {
                this.clearScrollInterval();
            }
        }
    }

    private clearScrollInterval(): void {
        clearInterval(this.scrollInterval as number);
        this.scrollInterval = undefined;
    }

    onDragAssets = (event: MouseEvent): void => {
        const target = event.target as HTMLElement;
        const isTargetFolderElement = target.classList.contains('ml-folders-folder');
        const isTargetHeaderElement = target.classList.contains('ml-folders-header');
        this.scrollOnDrag(event);
        if (
            this.foldersElement &&
            isElementDescendantOfElement(this.foldersElement.nativeElement, event.target)
        ) {
            let elementToHighlight: HTMLElement | null = null;
            if (target.closest('.ml-folders-folder') || isTargetFolderElement) {
                elementToHighlight = target.closest('ui-list-cell');
            }
            if (isTargetHeaderElement) {
                elementToHighlight = target;
            }
            if (target.closest('.ml-folders-header')) {
                elementToHighlight = target.closest('.ml-folders-header');
            }
            if (elementToHighlight) {
                this.removeFolderHighlighting();
                elementToHighlight.classList.add('isDraggingOn');
            }
        } else if (this.foldersElement) {
            this.removeFolderHighlighting();
        }

        // Cypress doesn't support event.x
        const eventPosition = eventToPosition(event);

        const hasDraggedOverThresholdX = Math.abs(eventPosition.x - this.startMousePosition.x) > 10;
        const hasDraggedOverThresholdY = Math.abs(eventPosition.y - this.startMousePosition.y) > 10;

        if (!this.isDraggingElements && (hasDraggedOverThresholdX || hasDraggedOverThresholdY)) {
            this.editorStateService.__appComponentViewRef.clear();
            const listSelectionLength = this.list.selection.selected.length;
            if (listSelectionLength && listSelectionLength !== this.prospectiveDragElements.length) {
                // When having selected elements, then dragging an unselected element
                // we want to only add the dragged unselected element.
                this.prospectiveDragElements = this.prospectiveDragElements.filter(
                    element =>
                        !this.list.selection.selected.find(selected => selected.data?.id === element.id)
                );

                this.list.deselectAll();
                this.prospectiveDragElements.forEach(element => {
                    this.list.select(element);
                });
            }

            this.prospectiveDragElements.forEach(element => {
                if (isEffectLibraryElement(element) && !this.isBrandLibraryElementImported(element)) {
                    return;
                }

                const draggingRef = this.libraryElementRenderingService.getComponentRef(element);
                if (this.kind === LibraryKind.Feeds && isVideoElement(element)) {
                    draggingRef.instance.src = this.currentFeedFieldComponent?.videosData[0].thumbnail;
                }

                this.draggingRefs.push(draggingRef);
                this.currentDragElements.push({
                    component: draggingRef.instance,
                    viewRef: draggingRef.hostView
                });
                this.editorStateService.__appComponentViewRef.insert(draggingRef.hostView);
                this.isDraggingElements = true;
            });
        }

        this.currentDragElements.forEach(({ component: draggingRef, viewRef }, i) => {
            // Render the image being dragged in 'full' size when mouse is on canvas
            if (
                isElementDescendantOfElement(this.editor.workspace.host.nativeElement, event.target) ||
                event.target === this.editor.workspace.host.nativeElement
            ) {
                const { zoom, canvasSize } = this.editorStateService;
                this.libraryElementRenderingService.setDraggingRefFullScale(
                    draggingRef,
                    canvasSize,
                    zoom
                );
            } else {
                draggingRef.transform = `scale(1)`;
                draggingRef.zIndex = 104;
            }
            // Offset each asset to visualize a stack
            draggingRef.left = event.pageX + i * 15 - draggingRef.width / 2;
            draggingRef.top = event.pageY + i * 15 - draggingRef.height / 2;
            viewRef.detectChanges();
        });
    };

    onStopDragAssets = async (event: MouseEvent): Promise<void> => {
        this.clearScrollInterval();
        this.draggingRefs.forEach(ref => ref.destroy());

        const target = event.target as HTMLElement;
        const workspace = this.editor.workspace;
        const isTargetFolderElement = target.classList.contains('ml-folders-folder');
        const isTargetFolderHeaderElement = target.classList.contains('ml-folders-header');
        const closestTargetFolder = target.closest('.ml-folders-folder');
        const selectedElementsIds = this.prospectiveDragElements.map(element => element.id);
        const isElementDescendantOfFolders = isElementDescendantOfElement(
            this.foldersElement.nativeElement,
            event.target
        );

        if (this.foldersElement && this.kind !== LibraryKind.Feeds) {
            let folderId: string;

            if (isElementDescendantOfFolders && (closestTargetFolder || isTargetFolderElement)) {
                folderId = isTargetFolderElement
                    ? target.getAttribute('data-folderId') || ''
                    : (closestTargetFolder?.getAttribute('data-folderId') ?? '');

                await this.brandlibraryFolderService.moveElementsToFolder(
                    selectedElementsIds,
                    folderId
                );
            }

            const isTargetWithinFolderHeader =
                isElementDescendantOfFolders &&
                (target.closest('.ml-folders-header') || isTargetFolderHeaderElement);
            if (isTargetWithinFolderHeader) {
                await this.brandlibraryFolderService.moveElementsToFolder(
                    selectedElementsIds,
                    this.selectedFolder!.parentFolderId!
                );
            }

            const hoveredElement = this.foldersElement.nativeElement.querySelector('.isDraggingOn');
            if (hoveredElement) {
                setTimeout(() => {
                    hoveredElement.classList.remove('isDraggingOn');
                });
            }

            if (this.isDraggingElements) {
                this.updateBrandLibrary(this.kind);
            }
        }

        // Check if we stop dragging the assets on the workspace
        const isTargetOutsideWorkspace =
            !isElementDescendantOfElement(workspace.host.nativeElement, event.target) &&
            event.target !== workspace.host.nativeElement;
        if (!this.isDraggingElements || isTargetOutsideWorkspace) {
            this.finishElementDragging();
            return;
        }

        // Assets are dropped on workspace
        await this.handleWorkspaceAssetDrop(event, this.prospectiveDragElements);
    };

    private async handleWorkspaceAssetDrop(
        event: MouseEvent,
        elements: IBrandLibraryElement[]
    ): Promise<void> {
        const workspace = this.editor.workspace;
        const canvasSize = this.editorStateService.canvasSize;
        const newElements: OneOfElementDataNodes[] = [];

        let firstAsset: undefined | { x: number; y: number; width: number; height: number };

        elements = await this.heavyVideoService.promptRemoveVideoElements(elements);

        const hasEffectElements = elements.some(isEffectLibraryElement);
        if (hasEffectElements) {
            this.showEffectsWarningNotification();
        }

        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];

            const isNonStyledElement =
                isImageOrVideoElement(element) &&
                !this.elementRenderingService.isStyledElement(element);

            const position = workspace.getCanvasPositionFromDocumentPosition({
                x: event.pageX,
                y: event.pageY
            });

            if (isNonStyledElement || isWidgetElement(element)) {
                const asset = this.brandLibraryDataService.getAssetByElement(element)! || {
                    id: undefined,
                    original: { id: undefined, url: element.properties[0].value },
                    thumbnail: { id: undefined, url: undefined },
                    width: this.THUMBNAIL_SIZE,
                    height: this.THUMBNAIL_SIZE
                };

                let originalWidth = asset.width;
                let originalHeight = asset.height;

                if (this.kind === LibraryKind.Feeds) {
                    const { id, path } = deserializeFeedString(elements[0].properties[0].value);
                    const feedData = this.mutatorService.renderer.feedStore?.getFeed(id);
                    const src = feedData?.data[0][decodeURIComponent(path)].value as string;

                    if (isVideoElement(element)) {
                        const videoData = this.currentFeedFieldComponent?.videosData[0];
                        originalWidth = videoData?.width || this.THUMBNAIL_SIZE;
                        originalHeight = videoData?.height || this.THUMBNAIL_SIZE;
                    } else {
                        const { width, height } = await this.getImageDimensions(src);
                        originalWidth = width;
                        originalHeight = height;
                    }
                }

                const { width, height } = getAspectRatioFit(
                    originalWidth,
                    originalHeight,
                    Math.min(
                        canvasSize.width,
                        this.kind === LibraryKind.Feeds ? this.THUMBNAIL_SIZE : originalWidth
                    ),
                    Math.min(
                        canvasSize.height,
                        this.kind === LibraryKind.Feeds ? this.THUMBNAIL_SIZE : originalHeight
                    )
                );

                if (!firstAsset) {
                    firstAsset = {
                        width: width,
                        height: height,
                        x: position.x,
                        y: position.y
                    };
                }

                // Offset each asset to visualize a stack
                if (elements.length > 1) {
                    position.x = firstAsset.x + 15 * i;
                    position.y = firstAsset.y + 15 * i;
                }

                const x = position.x / this.editorStateService.zoom - width / 2;
                const y = position.y / this.editorStateService.zoom - height / 2;
                const baseNode: INewBaseNode = this.createNode(
                    {
                        x,
                        y,
                        width,
                        height
                    },
                    element
                );

                const newElement = await this.createElementFromAsset(asset, baseNode, element);
                newElements.push(newElement);

                workspace.transform.cancel();
            } else {
                if (isTextNode(this.elementSelectionService.currentSelection.element)) {
                    workspace.deselectAllElements();
                }

                const elementData = this.elementRenderingService.getData(element);
                const { width, height } = getAspectRatioFit(
                    elementData.width,
                    elementData.height,
                    Math.min(canvasSize.width, elementData.width),
                    Math.min(canvasSize.height, elementData.height)
                );

                const newElement = await this.addElementToCanvas(
                    element,
                    position.x / this.editorStateService.zoom - width / 2,
                    position.y / this.editorStateService.zoom - height / 2
                );

                newElements.push(newElement);

                const newViewElement = this.editorStateService.renderer.getViewElementById(
                    newElement.id
                );

                if (!newViewElement) {
                    continue;
                }

                if (isTextNode(newViewElement)) {
                    newViewElement.__richTextRenderer?.editor_m?.resolveCharacterStyles();
                }

                workspace.stopCreateNewElement();

                if (!isTextLikeElement(element) && !isImageElement(element)) {
                    workspace.transform.cancel();
                }

                if (newViewElement.elementCid) {
                    this.editor.renderer.insertStyle_m(newViewElement.elementCid, {
                        ...this.editor.renderer.getDefaultStyle_m(newViewElement)
                    });
                }

                this.editor.renderer.setBackgroundElement_m(newViewElement);
            }
        }

        this.finishElementDragging();

        this.list.deselectAll();
        this.mutatorService.stopEditText();
        this.elementSelectionService.setSelection(...newElements);
    }

    private finishElementDragging(): void {
        this.currentDragElements = [];
        this.currentFeedFieldComponent = undefined;
        this.editorStateService.__appComponentViewRef.clear();
        this.editor.viewContainerRef.element.nativeElement.style.cursor = '';
        this.mutatorService.workspaceFocused = true;

        this.removeDragListeners();

        // Delay setting the flag to distinct click outside of media library
        // From when user drops an asset onto canvas
        setTimeout(() => {
            this.isDraggingElements = false;
            this.editor.workspace.isElementDragging$.next(false);
            this.detectChanges();
        });
    }

    private async addElementToCanvas(
        element: IElement,
        x: number | undefined,
        y: number | undefined
    ): Promise<OneOfElementDataNodes> {
        const canvasSize = this.editorStateService.canvasSize;
        const xPos = x || x === 0 ? x : canvasSize.width / 2;
        const yPos = y || y === 0 ? y : canvasSize.height / 2;
        const data = this.elementRenderingService.getData(element);
        const node = this.createNode(data, element);
        node.name = element.name;

        const { width, height, ratio } = getAspectRatioFit(
            node.width,
            node.height,
            Math.min(canvasSize.width, node.width),
            Math.min(canvasSize.height, node.height)
        );

        // need to scale to be accurate when re-creating other dimension elements. This should be done in a unified way
        if (node.border) {
            node.border.thickness = Math.round(node.border.thickness * ratio);
        }
        if (node.radius) {
            node.radius = {
                type: node.radius.type,
                topLeft: Math.round(node.radius.topLeft * ratio),
                topRight: Math.round(node.radius.topRight * ratio),
                bottomRight: Math.round(node.radius.bottomRight * ratio),
                bottomLeft: Math.round(node.radius.bottomLeft * ratio)
            };
        }
        node.width = width;
        node.height = height;
        node.x = xPos;
        node.y = yPos;

        return await this.elementCreatorService.createElement(
            node.kind,
            node as IElementDataNode, // Not sure why it doesn't allow OneOfElementDataNodes
            {
                shouldRescale: false
            }
        );
    }

    async duplicateElement(element: IElement): Promise<void> {
        const names = [...this.brandLibraryElements].map(e => e.name);

        if (!element.name) {
            switch (element.type) {
                case ElementKind.Button:
                    element.name = 'Button';
                    break;
                case ElementKind.Rectangle:
                    element.name = 'Rectangle';
                    break;
                case ElementKind.Ellipse:
                    element.name = 'Ellipse';
                    break;
                case ElementKind.Text:
                    element.name = 'Text';
                    break;
            }
        }
        element.name = generateUniqueNameInList(element.name, names);

        await this.brandLibraryElementService.duplicate(element);
    }

    async addAssetToCanvas(event: MouseEvent | void, element: IBrandLibraryElement): Promise<void> {
        if (!this.editor && event && event.target instanceof HTMLInputElement) {
            return;
        }

        const target = event?.currentTarget as HTMLElement;
        const targetChild = target?.children[0];
        if (targetChild instanceof HTMLElement) {
            targetChild.style.transition = 'none';
            setTimeout(() => (targetChild.style.transition = ''));
        }

        this.list.selection.clear();

        const asset = this.brandLibraryDataService.getAssetByElement(element)! || {
            id: undefined,
            original: { id: undefined, url: element.properties[0].value },
            thumbnail: { id: undefined, url: undefined },
            width: this.THUMBNAIL_SIZE,
            height: this.THUMBNAIL_SIZE
        };

        const isStyledElement =
            !isVideoElement(element) &&
            !isImageElement(element) &&
            this.elementRenderingService.isStyledElement(element);

        const defaultSize = {
            width: this.editorStateService.canvasSize.width / 2,
            height: this.editorStateService.canvasSize.height / 2
        };
        const { width, height } = isStyledElement ? this.getElementSize(element) : asset || defaultSize;

        const baseNode = this.createNode(
            getBoundingboxWithinBoundary({ width, height }, this.editorStateService.canvasSize),
            element
        );

        if (isStyledElement) {
            const newElement = await this.addElementToCanvas(element, baseNode.x, baseNode.y);
            const viewElement = this.editorStateService.renderer.getViewElementById(newElement.id);
            if (isTextNode(viewElement)) {
                viewElement.__richTextRenderer?.editor_m?.resolveCharacterStyles();
            }
        } else {
            const allowAddToCanvas = await this.heavyVideoService.promptVideoElement(element);
            if (allowAddToCanvas) {
                this.createElementFromAsset(asset, baseNode, element);
            }
        }

        this.removeDragListeners();
        this.editor.workspace.stopCreateNewElement();
        this.mutatorService.workspaceFocused = true;
    }

    private async createElementFromAsset(
        asset: OneOfLibraryAssets,
        baseNode: INewBaseNode,
        element: IBrandLibraryElement
    ): Promise<OneOfElementDataNodes> {
        switch (element.type) {
            case ElementKind.Image:
                if (!isImageLibraryAsset(asset)) {
                    throw new Error('Could not create image element. Asset is not an image');
                }

                return this.elementCreatorService.createImage(
                    {
                        ...baseNode,
                        imageAsset: {
                            id: asset.id,
                            url: asset.url,
                            height: asset.height,
                            name: asset.name,
                            width: asset.width,
                            isGenAi: asset.isGenAi
                        }
                    },
                    { element }
                );

            case ElementKind.Video:
                if (!isVideoLibraryAsset(asset)) {
                    throw new Error('Could not create video element. Asset is not a video');
                }

                return this.elementCreatorService.createVideo(
                    {
                        ...baseNode,
                        videoAsset: {
                            id: asset.id,
                            url: asset.url,
                            height: asset.height,
                            width: asset.width,
                            name: asset.name,
                            fileSize: asset.fileSize
                        }
                    },
                    { element }
                );

            case ElementKind.Widget:
            case ElementKind.BannerflowLibraryWidget:
                const isEffectElement = isEffectLibraryElement(element);
                const widgetAsset = this.getLibraryWidgetOfElement(element);
                const gainsightEvent = isEffectElement
                    ? GainsightEvent.AddEffectToCanvas
                    : GainsightEvent.AddWidgetToCanvas;

                this.gainsightService.sendCustomEvent(gainsightEvent, {
                    id: widgetAsset?.id ?? element.id,
                    name: widgetAsset?.name ?? element.name
                });

                if (isEffectElement) {
                    this.showEffectsWarningNotification();
                }

                return await this.elementCreatorService.createWidget(
                    {
                        ...baseNode
                    },
                    { element }
                );
            default:
                throw new Error(
                    `Could not create element. Element of type '${element.type}' is not supported in brand library`
                );
        }
    }

    private getElementSize(element: IElement): ISize {
        const elementData = this.elementRenderingService.getData(element);
        return getAspectRatioFit(
            elementData.width,
            elementData.height,
            Math.min(this.editorStateService.canvasSize.width, elementData.width),
            Math.min(this.editorStateService.canvasSize.height, elementData.height)
        );
    }

    private createNode<Data extends IBounds | OneOfElementDataNodes>(
        data: Data,
        element: IElement
    ): Data {
        const blElement = this.brandLibraryDataService.brandLibrary?.elements.find(
            e => e.id === element.id
        );

        return {
            ...data,
            parentId: blElement?.id
        };
    }

    isImageUrl(s: string): boolean {
        if (isUrl(s)) {
            return s.match(/\.(jpeg|jpg|gif|png|svg|webp)$/) !== null;
        }
        return false;
    }

    isVideoUrl(s: string): boolean {
        if (isUrl(s)) {
            return s.match(/\.(mp4)$/) !== null;
        }
        return false;
    }

    addFeededElementToCanvas(event: MouseEvent, feedItem: IFeedFieldListItem): void {
        const selectedElement = this.elementSelectionService.currentSelection.element;
        const element = this.feedDataToElement(feedItem);
        const feededURL = feedItem.values[0]?.toString() ?? '';

        if (!this.isImageUrl(feededURL) && !this.isVideoUrl(feededURL)) {
            if (selectedElement && isTextNode(selectedElement)) {
                const textVariable = createVariable(feedItem.id, feedItem.name);
                const viewElement =
                    this.mutatorService.renderer.getViewElementById<OneOfTextViewElements>(
                        selectedElement.id
                    );
                if (!viewElement) {
                    return;
                }
                const hasFeededContent = selectedElement.__dirtyContent?.spans.find(
                    span => span.type === SpanType.Variable && !!span.style.variable
                );

                if (hasFeededContent && !viewElement.__richTextRenderer?.editor_m?.inEditMode) {
                    this.addAssetToCanvas(event, element);
                    return;
                }

                this.mutatorService.setElementPropertyValue(selectedElement, 'feed', textVariable);
                return;
            }

            this.addAssetToCanvas(event, element);
        } else {
            this.editor.workspace.transform.cancel();
            this.editor.workspace.deselectAllElements();
            this.addAssetToCanvas(event, element);
        }
    }

    @UIDebounce(300)
    async search(searchQuery: string): Promise<void> {
        this.mediaLibraryService.search(searchQuery);
    }

    private emitUIListChanges(components: UIListComponent[]): void {
        for (const component of components) {
            if (component && component.dataSource) {
                component.dataSource.emit();
            }
        }
    }

    onSearchIconClick(): void {
        if (this.searchQuery) {
            this.mediaLibraryService.search('');
        } else {
            this.searchInput.focus();
        }
    }

    async getImageDimensions(src: string): Promise<{ width: number; height: number }> {
        const cachedDimensions = this.cachedImages.get(src);
        if (cachedDimensions) {
            return cachedDimensions;
        }

        const dimensions = await getImageDimensions(src);
        this.cachedImages.set(src, {
            width: dimensions.width,
            height: dimensions.height
        });

        return dimensions;
    }

    private removeDragListeners(): void {
        document.removeEventListener('mousemove', this.onDragAssets);
        document.removeEventListener('mouseup', this.onStopDragAssets);
    }

    private deleteElement = async (): Promise<void> => {
        const selectedElements = this.list.selection.selected
            .map(selected => selected.data as IBrandLibraryElement)
            .filter(element => !isEffectLibraryElement(element));

        await this.brandLibraryElementDeletionService.delete(...selectedElements);

        this.setBannerFlowLibraryImportedState();
        this.detectChanges();
        this.brandLibraryElementDeletionService.brandLibraryUpdated$.next();
    };

    createNewWidget(): void {
        this.editor.workspace.stopCreateNewElement();
        this.updateBrandLibrary(LibraryKind.Widget);
        const newWidget = createBrandlibraryElement({
            name: '',
            type: ElementKind.Widget,
            properties: [
                {
                    name: 'html',
                    unit: 'string',
                    value: ''
                },
                {
                    name: 'css',
                    unit: 'string',
                    value: ''
                },
                {
                    name: 'js',
                    unit: 'string',
                    value: ''
                },
                {
                    name: 'ts',
                    unit: 'string',
                    value: ''
                }
            ]
        });
        newWidget.parentFolderId = this.selectedFolder?.id;
        this.editor.editElement(newWidget);
        this.addMenuDropdown.closePopover();
    }

    uploadFont(): void {
        this.editor.openFontManager();
    }

    onEditElement(element: IBrandLibraryElement): void {
        if (!element) {
            throw new Error('Could not find element.');
        }
        this.uiTooltipService.closeAll();
        this.editor.editElement(element);
        this.removeDragListeners();
        this.detectChanges();
    }

    private async open(kind: LibraryKind): Promise<void> {
        this.setViewMode(kind);
        await this.getBannerflowLibraryWidgets();
        await this.updateBrandLibrary(kind);
        await this.navigateToUsersLastPositionInLibrary(kind);
    }

    togglePin(): void {
        this.mediaLibraryService.togglePinned();
    }

    selectionChanged(uiListNodes: IUIListDataNode[]): void {
        const elements: IBrandLibraryElement[] = uiListNodes.map(
            element => omit(element, 'removedByFilter') as IBrandLibraryElement
        );
        this.brandLibraryElementService.selectedElements$.next(elements);
        this.changeDetector.detectChanges();
        this.onSelectionChange(elements);
    }

    toggleLibrary = (kind: LibraryKind = LibraryKind.Any): void => {
        if (!this.editor) {
            return;
        }

        const transformBinder = this.editor.workspace.transform;
        if (transformBinder) {
            transformBinder.cancel();
        }

        this.mediaLibraryService.toggleMediaLibrary(kind, this.isEditingName);

        if (this.editor.workspace.selectionToolActive) {
            this.editor.workspace.toggleSelectionTool();
        }
    };

    private showEffectsWarningNotification(): void {
        this.uiNotificationService.open(
            'Effect elements might affect the performance of hosted display ads.',
            {
                placement: 'top',
                type: 'warning'
            }
        );
    }

    insertSelected = (event?: KeyboardEvent | MouseEvent): void => {
        if (event) {
            if (event instanceof MouseEvent) {
                event.stopPropagation();
            } else if (event instanceof KeyboardEvent && event.key.toLowerCase() !== 'enter') {
                return;
            }

            if (
                (event.target as HTMLElement).tagName === 'INPUT' ||
                (!this.selection.length && !this.selectedFeedItem)
            ) {
                return;
            }
        }

        this.list.selectionType = 'radio';

        if (this.uiDialogComponent?.config?.data?.callback) {
            if (
                this.context === AssetPropertyContext.Replace &&
                this.replaceAllOfAssetType &&
                this.showReplaceSizes
            ) {
                this.uiConfirmDialogService.confirm({
                    headerText: `Replace ${this.filteredAssetType} in all creatives`,
                    confirmText: 'Yes',
                    discardText: 'Cancel',
                    text: 'This action will automatically save all creatives in your Creative Set that use this element, including this creative. Do you want to proceed?',
                    onConfirm: async (): Promise<any> => {
                        if (this.kind === LibraryKind.Feeds) {
                            this.uiDialogComponent.config.data.callback(
                                {
                                    selectedFeed: this.currentFeed,
                                    selection: this.selectedFeedItem
                                },
                                true
                            );
                        } else {
                            this.uiDialogComponent.config.data.callback(this.selection[0], true);
                        }
                        this.currentFeed = undefined;
                        this.list.deselectAll();
                    },
                    onDiscarded: () => {
                        this.list.selectionType = 'single';
                    }
                });
            } else {
                if (this.uiDialogComponent.config.data.filter === LibraryKind.Feeds) {
                    this.uiDialogComponent.config.data.callback(
                        {
                            selectedFeed: this.currentFeed,
                            selection: this.selectedFeedItem
                        },
                        false
                    );
                } else {
                    this.uiDialogComponent.config.data.callback(this.selection[0], false);
                }
            }
        }
    };

    async getBannerflowLibraryWidgets(): Promise<void> {
        if (this.bannerflowLibraryWidgetsLoaded) {
            return;
        }

        try {
            const response = await this.widgetService.get();
            const activeWidgets = response.filter(widget => {
                return widget.state === LibraryWidgetState.Active;
            });

            this.bannerflowLibraryWidgets = activeWidgets.filter(w => !isEffectWidget(w));

            this.bannerflowLibraryEffects = activeWidgets.filter(isEffectWidget);
            this.bannerflowLibraryEffectElements = this.bannerflowLibraryEffects
                .filter(effect => !this.widgetIsImported(effect))
                .map(createNewBrandLibraryElementFromWidget);

            this.setBannerFlowLibraryImportedState();
            this.bannerflowLibraryWidgetsLoaded = true;
        } catch (err: unknown) {
            handleError('Could not fetch store widgets', { contexts: { originalError: err } });
        }
    }

    async importBannerflowLibraryWidget(widget: LibraryWidget): Promise<void> {
        if (this.widgetIsImported(widget)) {
            widget.imported = true;
            return;
        }

        const element = createNewBrandLibraryElementFromWidget(widget);

        this.addMenuDropdown.closePopover();
        await this.createBrandLibraryWidgetElement(element, widget);

        if (isEffectWidget(widget)) {
            // Filter out importable effect element
            this.bannerflowLibraryEffectElements = this.bannerflowLibraryEffectElements.filter(el => {
                const widgetReference = getLibraryWidgetReferenceOfElement(el);
                return widgetReference?.value !== widget.id;
            });

            this.gainsightService.sendCustomEvent(GainsightEvent.ImportEffect, {
                id: widget.id ?? '',
                name: widget.name
            });
        }

        this.updateBrandLibrary(this.kind, true);
    }

    private async createBrandLibraryWidgetElement(
        element: INewBrandLibraryElement | IBrandLibraryElement,
        widget: LibraryWidget
    ): Promise<void> {
        try {
            await firstValueFrom(this.brandLibraryDataService.createElement(element));
            this.setBannerFlowLibraryImportedState();
            widget.imported = true;
            this.uiNotificationService.open(`"${element.name}" was imported successfully.`, {
                autoCloseDelay: 5000,
                type: 'success',
                placement: 'top'
            });
        } catch (e) {
            widget.imported = false;
            this.logger.error(e);
            this.uiNotificationService.open(`Failed to import "${element.name}".`, {
                autoCloseDelay: 5000,
                type: 'error',
                placement: 'top'
            });
            throw e;
        }
    }

    getLibraryWidgetOfElement(element: IBrandLibraryElement): LibraryWidget | undefined {
        const widgetReference = getLibraryWidgetReferenceOfElement(element);

        if (!widgetReference) {
            return;
        }

        if (isEffectLibraryElement(element)) {
            return this.bannerflowLibraryEffects.find(effect => effect.id === widgetReference.value);
        }

        return this.bannerflowLibraryWidgets.find(widget => widget.id === widgetReference.value);
    }

    setBannerFlowLibraryImportedState(): void {
        if (!this.bannerflowLibraryWidgets) {
            return;
        }

        for (const widget of this.bannerflowLibraryWidgets) {
            widget.imported = this.widgetIsImported(widget);
        }
    }

    widgetIsImported(widget: LibraryWidget): boolean {
        if (!this.brandLibraryDataService.brandLibrary) {
            throw new Error('Brand library is not yet initialized');
        }

        return this.brandLibraryDataService.brandLibrary.elements.some(element => {
            if (!isBannerFlowLibraryWidget(element)) {
                return false;
            }

            const property = getLibraryWidgetReferenceOfElement(element);

            if (property && widget.id === property.value) {
                return true;
            }
        });
    }

    isBrandLibraryElementImported(element: IBrandLibraryElement | INewBrandLibraryElement): boolean {
        if (!isEffectLibraryElement(element)) {
            return true;
        }

        const widget = this.brandLibraryDataService.getWidgetAssetByElement(element);
        return Boolean(widget);
    }

    feedDataToElement(feedItem: IFeedFieldListItem): IBrandLibraryElement {
        const feedData = this.mutatorService.renderer.feedStore?.getFeed(this.currentFeed!.id);
        const value = feedData?.data[0][feedItem.name].value as string;
        const feedPath = feedItem.name;
        const isImageUrl = this.isImageUrl(value);
        const isVideoUrl = this.isVideoUrl(value);

        if (isImageUrl || (isVideoUrl && this.currentFeed)) {
            let type: ElementKind = ElementKind.Image;
            if (isVideoUrl) {
                type = ElementKind.Video;
            }

            const feed = createFeed(this.currentFeed!.id, feedPath, type);

            return createBrandlibraryElement({
                id: uuidv4(),
                name: feedPath,
                type,
                properties: [
                    {
                        value: serializeFeedValue(feed),
                        name: 'feedPath',
                        unit: 'string'
                    }
                ]
            });
        }

        const content = createRichTextVariableFromString(VARIABLE_PREFIX + feedPath, undefined, {
            variable: createVariable(this.currentFeed!.id, feedPath)
        });

        const element = createBrandlibraryElement({
            id: uuidv4(),
            name: feedPath,
            type: ElementKind.Text,
            properties: []
        });

        const textNode = this.nodeCreatorService.create(ElementKind.Text, {
            content,
            x: this.editorStateService.canvasSize.width / 2 - 50,
            y: this.editorStateService.canvasSize.height / 2 - 50,
            width: 100,
            height: 100
        });

        this.brandLibraryElementService.serializeBrandElementProperties(textNode, element);

        return element;
    }

    setSelectedFeed = (dataChange: FeedEvents['dataChanged']): void => {
        if (typeof dataChange === 'string') {
            return;
        }

        const { id, feedData: feed } = dataChange;

        if (this.currentFeed?.id !== id) {
            return;
        }

        this.mediaLibraryService.setSelectedFeedId(id);
        const categorizedFeedData: IFeedFieldListItem[] = [];

        for (const f of feed.data) {
            for (const item in f) {
                const obj = categorizedFeedData.find(c => c.name === item);
                const { value, targetUrl } = f[item];
                const itemValue = value?.toString() || '';
                if (!obj) {
                    categorizedFeedData.push({
                        id,
                        name: normalizeFeedPath(item),
                        values: [decodeXML(itemValue)],
                        targetUrls: [targetUrl]
                    });
                } else {
                    obj.values.push(decodeXML(itemValue));
                    obj.targetUrls.push(targetUrl);
                }
            }
        }

        this.feedData = categorizedFeedData;
        setTimeout(() => this.setFeedViewHeight());
        this.editor.renderer.feedStore?.on('dataChanged', this.setSelectedFeed);
    };

    backToFeedList(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        this.resetFeeds();
    }

    resetFeeds(): void {
        if (this.feedData && !this.inFeedPicker) {
            this.feedData = undefined;
            this.currentFeed = undefined;
        } else {
            this.list.goToParent();
        }
        this.editor.renderer.feedStore?.off('dataChanged', this.setSelectedFeed);
        this.mediaLibraryService.unselectFeed();
        this.detectChanges();
    }

    selectFeedItem(feedItem: IFeedFieldListItem): void {
        this.selectedFeedItem = feedItem;
        this.detectChanges();
    }

    stopPropagation(event: Event): void {
        event.stopPropagation();
    }

    closeBrandDialog(): void {
        this.uiDialogComponent.close();
    }

    showAIStudioNoAccessDialog(): void {
        this.aiStudioDialogService.showNoAccessDialog();
    }

    ngOnDestroy(): void {
        clearInterval(this.feedPollTimeout);
        this.clearScrollInterval();
        this.fileDropZone?.nativeElement?.removeEventListener('dragleave', this.onDragFileLeave);

        this.mediaLibraryService.search('');

        if (this.inDialog) {
            this.mediaLibraryService.closeDialog();
        }

        if (this.editor) {
            this.editor.removeRichTextBlurSuspensionElement(this.host.nativeElement);
            this.editor.workspace.isElementDragging$.next(false);
        }
        window.removeEventListener('keypress', this.insertSelected);
        window.removeEventListener('resize', this.setFeedViewHeight);
        this.hotkeyBetterService.off('OpenBrandLibrary', this.toggleBrandLibrary);
        this.hotkeyBetterService.off('OpenImageLibrary', this.toggleImage);
        if (this.hasVideoLibraryAccess) {
            this.hotkeyBetterService.off('OpenVideoLibrary', this.toggleVideo);
        }
        this.hotkeyBetterService.off('OpenWidgetLibrary', this.toggleWidget);
        this.hotkeyBetterService.off('OpenFeedsLibrary', this.toggleFeeds);
        this.hotkeyBetterService.off('OpenEffectsLibrary', this.toggleEffects);
        this.hotkeyBetterService.off('DeleteElement', this.deleteElement);

        this.editor.renderer.feedStore?.off('dataChanged', this.setSelectedFeed);

        if (!this.inDialog) {
            this.hotkeyBetterService.popContext();
        }

        this.removeDragListeners();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    initSorting(): void {
        const sortingStrategy = this.storage.get('brandLibrarySortingStrategy');
        const defaultSortingStrategy: SortingStrategy = { direction: 'asc', sortBy: 'name' };

        const initialSortingStrategy: SortingStrategy = sortingStrategy
            ? JSON.parse(sortingStrategy)
            : defaultSortingStrategy;

        this.updateSorting(initialSortingStrategy);
    }

    updateSorting(newSortingStrategy: Partial<SortingStrategy> = {}): void {
        if (!newSortingStrategy) {
            return;
        }

        this.sortDropdown?.closePopover?.();

        this.sortingStrategy = { ...this.sortingStrategy, ...newSortingStrategy };
        this.storage.set('brandLibrarySortingStrategy', JSON.stringify(this.sortingStrategy));

        this.dataSource.sort(this.sortingString);
        this.foldersSource.sort(this.sortingString);
    }

    get sortingString(): string {
        return (this.sortingStrategy.direction === 'asc' ? '' : '-') + this.sortingStrategy.sortBy;
    }
}

interface SortingStrategy {
    sortBy: 'name' | 'created' | 'modified';
    direction: 'asc' | 'desc';
}
