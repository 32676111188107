import { Injectable, inject } from '@angular/core';
import { cloneDeep } from '@apollo/client/utilities';
import { UIConfirmDialogService, UINotificationService } from '@bannerflow/ui';
import {
    cloneNodeStyles,
    isVersionedText,
    updateStyleIds
} from '@creative/elements/rich-text/text-nodes';
import {
    ElementSelection,
    cloneNodes,
    isGroupDataNode,
    isTextNode,
    isWidgetNode,
    toFlatNodeList
} from '@creative/nodes';
import { IAction } from '@domain/action';
import { IElementProperty } from '@domain/creativeset';
import { IVersionProperty } from '@domain/creativeset/version';
import { Masking, OneOfMaskableElementDataNodes } from '@domain/mask';
import { OneOfElementDataNodes } from '@domain/nodes';
import { distinctArrayById } from '@studio/utils/array';
import { createElementProperty } from '@studio/utils/element.utils';
import { omit } from '@studio/utils/utils';
import { VersionsService } from '../../../../shared/versions/state/versions.service';
import { isVersionedProperty } from '../../../../shared/versions/versions.utils';
import { ActionTriggers } from '../../properties-panel/actions/action';
import { EditorEventService } from '../../services/editor-event/editor-event.service';
import { ElementChangeType } from '../../services/editor-event/element-change';
import { EditorStateService } from '../../services/editor-state.service';
import { ElementCreatorService } from '../../services/element-creator.service';
import { ElementSelectionService } from '../../services/element-selection.service';
import { MutatorService } from '../../services/mutator.service';

@Injectable()
export class StudioWorkspaceService {
    private editorEventService = inject(EditorEventService);
    private editorStateService = inject(EditorStateService);
    private elementCreatorService = inject(ElementCreatorService);
    private uiConfirmDialogService = inject(UIConfirmDialogService);
    private versionsService = inject(VersionsService);
    private uiNotificationService = inject(UINotificationService);
    private elementSelectionService = inject(ElementSelectionService);
    private mutatorService = inject(MutatorService);

    async removeElement(selection: ElementSelection, force = false): Promise</** Removed */ boolean> {
        const selectionElements = Array.from(selection.elements);
        const elementsToClean: Map<OneOfElementDataNodes, IAction[]> =
            this.getElementsToClean(selectionElements);

        if (elementsToClean.size) {
            const tableString = this.createTableString(elementsToClean);

            // Selection is lost when dialog opens
            const selectionClone = new ElementSelection(cloneNodes(selection.nodes));

            const result = await this.uiConfirmDialogService.confirm({
                headerText: 'Delete element',
                text: `If you delete this element, the following actions where it is used as a target, will also be deleted:
                <br>
                <table align="left" class="delete-element-dialog-table">
                <thead align="left"><tr><td><b>Element</b></td><td><b>Action</b></td></tr></thead>
                <tbody align="left">
                ${tableString}
                </tbody>
                </table>`,
                cancelText: 'CANCEL',
                confirmText: 'DELETE'
            });

            if (result === 'confirm') {
                elementsToClean.forEach((actions, element) => {
                    element.actions = element.actions.filter(
                        act => act !== actions.find(action => action === act)
                    );
                });

                this.mutatorService.removeSelection(selectionClone, force);
                return true;
            }
        } else {
            this.mutatorService.removeSelection(selection, force);
            return true;
        }

        return false;
    }

    async detach(nodes: OneOfElementDataNodes[]): Promise<OneOfElementDataNodes[]> {
        const selection = this.elementSelectionService.currentSelection;
        const selectedGroups = selection.nodes.filter(isGroupDataNode);

        this.elementSelectionService.clearSelection();

        const creativeDataNode = this.editorStateService.document;
        creativeDataNode.preserveEmptyChildren(true);

        const newNodes: OneOfElementDataNodes[] = [];
        for (const node of nodes) {
            const newNode = await this.detachElement(node);
            if (newNode) {
                newNodes.push(newNode);
            }
        }

        creativeDataNode.preserveEmptyChildren(false);

        if (!newNodes.length) {
            return [];
        }

        if (selectedGroups.length) {
            this.editorEventService.creative.change('nodes', selectedGroups);
            this.elementSelectionService.setSelection(...selectedGroups);
        } else {
            this.editorEventService.creative.change('elements', newNodes);
            this.elementSelectionService.setSelection(...newNodes);
        }

        this.mutatorService.renderer.updateElementOrder_m();
        this.uiNotificationService.open(
            `Selected element ${nodes.length >= 2 ? 's' : ''} has been detached from other sizes`,
            {
                autoCloseDelay: 3000,
                placement: 'top',
                type: 'info'
            }
        );

        return newNodes;
    }

    private getElementsToClean(
        selectionElements: OneOfElementDataNodes[]
    ): Map<OneOfElementDataNodes, IAction[]> {
        const elementsToClean: Map<OneOfElementDataNodes, IAction[]> = new Map<
            OneOfElementDataNodes,
            IAction[]
        >();
        for (const selectionElement of selectionElements) {
            for (const element of this.editorStateService.renderer.creativeDocument.elements) {
                if (
                    element === selectionElement ||
                    selectionElements.some(sel => sel.id === element.id)
                ) {
                    continue;
                }
                const connectedAction = element.actions.filter(action =>
                    action.operations.find(op => op.target === selectionElement.id)
                );
                if (connectedAction.length) {
                    const el = elementsToClean.get(element) || [];
                    elementsToClean.set(element, [...el, ...connectedAction]);
                }
            }
        }
        return elementsToClean;
    }

    private createTableString(elementsToClean: Map<OneOfElementDataNodes, IAction[]>): string {
        let tableString = '';
        elementsToClean.forEach((actions, element) => {
            for (const action of actions) {
                const trigger = ActionTriggers.find(
                    actionTrigger => actionTrigger.value === action.triggers[0]
                );

                if (!trigger) {
                    continue;
                }

                tableString += `<tr><td>${
                    element.name
                }</td><td>On ${trigger.name.toLowerCase()}</td></tr>`;
            }
        });
        return tableString;
    }

    private async detachElement(
        node: OneOfElementDataNodes
    ): Promise<OneOfElementDataNodes | undefined> {
        const elementProperties = this.detachElementProperties(node);
        const parent = node.__parentNode || node.__rootNode;

        if (!parent) {
            throw new Error('Not possible to detach an node without a parent.');
        }

        const currentIndex = toFlatNodeList(parent).findIndex(({ id }) => id === node.id);
        const creativesetElement = this.editorStateService.getElementById(node.id);

        const elementWasRemoved = await this.removeElement(new ElementSelection([node]), false);

        if (!elementWasRemoved) {
            return;
        }

        const { defaultVersionProperties, versionProperties } = this.editorStateService;

        const newElement = await this.elementCreatorService.createElementCopy(
            node,
            {
                values: {
                    defaultVersionProperties,
                    elementProperties,
                    versionProperties
                },
                element: creativesetElement,
                shouldRescale: false
            },
            true
        );

        parent.moveNode_m?.(newElement, currentIndex);

        return newElement;
    }

    private detachElementProperties(node: OneOfElementDataNodes): IElementProperty[] {
        const { versionProperties, elements, defaultVersionProperties } = this.editorStateService;
        const allVersionProperties = distinctArrayById([
            ...versionProperties,
            ...defaultVersionProperties
        ]);
        const element = cloneDeep(elements.find(({ id }) => id === node.id));

        if (!element) {
            return [];
        }

        const elementProperties = element.properties.map(property =>
            createElementProperty(omit(property, 'id', 'clientId'))
        );

        const styleIdMap = isTextNode(node)
            ? cloneNodeStyles(node, this.editorStateService.document.id)
            : undefined;

        for (const elementProperty of elementProperties) {
            const versionProperty = allVersionProperties.find(
                ({ id }) => id === elementProperty.versionPropertyId
            );

            if (!versionProperty) {
                continue;
            }

            const oldVersionProperty = cloneDeep(versionProperty);

            if (elementProperty.versionPropertyId) {
                elementProperty.value = oldVersionProperty.value;

                this.updateAndUpsertVersionProperties(
                    node,
                    oldVersionProperty,
                    elementProperty,
                    styleIdMap
                );
            }
        }

        return elementProperties;
    }

    private updateAndUpsertVersionProperties(
        node: OneOfElementDataNodes,
        oldVersionProperty: IVersionProperty,
        elementProperty: IElementProperty,
        styleIdMap?: Map<string, string>
    ): void {
        if (!isVersionedProperty(oldVersionProperty)) {
            return;
        }

        const currentDocumentId = this.editorStateService.document.id;
        const unitName = oldVersionProperty.name;
        const versionedElementProperty = this.editorStateService.propertyAsVersionableProperty(
            elementProperty,
            unitName
        );
        const currentVersion = this.editorStateService.currentVersion;
        const versions = distinctArrayById([currentVersion, ...this.editorStateService.versions]);

        if (isWidgetNode(node)) {
            const customProperty = node.customProperties.find(
                ({ versionPropertyId }) => versionPropertyId === oldVersionProperty.id
            );
            if (customProperty) {
                customProperty.versionPropertyId = versionedElementProperty.versionPropertyId;
            }
        }

        for (const version of versions) {
            const property = version.properties.find(({ id }) => id === oldVersionProperty.id);

            if (!property) {
                continue;
            }

            if (isVersionedText(property)) {
                // Clean up documentid references that no longer are valid
                const oldProperty = cloneDeep(property);

                for (const style of oldProperty.value.styles) {
                    delete style.styleIds[currentDocumentId];
                }

                this.versionsService.upsertVersionProperty(
                    version.id,
                    cloneDeep({
                        ...oldProperty,
                        id: oldProperty.id
                    })
                );
            }

            const newProperty = cloneDeep(property);

            if (isVersionedText(newProperty) && styleIdMap) {
                updateStyleIds(newProperty, styleIdMap, this.editorStateService.document.id);
            }

            const newVersionProperty: IVersionProperty = {
                ...newProperty,
                id: versionedElementProperty.versionPropertyId
            };

            this.versionsService.upsertVersionProperty(version.id, newVersionProperty);
        }
    }

    maskElements(
        elementAsMask: OneOfMaskableElementDataNodes,
        elementsToBeMasked: OneOfMaskableElementDataNodes[]
    ): void {
        const parentNode = elementAsMask.__parentNode;
        const elementChangeType = parentNode ? ElementChangeType.Burst : ElementChangeType.Skip;

        for (const element of [elementAsMask, ...elementsToBeMasked]) {
            const isMask = element.id === elementAsMask.id;
            const elementId = isMask ? undefined : elementAsMask.id;

            this.mutatorService.setElementPropertyValue(
                element,
                'masking',
                {
                    isMask,
                    elementId
                } satisfies Masking,
                elementChangeType
            );
        }

        if (!parentNode) {
            this.elementCreatorService.createGroup(
                [elementAsMask, ...elementsToBeMasked],
                'Masked group'
            );
        } else {
            this.editorEventService.creative.change('elements', [elementAsMask, ...elementsToBeMasked]);
        }
    }

    removeMasking(elements: OneOfMaskableElementDataNodes[]): void {
        for (const element of elements) {
            this.mutatorService.setElementPropertyValue(
                element,
                'masking',
                undefined,
                ElementChangeType.Burst
            );
        }
    }
}
