import { Injectable } from '@angular/core';
import { cloneDeep } from '@apollo/client/utilities';
import { UIConfirmDialogService, UINotificationService } from '@bannerflow/ui';
import { resolveElementTextStyle } from '@creative/elements/rich-text/text-nodes';
import { stripCustomPropertyPrefix } from '@creative/elements/widget/utils';
import { createInlineStyledTextFromText, isWidgetNode } from '@creative/nodes/helpers';
import {
    convertAnimationToDto,
    convertStateToDto,
    convertVideoAssetToDto,
    convertVideoSettingsToDto,
    deserializeWidgetPropertyValue,
    INLINE_STYLED_TEXT,
    serializeElementPropertyToStringValue,
    serializeWidgetPropertyValue
} from '@creative/serialization';
import { convertActionToDto } from '@creative/serialization/action-serializer';
import { serializeInlineStyledText } from '@creative/serialization/text-serializer';
import {
    IBrandLibrary,
    IBrandLibraryElement,
    IBrandLibraryWidgetElement,
    INewBrandLibraryElement
} from '@domain/brand/brand-library';
import { ElementPropertyUnit, IElement } from '@domain/creativeset';
import { IElementProperty } from '@domain/creativeset/element';
import { IVersionedText, IVersionProperty } from '@domain/creativeset/version';
import { ElementKind } from '@domain/elements';
import {
    ICreativeDataNode,
    ITextViewElement,
    IVideoElementDataNode,
    OneOfElementDataNodes,
    OneOfElementPropertyKeys,
    OneOfTextDataNodes
} from '@domain/nodes';
import { IWidgetElementDataNode, IWidgetSelectOption, WIDGET_PROPERTY_PREFIX } from '@domain/widget';
import { createBrandlibraryElement, createElementProperty } from '@studio/utils/element.utils';
import { omit } from '@studio/utils/utils';
import { firstValueFrom, Subject } from 'rxjs';
import { BrandLibraryDataService } from '../../../shared/media-library/brand-library.data.service';
import { VersionsService } from '../../../shared/versions/state/versions.service';
import { isVersionedProperty } from '../../../shared/versions/versions.utils';
import { EditorStateService, ElementCreatorService, HistoryService } from '../services';
import { BrandLibraryElementDeletionService } from './brandlibrary-element-deletion.service';

export type BrandLibraryElementDataNode = Exclude<OneOfElementDataNodes, IWidgetElementDataNode>;

@Injectable()
export class BrandLibraryElementService {
    brandLibraryUpdated$ = new Subject<void>();
    selectedElements$ = new Subject<IBrandLibraryElement[]>();
    deleteConfirmDialogOpen: boolean;

    constructor(
        private uiConfirmDialogService: UIConfirmDialogService,
        private uiNotificationService: UINotificationService,
        private editorStateService: EditorStateService,
        private elementCreatorService: ElementCreatorService,
        private versionsService: VersionsService,
        private brandLibraryDataService: BrandLibraryDataService,
        private historyService: HistoryService,
        private brandLibraryElementDeletionService: BrandLibraryElementDeletionService
    ) {}

    async add(
        element: BrandLibraryElementDataNode,
        creativeDocument: ICreativeDataNode,
        displayNotification = true
    ): Promise<void> {
        const viewElement = this.editorStateService.renderer.getViewElementById<ITextViewElement>(
            element.id
        );

        if (!viewElement || !this.brandLibraryDataService.brandLibrary) {
            return;
        }

        const dataNode = viewElement.__data;

        // Create the element that is going to be saved in brandLibrary
        const brandLibraryElement = this.createNewBrandLibraryElement(
            resolveElementTextStyle(viewElement).__data
        );

        // Get current ids from brandLibrary
        const currentIds = this.brandLibraryDataService.brandLibrary.elements.map(e => e.id);

        await firstValueFrom(this.brandLibraryDataService.createElement(brandLibraryElement, dataNode));
        const newBrandLibrary = this.brandLibraryDataService.brandLibrary;

        // Get the newly created element from brandLibrary
        const newBrandlibraryElement = newBrandLibrary.elements.find(
            el => currentIds.indexOf(el.id) < 0
        );
        // Get the element that has been uploaded
        const dataElement = creativeDocument.elements.find(e => e.id === element.id);

        if (newBrandlibraryElement && dataElement) {
            // Apply the new brandLibrary element id to the element's parentId
            this.applyParentId(dataElement, newBrandlibraryElement, currentIds, newBrandLibrary);
        }

        this.brandLibraryElementDeletionService.brandLibraryUpdated$.next();

        if (displayNotification) {
            this.displayNotification('Element has been added to brand library');
        }
    }

    private createNewBrandLibraryElement(
        element: BrandLibraryElementDataNode
    ): INewBrandLibraryElement {
        const brandLibraryElement = createBrandlibraryElement({ type: element.kind });
        this.setElementProperties(element, brandLibraryElement, true);
        return brandLibraryElement;
    }

    async update(
        element: BrandLibraryElementDataNode,
        creativesetElement: IBrandLibraryElement,
        disableConfirm = false,
        updateName = false,
        displayNotification = true
    ): Promise<void> {
        const viewElement = this.editorStateService.renderer.getViewElementById(element.id);
        if (!viewElement) {
            return;
        }
        resolveElementTextStyle(viewElement);
        if (!disableConfirm) {
            const confirm = await this.confirmDialog();
            if (confirm) {
                await this.updateElement(creativesetElement, element, updateName);
            } else {
                return;
            }
        } else {
            await this.updateElement(creativesetElement, element, updateName);
        }

        if (displayNotification) {
            return this.displayNotification('Element has been updated');
        }
    }

    async updateName(
        element: BrandLibraryElementDataNode,
        creativeSetElement: IBrandLibraryElement,
        updateName = false
    ): Promise<void> {
        await this.updateElement(creativeSetElement, element, updateName);
        this.displayNotification('Element has been updated');
    }

    async duplicate(element: IElement): Promise<void> {
        const brandLibraryElement = createBrandlibraryElement(element);
        await firstValueFrom(this.brandLibraryDataService.createElement(brandLibraryElement));
        this.brandLibraryElementDeletionService.brandLibraryUpdated$.next();
    }

    private applyParentId(
        element: OneOfElementDataNodes,
        newBrandLibraryElement: IBrandLibraryElement,
        preIds: string[],
        brandLibrary: Readonly<IBrandLibrary>
    ): void {
        if (element && newBrandLibraryElement) {
            element.parentId = newBrandLibraryElement.id;
        }

        // If no other elements where present in the library
        else if (preIds.length === 0 && element && brandLibrary.elements.length === 1) {
            element.parentId = [...brandLibrary.elements][0].id;
        }

        for (const snapshot of this.historyService.getAllSnapshots()) {
            const snapshotDocumentElement = snapshot.document.elements.find(el => el.id === element.id);
            if (snapshotDocumentElement) {
                snapshotDocumentElement.parentId = newBrandLibraryElement.id;
            }
        }
    }

    private async updateElement(
        creativesetElement: IBrandLibraryElement,
        element: BrandLibraryElementDataNode,
        updateName: boolean
    ): Promise<void> {
        creativesetElement.properties = [];
        this.setElementProperties(element, creativesetElement, updateName);
        const updatedBrandLibrary = await firstValueFrom(
            this.brandLibraryDataService.updateElement(creativesetElement)
        );
        if (updatedBrandLibrary) {
            this.brandLibraryDataService.loadBrandLibrary();
            await firstValueFrom(this.brandLibraryDataService.brandLibrary$);
            this.brandLibraryElementDeletionService.brandLibraryUpdated$.next();
        }
    }

    // TODO: make generic logic in serializer?
    private setElementProperties(
        element: BrandLibraryElementDataNode,
        brandLibraryElement: INewBrandLibraryElement,
        updateName = false
    ): void {
        this.serializeBrandElementProperties(element, brandLibraryElement);

        if (updateName) {
            brandLibraryElement.name = element.name;
        }

        switch (element.kind) {
            case ElementKind.Rectangle:
                brandLibraryElement.type = ElementKind.Rectangle;
                break;
            case ElementKind.Ellipse:
                brandLibraryElement.type = ElementKind.Ellipse;
                break;
            case ElementKind.Button:
                brandLibraryElement.type = ElementKind.Button;
                break;
            case ElementKind.Text:
                brandLibraryElement.type = ElementKind.Text;
                break;
            case ElementKind.Image:
                brandLibraryElement.type = ElementKind.Image;
                break;
            case ElementKind.Video:
                brandLibraryElement.type = ElementKind.Video;
                break;
            default:
                break;
        }
    }

    serializeBrandElementProperties(
        elementData: OneOfElementDataNodes,
        brandLibraryElement: IBrandLibraryElement | INewBrandLibraryElement
    ): void {
        const propertyKeys = Object.keys(elementData) as OneOfElementPropertyKeys[];
        for (const key of propertyKeys) {
            if (!isValidPropertyWithValue(elementData, key)) {
                continue;
            }
            let value: any;
            const unit = typeof elementData[key] as ElementPropertyUnit;
            switch (key) {
                case 'content': {
                    const content =
                        (elementData as OneOfTextDataNodes).__dirtyContent || elementData[key];
                    brandLibraryElement.properties.push(
                        createElementProperty({
                            name: INLINE_STYLED_TEXT,
                            unit: 'object',
                            value: serializeInlineStyledText(createInlineStyledTextFromText(content))
                        })
                    );
                    continue;
                }
                case 'states':
                case 'animations':
                case 'actions':
                    if (key === 'states') {
                        value = elementData.states.map(convertStateToDto);
                    } else if (key === 'animations') {
                        value = elementData.animations.map(animation =>
                            convertAnimationToDto(animation)
                        );
                    } else if (key === 'actions') {
                        value = elementData.actions
                            .filter(action =>
                                action.operations.every(op => op.target === elementData.id)
                            )
                            .map(action => convertActionToDto(action));
                    }

                    brandLibraryElement.properties.push(
                        createElementProperty({
                            name: key,
                            unit: 'array',
                            value: serializeElementPropertyToStringValue(key, value)
                        })
                    );
                    continue;
                case 'videoAsset':
                case 'videoSettings':
                    if (key === 'videoAsset') {
                        const videoAsset = (elementData as IVideoElementDataNode)?.videoAsset;
                        value = convertVideoAssetToDto(videoAsset);
                    } else if (key === 'videoSettings') {
                        value = convertVideoSettingsToDto(elementData[key]);
                    }
                    brandLibraryElement.properties.push(
                        createElementProperty({
                            name: key,
                            unit,
                            value: serializeElementPropertyToStringValue(key, value)
                        })
                    );
                    continue;
                case 'videoReference':
                case 'imageReference':
                    brandLibraryElement.properties.push(
                        createElementProperty({
                            name: key,
                            unit: 'id',
                            value: elementData[key]
                        })
                    );
                    continue;
                default:
                    value = elementData[key];
                    break;
            }

            brandLibraryElement.properties.push(
                createElementProperty({
                    name: key,
                    unit,
                    value
                })
            );
        }
    }

    async updateWidgetFromLibrary(
        widget: IWidgetElementDataNode,
        libraryWidget: IBrandLibraryWidgetElement,
        element: IElement
    ): Promise<void> {
        const updatedWidget = await this.getUpdatedWidgetElementFromLibrary(
            widget,
            libraryWidget,
            true
        );

        if (!updatedWidget) {
            throw new Error('Could not get updated widget from library');
        }

        // Patch props that we don't want to update
        updatedWidget.customProperties.forEach(property => {
            const oldProperty = widget.customProperties.find(cp => cp.name === property.name);

            if (oldProperty) {
                if (property.unit === 'text' || property.unit === 'feed') {
                    property.versionPropertyId = oldProperty.versionPropertyId;
                }
            }

            if (isVersionedProperty(property)) {
                const elementProperty = element.properties.find(el => el.name === property.name);

                if (!elementProperty) {
                    const versionProperty = this.editorStateService.versionProperties.find(
                        el => el.id === property.versionPropertyId
                    );

                    /**
                     * Create a new version property if the property has a reference
                     * to one that doesn't exist in the case of corrupt data
                     */
                    if (!versionProperty) {
                        this.editorStateService.propertyAsVersionableProperty(
                            property,
                            property.unit === 'feed' ? 'feed' : 'content'
                        );
                    }

                    element.properties.push(
                        createElementProperty({
                            ...omit(property, 'id')
                        })
                    );

                    return;
                }

                property.versionPropertyId = elementProperty.versionPropertyId;
                property.value = elementProperty.versionPropertyId ? undefined : elementProperty.value;
            }
        });

        const propertiesToPersist = /^(html|css|ts|js|widgetReference|widgetContentBlobReference)$/;

        const newProperties: IElementProperty[] = [];

        for (const property of element.properties) {
            if (propertiesToPersist.test(property.name)) {
                newProperties.push(property);
                continue;
            }

            const propertyExist = updatedWidget.customProperties.some(
                customProperty =>
                    customProperty.name.replace(WIDGET_PROPERTY_PREFIX, '') === property.name &&
                    customProperty.unit === property.unit
            );

            if (propertyExist) {
                newProperties.push(property);
            }
        }

        element.properties = newProperties;
        widget.customProperties = updatedWidget.customProperties;
    }

    async getUpdatedWidgetElementFromLibrary(
        documentElement: IWidgetElementDataNode,
        elementFromLibrary: IBrandLibraryWidgetElement,
        skipEmit?: boolean
    ): Promise<IWidgetElementDataNode | undefined> {
        if (!isWidgetNode(documentElement)) {
            return;
        }

        const clonedWidget = cloneDeep(elementFromLibrary);
        clonedWidget.name = documentElement.name;

        clonedWidget.properties.forEach(clonedWidgetProperty => {
            const property = documentElement.customProperties.find(
                ({ name }) => name === stripCustomPropertyPrefix(clonedWidgetProperty.name)
            );

            if (!property) {
                return;
            }

            /**
             * Get the version property value, needed when property unit changes
             * and multiple different versions and sizes are affected
             */
            const versionProperty = this.editorStateService.versionProperties.find(
                ({ id }) => id === property.versionPropertyId
            ) as IVersionProperty<IVersionedText> | undefined;

            const isSameUnit = property.unit === clonedWidgetProperty.unit;
            const unit = isSameUnit ? property.unit : clonedWidgetProperty.unit;
            const value = isSameUnit
                ? (versionProperty?.value ?? property.value)
                : clonedWidgetProperty.value;

            switch (unit) {
                case 'select': {
                    // Add new options from BL, and preserve the selected options
                    const oldOptions = value as IWidgetSelectOption[];
                    const newOptions = deserializeWidgetPropertyValue(
                        clonedWidgetProperty
                    ) as IWidgetSelectOption[];

                    const oldSelection = oldOptions.find(option => option.selected);
                    const newSelection = newOptions.find(
                        option => option.value === oldSelection?.value
                    );

                    if (newSelection) {
                        // 1st option is selected by default
                        newOptions[0].selected = false;
                        newSelection.selected = true;
                    }

                    clonedWidgetProperty.value = serializeWidgetPropertyValue('select', newOptions);
                    break;
                }
                default:
                    clonedWidgetProperty.value = serializeWidgetPropertyValue(property.unit, value);
                    break;
            }
        });

        const newWidget = await this.elementCreatorService.createWidget(
            omit(documentElement, 'customProperties', 'id'),
            { element: clonedWidget, skipEmit }
        );

        if (!skipEmit) {
            this.patchPreviousVersionsProps(documentElement, newWidget);
        }

        return newWidget;
    }

    private patchPreviousVersionsProps(
        widget: IWidgetElementDataNode,
        newWidget: IWidgetElementDataNode
    ): void {
        // patch versions with previous values before removing them
        widget.customProperties.forEach(prop => {
            this.editorStateService.versions.forEach(version => {
                const prevValue = version.properties.find(
                    versionProp => versionProp.id === prop.versionPropertyId
                )?.value;
                const newProp = newWidget.customProperties.find(
                    newWidgetProp => newWidgetProp.name === prop.name
                );
                const newVersionProp = version.properties.find(
                    versionProp => versionProp.id === newProp?.versionPropertyId
                );

                if (newVersionProp && prevValue) {
                    this.versionsService.upsertVersionProperty(version.id, {
                        ...newVersionProp,
                        value: prevValue
                    });
                } else if (prevValue && newProp) {
                    this.versionsService.addVersionProperty(version.id, {
                        id: newProp.versionPropertyId!,
                        value: prevValue,
                        name: newProp.unit === 'text' ? 'content' : newProp.unit
                    });
                }
            });
        });
    }

    async confirmDialog(): Promise<boolean> {
        const result = await this.uiConfirmDialogService.confirm({
            headerText: 'Update brand element',
            text: 'Do you want to update the brand element?',
            confirmText: 'Yes'
        });
        return result !== 'cancel';
    }

    private displayNotification(message: string): void {
        return this.uiNotificationService.open(message, {
            autoCloseDelay: 5000,
            placement: 'top',
            type: 'info'
        });
    }
}

function isValidPropertyWithValue(elementData: OneOfElementDataNodes, property: string): boolean {
    return (
        elementData[property] !== undefined &&
        property !== 'name' &&
        property !== 'constraint' &&
        property !== 'kind' &&
        !property.includes('__')
    );
}
