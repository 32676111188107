import { IElementProperty } from '@domain/creativeset/element';
import { IVersion, IVersionedText } from '@domain/creativeset/version';
import { ICreativeDataNode, OneOfElementPropertyKeys, OneOfTextDataNodes } from '@domain/nodes';
import { characterProperties } from '@domain/property';
import {
    ICommonStyledProperties,
    IRichTextEditorStyleResolver,
    IStyleUpdateDirective,
    IStyleUpdateSequence,
    StyleUpdateSequenceType
} from '@domain/rich-text/rich-text.editor.style-resolver.header';
import { IRichText } from '@domain/rich-text/rich-text.header';
import {
    CharacterPropertyKeys,
    ICharacterProperties,
    ICharacterStylesMap,
    IText,
    OneOfContentSpans,
    OneOfEditableSpans,
    PreviousStyleId,
    PreviousStyleIdType,
    SpanType,
    TextStyle
} from '@domain/text';
import { cloneDeep, cloneMapDeep } from '@studio/utils/clone';
import { __parent, parent } from '@studio/utils/di';
import { uuidv4 } from '@studio/utils/id';
import { exclude, omit } from '@studio/utils/utils';
import { getDefaultTextValue } from '../../element-templates';
import { RichTextEditorService } from './rich-text.editor';
import { isSpanMergeable_m } from './rich-text.span.utils';
import {
    copySpans,
    getCommonStyledPropertiesFromUniqueOnes,
    getHashFromStyle,
    hasSameStyle,
    hasSameStyleProperty,
    isContentSpan,
    isEmptyStyle,
    isSubStyleOf,
    isVariableSpan,
    isVersionedText,
    isVersionedTextContentSpan
} from './text-nodes';

/* eslint-disable @typescript-eslint/no-shadow */

export class RichTextEditorStyleResolver implements IRichTextEditorStyleResolver {
    get text(): IRichText {
        return this.editor.text;
    }
    private spans: OneOfEditableSpans[] = [];
    private versions: Readonly<IVersion[]> = [];
    private currentVersionId: string;
    private characterStylesMap: ICharacterStylesMap;
    private styleHashMap: Map</* styleHash */ string, /* styleId */ string>;
    private textDataElement: OneOfTextDataNodes;
    private document: ICreativeDataNode;
    private contentProperty: IElementProperty;
    private style: TextStyle;
    private shouldUpdateElementAndVersions: boolean;
    private deletedStyleHashMap: Map</* styleHash */ string, /* styleId */ string>;

    constructor(@parent() private editor: RichTextEditorService) {}

    getCommonlyStyledProperties(
        acrossAllVersions = true,
        spans?: OneOfEditableSpans[]
    ): ICommonStyledProperties {
        let setFirstSpanStyle = false;
        const firstSpansStyle = {} as Partial<ICharacterProperties>;
        const uniquelyStyledPropertiesOfFirstSpanStyle = new Set<string>();

        for (const span of spans || this.text.spans_m) {
            if (!isContentSpan(span)) {
                continue;
            }

            if (!setFirstSpanStyle) {
                for (const property of exclude(characterProperties, '__fontFamilyId')) {
                    if (property in span.style) {
                        firstSpansStyle[property] = span.style[property];
                    }
                }
            } else {
                for (const property of exclude(characterProperties, '__fontFamilyId')) {
                    if (
                        property in firstSpansStyle &&
                        !hasSameStyleProperty(
                            property as CharacterPropertyKeys,
                            firstSpansStyle,
                            span.style,
                            /* treatMissingAsFalse */ false
                        )
                    ) {
                        uniquelyStyledPropertiesOfFirstSpanStyle.add(property);
                    }
                }
            }
            setFirstSpanStyle = true;
        }

        if (acrossAllVersions) {
            for (const version of this.versions) {
                if (version.id === this.currentVersionId) {
                    continue;
                }
                const versionProperties = version.properties;
                const versionValue = versionProperties.find(
                    v => v.id === this.contentProperty.versionPropertyId
                );
                if (!versionValue) {
                    continue;
                }

                for (const span of (versionValue.value as IVersionedText).styles) {
                    if (
                        span.type === SpanType.Newline ||
                        span.type === SpanType.Space ||
                        span.type === SpanType.Word
                    ) {
                        const styleId = span.styleIds[this.document.id];
                        let spanStyle = {};
                        if (styleId) {
                            const characterStyles = this.characterStylesMap;
                            spanStyle = characterStyles.get(styleId) ?? {};
                        }
                        for (const property of exclude(characterProperties, '__fontFamilyId')) {
                            if (
                                firstSpansStyle[property] !== undefined &&
                                !hasSameStyleProperty(
                                    property as CharacterPropertyKeys,
                                    firstSpansStyle,
                                    spanStyle
                                )
                            ) {
                                uniquelyStyledPropertiesOfFirstSpanStyle.add(property);
                            }
                        }
                    }
                }
            }
        }
        const propertySet = getCommonStyledPropertiesFromUniqueOnes(
            uniquelyStyledPropertiesOfFirstSpanStyle,
            firstSpansStyle
        );
        const properties: ICommonStyledProperties = {};
        for (const property of propertySet) {
            properties[property] = firstSpansStyle[property];
        }
        return properties;
    }

    resolveCharacterStyles(skipStylePromotion = false): void {
        const resolvedText = this.getResolvedText(
            /* updateElementAndVersions */ true,
            skipStylePromotion
        );
        if (!resolvedText) {
            return;
        }

        const { style, spans } = resolvedText;
        const { element_m } = this.text;

        this.text.spans_m = spans;
        this.text.style = style;

        if (!element_m) {
            throw new Error('Element was undefined while resolving character styles.');
        }

        element_m.content = { style: { ...style }, spans: copySpans(spans) };
        element_m.characterStyles = this.characterStylesMap;
        element_m.__styleHashMap = this.styleHashMap;
        element_m.__deletedStyleHashMap = this.deletedStyleHashMap;
    }

    getResolvedText(updateElementAndVersions = false, skipStylePromotion = false): IText | undefined {
        this.shouldUpdateElementAndVersions = updateElementAndVersions;
        const self = this;
        const oldStyle = this.text.style;
        const { currentVersion, document, elements, versions } = this.editor.editorStateService!;
        this.document = document;
        this.currentVersionId = currentVersion.id;
        this.versions = versions;
        const currentVersionId = this.currentVersionId;
        const spans = (this.spans = copySpans(this.text.spans_m));
        const contentSpans = spans.filter(isContentSpan);
        const element = (this.textDataElement = this.text.element_m!);
        const textDataElement = elements.find(element => element.id === this.textDataElement?.id);

        if (!textDataElement) {
            return;
        }

        const contentProperty = (this.contentProperty = textDataElement.properties.find(
            p => p.name === 'content'
        )!);
        const characterStyles = (this.characterStylesMap = cloneMapDeep(
            this.textDataElement.characterStyles
        ));

        // Keep track of all characters styles with the help of their style hash.
        this.deletedStyleHashMap = cloneMapDeep(this.textDataElement.__deletedStyleHashMap);
        const styleHashMap = (this.styleHashMap = cloneMapDeep(this.textDataElement.__styleHashMap));
        const style = (this.style = cloneDeep(this.text.style));

        // NOTE, this method is in a process of being refactored. Nested function declarations will be lifted out to method declararations.
        // We are also thinking about to remove element style i.e. making style promotions obsolete (so those functions don't need refactoring).

        // Remove any sub styles, since element styles can generate the same style as the character styles.
        this.removeSubStyles();

        // Update style ids based on style properties.
        this.updateElementCharacterStyles(updateStyleIdsAndGetStyleUpdateDirective);

        if (!skipStylePromotion) {
            // Promote commonly styled properties. Across all versions.
            promoteCommonlyStyledPropertiesToElementStyle();

            // Prune duplicate and unused character styles
            this.pruneCharacterStyles();

            // Promote first span styles if all text in all versions have style overrides
            promoteFirstSpanStyleToElementStyle();

            // Prune duplicate and unused character styles
            this.pruneCharacterStyles();
        }

        updateSpansFromCharacterStyles();

        return { style, spans };

        function updateSpansFromCharacterStyles(): void {
            for (const span of contentSpans) {
                if (!span.styleId) {
                    continue;
                }

                const style = characterStyles.get(span.styleId);
                if (!style) {
                    continue;
                }

                if (isVariableSpan(span)) {
                    // TODO: Temp fix, since we don't update char style it can still somehow reuse the old feed settings.
                    span.style = Object.assign(omit(style, 'variable'), {
                        variable: span.style.variable
                    });
                } else {
                    span.style = style;
                }
            }
        }

        function promoteFirstSpanStyleToElementStyle(): void {
            if (!contentSpans.length) {
                return;
            }

            const firstSpan = contentSpans[0];
            const firstSpanStyleId = firstSpan.styleId!;
            const firstSpanStyle = firstSpan.style;

            if (!self.allSpansAreStyledInAllVersions() || isVariableSpan(firstSpan)) {
                return;
            }

            demoteCurrentElementStyleToCharStyle();
            removeStyleIdOnAllSpansEqualToFirstSpan();

            // Update all styles to have the current element styles. So they are not affected by a
            // style promotions.
            updateAllCurrentCharacterStylesToReflectFirstSpanStylePromotion();

            // Assign first span style to element style.
            Object.assign(self.style, omit(firstSpanStyle, 'fontSize'));

            if (firstSpanStyle.fontSize) {
                /* first operand uses relative font size */
                self.style.fontSize = firstSpanStyle.fontSize * oldStyle.fontSize;
            }

            // We can safely delete this, because it is promoted in all versions.
            characterStyles.delete(firstSpanStyleId);

            for (const [styleHash, styleId] of styleHashMap.entries()) {
                if (styleId === firstSpanStyleId) {
                    styleHashMap.delete(styleHash);
                    break;
                }
            }

            function updateAllCurrentCharacterStylesToReflectFirstSpanStylePromotion(): void {
                for (const [styleId, style] of characterStyles.entries()) {
                    if (styleId === firstSpanStyleId) {
                        continue;
                    }

                    for (const property in firstSpanStyle) {
                        if (property === 'fontSize') {
                            // Notice, if we promote the first span styles that has a font size. Since, font size in
                            // spans are relative to the element font size, we need to recalculate so they become
                            // relative the new element font size.
                            style[property] =
                                style[property] !== undefined
                                    ? style[property]! / firstSpanStyle.fontSize!
                                    : 1 / firstSpanStyle.fontSize!;
                        } else if (style[property] === undefined) {
                            style[property] =
                                self.style[property] !== undefined
                                    ? self.style[property]
                                    : // We need to get the default text value. Otherwise, a promoted property can override
                                      // a span style that is not promoted. For instance, 'fill' that gets promoted because
                                      // it is in the most dominant style should not add fill to other spans.
                                      getDefaultTextValue(property as OneOfElementPropertyKeys);
                        }
                    }
                    styleHashMap.set(getHashFromStyle(style), styleId);
                }
            }

            // Current element spans should not be affected by a style promotions. So they should be
            // assigned a span style representing the old element style.
            function demoteCurrentElementStyleToCharStyle(): void {
                const currentElementStyle = {} as Partial<ICharacterProperties>;

                for (const property in firstSpanStyle) {
                    if (property === 'fontSize') {
                        currentElementStyle[property] = 1 / firstSpanStyle.fontSize!;
                    } else {
                        currentElementStyle[property] =
                            typeof oldStyle[property] !== 'undefined'
                                ? oldStyle[property]
                                : getDefaultTextValue(property as OneOfElementPropertyKeys);
                    }
                }

                if (isEmptyStyle(currentElementStyle)) {
                    return;
                }

                const currentElementStyleId = getOrCreateStyleIdByStyle(currentElementStyle);
                characterStyles.set(currentElementStyleId, currentElementStyle);
                styleHashMap.set(getHashFromStyle(currentElementStyle), currentElementStyleId);
                for (const span of contentSpans) {
                    span.styleId ||= currentElementStyleId;
                }

                for (const version of versions) {
                    if (version.id === currentVersionId) {
                        continue;
                    }
                    const versionProperties = version.properties;
                    const versionValue = versionProperties.find(
                        ({ id }) => id === contentProperty.versionPropertyId
                    );

                    if (!versionValue) {
                        continue;
                    }

                    const versionedText = versionValue.value as IVersionedText;
                    for (const span of versionedText.styles) {
                        if (
                            isVersionedTextContentSpan(span) &&
                            Object.keys(span.styleIds).length === 0
                        ) {
                            span.styleIds[document.id] = currentElementStyleId;
                        }
                    }
                }
            }

            function removeStyleIdOnAllSpansEqualToFirstSpan(): void {
                // Reset first style's spans

                for (const span of contentSpans) {
                    if (span.styleId === firstSpanStyleId) {
                        span.style = {};
                        span.styleId = undefined;
                        delete span.styleIds[document.id];
                    }
                }

                for (const version of versions) {
                    if (version.id === currentVersionId) {
                        continue;
                    }

                    const versionProperties = version.properties;
                    const versionValue = versionProperties.find(
                        ({ id }) => id === contentProperty.versionPropertyId
                    );

                    if (!versionValue) {
                        continue;
                    }

                    const versionedText = versionValue.value as IVersionedText;
                    for (const span of versionedText.styles) {
                        if (
                            isVersionedTextContentSpan(span) &&
                            span.styleIds[document.id] === firstSpanStyleId
                        ) {
                            delete span.styleIds[document.id];
                        }
                    }
                }
            }
        }

        function createStyleIdByHash(hash: string): string {
            const deletedStyleId = self.deletedStyleHashMap.get(hash);
            if (deletedStyleId) {
                self.deletedStyleHashMap.delete(hash);
                return deletedStyleId;
            }

            return uuidv4();
        }

        function getOrCreateStyleIdByStyle(style: Partial<ICharacterProperties>): string {
            const hash = getHashFromStyle(style);
            let styleId = styleHashMap.get(hash);
            if (!styleId) {
                styleId = createStyleIdByHash(hash);
                styleHashMap.set(hash, styleId);
            }
            return styleId;
        }

        /*
         * Remove commonly styled properties across whole text. Multiple styles can have the same
         * properties(and values) across the whole text.
         */
        function promoteCommonlyStyledPropertiesToElementStyle(): void {
            const commonlyStyledProperties = self.getCommonlyStyledProperties(true, self.spans);

            for (const property in commonlyStyledProperties) {
                if (property === 'fontSize') {
                    style[property] = style.fontSize * (commonlyStyledProperties[property] as number);
                } else {
                    style[property] = commonlyStyledProperties[property];
                }

                for (const span of contentSpans) {
                    delete span.style[property];
                }

                if (self.shouldUpdateElementAndVersions) {
                    for (const version of versions) {
                        if (version.id === currentVersionId) {
                            continue;
                        }
                        const versionProperties = version.properties;
                        const versionValue = versionProperties.find(
                            ({ id }) => id === contentProperty.versionPropertyId
                        );

                        if (!versionValue) {
                            continue;
                        }

                        const versionedText = versionValue.value as IVersionedText;
                        for (const span of versionedText.styles) {
                            const characterStyles = element.characterStyles;
                            const spanStyle = characterStyles.get(span.styleIds[document.id]);
                            if (spanStyle) {
                                delete spanStyle[property];
                            }
                        }
                    }
                }
            }
            self.updateStyleIds();
        }

        function updateStyleIdsAndGetStyleUpdateDirective(
            spans: OneOfEditableSpans[],
            documentId: string,
            characterStyles: ICharacterStylesMap,
            styleHashMap: Map<string, string>
        ): IStyleUpdateDirective {
            const styleUpdateSequence = new Map</* styleId */ string, IStyleUpdateSequence[]>();
            const styleAdditionsSpans = new Set<OneOfContentSpans>();
            const contentSpans = spans.filter(isContentSpan);

            for (const span of contentSpans) {
                const styleId = span.styleId;
                const existingStyleId = getStyleIdByStyle(span.style);
                const hasEmptyStyle = isEmptyStyle(span.style);

                if (!styleId) {
                    if (hasEmptyStyle) {
                        // Don't do anything
                        continue;
                    }

                    if (existingStyleId) {
                        span.styleId = existingStyleId;
                    } else {
                        styleAdditionsSpans.add(span);
                    }
                    continue;
                }

                if (hasEmptyStyle) {
                    span.styleId = undefined;
                    delete span.styleIds[documentId];
                    appendUpdateSequence(styleId, {
                        span,
                        style: undefined,
                        type: StyleUpdateSequenceType.Delete
                    });
                } else {
                    const elementCharacterStyle = characterStyles.get(styleId)!;
                    const isSameStyle = hasSameStyle(span.style, elementCharacterStyle);
                    const updateSequence = existingStyleId
                        ? StyleUpdateSequenceType.UpdateToExistingStyle
                        : StyleUpdateSequenceType.UpdateToNewStyle;

                    if (!isSameStyle && existingStyleId) {
                        span.styleId = existingStyleId;
                        span.styleIds[documentId] = existingStyleId;
                    }

                    appendUpdateSequence(styleId, {
                        span,
                        style: omit(span.style, '__fontFamilyId'),
                        type: isSameStyle ? StyleUpdateSequenceType.Unchanged : updateSequence,
                        styleId: isSameStyle ? undefined : existingStyleId
                    });
                }
            }

            const styleUpdates = new Map</* styleId */ string, Partial<ICharacterProperties>>();
            const styleAdditions = new Map</* styleId */ string, Partial<ICharacterProperties>>();
            const styleDeletions = new Set</* styleId */ string>();

            for (const span of styleAdditionsSpans) {
                const styleId = getOrCreateStyleIdByStyle(span.style);
                span.styleId = styleId;
                styleAdditions.set(styleId, omit(span.style, '__fontFamilyId'));
            }

            for (const [styleId, updateSequence] of styleUpdateSequence.entries()) {
                let hasSameStyleUpdateInWholeSequence = true;
                let lastStyle: Partial<ICharacterProperties> | undefined;
                let lastStyleUpdateType = StyleUpdateSequenceType.None;
                let lastStyleId: string | undefined;
                const hasOnlyUnchanged = updateSequence.every(
                    ({ type }) => type === StyleUpdateSequenceType.Unchanged
                );
                let n = 0;

                // Only update styles that has same style on all spans(update sequence).
                for (const update of updateSequence) {
                    if (
                        n !== 0 &&
                        !hasSameStyle(update.style!, lastStyle!) &&
                        update.type !== lastStyleUpdateType
                    ) {
                        hasSameStyleUpdateInWholeSequence = false;
                        break;
                    }
                    lastStyle = update.style;
                    lastStyleUpdateType = update.type;
                    lastStyleId = styleId;
                    n++;
                }

                if (hasOnlyUnchanged) {
                    continue;
                }

                if (hasSameStyleUpdateInWholeSequence) {
                    switch (lastStyleUpdateType) {
                        case StyleUpdateSequenceType.UpdateToExistingStyle:
                            if (!hasSameStyleIdInOtherVersions(lastStyleId!)) {
                                styleDeletions.add(lastStyleId!);
                                self.removeStyleIdFromPreviousStyleIds(lastStyleId!);
                            }
                            break;
                        case StyleUpdateSequenceType.UpdateToNewStyle:
                            styleUpdates.set(styleId, lastStyle!);
                            break;
                        case StyleUpdateSequenceType.Delete:
                            if (!hasSameStyleIdInOtherVersions(lastStyleId!)) {
                                styleDeletions.add(lastStyleId!);
                                self.removeStyleIdFromPreviousStyleIds(lastStyleId!);
                            }
                            break;
                        default:
                            throw new Error('Did not expected other update style types here.');
                    }
                } else {
                    for (const update of updateSequence) {
                        if (update.type === StyleUpdateSequenceType.Unchanged) {
                            continue;
                        }
                        if (update.style === undefined) {
                            update.span.styleId = undefined;
                        } else {
                            const hash = getHashFromStyle(update.style);
                            const styleId = styleHashMap.get(hash);
                            if (styleId) {
                                update.span.styleId = styleId;
                            } else {
                                const newStyleId = createStyleIdByHash(hash);
                                update.span.styleId = newStyleId;
                                styleHashMap.set(hash, newStyleId);
                                styleAdditions.set(newStyleId, update.style);
                            }
                        }
                    }
                }
            }

            for (const span of contentSpans) {
                if (span.styleId) {
                    span.styleIds[documentId] = span.styleId;
                }
            }

            updatePreviousStylesOfContentSpans(contentSpans);

            return {
                updates: styleUpdates,
                additions: styleAdditions,
                deletions: styleDeletions
            };

            /**
             * We store previous styles, because we want to accuretaly update styles in a session in design view.
             * For example, changing one span to a different style on multiple steps needs to be tracked across
             * all of those steps. We cannot rely on the current state of the spans, because updating just the
             * current state of the span to a different style, doesn't capture all the history of changes.
             *
             * Example:
             *   * Create two version Swedish, English.
             *   * Mark a span uppercase in English.
             *   * Go to Swedish, mark 50% of the uppercase span to uppercase/underline and the other 50% to
             *     uppercase/strikethrough.
             *
             *   If, we used only the current state as the model for updating changes to styles. The English version
             *   would have updated to have uppercase/strikethrough. Which is not intentional.
             *
             *
             * TODO: Might change this to just capture state from before open instead. Since, users never remember
             *       more than one state.
             */
            function updatePreviousStylesOfContentSpans(contentSpans: OneOfContentSpans[]): void {
                let depthIndex = 0;
                let depthLength = 0;

                for (const span of contentSpans) {
                    const previousStyleIdsLength = span.__previousStyleIds!.length;
                    if (previousStyleIdsLength > depthLength) {
                        depthLength = previousStyleIdsLength;
                    }
                }

                while (depthIndex < depthLength) {
                    // Store all previous style id:s candidates. A candidate is when a 'previousStyleId' differs from the
                    // 'currentStyleId'. And they are being dismissed whenever we encounter a span that differs from the
                    // candidate replacement.
                    const previousStyleIdUpdateCandidates = new Map<
                        /* currentStyleId */ string,
                        /* replacementStyleId */ string
                    >();
                    const dismissedStyleIdReplacements = new Set</* styleId */ string>();
                    const previousStyle = new Map<
                        /* styleId */ string,
                        /* previousStyle */ Partial<ICharacterProperties>
                    >();

                    for (const span of contentSpans) {
                        const previousStyleId = span.__previousStyleIds![depthIndex];

                        if (self.isPrevStyleIdUnchangedOrUndefined(previousStyleId)) {
                            continue;
                        }

                        // Skip previous style ids that are newer than where it was committed in history
                        const historyIndex =
                            span.__previousStyleIdToHistoryIndexMap!.get(previousStyleId);
                        const isStyleIdInHistory = historyIndex !== depthIndex;
                        if (isStyleIdInHistory) {
                            continue;
                        }

                        // Empty styles should not trigger style replacements
                        if (isEmptyStyle(span.style)) {
                            dismissedStyleIdReplacements.add(previousStyleId);
                            continue;
                        }

                        const currentStyleId = span.styleId;
                        if (!currentStyleId) {
                            continue;
                        }

                        const replacementStyleId = previousStyleIdUpdateCandidates.get(previousStyleId);
                        if (!replacementStyleId) {
                            previousStyleIdUpdateCandidates.set(previousStyleId, currentStyleId);
                            previousStyle.set(previousStyleId, cloneDeep(span.style));
                        } else if (currentStyleId !== replacementStyleId) {
                            dismissedStyleIdReplacements.add(previousStyleId);
                        }
                    }

                    for (const [
                        previousStyleId,
                        replacementStyleId
                    ] of previousStyleIdUpdateCandidates.entries()) {
                        if (dismissedStyleIdReplacements.has(previousStyleId)) {
                            continue;
                        }
                        if (
                            previousStyleId !== replacementStyleId &&
                            characterStyles.has(previousStyleId)
                        ) {
                            const style = previousStyle.get(previousStyleId)!;
                            styleUpdates.set(previousStyleId, style);
                        }
                    }
                    depthIndex++;
                }

                const updatedStyleIds: string[] = [];
                updateLatestRowOnPreviousStyleTable(updatedStyleIds);

                // If updated style ids is not set previously, set it.
                // TODO: Don't know if this is necessary anymore.
                for (const styleId of updatedStyleIds) {
                    for (const span of contentSpans) {
                        if (lastPreviousStyleId(span) === styleId) {
                            span.__previousStyleIds![depthLength] = styleId;
                            span.__previousStyleIdToHistoryIndexMap!.set(styleId, depthLength);
                        }
                    }
                }

                /**
                 * Update the latest row on the previous style table.
                 * This function adds the styleId of each span to the __previousStyleIds array,
                 * and updates the __previousStyleIdToHistoryIndexMap accordingly.
                 */
                function updateLatestRowOnPreviousStyleTable(updatedStyleIds: string[]): void {
                    for (const span of contentSpans) {
                        const previousStyleId = lastPreviousStyleId(span);

                        if (!span.styleId) {
                            if (lastPreviousStyleId(span, true) === PreviousStyleIdType.Undefined) {
                                // There should be no reason to store two undefined ids in a row.
                                span.__previousStyleIds!.push(PreviousStyleIdType.Undefined);
                            }
                            continue;
                        }

                        if (span.styleId === previousStyleId) {
                            span.__previousStyleIds!.push(PreviousStyleIdType.Unchanged);
                        } else {
                            const index = span.__previousStyleIds!.length;
                            span.__previousStyleIdToHistoryIndexMap!.set(span.styleId, index);
                            span.__previousStyleIds!.push(span.styleId);
                            updatedStyleIds.push(span.styleId);
                        }
                    }
                }
            }

            /**
             * Gets last previous style id from a span.
             * @param span - The span to get the last previous style id from.
             * @param includeTypes - Include the 'Unchanged' and 'Undefined' types.
             */
            function lastPreviousStyleId(
                span: OneOfContentSpans,
                includeTypes?: boolean
            ): string | PreviousStyleIdType | undefined {
                const orderedPreviousStyleIds = span.__previousStyleIds!.slice().reverse();
                const styleId = orderedPreviousStyleIds.find(
                    styleId => !self.isPrevStyleIdUnchangedOrUndefined(styleId) || includeTypes
                );

                return styleId;
            }

            function hasSameStyleIdInOtherVersions(styleId: string): boolean {
                let hasSameStyleInOtherVersions = false;
                outer: for (const version of versions) {
                    if (version.id === currentVersionId) {
                        continue;
                    }
                    for (const property of version.properties) {
                        const value = property.value as IVersionedText;
                        if (property.id === contentProperty.versionPropertyId) {
                            for (const span of value.styles) {
                                if (span.type === SpanType.Word || span.type === SpanType.Space) {
                                    if (styleId === span.styleIds[documentId]) {
                                        hasSameStyleInOtherVersions = true;
                                        break outer;
                                    }
                                }
                            }
                            continue outer;
                        }
                    }
                }
                return hasSameStyleInOtherVersions;
            }

            function appendUpdateSequence(styleId: string, update: IStyleUpdateSequence): void {
                let updateSequence = styleUpdateSequence.get(styleId);
                if (updateSequence) {
                    updateSequence.push(update);
                } else {
                    updateSequence = [update];
                    styleUpdateSequence.set(styleId, updateSequence);
                }
            }

            function getStyleIdByStyle(style: Partial<ICharacterProperties>): string | undefined {
                const hash = getHashFromStyle(style);
                const styleId = styleHashMap.get(hash);
                if (!styleId) {
                    return undefined;
                }
                return styleId;
            }
        }
    }

    private isPrevStyleIdUnchangedOrUndefined(
        prevStyleId: PreviousStyleId | undefined
    ): prevStyleId is PreviousStyleIdType {
        return (
            prevStyleId === PreviousStyleIdType.Unchanged ||
            prevStyleId === PreviousStyleIdType.Undefined
        );
    }

    /**
     * Removes a style ID from the previous style IDs of each content span in the text.
     * @param styleId - The style ID to be removed.
     */
    private removeStyleIdFromPreviousStyleIds(styleId: string): void {
        const contentSpans = this.text.spans_m.filter(isContentSpan);
        for (const span of contentSpans) {
            span.__previousStyleIds = span.__previousStyleIds!.map(previousStyleId => {
                return previousStyleId === styleId ? PreviousStyleIdType.Undefined : previousStyleId;
            });
        }
    }

    private pruneCharacterStyles(): void {
        const duplicateStyles = new Map</* styleId */ string, /* styleIds */ string[]>();
        const duplicateStyleHashMap = new Map</* styleHash */ string, /* styleId */ string>();
        const usedStyleIds = new Set<string /* styleId */>();
        const contentSpans = this.spans.filter(isContentSpan);

        for (const span of contentSpans) {
            if (span.styleId) {
                usedStyleIds.add(span.styleId);
            }
        }

        // Delete duplicate character styles
        for (const [styleId, style] of this.characterStylesMap.entries()) {
            if (isEmptyStyle(style)) {
                this.characterStylesMap.delete(styleId);
                continue;
            }
            const hash = getHashFromStyle(style);
            const hashMapStyleId = duplicateStyleHashMap.get(hash);
            if (hashMapStyleId) {
                const styleIds = duplicateStyles.get(hashMapStyleId);
                if (styleIds) {
                    styleIds.push(styleId);
                } else {
                    duplicateStyles.set(hashMapStyleId, [styleId]);
                }
            } else {
                this.styleHashMap.set(hash, styleId);
                duplicateStyleHashMap.set(hash, styleId);
            }
        }

        // Delete duplicate character styles in other versions
        if (this.shouldUpdateElementAndVersions) {
            const allVersionProperties = this.versions.flatMap(version => version.properties);

            for (const property of allVersionProperties) {
                if (property.name !== 'content' || !isVersionedText(property)) {
                    continue;
                }

                for (let span of property.value.styles) {
                    for (const [replacementStyleId, replacableStyleIds] of duplicateStyles.entries()) {
                        const styleId = span.styleIds[this.document.id];
                        if (styleId && replacableStyleIds.includes(styleId)) {
                            // readonly property and cannot be reassigned
                            span = cloneDeep(span);
                            span.styleIds[this.document.id] = replacementStyleId;
                        }
                    }

                    // Record used styles
                    if (isVersionedTextContentSpan(span)) {
                        const styleId = span.styleIds[this.document.id];
                        if (styleId) {
                            usedStyleIds.add(styleId);
                        }
                    }
                }
            }
        }

        // Prune unused styles
        for (const [styleId, style] of this.characterStylesMap.entries()) {
            if (!usedStyleIds.has(styleId)) {
                this.characterStylesMap.delete(styleId);
                this.deleteStyleHashMapEntryByStyle(style);
            }
        }
    }

    private deleteStyleHashMapEntryByStyle(style: Partial<ICharacterProperties>): void {
        const hash = getHashFromStyle(style);
        this.styleHashMap.delete(hash);
    }

    private updateElementCharacterStyles(
        updateStyleIdsAndGetStyleUpdateDirective: (
            spans: OneOfEditableSpans[],
            documentId: string,
            characterStyles: ICharacterStylesMap,
            styleHashMap: Map<string, string>
        ) => IStyleUpdateDirective
    ): void {
        const updateDirective = updateStyleIdsAndGetStyleUpdateDirective(
            this.spans,
            this.document.id,
            this.characterStylesMap,
            this.styleHashMap
        );

        for (const [styleId, style] of updateDirective.additions.entries()) {
            this.characterStylesMap.set(styleId, cloneDeep(style));
            this.styleHashMap.set(getHashFromStyle(style), styleId);
        }

        for (const [styleId, style] of updateDirective.updates.entries()) {
            const oldStyle = this.characterStylesMap.get(styleId)!;
            if (oldStyle) {
                this.styleHashMap.delete(getHashFromStyle(oldStyle));
            }
            this.characterStylesMap.set(styleId, cloneDeep(style!));
            this.styleHashMap.set(getHashFromStyle(style!), styleId);
        }

        for (const styleId of updateDirective.deletions) {
            const oldStyle = this.characterStylesMap.get(styleId)!;
            if (!oldStyle) {
                continue;
            }
            this.characterStylesMap.delete(styleId);
            this.deletedStyleHashMap.set(getHashFromStyle(oldStyle), styleId);
            this.styleHashMap.delete(getHashFromStyle(oldStyle));
        }
    }

    private removeSubStyles(): void {
        for (const [styleId, style] of this.characterStylesMap) {
            if (isSubStyleOf(this.style, style)) {
                this.characterStylesMap.delete(styleId);
            }
        }
    }

    private updateStyleIds(): void {
        const contentSpans = this.spans.filter(isContentSpan);
        for (const span of contentSpans) {
            this.pruneExcessiveStyleProperties(span.style);
            if (isEmptyStyle(span.style)) {
                delete span.styleIds[this.document.id];
                span.styleId = undefined;
            } else {
                // Ignore variable, it will differ, since changes are applied to the version property
                // and not to other documents' characterStyles
                const styleHash = getHashFromStyle(span.style);
                const styleId = this.styleHashMap.get(styleHash);
                if (styleId) {
                    span.styleIds[this.document.id] = styleId;
                    span.styleId = styleId;
                } else {
                    const styleId = uuidv4();
                    this.styleHashMap.set(styleHash, styleId);
                    this.characterStylesMap.set(styleId, span.style);
                    span.styleIds[this.document.id] = styleId;
                    span.styleId = styleId;
                }
            }
        }
        if (this.shouldUpdateElementAndVersions) {
            this.updateStyleIdsInOtherVersions();
        }

        this.mergeSpans();
    }

    private updateStyleIdsInOtherVersions(): void {
        for (const version of this.versions) {
            if (version.id === this.currentVersionId) {
                continue;
            }
            let versionValue = version.properties.find(
                ({ id }) => id === this.contentProperty.versionPropertyId
            );
            if (!versionValue) {
                continue;
            }

            versionValue = cloneDeep(versionValue);
            const versionedText = versionValue.value as IVersionedText;
            for (const span of versionedText.styles) {
                const characterStylesMap = this.characterStylesMap;
                const styleId = span.styleIds[this.document.id];
                if (!styleId) {
                    continue;
                }
                const spanStyle = characterStylesMap.get(styleId);
                this.pruneExcessiveStyleProperties(spanStyle);

                if (!spanStyle || isEmptyStyle(spanStyle)) {
                    delete span.styleIds[this.document.id];
                } else {
                    const styleHash = getHashFromStyle(spanStyle);
                    const styleId = this.styleHashMap.get(styleHash);
                    if (styleId) {
                        span.styleIds[this.document.id] = styleId;
                    } else {
                        const styleId = uuidv4();
                        this.styleHashMap.set(styleHash, styleId);
                        characterStylesMap.set(styleId, spanStyle);
                        span.styleIds[this.document.id] = styleId;
                    }
                }
            }
            this.editor.updateVersionProperty(version.id, versionValue);
        }
    }

    private allSpansAreStyledInAllVersions(): boolean {
        const contentSpans = this.spans.filter(isContentSpan);
        for (const span of contentSpans) {
            if (!span.styleId || isVariableSpan(span)) {
                // We shouldn't promote first span if not all spans are styled or if it's a variable.
                return false;
            }
        }

        for (const version of this.versions) {
            if (version.id === this.currentVersionId) {
                continue;
            }
            const versionProperties = version.properties;
            const versionValue = versionProperties.find(
                ({ id }) => id === this.contentProperty.versionPropertyId
            );

            if (!versionValue) {
                continue;
            }

            const versionedText = versionValue.value as IVersionedText;
            for (const span of versionedText.styles) {
                if (isVersionedTextContentSpan(span)) {
                    const styleId = span.styleIds[this.document.id];
                    if (Object.keys(span.styleIds).length === 0 || !styleId) {
                        // We shouldn't promote first span if not all spans are styled.
                        return false;
                    }
                }
            }
        }
        return true;
    }

    /**
     * Prune style properties that has the same value as element styles.
     */
    private pruneExcessiveStyleProperties(style?: Partial<ICharacterProperties>): void {
        if (!style) {
            return;
        }

        const styleProperties = omit(style, 'variable', '__fontFamilyId');
        for (const property in styleProperties) {
            if (hasSameStyleProperty(property as CharacterPropertyKeys, style, this.style)) {
                delete style[property];
            }
        }
    }

    private mergeSpans(): void {
        let lastSpan: OneOfEditableSpans | undefined;
        const newSpans: OneOfEditableSpans[] = [];
        for (const span of this.spans) {
            if (span.content === '') {
                continue;
            } else if (lastSpan && isSpanMergeable_m(lastSpan, span)) {
                lastSpan.content += span.content;
                continue;
            }

            lastSpan = { ...span };
            newSpans.push(lastSpan);
        }
        this.spans = newSpans;
    }
}

__parent(RichTextEditorStyleResolver, 'text', 0);
