import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UIDropdownComponent, UIModule } from '@bannerflow/ui';
import { firstValueFrom } from 'rxjs';
import { CreativesetDataService } from '../../creativeset/creativeset.data.service';
import { FontValidationService } from '../../services';
import { VideoValidationService } from '../../services/video-validation.service';
import { UserSettingsService } from '../../user-settings/state/user-settings.service';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule],
    selector: 'manage-warnings-dropdown',
    templateUrl: './manage-warnings-dropdown.component.html',
    styleUrls: ['./manage-warnings-dropdown.component.scss']
})
export class ManageWarningsDropdownComponent implements OnInit {
    @ViewChild('dropdown', { static: true }) dropdown: UIDropdownComponent;
    @Input() isManageView = false;

    showIcon = true;
    showVideoIcon = true;

    constructor(
        private creativesetDataService: CreativesetDataService,
        private userSettingsService: UserSettingsService,
        private fontValidationService: FontValidationService,
        private videoValidationService: VideoValidationService
    ) {}

    ngOnInit(): void {
        this.updateIcons();
    }

    updateIcons(): void {
        this.updateShowIcon();
        this.updateVideoShowIcon();
    }

    private async updateShowIcon(): Promise<void> {
        const ignoredResults = await firstValueFrom(this.userSettingsService.fontValidationWarnings$);
        const ignoredCreativesets = new Set(
            ignoredResults.map(validateState => validateState.creativesetId)
        );
        this.showIcon = !ignoredCreativesets.has(this.creativesetDataService.creativeset.id);
    }

    private async updateVideoShowIcon(): Promise<void> {
        const ignoredResults = await firstValueFrom(this.userSettingsService.videoValidationWarnings$);
        const ignoredElements = ignoredResults.map(validateState => validateState.id);

        this.showVideoIcon = !ignoredElements.some(
            ignoredElementId => this.creativesetDataService.creativeset.id === ignoredElementId
        );
    }

    clearFontValidationWarnings(): void {
        this.fontValidationService.clearSuppressedWarnings();
        this.showIcon = true;
    }

    clearVideoValidationWarnings(): void {
        this.videoValidationService.clearSuppressedWarnings(this.creativesetDataService.creativeset.id);
        this.showVideoIcon = true;
    }

    clearAllValidationWarnings(): void {
        this.clearVideoValidationWarnings();
        this.clearFontValidationWarnings();
        this.showIcon = true;
    }
}
