import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    Signal
} from '@angular/core';
import { Logger } from '@bannerflow/sentinel-logger';
import { Color } from '@creative/color';
import { DEFAULT_VIDEO_SETTINGS } from '@creative/elements/video/video';
import { isHidden } from '@creative/nodes/helpers';
import { IVideoRenderer } from '@domain/creative/elements/video/video-renderer.header';
import { AssetReference, IVideoElementAsset } from '@domain/creativeset/element-asset';
import { ElementKind } from '@domain/elements';
import { FeededReference, IFeed } from '@domain/feed';
import { IVideoElementDataNode, IVideoViewElement, OneOfElementDataNodes } from '@domain/nodes';
import { IState } from '@domain/state';
import {
    IPlaybackButton,
    IVideoSettings,
    IVideoStreamingSettings,
    PlaybackRate,
    VideoSizeMode
} from '@domain/video';
import { EventLoggerService, VideoPropertyChangeEvent } from '@studio/monitoring/events';
import { createElementProperty } from '@studio/utils/element.utils';
import { uuidv4 } from '@studio/utils/id';
import { clamp, decimal, deepEqual } from '@studio/utils/utils';
import { merge, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ColorService } from '../../../../shared/services/color.service';
import { UserService } from '../../../../shared/user/state/user.service';
import {
    EditorEventService,
    EditorSaveStateService,
    EditorStateService,
    ElementReplaceService,
    HistoryService
} from '../../services';
import { ElementChangeType } from '../../services/editor-event/element-change';
import { MutatorService } from '../../services/mutator.service';
import { AssetPropertyContext, IFeedChange } from '../asset-property/asset-property';
import { IMixedProperty } from '../index';
import { createMixedProperty } from '../mixed-properties';
import { PropertiesService } from '../properties.service';

@Component({
    selector: 'video-properties',
    templateUrl: './video-properties.component.html',
    styleUrls: ['../common.scss', './video-properties.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPropertiesComponent implements OnInit, OnDestroy {
    @Input() elements$: Observable<IVideoElementDataNode[]>;
    private elements: IVideoElementDataNode[];
    private viewElements: Array<IVideoViewElement | undefined>;
    stateOrElement: IState | OneOfElementDataNodes;
    AssetPropertyContext = AssetPropertyContext;
    autoplay = createMixedProperty<boolean | undefined>(false);
    streaming = createMixedProperty<IVideoStreamingSettings | undefined>({
        ...DEFAULT_VIDEO_SETTINGS.streaming
    });
    stopWithCreative = createMixedProperty<boolean | undefined>(false);
    loop = createMixedProperty<boolean | undefined>(true);
    restartWithCreative = createMixedProperty<boolean | undefined>(true);
    asset = createMixedProperty<IVideoElementAsset | undefined>();
    playbackRate = createMixedProperty<PlaybackRate | undefined>(1);
    playbackButton = createMixedProperty<IPlaybackButton | undefined>({
        ...DEFAULT_VIDEO_SETTINGS.playbackButton
    });
    startTime = createMixedProperty<number | undefined>(0);
    endTime = createMixedProperty<number | undefined>();
    sizeMode = createMixedProperty<VideoSizeMode | undefined>(VideoSizeMode.Fit);
    maxEndTime: number;
    feed = createMixedProperty<IFeed>();
    VideoSizeMode = VideoSizeMode;
    ElementChangeType = ElementChangeType;
    ElementKind = ElementKind;
    volume = createMixedProperty<number | undefined>(0);
    isEmployee: Signal<boolean>;

    private logger = new Logger('VideoPropertiesComponent');
    private unsubscribe$ = new Subject<void>();

    constructor(
        private changeDetector: ChangeDetectorRef,
        private propertiesService: PropertiesService,
        private mutationService: MutatorService,
        private colorService: ColorService,
        private historyService: HistoryService,
        private editorEvent: EditorEventService,
        private editorStateService: EditorStateService,
        private eventLoggerService: EventLoggerService,
        private elementReplaceService: ElementReplaceService,
        private editorSaveStateService: EditorSaveStateService,
        private userService: UserService
    ) {
        this.propertiesService
            .observeDataElementOrStateChange()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(({ state, element }) => {
                this.stateOrElement = state || element;
            });

        this.isEmployee = this.userService.getIsEmployee();
    }

    ngOnInit(): void {
        this.elements$
            .pipe(
                filter(elements => !!elements.length),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(elements => {
                this.elements = elements;
                this.viewElements = this.elements
                    .filter(element => !isHidden(element))
                    .map(element => this.mutationService.renderer.getViewElementById(element.id)); // element can be undefined
                this.updateProperties();
            });

        merge(this.historyService.onChange$, this.editorEvent.elements.immediateChange$)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.updateProperties();
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private updateProperties(): void {
        this.setDurationProperties();
        this.volume = this.getUpdatedMixedProperty('volume');
        this.sizeMode = this.getUpdatedMixedProperty('sizeMode');
        this.autoplay = this.getUpdatedMixedProperty('autoplay');
        this.streaming = this.getUpdatedMixedProperty('streaming');
        this.loop = this.getUpdatedMixedProperty('loop');
        this.stopWithCreative = this.getUpdatedMixedProperty('stopWithCreative');
        this.playbackButton = this.getUpdatedMixedProperty('playbackButton');
        this.restartWithCreative = this.getUpdatedMixedProperty('restartWithCreative');

        this.playbackRate = this.getUpdatedMixedProperty('playbackRate');

        this.changeDetector.detectChanges();
    }

    private getUpdatedMixedProperty<Property extends keyof IVideoSettings>(
        property: Property
    ): IMixedProperty<IVideoSettings[Property] | undefined> {
        let isMixed = false;

        if (typeof DEFAULT_VIDEO_SETTINGS[property] !== 'object') {
            isMixed = new Set(this.elements.map(el => el.videoSettings[property])).size > 1;
        }

        if (!isMixed && this.elements.length > 1) {
            const propertyValues = this.elements.map(el => el.videoSettings[property]);

            for (const propertyA of propertyValues) {
                for (const propertyB of propertyValues) {
                    if (!deepEqual(propertyA, propertyB)) {
                        isMixed = true;
                        break;
                    }
                }
                if (isMixed) {
                    break;
                }
            }
        }

        const value =
            this.elements.length > 1 && isMixed ? undefined : this.elements[0].videoSettings[property];
        return createMixedProperty(value, isMixed);
    }

    private setVideoSettingProperty<Property extends keyof IVideoSettings>(
        property: Property,
        value: IVideoSettings[Property],
        changeType?: ElementChangeType
    ): void {
        this.elements.forEach(element => {
            const settings = { ...element.videoSettings } as IVideoSettings;

            // Make sure we don't set values that exceed the video's duration
            if (property === 'startTime' || property === 'endTime') {
                const viewElement = this.viewElements.find(ve => ve?.id === element.id)!;
                const maxValue = isNaN(viewElement.__videoRenderer.duration_m)
                    ? element.duration
                    : viewElement.__videoRenderer.duration_m;
                (value as number) = clamp(0, isNaN(value as number) ? 0 : (value as number), maxValue);
            }

            settings[property] = value;

            this.mutationService.setElementPropertyValue(
                element,
                'videoSettings',
                settings,
                changeType
            );
        });

        this.changeDetector.detectChanges();
    }

    private setDurationProperties(): void {
        this.maxEndTime = 0;

        for (const element of this.viewElements) {
            if (!element) {
                continue;
            }
            const duration = decimal(element.__videoRenderer.duration_m);

            if (isNaN(duration)) {
                this.getVideoDurationWhenVideoIsLoaded(element.__videoRenderer);
            } else {
                this.maxEndTime = duration;
                this.setStartAndEndTime();
            }
        }
    }

    private getVideoDurationWhenVideoIsLoaded(videoRenderer: IVideoRenderer): void {
        const videoDOMElement = videoRenderer.videoDOMElement;
        const eventName = 'loadedmetadata';
        const listener = (): void => {
            videoDOMElement.removeEventListener(eventName, listener);
            this.maxEndTime = decimal(videoRenderer.duration_m);
            this.setStartAndEndTime();
        };

        videoDOMElement.addEventListener(eventName, listener);
    }

    private setStartAndEndTime(): void {
        this.startTime = this.getUpdatedMixedProperty('startTime');
        if (!this.startTime.value) {
            this.startTime.value = DEFAULT_VIDEO_SETTINGS.startTime;
        }
        if (this.startTime.value > this.maxEndTime) {
            this.startTime.value = 0;
            this.setVideoSettingProperty('startTime', 0);
        }
        this.endTime = this.getUpdatedMixedProperty('endTime');
        if (!this.endTime.value || this.endTime.value > this.maxEndTime) {
            this.endTime.value = undefined;
        }
    }

    onSizeModeChange(sizeMode: VideoSizeMode): void {
        this.sizeMode.value = sizeMode;
        this.setVideoSettingProperty('sizeMode', sizeMode);
    }

    onStreamingChange(streamingEnabled: boolean): void {
        this.streaming.value ??= { ...DEFAULT_VIDEO_SETTINGS.streaming };
        this.streaming.value.enabled = streamingEnabled;
        this.setVideoSettingProperty('streaming', this.streaming.value);
        this.setVideoSettingProperty('optimization', this.streaming.value); // optimization is dependent on streaming
    }

    onAutoplayChange(autoplay: boolean): void {
        this.autoplay.value = autoplay;
        this.setVideoSettingProperty('autoplay', autoplay);
        if (!this.playbackButton.value) {
            this.playbackButton.value = { ...DEFAULT_VIDEO_SETTINGS.playbackButton };
        }
        this.playbackButton.value.enabled = !autoplay;
        this.setVideoSettingProperty('playbackButton', this.playbackButton.value);
    }

    onLoopChange(value: boolean): void {
        this.loop.value = value;
        this.setVideoSettingProperty('loop', value);
    }

    onRestartWithCreativeChange(value: boolean): void {
        this.restartWithCreative.value = value;
        this.setVideoSettingProperty('restartWithCreative', value);
    }

    onPlaybackButtonToggle(value: boolean): void {
        if (!this.playbackButton.value) {
            this.playbackButton.value = { ...DEFAULT_VIDEO_SETTINGS.playbackButton };
        }
        this.playbackButton.value.enabled = value;
        this.setVideoSettingProperty('playbackButton', this.playbackButton.value);

        this.autoplay.value = !value;
        this.setVideoSettingProperty('autoplay', this.autoplay.value);
    }

    onPlaybackButtonSizeChange(
        value: number,
        changeType: ElementChangeType = ElementChangeType.Burst
    ): void {
        if (!this.playbackButton.value) {
            this.playbackButton.value = DEFAULT_VIDEO_SETTINGS.playbackButton;
        }
        this.playbackButton.value.size = value;
        this.setVideoSettingProperty('playbackButton', this.playbackButton.value, changeType);
    }

    onPlaybackButtonColorChange(
        value: Color,
        changeType: ElementChangeType = ElementChangeType.Burst
    ): void {
        if (!this.playbackButton.value) {
            this.playbackButton.value = { ...DEFAULT_VIDEO_SETTINGS.playbackButton };
        }
        this.playbackButton.value.color = value;
        this.setVideoSettingProperty('playbackButton', this.playbackButton.value, changeType);
    }

    onPlaybackButtonColorPreview(value: Color): void {
        if (!this.playbackButton.value) {
            this.playbackButton.value = { ...DEFAULT_VIDEO_SETTINGS.playbackButton };
        }
        this.setVideoSettingProperty(
            'playbackButton',
            {
                ...this.playbackButton.value,
                color: value
            },
            ElementChangeType.Skip
        );
    }

    onStopWithCreativeChange(value: boolean): void {
        this.stopWithCreative.value = value;
        this.setVideoSettingProperty('stopWithCreative', value);
    }

    onStartTimeChange(value: number): void {
        this.startTime.value = value;
        this.setVideoSettingProperty('startTime', value);
    }

    onEndTimeChange(value: number | undefined): void {
        value = typeof value === 'number' ? clamp(0, value, this.maxEndTime) : value;

        this.endTime.value = value === 0 ? undefined : value;

        if (value === undefined) {
            value = 0;
        }

        this.setVideoSettingProperty('endTime', value);
    }

    onPlaybackRateChange(value: PlaybackRate): void {
        this.playbackRate.value = value;
        this.setVideoSettingProperty('playbackRate', value);
    }

    async onFeedSettingsChanged(event: IFeedChange): Promise<void> {
        this.feed.value = event.feed;
        this.replaceFeededVideo(event);

        if (event.replaceAll) {
            this.editorSaveStateService.save({
                saveAll: true,
                saveAndExit: false
            });
        }
    }

    private replaceFeededVideo(event: IFeedChange): void {
        for (const element of this.elements) {
            this.eventLoggerService.log(
                new VideoPropertyChangeEvent('feed', element.feed, event.feed),
                this.logger
            );

            this.elements.forEach(el => {
                this.mutationService.setElementPropertyValue(el, 'feed', event.feed);
            });

            if (event.replaceAll) {
                this.elementReplaceService.replaceFeededMediaInAllDesigns(element, event.feed);
            } else {
                this.elementReplaceService.replaceFeededMediaInDesign(
                    element,
                    event.feed,
                    this.editorStateService.designFork
                );
            }

            const editorElement = this.editorStateService.getElementById(element.id);

            const videoReference = editorElement.properties.find(
                prop => prop.name === AssetReference.Video
            );
            const feededVideoReference = editorElement.properties.find(
                prop => prop.name === FeededReference.Video
            );
            const feedId = `${event.feed.id}.${event.feed.path}`;

            if (feededVideoReference) {
                feededVideoReference.value = feedId;
            } else {
                editorElement.properties.push(
                    createElementProperty({
                        clientId: uuidv4(),
                        name: FeededReference.Video,
                        unit: 'id',
                        value: feedId
                    })
                );
            }

            if (videoReference) {
                editorElement.properties = editorElement.properties.filter(
                    prop => prop.name !== AssetReference.Video
                );
            }

            element.parentId = undefined;
        }
    }

    toggleColorPicker(): void {
        this.colorService.toggleColorPicker('playbackButtonColor');
    }

    onVolumeChange(value: number, changeType: ElementChangeType = ElementChangeType.Burst): void {
        this.setVideoSettingProperty('volume', value / 100, changeType);
    }

    undo(): void {
        this.historyService.undo$.next();
    }

    redo(): void {
        this.historyService.redo$.next();
    }

    getMaxDurationInputValue(): number {
        const MAX_ALLOWED_INPUT_TIME = 9999999;
        return this.maxEndTime > MAX_ALLOWED_INPUT_TIME ? MAX_ALLOWED_INPUT_TIME : this.maxEndTime;
    }
}
