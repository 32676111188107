import { InjectionToken } from '@angular/core';

export type FeatureFlagsConfig =
    | { enabled: false }
    | {
          enabled: true;
          url: string;
          clientKey: string;
          appName: string;
      };

export enum StudioFeatureFlags {
    SizeCollections = 'StudioClient-SizeCollections',
    // check for SizeCollections to be disabled
    SizeCollectionsDisabled = '!StudioClient-SizeCollections'
}

export const FEATURE_FLAGS_CONFIG = new InjectionToken<FeatureFlagsConfig>('FeatureFlags config');
