import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';
import { filterExistingLocalizations } from '../brand.utils';
import * as BrandActions from './brand.actions';
import * as BrandSelectors from './brand.selectors';

@Injectable({ providedIn: 'root' })
export class BrandService {
    private readonly store = inject(Store);

    loaded$ = this.store.select(BrandSelectors.getBrandLoaded);
    error$ = this.store.select(BrandSelectors.getBrandError);
    brand$ = this.store.pipe(BrandSelectors.getBrandWhenLoaded, take(1));
    brandId$ = this.store.select(BrandSelectors.getBrandId).pipe(filter(Boolean));
    accountSlug$ = this.store.select(BrandSelectors.getAccountSlug).pipe(filter(Boolean));
    slug$ = this.store.select(BrandSelectors.getBrandSlug).pipe(filter(Boolean));

    brandSizes$ = this.store.select(BrandSelectors.getBrandSizes);
    brandSizeGroups$ = this.store.select(BrandSelectors.getBrandSizeGroups);
    brandPalettes$ = this.store.select(BrandSelectors.getBrandPalettes);
    brandPalettes = this.store.selectSignal(BrandSelectors.getBrandPalettes);
    localizations$ = this.store.select(BrandSelectors.getBrandLocalizations);
    existingLocalizations$ = this.localizations$.pipe(map(filterExistingLocalizations));
    name$ = this.store.select(BrandSelectors.getBrandName);
    brandLogoUrl$ = this.store.select(BrandSelectors.getBrandLogoUrl);

    loadBrand(brandId: string): void {
        this.store.dispatch(BrandActions.loadActions.loadBrand({ brandId }));
    }
}
