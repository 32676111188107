import { isNewlineLikeCharacter, isSpaceLikeCharacter } from '@creative/elements/rich-text/text-nodes';
import { ITextSpan, IVersionedText } from '@domain/creativeset/version';
import { IStyleIdMap, SpanType } from '@domain/text';
import { IMatValueChangeEvent } from '@studio/domain/components/translation-page';
import { deepEqual } from '@studio/utils/utils';

/**
 * Handle 3 types of edition:
 * * 'add': When user writes or pastes some text (no selected text)
 * * 'delete': When user deletes a character or a selection
 * * 'replace': When the user writes/pastes some text after selecting some content
 *
 * Behavior is described in: https://docs.google.com/document/d/13v04-c2ReQmK8fKOwDQjTifE2i0TRofEVrCOB7kHWfY/edit
 * It is meant to imitate Google Doc's behavior
 * */
export function applyModificationToVersionProperty(
    modification: IMatValueChangeEvent,
    versionedText: IVersionedText
): IVersionedText {
    let result: IVersionedText;
    switch (modification.type) {
        case 'replace':
            result = handleReplace(modification, versionedText);
            break;
        case 'delete':
            result = handleDelete(modification, versionedText);
            break;
        case 'add':
        default:
            result = handleAddition(modification, versionedText);
            break;
    }

    result = {
        text: result.text,
        styles: mergeSpans(result.styles)
    };
    try {
        verifyVersionedText(result);
    } catch (e) {
        console.error(e);
    }
    return result;
}

function handleReplace(
    modification: IMatValueChangeEvent,
    versionedText: IVersionedText
): IVersionedText {
    const newStyles: ITextSpan[] = [];
    const delta = modification.newValue.length - modification.oldValue.length;
    let replaceDone = false;

    for (const span of versionedText.styles) {
        // span outside selection - before
        if (span.position + span.length <= modification.selection.start) {
            newStyles.push(span);
            continue;
        }
        // span outisde selection - after
        if (span.position >= modification.selection.end) {
            newStyles.push({
                ...span,
                position: span.position + delta
            });
            continue;
        }
        // span partially inside selection - beginning - split - replace should use this span
        if (
            span.position < modification.selection.start &&
            span.position + span.length > modification.selection.start
        ) {
            newStyles.push({
                ...span,
                length: modification.selection.start - span.position
            });
        }

        if (!replaceDone) {
            // Create and append newSpans. Once
            const newText = getNewTextFromReplacement(modification);
            const newTextSpans = createSpansFromString(
                newText,
                span.styleIds,
                modification.selection.start
            );
            newStyles.push(...newTextSpans);
            replaceDone = true;
        }

        // span partially inside selection - end - split
        if (
            span.position < modification.selection.end &&
            span.position + span.length > modification.selection.end
        ) {
            newStyles.push({
                ...span,
                position: modification.selection.end + delta,
                length: span.position + span.length - modification.selection.end
            });
        }
    }
    return {
        text: modification.newValue,
        styles: newStyles
    };
}
function getNewTextFromReplacement(modification: IMatValueChangeEvent): string {
    const endIndex =
        modification.newValue.length - (modification.oldValue.length - modification.selection.end);
    return modification.newValue.substring(modification.selection.start, endIndex);
}
function handleDelete(
    modification: IMatValueChangeEvent,
    versionedText: IVersionedText
): IVersionedText {
    const newStyles: ITextSpan[] = [];
    const deltaDeletion = modification.oldValue.length - modification.newValue.length;
    for (const span of versionedText.styles) {
        const isSpanInsideSelection =
            span.position >= modification.selection.start &&
            span.position + span.length <= modification.selection.end;
        if (isSpanInsideSelection) {
            continue;
        }
        const isSpanBeforeSelection = span.position + span.length <= modification.selection.start;
        if (isSpanBeforeSelection) {
            // Span is completely before selection.
            // Remains untouched
            newStyles.push(span);
            continue;
        }
        const isSpanAfterSelection = span.position >= modification.selection.end;
        if (isSpanAfterSelection) {
            // Span is completely after selection.
            // Length remains, position needs to be updated
            newStyles.push({
                ...span,
                position: span.position - deltaDeletion
            });
            continue;
        }

        if (span.position < modification.selection.start) {
            const newLength = modification.selection.start - span.position;

            newStyles.push({
                ...span,
                length: newLength
            });
        }
        if (span.position + span.length >= modification.selection.end) {
            const newLength = span.position + span.length - modification.selection.end;
            const prevSpan = newStyles.at(-1);
            const newPosition = prevSpan ? prevSpan.position + prevSpan.length : 0;
            newStyles.push({
                ...span,
                position: newPosition,
                length: newLength
            });
        }
    }
    return {
        text: modification.newValue,
        styles: newStyles
    };
}
function handleAddition(
    modification: IMatValueChangeEvent,
    versionedText: IVersionedText
): IVersionedText {
    const newStyles: ITextSpan[] = [];
    const modificationLength = modification.newValue.length - modification.oldValue.length;
    const newText = modification.newValue.substring(
        modification.selection.start,
        modification.selection.start + modificationLength
    );
    let extraSpan: ITextSpan | undefined;
    // Add spans before modification
    for (const span of versionedText.styles) {
        if (span.position >= modification.selection.start) {
            break;
        }
        if (span.position + span.length <= modification.selection.start) {
            newStyles.push(span);
            continue;
        }
        const newLength = modification.selection.start - span.position;

        newStyles.push({
            ...span,
            length: newLength
        });
        // if needed to split a span, extraSpan is the second half
        if (span.length > newLength) {
            extraSpan = {
                ...span,
                position: span.position + modificationLength + newLength,
                length: span.length - newLength
            };
        }
    }

    // Use last added span as base. If adding at the beginning, use first span from previous text
    let lastSpan = newStyles.at(-1);
    if (!lastSpan && modification.selection.start === 0) {
        lastSpan = versionedText.styles.at(0);
    }
    const inheritedStyleIds = lastSpan?.styleIds ?? {};
    const stylesFromNewText = createSpansFromString(
        newText,
        inheritedStyleIds,
        modification.selection.start
    );

    newStyles.push(...stylesFromNewText);
    if (extraSpan) {
        newStyles.push(extraSpan);
    }

    // Add the rest of the spans
    for (const span of versionedText.styles) {
        if (span.position < modification.selection.start) {
            // Ignore first part of array
            continue;
        }
        newStyles.push({
            ...span,
            // move position
            position: span.position + modificationLength
        });
    }

    return {
        text: modification.newValue,
        styles: newStyles
    };
}

function createSpansFromString(
    newString: string,
    styleIds: IStyleIdMap,
    fromPosition: number
): ITextSpan[] {
    const result: ITextSpan[] = [];
    let index = fromPosition;
    for (const char of Array.from(newString)) {
        let spanType = SpanType.Word;
        if (isNewlineLikeCharacter(char.charCodeAt(0))) {
            spanType = SpanType.Newline;
        }
        if (isSpaceLikeCharacter(char.charCodeAt(0))) {
            spanType = SpanType.Space;
        }
        result.push({
            type: spanType,
            length: char.length,
            position: index,
            styleIds
        });
        index += char.length;
    }
    return mergeSpans(result);
}

function mergeSpans(spans: ITextSpan[]): ITextSpan[] {
    const newStyles: ITextSpan[] = [];
    for (const span of spans) {
        const lastSpan = newStyles.at(-1);
        if (!lastSpan) {
            newStyles.push(span);
            continue;
        }
        if (
            lastSpan.type === span.type &&
            deepEqual(lastSpan.styleIds, span.styleIds) &&
            deepEqual(lastSpan.variable, span.variable)
        ) {
            lastSpan.length += span.length;
            continue;
        }
        newStyles.push(span);
    }
    return newStyles;
}

function verifyVersionedText(versionedText: IVersionedText): void {
    for (let i = 1; i < versionedText.styles.length; i++) {
        const previousSpan = versionedText.styles[i - 1];
        const span = versionedText.styles[i];
        if (span.position !== previousSpan.position + previousSpan.length) {
            throw new Error('Invalid span position');
        }
    }
    const lastSpan = versionedText.styles.at(-1);

    if (!!lastSpan && versionedText.text.length !== lastSpan.length + lastSpan.position) {
        throw new Error(
            `Invalid last span. Text length:${versionedText.text.length}. LastSpan length+position:${lastSpan.length + lastSpan.position}`
        );
    }
}
