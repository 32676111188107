import { Injectable } from '@angular/core';
import { Logger } from '@bannerflow/sentinel-logger';
import { UINotificationService } from '@bannerflow/ui';
import { Color } from '@creative/color';
import { CreativeDataNode, createVersionedTextFromText, isGroupDataNode } from '@creative/nodes';
import { IDesign } from '@domain/creativeset';
import { ApprovalStatus, ICreative } from '@domain/creativeset/creative';
import { IElement } from '@domain/creativeset/element';
import { CreativeSize } from '@domain/creativeset/size';
import { IVersionProperty, IVersionedText } from '@domain/creativeset/version';
import { ElementKind } from '@domain/elements';
import { IFontStyle } from '@domain/font';
import { IFontFamily } from '@domain/font-families';
import { INodeWithChildren, OneOfDataNodes } from '@domain/nodes';
import { concatLatestFrom } from '@ngrx/operators';
import {
    PSDElement,
    PSDElementType,
    PSDErrorType,
    PSDGroupElement,
    PSDLayerElement,
    PSDRootElement,
    PSDTextElement,
    PSDVectorElement,
    getPSDErrorMessage
} from '@studio/domain/components/psd-import/psd';
import {
    EventLoggerService,
    PSDImportConversionEvent,
    PSDImportError
} from '@studio/monitoring/events';
import { createDesign } from '@studio/utils/design.utils';
import { createElement, createElementProperty } from '@studio/utils/element.utils';
import { uuidv4 } from '@studio/utils/id';
import { removeFileExtension } from '@studio/utils/url';
import { Observable, Subject, map } from 'rxjs';
import { FontFamiliesService } from '../../../shared/font-families/state/font-families.service';
import { VersionsService } from '../../../shared/versions/state/versions.service';
import { NodeCreatorService } from '../../design-view/services/data-node-creator.service';
import { PSDConversionError } from './conversion-errors';
import {
    getGroupDataFromPsdElement,
    getImageDataFromPsdElementData,
    getPossibleFontStyle,
    getRectangleDataFromPsdElement,
    getTextDataFromPsdElement
} from './creative-converter.utils';
import { PsdConverterService } from './psd/psd-converter.service';
import { isPSDGroupElement } from './psd/psd-reader';

interface ConvertedCreative {
    creative: ICreative;
    blueprint: PSDRootElement;
}

@Injectable()
export class CreativeConverterService {
    private _convertedCreative$ = new Subject<{
        size: CreativeSize;
        design: IDesign;
        blueprint: PSDRootElement;
    }>();
    // the default version is updated while creating texts, so we need to concat to get the latest value
    convertedCreative$: Observable<ConvertedCreative> = this._convertedCreative$.asObservable().pipe(
        concatLatestFrom(() => this.versionService.defaultVersion$),
        map(([{ size, design, blueprint }, defaultVersion]) => ({
            creative: {
                id: uuidv4(),
                version: defaultVersion,
                size,
                design,
                connectedCampaigns: [],
                approvalStatus: ApprovalStatus.None
            },
            blueprint
        }))
    );

    private logger = new Logger('CreativeConverterService');
    private fontFamilies: IFontFamily[];

    constructor(
        private nodeCreatorService: NodeCreatorService,
        private versionService: VersionsService,
        private psdConverterService: PsdConverterService,
        private uiNotificationService: UINotificationService,
        private eventLoggerService: EventLoggerService,
        private fontFamiliesService: FontFamiliesService
    ) {
        this.fontFamiliesService.nonDeletedBrandFontFamilies$.subscribe(fontFamilies => {
            this.fontFamilies = fontFamilies;
        });
    }

    readPSDFile(file: File): void {
        const reader = new FileReader();
        const fileName = file.name;
        reader.onload = (): void => {
            const arrayBuffer = reader.result as ArrayBuffer;
            this.convertPSDtoCreative(arrayBuffer, fileName);
        };

        try {
            reader.readAsArrayBuffer(file);
        } catch (error: unknown) {
            this.handleError(PSDErrorType.FileRead, error as Error);
        }
    }

    private convertPSDtoCreative(fileBuffer: ArrayBuffer, fileName: string): void {
        try {
            const tStart = performance.now();
            const blueprint = this.psdConverterService.convertPSDtoBlueprint(fileBuffer);
            this.createCreativeFromBlueprint(blueprint, fileName);

            const fileSize = fileBuffer.byteLength;
            const amountOfLayers = blueprint.data.flatChildren.length;
            const duration = Math.round(performance.now() - tStart);
            this.eventLoggerService.log(
                new PSDImportConversionEvent(fileSize, amountOfLayers, duration),
                this.logger
            );
        } catch (e: unknown) {
            if (e instanceof PSDConversionError) {
                this.handleError(e.type, e);
                return;
            }
            this.handleError(PSDErrorType.CreativeConversion, e as Error);
        }
    }

    private handleError(psdErrorType: PSDErrorType, error: Error): void {
        const psdImportError = new PSDImportError(psdErrorType, error.message);
        this.eventLoggerService.log(psdImportError, this.logger);
        this.showErrorNotification(getPSDErrorMessage(psdErrorType));
    }

    private showErrorNotification(errorMessage: string): void {
        this.uiNotificationService.open(errorMessage, {
            type: 'error',
            placement: 'top',
            autoCloseDelay: 5000
        });
    }

    private createCreativeFromBlueprint(rootElement: PSDRootElement, fileName: string): void {
        if (rootElement.type !== PSDElementType.Root) {
            throw new Error('Not a root element');
        }

        if (!rootElement.data.children || rootElement.data.children?.length === 0) {
            throw new Error('Root element does not contain any children');
        }

        const creativeWidth = rootElement.size.width;
        const creativeHeight = rootElement.size.height;

        const creativeDataNode = new CreativeDataNode({
            id: uuidv4(),
            width: creativeWidth,
            height: creativeHeight,
            fill: Color.parse('#ffffff')
        });

        const designElements = this.createDataNodesFromBlueprintElements(
            rootElement.data.children,
            creativeDataNode,
            creativeDataNode.id
        );

        const design = createDesign({
            elements: designElements,
            document: creativeDataNode
        });

        const sizeId = uuidv4();
        const size: CreativeSize = {
            id: sizeId,
            width: creativeWidth,
            height: creativeHeight,
            name: `${removeFileExtension(fileName)}-${sizeId.substring(0, 8)}`
        };

        this._convertedCreative$.next({ size, design, blueprint: rootElement });
    }

    private createDataNodesFromBlueprintElements(
        psdElements: PSDElement[],
        nodeOrGroup: INodeWithChildren,
        rootNodeId: string
    ): IElement[] {
        const elements: IElement[] = [];

        for (const element of psdElements) {
            // prevent errored elements to get into the creative
            if (element.error) {
                this.logger.warn(`Element ${element.name} has an error and will be skipped`);
                continue;
            }

            const extracted = this.extractNodeAndElement(element, rootNodeId);
            // skip unsupported elements
            if (!extracted) {
                continue;
            }
            nodeOrGroup.addNode_m(extracted.dataNode);
            elements.push(extracted.element);

            if (isPSDGroupElement(element) && isGroupDataNode(extracted.dataNode)) {
                const groupDesignElements = this.createDataNodesFromBlueprintElements(
                    extracted.children!,
                    extracted.dataNode,
                    rootNodeId
                );
                elements.push(...groupDesignElements);
            }
        }
        return elements;
    }

    private extractNodeAndElement(
        element: PSDElement,
        rootNodeId: string
    ): IExtractedElement | undefined {
        switch (element.type) {
            case PSDElementType.Root:
                this.logger.warn('Extracting a data node from root layer is impossible');
                return;
            case PSDElementType.Unknown:
                this.logger.verbose(`Extracting a data node from ${element.type} is not supported`);
                return;
            case PSDElementType.Text:
                return this.extractTextElement(element, rootNodeId);
            case PSDElementType.Layer:
                return this.extractLayerElement(element);
            case PSDElementType.Vector:
                return this.extractVectorElement(element);
            case PSDElementType.Group:
                return this.extractGroupElement(element);
            default:
                throw new Error(`Unsupported PSD element type: ${(element as PSDElement).type}`);
        }
    }

    private extractGroupElement(psdElement: PSDGroupElement): IExtractedElement {
        const data = getGroupDataFromPsdElement(psdElement);
        const dataNode = this.nodeCreatorService.create(ElementKind.Group, data);
        const element = this.createDesignElement(ElementKind.Group, dataNode.id, psdElement.name);

        return {
            dataNode,
            element,
            children: psdElement.data.children
        };
    }

    private extractVectorElement(psdElement: PSDVectorElement): IExtractedElement {
        const data = getRectangleDataFromPsdElement(psdElement);
        const dataNode = this.nodeCreatorService.create(ElementKind.Rectangle, data);
        const element = this.createDesignElement(ElementKind.Rectangle, psdElement.id, psdElement.name);

        return {
            dataNode,
            element
        };
    }

    private extractLayerElement(psdElement: PSDLayerElement): IExtractedElement {
        const data = getImageDataFromPsdElementData(psdElement);
        const dataNode = this.nodeCreatorService.create(ElementKind.Image, data);
        const element = this.createDesignElement(ElementKind.Image, psdElement.id, psdElement.name);

        return {
            dataNode,
            element
        };
    }

    private validateFont(psdElement: PSDTextElement, font?: IFontStyle): void {
        if (!font) {
            return;
        }
        const fontFamily = this.fontFamilies.find(({ id }) => id === font.fontFamilyId);
        const fontFamilyName = fontFamily?.name;
        const fontStyleName = fontFamily?.fontStyles.find(fontStyle => fontStyle.id === font.id)?.name;
        const fontName = `${fontFamilyName}-${fontStyleName}`; // we assume the name follows this format: <family>-<style>

        if (fontName !== psdElement.data.font && psdElement.data.font) {
            psdElement.error = new PSDConversionError(
                getPSDErrorMessage(PSDErrorType.OriginalFontNotFound, psdElement.data.font, fontName),
                PSDErrorType.OriginalFontNotFound,
                {
                    missingFontName: psdElement.data.font,
                    alternativeFontName: fontName
                }
            );
        }
    }

    private extractTextElement(psdElement: PSDTextElement, rootNodeId: string): IExtractedElement {
        const data = getTextDataFromPsdElement(psdElement, rootNodeId, this.fontFamilies);
        data.font = getPossibleFontStyle(psdElement.data.font, this.fontFamilies);

        this.validateFont(psdElement, data.font);

        const dataNode = this.nodeCreatorService.create(ElementKind.Text, data);
        const versionPropertyValue = createVersionedTextFromText(dataNode.content);
        const versionProperty = this.propertyAsVersionableProperty(versionPropertyValue);
        this.versionService.addVersionPropertiesToDefault(versionProperty);

        const element = this.createDesignElement(ElementKind.Text, psdElement.id, psdElement.name);
        const epv = createElementProperty({
            name: 'content',
            value: '',
            versionPropertyId: versionProperty.id
        });
        element.properties.push(epv);

        return {
            dataNode,
            element
        };
    }

    private propertyAsVersionableProperty(versionPropertyValue: IVersionedText): IVersionProperty {
        return {
            id: uuidv4(),
            name: 'content',
            value: versionPropertyValue
        };
    }

    private createDesignElement(kind: ElementKind, id: string, name: string): IElement {
        return createElement({
            id,
            type: kind,
            name: name,
            properties: []
        });
    }
}

interface IExtractedElement {
    element: IElement;
    dataNode: OneOfDataNodes;
    children?: PSDElement[];
}
