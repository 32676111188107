import { inject, Injectable } from '@angular/core';
import { UINotificationService } from '@bannerflow/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { EventLoggerService, TranslationPanelDoneErrorEvent } from '@studio/monitoring/events';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CreativeSetShowcaseDataService } from './creativeset-showcase.data.service';
import { creativeSetShowcaseActions } from './creativeset-showcase.actions';
import { getShowcaseByKey } from './creativeset-showcase.selectors';

@Injectable()
export class CreativeSetShowcaseEffects {
    private actions$ = inject(Actions);
    private eventLoggerService = inject(EventLoggerService);
    private showcaseDataService = inject(CreativeSetShowcaseDataService);
    private store = inject(Store);
    private uiNotificationService = inject(UINotificationService);

    loadShowcaseCreativeSet$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(creativeSetShowcaseActions.loadShowcaseCreativeSet),
            switchMap(action =>
                this.showcaseDataService.getShowcaseCreativeSet(action.key).pipe(
                    map(showcase =>
                        creativeSetShowcaseActions.loadShowcaseCreativeSetSuccess(showcase)
                    ),
                    catchError(error =>
                        of(creativeSetShowcaseActions.loadShowcaseCreativeSetFailure({ error }))
                    )
                )
            )
        );
    });

    loadCreativeSetShowcases$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(creativeSetShowcaseActions.loadCreativeSetShowcases),
            switchMap(action =>
                this.showcaseDataService.getShowcasesOfCreativeSet(action.creativesetId).pipe(
                    map(response =>
                        creativeSetShowcaseActions.loadCreativeSetShowcasesSuccess({ response })
                    ),
                    catchError(error =>
                        of(creativeSetShowcaseActions.loadCreativeSetShowcasesFailure({ error }))
                    )
                )
            )
        );
    });

    createCreativeSetShowcase$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(creativeSetShowcaseActions.createCreativeSetShowcase),
            switchMap(({ showcase }) =>
                this.showcaseDataService.createCreativeSetShowcase(showcase).pipe(
                    map(createdShowcase =>
                        creativeSetShowcaseActions.createCreativeSetShowcaseSuccess({
                            showcase: createdShowcase
                        })
                    ),
                    catchError(error =>
                        of(creativeSetShowcaseActions.createCreativeSetShowcaseFailure({ error }))
                    )
                )
            )
        );
    });

    createCreativeSetShowcaseSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(creativeSetShowcaseActions.createCreativeSetShowcaseSuccess),
                tap(({ showcase }) => {
                    // filter when creating QR showcase links
                    if (showcase.invitations.length > 0) {
                        const message = 'Invitations sent to selected users.';
                        this.uiNotificationService.open(message, {
                            placement: 'top',
                            autoCloseDelay: 5000
                        });
                    }
                })
            );
        },
        { dispatch: false }
    );

    createCreativeSetShowcaseFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(creativeSetShowcaseActions.createCreativeSetShowcaseFailure),
                tap(() => {
                    this.uiNotificationService.open('Could not create shared links.', {
                        placement: 'top',
                        type: 'error',
                        autoCloseDelay: 5000
                    });
                })
            );
        },
        { dispatch: false }
    );

    updateCreativeSetShowcase$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(creativeSetShowcaseActions.updateCreativeSetShowcase),
            concatLatestFrom(({ showcaseKey }) => this.store.select(getShowcaseByKey(showcaseKey))),
            switchMap(([{ changes, showcaseKey }, showcase]) => {
                if (!showcase) {
                    throw new Error(`Showcase ${showcaseKey} not found`);
                }
                const updateShowcase = { ...showcase, ...changes };
                return this.showcaseDataService.updateCreativeSetShowcase(updateShowcase).pipe(
                    map(response =>
                        creativeSetShowcaseActions.updateCreativeSetShowcaseSuccess({
                            showcase: response
                        })
                    ),
                    catchError(error =>
                        of(creativeSetShowcaseActions.updateCreativeSetShowcaseFailure({ error }))
                    )
                );
            })
        );
    });

    updateCreativeSetShowcaseSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(creativeSetShowcaseActions.updateCreativeSetShowcaseSuccess),
                tap(() => {
                    this.uiNotificationService.open('Link updated successfully.', {
                        placement: 'top',
                        autoCloseDelay: 5000,
                        type: 'success'
                    });
                })
            );
        },
        { dispatch: false }
    );

    updateCreativeSetShowcaseFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(creativeSetShowcaseActions.updateCreativeSetShowcaseFailure),
                tap(() => {
                    this.uiNotificationService.open('Could not update shared link.', {
                        placement: 'top',
                        type: 'error',
                        autoCloseDelay: 5000
                    });
                })
            );
        },
        { dispatch: false }
    );

    deleteCreativeSetShowcase$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(creativeSetShowcaseActions.deleteCreativeSetShowcase),
            switchMap(({ showcaseKey }) => {
                return this.showcaseDataService.deleteCreativeSetShowcase(showcaseKey).pipe(
                    map(() =>
                        creativeSetShowcaseActions.deleteCreativeSetShowcaseSuccess({ showcaseKey })
                    ),
                    catchError(error =>
                        of(creativeSetShowcaseActions.deleteCreativeSetShowcaseFailure({ error }))
                    )
                );
            })
        );
    });

    deleteCreativeSetShowcaseSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(creativeSetShowcaseActions.deleteCreativeSetShowcaseSuccess),
                tap(() => {
                    this.uiNotificationService.open('Link deleted successfully.', {
                        placement: 'top',
                        autoCloseDelay: 5000
                    });
                })
            );
        },
        { dispatch: false }
    );

    deleteCreativeSetShowcaseFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(creativeSetShowcaseActions.deleteCreativeSetShowcaseFailure),
                tap(() => {
                    this.uiNotificationService.open('Could not delete showcase.', {
                        type: 'error',
                        placement: 'top',
                        autoCloseDelay: 5000
                    });
                })
            );
        },
        { dispatch: false }
    );

    completeTranslation$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(creativeSetShowcaseActions.completeTranslation),
            switchMap(({ creativesetId, showcaseKey }) => {
                return this.showcaseDataService
                    .completeShowcaseCreativeSetTranslation(creativesetId, showcaseKey)
                    .pipe(
                        map(() => creativeSetShowcaseActions.completeTranslationSuccess()),
                        catchError(error =>
                            of(creativeSetShowcaseActions.completeTranslationFailure({ error }))
                        )
                    );
            })
        );
    });

    completeTranslationFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(creativeSetShowcaseActions.completeTranslationFailure),
                tap(({ error }) => {
                    this.eventLoggerService.log(new TranslationPanelDoneErrorEvent(error as Error));

                    this.uiNotificationService.open('Could not complete translation.', {
                        type: 'error',
                        placement: 'top',
                        autoCloseDelay: 5000
                    });
                })
            );
        },
        { dispatch: false }
    );
}
