import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ISocialPlacement, SocialNetworkType } from '@domain/social';
import { ISocialNetwork } from '@studio/domain/social';
import { Observable } from 'rxjs';
import { BrandService } from '../../../../shared/brand/state/brand.service';
import { SocialGuideService } from '../../../../shared/services/social-guide.service';

@Component({
    selector: 'social-overlay',
    templateUrl: './social-overlay.component.html',
    styleUrls: ['./social-overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialOverlayComponent implements OnInit {
    selectedNetwork$: Observable<ISocialNetwork>;
    selectedPlacement$: Observable<ISocialPlacement>;
    showOverlay$: Observable<boolean>;
    SocialNetworkType = SocialNetworkType;

    constructor(
        public brandService: BrandService,
        private socialGuideService: SocialGuideService
    ) {}

    ngOnInit(): void {
        this.selectedNetwork$ = this.socialGuideService.selectedNetwork$;
        this.selectedPlacement$ = this.socialGuideService.selectedPlacement$;
        this.showOverlay$ = this.socialGuideService.showOverlay$;
    }
}
