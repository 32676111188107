import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { KeyframeService } from '@app/design-view/timeline';
import { SentinelService } from '@bannerflow/sentinel';
import { Logger } from '@bannerflow/sentinel-logger';
import { sortByTime } from '@creative/animation.utils';
import { hasSameStyle, isContentSpan, isVariableSpan } from '@creative/elements/rich-text/text-nodes';
import { cloneNodes } from '@creative/nodes/data-node.utils';
import {
    isImageNode,
    isState,
    isTextDataElement,
    isTextNode,
    isVideoNode
} from '@creative/nodes/helpers';
import { ElementSelection } from '@creative/nodes/selection';
import { getStateById } from '@creative/rendering';
import { cloneCreativeDocument, stringifyDesignDocument } from '@creative/serialization/index';
import { serializeStyle } from '@creative/serialization/text-serializer';
import { serializeVersions } from '@creative/serialization/versions/version-serializer';
import { IAnimationKeyframe } from '@domain/animation';
import { IDesign } from '@domain/creativeset';
import { IElement } from '@domain/creativeset/element';
import { ISerializedVersion, IVersion } from '@domain/creativeset/version';
import { IFontFamily } from '@domain/font-families';
import { ICreativeDataNode, OneOfTextViewElements } from '@domain/nodes';
import { ITextSelection } from '@domain/rich-text/rich-text.selection.header';
import { IState } from '@domain/state';
import { ICharacterProperties, IVariableSpan } from '@domain/text';
import { IGuideline } from '@domain/workspace';
import { concatLatestFrom } from '@ngrx/operators';
import { cloneDeep, simpleClone } from '@studio/utils/clone';
import { HistoryQueue } from '@studio/utils/history-queue';
import { hasMediaReference } from '@studio/utils/media';
import { deepEqual } from '@studio/utils/utils';
import { Subject, filter } from 'rxjs';
import { VersionsService } from '../../../shared/versions/state/versions.service';
import { DesignViewComponent } from '../design-view.component';
import { PropertiesService } from '../properties-panel/properties.service';
import { EditorSaveStateService } from './editor-save-state.service';
import { EditorStateService } from './editor-state.service';
import { ElementSelectionService, InteractableInstance } from './element-selection.service';

export type IEditorSnapshot = {
    copyType: InteractableInstance;
    elements: IElement[];
    document: ICreativeDataNode;
    textStyleCursor: Partial<ICharacterProperties> | undefined;
    selection: ElementSelection | undefined;
    latestSelectionType: InteractableInstance;
    textSelection: ITextSelection | undefined;
    versions: IVersion[];
    isVersionable: boolean;
    selectedVersionId: string;
    defaultVersionId: string | undefined;
    playhead: number;
    guidelines: IGuideline[];
    activeGuideline: IGuideline | undefined;
    activeState: IState | undefined;
    keyframeSelection: { keyframes: IAnimationKeyframe[]; states: IState[] } | undefined;
    expandedAnimationElements: string[];
    fontFamilies?: IFontFamily[];
};

export interface ISaveSnapshotOption {
    isVersionable?: boolean;
    hasChanged?: boolean;
}

@Injectable()
export class HistoryService {
    private _onChange$ = new Subject<{
        undos: ReadonlyArray<IEditorSnapshot>;
        redos: ReadonlyArray<IEditorSnapshot>;
    }>();
    onChange$ = this._onChange$.asObservable();
    undo$ = new Subject<void>();
    redo$ = new Subject<void>();
    private _onDirtyChange$ = new Subject<boolean>();
    onDirtyChange$ = this._onDirtyChange$.asObservable();
    private _snapshotApply$ = new Subject<void>();
    snapshotApply$ = this._snapshotApply$.asObservable();

    editor: DesignViewComponent;
    isApplyingSnapshot = false;

    private historyQueue = new HistoryQueue<IEditorSnapshot>();
    private snapshotCandidates: IEditorSnapshot[] = [];

    lastDirtyCheck = false;

    // Backend state is only used for keeping track of dirty designs during save.
    elementsBackendState: IElement[];
    documentBackendState: any;
    private versionsBackendState: ISerializedVersion[];
    designsBackendState: IDesign[];

    private selectedVersion?: IVersion;
    private defaultVersion: IVersion;
    private versions: IVersion[];

    private logger = new Logger('HistoryService');

    constructor(
        private editorSaveStateService: EditorSaveStateService,
        private editorStateService: EditorStateService,
        private elementSelectionService: ElementSelectionService,
        private keyframeService: KeyframeService,
        private propertiesService: PropertiesService,
        private sentinelService: SentinelService,
        private versionsService: VersionsService
    ) {
        this.versionsService.loaded$
            .pipe(
                takeUntilDestroyed(),
                filter(Boolean),
                concatLatestFrom(() => this.versionsService.versions$)
            )
            .subscribe(([_, versions]) => {
                this.versionsBackendState = serializeVersions(
                    versions,
                    this.sentinelService
                ) as ISerializedVersion[];
            });

        this.versionsService.selectedVersion$.pipe(takeUntilDestroyed()).subscribe(selectedVersion => {
            this.selectedVersion = selectedVersion;
        });

        this.versionsService.defaultVersion$.pipe(takeUntilDestroyed()).subscribe(defaultVersion => {
            this.defaultVersion = defaultVersion;
        });

        this.versionsService.versions$.pipe(takeUntilDestroyed()).subscribe(versions => {
            this.versions = versions;
        });

        this.editorSaveStateService.saveSuccess$.pipe(takeUntilDestroyed()).subscribe(() => {
            this.storeCurrentStateAsBackendState();
            this.clear();
        });
    }

    public popUndo(): IEditorSnapshot | undefined {
        this.logger.verbose('Popping undo stack');
        const value = this.historyQueue.popUndo();
        // Keep the cloneDeep here so no pesky programmer starts mutating the undo stack
        return cloneDeep(value);
    }

    public popRedo(): IEditorSnapshot | undefined {
        this.logger.verbose('Popping redo stack');
        const value = this.historyQueue.popRedo();
        // Keep the cloneDeep here so no pesky programmer starts mutating the redo stack
        return cloneDeep(value);
    }

    addSnapshot(): void {
        this.logger.verbose('Adding snapshot');

        this.createSnapshotCandidate();
        this.saveLastSnapshotCandidate({ hasChanged: true });
    }

    createSnapshotCandidate = (): void => {
        this.logger.verbose('Creating snapshot candidate');

        const snapshot = this.createSnapshot();
        this.snapshotCandidates.push(snapshot);
    };

    saveLastSnapshotCandidate = (
        option: ISaveSnapshotOption = { hasChanged: true, isVersionable: false }
    ): void => {
        if (option.hasChanged === undefined) {
            option.hasChanged = true;
        }

        if (option.hasChanged) {
            const snapshot = this.snapshotCandidates.pop();
            if (snapshot) {
                this.saveSnapshot({
                    ...snapshot,
                    isVersionable: !!option.isVersionable
                });
            }
            this.checkDirtiness();
        }

        this.disposeSnapshotCandidates();
    };

    private saveSnapshot(snapshot: IEditorSnapshot): void {
        this.logger.verbose('Saving snapshot');
        this.historyQueue.push(snapshot);
    }

    isClean = (): boolean => !this.isDirty();

    isDirty = (): boolean => {
        const documentIsDirty =
            this.documentBackendState !== stringifyDesignDocument(this.editorStateService.document);

        if (documentIsDirty) {
            return true;
        }

        for (const element of this.editorStateService.document.elements) {
            if (isTextDataElement(element)) {
                if (!element.__dirtyContent) {
                    continue;
                }
                if (element.__dirtyContent.spans.length !== element.content.spans.length) {
                    return true;
                }

                const dirtyStyle = element.__dirtyContent.style;
                const cleanStyle = element.content.style;

                if (serializeStyle(dirtyStyle) !== serializeStyle(cleanStyle)) {
                    return true;
                }

                const dirtySpans = element.__dirtyContent.spans;
                const cleanSpans = element.content.spans;

                for (let i = 0; i < dirtySpans.length; i++) {
                    const dirtySpan = dirtySpans[i];
                    const cleanSpan = cleanSpans[i];
                    const isDifferentType = dirtySpan.type !== cleanSpan.type;
                    const isDifferentContent = dirtySpan.content !== cleanSpan.content;

                    if (isDifferentType || isDifferentContent) {
                        return true;
                    }

                    const bothAreContentSpans = isContentSpan(dirtySpan) && isContentSpan(cleanSpan);
                    const bothAreVariableSpans = isVariableSpan(dirtySpan) && isVariableSpan(cleanSpan);

                    if (bothAreVariableSpans && !this.spansHasSameFeed(dirtySpan, cleanSpan)) {
                        return true;
                    }

                    if (bothAreContentSpans && !hasSameStyle(dirtySpan.style, cleanSpan.style)) {
                        return true;
                    }
                }
            }
        }

        const serializedCurrentElements: IElement[] = this.editorStateService.elements;
        const elementsIsDirty = !deepEqual(serializedCurrentElements, this.elementsBackendState);
        if (elementsIsDirty) {
            return true;
        }

        const versionIsDirty = this.isVersionDirty();
        if (versionIsDirty) {
            return true;
        }

        return false;
    };

    private spansHasSameFeed(dirtySpan: IVariableSpan, cleanSpan: IVariableSpan): boolean {
        const dirtyVariable = dirtySpan.style.variable;
        const cleanVariable = cleanSpan.style.variable;

        if (!dirtyVariable && cleanVariable !== dirtyVariable) {
            return false;
        }

        const sameStart = dirtyVariable?.step.start === cleanVariable?.step.start;
        const sameSize = dirtyVariable?.step.size === cleanVariable?.step.size;

        return sameStart && sameSize;
    }

    checkDirtiness(): boolean {
        const status = this.isDirty();
        if (status !== this.lastDirtyCheck) {
            this.lastDirtyCheck = status;
            this.emitDirtyChange(this.lastDirtyCheck);
        }
        this.notifyChange();
        return status;
    }

    emitSnapshotChange(): void {
        this._snapshotApply$.next();
    }

    private emitDirtyChange(isDirty: boolean): void {
        this._onDirtyChange$.next(isDirty);
    }

    isPristine = (): boolean => !this.isDirty();

    storeCurrentStateAsBackendState(): void {
        this.documentBackendState = stringifyDesignDocument(this.editorStateService.document);
        this.elementsBackendState = cloneDeep(this.editorStateService.elements);
        this.versionsBackendState = serializeVersions(
            this.versions,
            this.sentinelService
        ) as ISerializedVersion[];
        this.designsBackendState = cloneDeep(this.editorStateService.designs);
        if (this.lastDirtyCheck === true) {
            this.emitDirtyChange(false);
        }
        this.lastDirtyCheck = false;
    }

    disposeSnapshotCandidates(): void {
        this.snapshotCandidates = [];
        this.historyQueue.clearRedos();
    }

    clear(): void {
        this.logger.verbose('Clearing history');
        this.disposeSnapshotCandidates();
        this.historyQueue.clearUndos();
        this.notifyChange();
    }

    createSnapshot(): IEditorSnapshot {
        const activeGuideline = this.editor.workspace.transform.onGuidelineChange$.getValue();
        const selection = this.elementSelectionService.currentSelection;
        const getCopyableKeyframes = this.getCopyableKeyframesFromSelection(
            this.keyframeService.keyframes
        );

        const selectedKeyframes =
            getCopyableKeyframes.length === this.keyframeService.keyframes.size
                ? getCopyableKeyframes.sort(sortByTime)
                : []; // This makes sure we only copy keyframes when all selected keyframes are copyable
        const selectedStates = this.getStatesFromKeyframes([...selectedKeyframes]);

        const activeState = isState(this.propertiesService.stateData)
            ? { ...this.propertiesService.stateData }
            : undefined;

        const expandedAnimationElements: string[] = [];
        this.editor.timeline?.timelineElementComponents.forEach(c => {
            if (c.expanded) {
                expandedAnimationElements.push(c.node.id);
            }
        });

        const element = selection?.element;
        const viewElement =
            element &&
            this.editorStateService.renderer.getViewElementById<OneOfTextViewElements>(element.id);

        const snapshot: IEditorSnapshot = {
            copyType:
                selection && selection.length === 1 && selectedKeyframes.length > 0
                    ? 'keyframe'
                    : 'element',
            elements: cloneDeep(this.editorStateService.elements),
            document: cloneCreativeDocument(this.editorStateService.document, true),
            selection: new ElementSelection(cloneNodes(selection.nodes)),
            latestSelectionType: this.elementSelectionService.latestSelectionType,
            textSelection: isTextNode(selection?.element)
                ? viewElement?.__richTextRenderer?.editor_m?.selection.getTextSelection()
                : undefined,
            versions: cloneDeep(this.versions),
            isVersionable: false,
            selectedVersionId: this.selectedVersion!.id,
            defaultVersionId: this.defaultVersion.id,
            playhead: this.editor.time,
            activeGuideline: activeGuideline ? simpleClone(activeGuideline) : activeGuideline,
            guidelines: simpleClone(this.editor.workspace.design.document.guidelines),
            activeState,
            keyframeSelection: {
                keyframes: simpleClone(Array.from(selectedKeyframes)),
                states: cloneDeep(Array.from(selectedStates))
            },
            expandedAnimationElements,
            textStyleCursor: undefined
        };

        snapshot.selection = new ElementSelection(snapshot.selection?.nodesAsSortedArray());
        snapshot.document.guidelines = snapshot.guidelines;

        return snapshot;
    }

    private getCopyableKeyframesFromSelection(
        keyframes: Set<IAnimationKeyframe>
    ): IAnimationKeyframe[] {
        // Only copy keyframes from animation of type keyframe
        return [...keyframes].filter(keyframe =>
            this.editor.renderer.creativeDocument.elements.some(el =>
                el.animations.some(
                    ani =>
                        ani.keyframes.findIndex(kf => kf.id === keyframe.id) > -1 &&
                        ani.type &&
                        ['keyframe'].indexOf(ani.type) > -1
                )
            )
        );
    }

    private getStatesFromKeyframes(keyframes: IAnimationKeyframe[]): IState[] {
        const states = keyframes.reduce((accumulator: IState[], value) => {
            this.editor.renderer.creativeDocument.elements.forEach(el => {
                const state = getStateById(el, value.stateId);
                if (state) {
                    accumulator.push(state);
                }
            });
            return accumulator;
        }, []);
        return states;
    }

    private isVersionDirty(): boolean {
        // Remove view attributes
        const cleanVersionsArray = serializeVersions(this.versions, this.sentinelService);
        return !deepEqual(cleanVersionsArray, this.versionsBackendState);
    }

    updateSnapshotsAssetUrl(oldAssetId: string, newAssetId: string, newURL: string): void {
        for (const snapshot of this.getAllSnapshots()) {
            this.updateSnapshotAssetUrl(oldAssetId, newAssetId, newURL, snapshot);
        }
    }

    removeFailedAssetUpload(assetId: string): void {
        for (const snapshot of this.getAllSnapshots()) {
            for (const element of snapshot.elements) {
                for (const property of element.properties) {
                    if (hasMediaReference(property) && property.value === assetId) {
                        snapshot.elements = snapshot.elements.filter(el => el.id !== element.id);
                        snapshot.document.removeNodeById_m(element.id);
                    }
                }
            }
        }
    }

    getAllSnapshots(): IEditorSnapshot[] {
        const copiedSnapshot = this.editor?.copiedSnapshot ? [this.editor.copiedSnapshot] : [];
        const currentSnapshot = this.historyQueue.current ? [this.historyQueue.current] : [];
        const snapshots = [
            ...this.historyQueue.undos,
            ...this.historyQueue.redos,
            ...currentSnapshot,
            ...copiedSnapshot
        ];
        const validSnapshots = snapshots.filter(Boolean);

        if (validSnapshots.length < snapshots.length) {
            this.logger.warn('Found invalid snapshots! Filtering them!');
        }

        return validSnapshots;
    }

    private notifyChange(): void {
        this._onChange$.next({ undos: this.historyQueue.undos, redos: this.historyQueue.redos });
    }

    private updateSnapshotAssetUrl(
        oldAssetId: string,
        newAssetId: string,
        newURL: string,
        snapshot: IEditorSnapshot
    ): void {
        snapshot.document.elements.forEach(element => {
            if (isImageNode(element) && element.imageAsset?.id === oldAssetId) {
                element.imageAsset.url = newURL;
                element.imageAsset.id = newAssetId;
            }
            if (isVideoNode(element) && element.videoAsset?.id === oldAssetId) {
                element.videoAsset.url = newURL;
                element.videoAsset.id = newAssetId;
            }
        });

        snapshot.elements.forEach(element => {
            element.properties.forEach(property => {
                if (hasMediaReference(property) && property.value === oldAssetId) {
                    property.value = newAssetId;
                }
            });
        });
    }
}
