<ng-container *ngIf="!hideButtons">
    <div class="left">
        <dropdown-menu #menu></dropdown-menu>
        <topbar-context-menu
            *ngIf="menu && menu.dropdown"
            [target]="menu.dropdown"
            [border]="contextMenuBorder"></topbar-context-menu>
        <topbar-breadcrumbs (dblclick)="showButtons = !showButtons" />

        @if (showButtons && (!isProd || (isEmployee$ | async))) {
            <creative-preview-access class="test-buttons" />
        }
    </div>
    <div class="center">
        <version-picker
            [allowManageVersions]="false"
            (pickerOpen)="onVersionPickerOpen()"
            (pickerClose)="onVersionPickerClose()"
            *ngIf="creativeset">
        </version-picker>
        @if (designApiEnabled) {
            <ui-select
                [selected]="selectedSize"
                (selectedChange)="onSizeChange($event)">
                @for (size of sizes; track size.id) {
                    <ui-option [value]="size"> {{ size.width }}x{{ size.height }} </ui-option>
                }
            </ui-select>
        }
    </div>
    <div class="right">
        @if (isEmployee$ | async) {
            <feature-toggle
                uiTooltip="Features in development. Use at your own risk!"
                uiTooltipPosition="bottom" />
            <toggle-environment />
        }

        <current-users />

        <comments-overview [creativeId]="creativeId" />

        <ui-button
            *ngIf="showUploadButton$ | async; else saveButtonTpl"
            id="upload-button"
            data-test-id="upload-button"
            type="discrete"
            text="UPLOADING..."
            [disabled]="true"></ui-button>

        <ng-template #saveButtonTpl>
            <ui-button
                id="save-design-button"
                data-test-id="save-design-button"
                #saveButton
                type="primary"
                [text]="saveButtonDisplayText"
                [disabled]="(disabled$ | async)!"
                [loading]="(loading$ | async)!"
                (click)="onSaveButtonClick()"></ui-button>
        </ng-template>

        <help-menu />

        <ui-svg-icon
            uiTooltip="Exit"
            uiTooltipPosition="left"
            class="exit-icon"
            id="exit-editor-button"
            data-test-id="exit-editor-button"
            icon="close"
            fill="#B5B5B5"
            [ngStyle]="{ pointerEvents: (loading$ | async)! ? 'none' : 'auto' }"
            (click)="exit()"
            >done
        </ui-svg-icon>
    </div>
</ng-container>
