import { APP_INITIALIZER, EnvironmentProviders, inject, makeEnvironmentProviders } from '@angular/core';
import { take, tap } from 'rxjs';
import { FeatureFlagsService } from './feature-flags.service';
import { FEATURE_FLAGS_CONFIG, FeatureFlagsConfig } from './feature-flags.types';

export function provideFeatureFlags(configs: FeatureFlagsConfig): EnvironmentProviders {
    return makeEnvironmentProviders([
        { provide: FEATURE_FLAGS_CONFIG, useValue: configs },
        {
            provide: APP_INITIALIZER,
            useFactory: () => {
                const featureFlagsService = inject(FeatureFlagsService);
                return () =>
                    featureFlagsService.initialized$.pipe(
                        take(1),
                        tap(() => featureFlagsService.start())
                    );
            },
            multi: true
        }
    ]);
}
