import { Injectable, inject } from '@angular/core';
import { SentinelService } from '@bannerflow/sentinel';
import { UIDialogService } from '@bannerflow/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { ICampaignStatus, PublishStatus } from '@domain/campaign';
import { ICreative } from '@domain/creativeset/creative/creative';
import {
    EventLoggerService,
    PublishChangesEndEvent,
    PublishChangesEvent
} from '@studio/monitoring/events';
import { EMPTY, Observable, catchError, delay, filter, map, of, switchMap, take } from 'rxjs';
import { PublishChangesDialogComponent } from '../../../pages/manage-view/publish-changes-dialog/publish-changes-dialog.component';
import { BrandService } from '../../brand/state/brand.service';
import { CreativesetDataService } from '../../creativeset/creativeset.data.service';
import { EnvironmentService } from '../../services/environment.service';
import * as DisplayCampaignActions from './display-campaign.actions';
import { DisplayCampaignDataService } from './display-campaign.data.service';
import { DisplayCampaignService } from './display-campaign.service';
import { isCreativePublishable } from './display-campaign.utils';

@Injectable()
export class DisplayCampaignEffects {
    private actions$ = inject(Actions);
    private environmentService = inject(EnvironmentService);
    private brandService = inject(BrandService);
    private displayCampaignService = inject(DisplayCampaignService);
    private displayCampaignDataService = inject(DisplayCampaignDataService);
    private creativeSetDataService = inject(CreativesetDataService);
    private uiDialogService = inject(UIDialogService);
    private eventLoggerService = inject(EventLoggerService);
    private sentinelService = inject(SentinelService);

    loadCreativeset$ = createEffect(() => {
        // For some reason, brand$ is not triggered after creativeset$. So we need to wait for it
        return this.brandService.brand$.pipe(
            switchMap(() => this.creativeSetDataService.creativeset$),
            filter(creativeset => !!creativeset && !this.environmentService.inShowcaseMode),
            map(creativeset => {
                const campaignIds = Array.from(
                    new Set(
                        creativeset.creatives.map(({ connectedCampaigns }) => connectedCampaigns).flat()
                    )
                );
                return DisplayCampaignActions.loadCampaignsStatus({ campaignIds });
            })
        );
    });

    loadCampaignsStatus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DisplayCampaignActions.loadCampaignsStatus),
            filter(({ campaignIds }) => !!campaignIds?.length),
            concatLatestFrom(() => [this.brandService.accountSlug$, this.brandService.slug$]),
            switchMap(([{ campaignIds, polling }, accountSlug, slug]) => {
                if (!accountSlug || !slug) {
                    return of(
                        DisplayCampaignActions.loadCampaignsStatusFailure({
                            error: new Error('Brand not loaded?')
                        })
                    );
                }
                return this.displayCampaignDataService
                    .loadCampaignStatuses(campaignIds, accountSlug, slug)
                    .pipe(
                        switchMap(campaignPublishStatus =>
                            of(
                                DisplayCampaignActions.loadCampaignsStatusSuccess({
                                    campaignPublishStatus,
                                    polling
                                })
                            )
                        ),
                        catchError(error =>
                            of(DisplayCampaignActions.loadCampaignsStatusFailure({ error }))
                        )
                    );
            })
        );
    });

    pushChangesPrompt$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DisplayCampaignActions.pushChangesPrompt),
            concatLatestFrom(() => [
                this.displayCampaignService.campaigns$,
                this.displayCampaignService.loaded$
            ]),
            filter(([_actions, _campaignPublishStatus, loaded]) => !!loaded),
            switchMap(([{ creatives }, campaignPublishStatus]) => {
                const updatedCreatives = this.getUpdatedCreatives(creatives);
                const publishableCreatives = this.getPublishableCreatives(
                    updatedCreatives,
                    campaignPublishStatus
                );
                if (!publishableCreatives.length) {
                    return EMPTY;
                }
                const filteredCampaigns = this.getFilteredCampaigns(
                    publishableCreatives,
                    campaignPublishStatus
                );
                return this.openDialogAndHandleResponse(publishableCreatives, filteredCampaigns);
            }),
            switchMap(({ confirmed, creatives, campaigns }) => {
                if (!confirmed) {
                    return EMPTY;
                }
                return of(DisplayCampaignActions.pushChanges({ creatives, campaigns }));
            })
        );
    });

    pushChanges$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DisplayCampaignActions.pushChanges),
            concatLatestFrom(() => [
                this.displayCampaignService.campaigns$,
                this.displayCampaignService.pushingChangesOnCampaignIds$
            ]),
            filter(
                ([_actions, _campaignPublishStatus, pushingChangesOnCampaigns]) =>
                    !!pushingChangesOnCampaigns.length
            ),
            switchMap(([{ creatives }]) => {
                this.eventLoggerService.log(new PublishChangesEvent());
                this.sentinelService.trackPageAction('Published Creatives', creatives.length);
                return this.displayCampaignDataService
                    .pushChanges(this.creativeSetDataService.creativeset.id, creatives)
                    .pipe(
                        map(({ failures }) =>
                            failures.length
                                ? DisplayCampaignActions.pushChangesFailure({ error: failures })
                                : DisplayCampaignActions.pushChangesSuccess({ creatives })
                        )
                    );
            })
        );
    });

    pushChangesSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DisplayCampaignActions.pushChangesSuccess),
            concatLatestFrom(() => this.displayCampaignService.pushingChangesOnCampaignIds$),
            switchMap(([{ creatives }, campaignIds]) => {
                if (!campaignIds.length) {
                    this.eventLoggerService.log(new PublishChangesEndEvent());
                    return EMPTY;
                }

                return of(
                    DisplayCampaignActions.loadCampaignsStatus({
                        campaignIds,
                        polling: {
                            creatives,
                            intervalIndex: 1,
                            lastPoll: Date.now()
                        }
                    })
                );
            })
        );
    });

    pollStatus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DisplayCampaignActions.loadCampaignsStatusSuccess),
            filter(({ polling }) => !!polling),
            concatLatestFrom(() => this.displayCampaignService.pushingChangesOnCampaignIds$),
            switchMap(([{ polling }, pushingChangesOnCampaignIds]) => {
                if (
                    !polling?.creatives ||
                    !pushingChangesOnCampaignIds.length ||
                    polling.intervalIndex > 30
                ) {
                    return EMPTY;
                }
                const delayTime = Math.min(2000, Math.pow(2, polling.intervalIndex) * 300);
                return of(
                    DisplayCampaignActions.loadCampaignsStatus({
                        polling: {
                            creatives: polling.creatives,
                            intervalIndex: polling.intervalIndex + 1,
                            lastPoll: Date.now()
                        },
                        campaignIds: pushingChangesOnCampaignIds
                    })
                ).pipe(delay(delayTime));
            })
        );
    });

    private getUpdatedCreatives(creatives: ICreative[]): ICreative[] {
        return creatives.length ? creatives : this.creativeSetDataService.creativeset.creatives;
    }
    private getPublishableCreatives(
        creatives: ICreative[],
        campaignPublishStatus: ICampaignStatus[]
    ): ICreative[] {
        return creatives.filter(creative => isCreativePublishable(creative, campaignPublishStatus));
    }
    private getFilteredCampaigns(
        publishableCreatives: ICreative[],
        campaignPublishStatus: ICampaignStatus[]
    ): ICampaignStatus[] {
        const publishableCreativeIds = publishableCreatives.map(({ id }) => id);
        return campaignPublishStatus.filter(
            ({ creatives, status }) =>
                creatives.some(({ creativeId }) => publishableCreativeIds.includes(creativeId)) &&
                status !== PublishStatus.Publishing &&
                status !== PublishStatus.NotPublished
        );
    }
    private openDialogAndHandleResponse(
        publishableCreatives: ICreative[],
        filteredCampaigns: ICampaignStatus[]
    ): Observable<{
        confirmed: boolean;
        creatives: ICreative[];
        campaigns: ICampaignStatus[];
    }> {
        const dialog = this.uiDialogService.openComponent(PublishChangesDialogComponent, {
            headerText: 'Push changes to campaign',
            theme: 'default',
            width: '700px',
            data: { creatives: publishableCreatives, campaigns: filteredCampaigns }
        });
        return dialog.afterClose().pipe(
            take(1),
            map(() => ({
                confirmed: !!dialog.config.data.confirmed,
                creatives: publishableCreatives,
                campaigns: filteredCampaigns
            }))
        );
    }
}
