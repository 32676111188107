import { inject, Injectable } from '@angular/core';
import { T } from '@creative/creative.container';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Container } from '@studio/utils/di';
import { catchError, combineLatestWith, map, of, switchMap, tap } from 'rxjs';
import { BrandService } from '../../brand/state/brand.service';
import { FontFamiliesDataService } from '../font-families.data.service';
import * as FontFamiliesActions from './font-families.actions';
import { FontFamiliesService } from './font-families.service';

@Injectable()
export class FontFamiliesEffects {
    private actions$ = inject(Actions);
    private brandService = inject(BrandService);
    private container = inject(Container<T>);
    private fontFamiliesDataService = inject(FontFamiliesDataService);
    private fontFamiliesService = inject(FontFamiliesService);

    loadFontFamilies$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FontFamiliesActions.loadFontFamiliesOfBrand),
            combineLatestWith(this.brandService.brandId$),
            switchMap(([_, brandId]) =>
                this.fontFamiliesDataService.getFontFamiliesByBrandId(brandId).pipe(
                    map(fontFamilies =>
                        FontFamiliesActions.loadFontFamiliesOfBrandSuccess({ fontFamilies })
                    ),
                    catchError(error =>
                        of(FontFamiliesActions.loadFontFamiliesOfBrandFailure({ error }))
                    )
                )
            )
        );
    });

    registerFontFamiles$ = createEffect(
        () => {
            return this.fontFamiliesService.fontFamilies$.pipe(
                tap(fontFamilies => {
                    this.container?.register_m(T.FONT_FAMILIES, fontFamilies);
                })
            );
        },
        { dispatch: false }
    );

    searchExternalFont$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FontFamiliesActions.searchExternalFonts),
            switchMap(({ searchTerm }) => {
                return this.fontFamiliesDataService.searchExternalFont(searchTerm).pipe(
                    map(response => FontFamiliesActions.searchExternalFontsSuccess({ response })),
                    catchError(error => of(FontFamiliesActions.searchExternalFontsFailure({ error })))
                );
            })
        );
    });

    importExternalFonts$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FontFamiliesActions.importExternalFonts),
            concatLatestFrom(() => this.brandService.brandId$),
            switchMap(([{ externalFontFamilies }, brandId]) => {
                return this.fontFamiliesDataService
                    .importExternalFont(brandId, externalFontFamilies)
                    .pipe(
                        map(response => FontFamiliesActions.importExternalFontsSuccess({ response })),
                        catchError(error =>
                            of(FontFamiliesActions.importExternalFontsFailure({ error }))
                        )
                    );
            })
        );
    });
}
