import { Injectable } from '@angular/core';
import { GET_USER } from '@data/graphql';
import { IUser } from '@domain/user';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UserDataService {
    constructor(private apollo: Apollo) {}

    getUser(accountSlug: string): Observable<IUser> {
        return this.apollo
            .watchQuery<{ user: IUser }>({
                query: GET_USER,
                variables: {
                    accountSlug
                }
            })
            .valueChanges.pipe(map(result => result.data.user));
    }
}
