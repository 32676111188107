import { isVersionedText, isVersionedWidgetText } from '@creative/elements/rich-text/text-nodes';
import { isWidgetElement } from '@creative/nodes';
import { ICreative } from '@domain/creativeset/creative';
import { IVersion, IVersionProperty } from '@domain/creativeset/version';

// TranslationPanel doesn't update the styles array when updating widgets
// This is a soft migration for all widgets when the user triggers a save
export function fixWidgetVersionProperties(versions: IVersion[], creatives: ICreative[]): IVersion[] {
    for (const version of versions) {
        version.properties = version.properties.map(versionProperty =>
            mapVersionProperty(versionProperty, creatives)
        );
    }

    return versions;
}

function mapVersionProperty(
    versionProperty: IVersionProperty,
    creatives: ICreative[]
): IVersionProperty {
    if (!isVersionedWidgetText(versionProperty) && !isVersionedText(versionProperty)) {
        return versionProperty;
    }
    if (!isWidgetVersionProperty(versionProperty.id, creatives)) {
        return versionProperty;
    }
    return {
        ...versionProperty,
        name: 'widgetText',
        value: {
            text: versionProperty.value.text
        }
    };
}

function isWidgetVersionProperty(versionPropertyId: string, creatives: ICreative[]): boolean {
    for (const creative of creatives) {
        for (const element of creative.design?.elements ?? []) {
            if (!isWidgetElement(element)) {
                continue;
            }
            if (
                element.properties.some(
                    elementProperty => versionPropertyId === elementProperty.versionPropertyId
                )
            ) {
                return true;
            }
        }
    }
    return false;
}
