import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from '@angular/core';
import { GainsightEvent, GainsightService } from '../../../shared/services/gainsight.service';
import { UIModule } from '@bannerflow/ui';
import { RichText } from '@creative/elements/rich-text/rich-text';
import {
    createStyleIndexMap,
    isContentSpan,
    sequenceStyleIds
} from '@creative/elements/rich-text/text-nodes';
import { createDefaultTextProperties } from '@creative/nodes/helpers';
import { ICreativeset } from '@domain/creativeset/creativeset';
import { ITextSpan, IVersionedText } from '@domain/creativeset/version';
import {
    INewlineSpan,
    IStyleIdMap,
    IText,
    OneOfContentSpans,
    OneOfEditableSpans,
    SpanType,
    TextDirection
} from '@domain/text';

@Component({
    standalone: true,
    imports: [CommonModule, UIModule],
    selector: 'rich-text-preview',
    templateUrl: 'rich-text-preview.component.html',
    styleUrls: ['rich-text-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.rich-text-preview]': 'true'
    }
})
export class RichTextPreviewComponent implements OnChanges {
    @Input() text: IVersionedText;
    @Input() creativeset: ICreativeset;
    @Output() stylingSelected: EventEmitter<IStyleIdMap> = new EventEmitter();
    styleMap: Map<string, number>;
    indexedText: IText;

    constructor(private gainsightService: GainsightService) {}

    ngOnChanges(): void {
        if (this.text) {
            const { styleHashToIndexMap } = createStyleIndexMap(this.text);
            this.styleMap = styleHashToIndexMap;
            this.indexedText = this.createUnderscoredTextFromVersionedText(this.text);
        }
    }

    shouldShowIndex(span: OneOfEditableSpans): boolean {
        if (this.styleMap.size < 2 || !this.indexedText || span.attributes.styleIndex === undefined) {
            return false;
        }
        const index = this.indexedText.spans.indexOf(span);

        if (index < this.indexedText.spans.length - 1) {
            const nextSpan = this.indexedText.spans.find(
                (span, i) => span.type !== SpanType.Newline && span.type !== SpanType.End && i > index
            );
            return !nextSpan || span.attributes.styleIndex !== nextSpan.attributes.styleIndex;
        }
        return true;
    }

    isSpace(span: OneOfEditableSpans): boolean {
        return span.type === SpanType.Space;
    }

    isNewline(span: OneOfEditableSpans): boolean {
        return span.type === SpanType.Newline;
    }

    renderSpan(span: OneOfEditableSpans): string {
        if (span.type === SpanType.End) {
            return '';
        }
        if (span.type === SpanType.Newline) {
            return '\n';
        }
        return span.content;
    }

    selectStyling(indexedSpan: OneOfEditableSpans): void {
        if (indexedSpan && indexedSpan.attributes.styleIndex !== undefined) {
            if (!isContentSpan(indexedSpan)) {
                return;
            }
            const span = this.getOriginalSpan(indexedSpan);
            this.stylingSelected.emit(span.styleIds);
            this.gainsightService.sendCustomEvent(GainsightEvent.ApplyCharacterStyling);
        }
    }

    getTextDirection(): TextDirection.Ltr | TextDirection.Rtl {
        const dir = RichText.getBidiDirection_m(this.text.text.charCodeAt(0));
        return dir === TextDirection.InferFromContext || dir === TextDirection.Previous
            ? TextDirection.Ltr
            : dir;
    }

    private getOriginalSpan(indexedSpan: OneOfEditableSpans): ITextSpan {
        if (this.text && this.indexedText && indexedSpan) {
            const index = this.indexedText.spans.indexOf(indexedSpan);
            return this.text.styles[index];
        }
        throw new Error('Could not find orignal span.');
    }

    private createUnderscoredTextFromVersionedText(text: IVersionedText): IText {
        const spans: OneOfEditableSpans[] = [];
        for (let i = 0; i < text.styles.length; i++) {
            const span = text.styles[i];
            switch (span.type) {
                case SpanType.Word:
                case SpanType.Space:
                case SpanType.Variable: {
                    const sequencedStyleIds = sequenceStyleIds(span.styleIds);
                    const styleIndex = sequencedStyleIds
                        ? this.styleMap.get(sequencedStyleIds)
                        : undefined;
                    spans.push({
                        type: span.type,
                        style: span.type === SpanType.Variable ? { variable: span.variable } : {},
                        content: text.text.substr(span.position, span.length),
                        top: 0,
                        left: 0,
                        width: 0,
                        height: 0,
                        lineHeight: 0,
                        attributes: {
                            styleIndex
                        }
                    } as OneOfContentSpans);
                    break;
                }
                case SpanType.Newline:
                    spans.push({
                        type: SpanType.Newline,
                        style: {},
                        content: text.text.substr(span.position, span.length),
                        top: 0,
                        left: 0,
                        width: 0,
                        height: 0,
                        lineHeight: 0,
                        attributes: {}
                    } as INewlineSpan);
                    break;
                case SpanType.End:
                    spans.push({
                        attributes: {},
                        top: 0,
                        left: 0,
                        width: 0,
                        height: 0,
                        lineHeight: 0,
                        type: SpanType.End,
                        content: 'END',
                        dir: TextDirection.Ltr
                    });
                    break;
                default:
                    throw new Error('Unknown span type');
            }
        }

        const style = createDefaultTextProperties();
        (style as any).padding = undefined; // We will use the DOM element style, instead of "manual style".
        return {
            style,
            spans
        };
    }
}
