import { IBrandLocalization, IBrandPalette, IBrandSize, IBrandSizeGroup } from '@domain/brand';
import { IAsyncState } from '@domain/store/async';
import { createReducer, on } from '@ngrx/store';
import { creativeSetShowcaseActions } from '../../creativeset-showcase/state/creativeset-showcase.actions';
import { mapColorPalette } from '../brand.utils';
import * as BrandActions from './brand.actions';

export const BRAND_FEATURE_KEY = 'brand';

export interface BrandPartialState {
    readonly [BRAND_FEATURE_KEY]: BrandState;
}

export interface BrandState extends IAsyncState {
    id?: string;
    accountSlug?: string;
    slug?: string;
    brandLogoUrl?: string;
    name?: string;
    localizations: IBrandLocalization[];
    sizeFormats: IBrandSize[];
    sizeFormatGroups: IBrandSizeGroup[];
    palettes: IBrandPalette[];
}

export const initialState = {
    loaded: false,
    localizations: [],
    sizeFormats: [],
    sizeFormatGroups: [],
    palettes: []
};

export const reducer = createReducer<BrandState>(
    initialState,
    on(BrandActions.loadActions.loadBrand, state => {
        return { ...state, loaded: false, error: undefined };
    }),
    on(BrandActions.loadActions.loadBrandSuccess, (state, { brand }) => {
        return {
            ...state,
            ...brand,
            brandLogoUrl: brand.brandLogoUrl ?? undefined,
            palettes: brand.palettes.map(mapColorPalette),
            loaded: true
        };
    }),
    on(BrandActions.loadActions.loadBrandFailure, (state, { error }) => {
        return { ...state, error };
    }),

    on(
        creativeSetShowcaseActions.loadShowcaseCreativeSetSuccess,
        (state, { brand: { id, localizations } }) => {
            return {
                ...state,
                loaded: true,
                id,
                localizations: localizations as IBrandLocalization[] // we lie in showcase, but it's fine
            };
        }
    )
);
