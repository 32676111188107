import { Injectable, inject } from '@angular/core';
import { isApolloError } from '@apollo/client/core';
import { UINotificationService } from '@bannerflow/ui';
import { isVersionedText } from '@creative/elements/rich-text/text-nodes';
import { IDesign } from '@domain/creativeset';
import { ICreative } from '@domain/creativeset/creative/creative';
import { ITextSpan, IVersion, IVersionProperty, IVersionedText } from '@domain/creativeset/version';
import { TranslatedTextToVersionProperty } from '@domain/translations';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { isKnownErrorResponse } from '@studio/domain/api/error';
import { cloneDeep } from '@studio/utils/clone';
import { getPrimaryGraphQlError } from '@studio/utils/errors/apps-errors';
import { removeDuplicates } from '@studio/utils/utils';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BrandService } from '../../brand/state/brand.service';
import { CreativesetDataService } from '../../creativeset/creativeset.data.service';
import { FiltersService } from '../../filters/state/filters.service';
import { BannerlingoDataService } from '../bannerlingo.data.service';
import { getNewStylesForTranslatedText } from '../span-styles.utils';
import { VersionsDataService } from './versions.data.service';
import {
    prepareVersionsForUpdate,
    sanitizeVersionIdsFromURL,
    validateNewVersion
} from '../versions.utils';
import { VersionsActions } from './versions.actions';
import { VersionsService } from './versions.service';

@Injectable()
export class VersionsEffects {
    private actions$ = inject(Actions);
    private bannerlingoDataService = inject(BannerlingoDataService);
    private brandService = inject(BrandService);
    private creativesetDataService = inject(CreativesetDataService);
    private filtersService = inject(FiltersService);
    private uiNotificationService = inject(UINotificationService);
    private versionsDataService = inject(VersionsDataService);
    private versionsService = inject(VersionsService);

    initializeVersions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(VersionsActions.init),
            switchMap(
                ({
                    versions,
                    selectedVersionsIds,
                    defaultVersion,
                    creativesetId,
                    versionParamProvided,
                    selectableIds
                }) => {
                    selectedVersionsIds = removeDuplicates(selectedVersionsIds);
                    const newDefaultVersion = versions.find(({ id }) => defaultVersion.id === id)!;
                    let selectableVersions = versions;

                    if (selectableIds) {
                        selectableVersions = versions.filter(({ id }) => selectableIds.includes(id));
                    }

                    const sanitizedSelectedVersionsIds = sanitizeVersionIdsFromURL(
                        selectedVersionsIds,
                        selectableVersions,
                        defaultVersion,
                        versionParamProvided
                    );

                    return of(
                        VersionsActions.initSuccess({
                            versions,
                            defaultVersion: newDefaultVersion,
                            selectedVersionsIds: sanitizedSelectedVersionsIds,
                            creativesetId,
                            selectableIds
                        })
                    );
                }
            )
        );
    });

    resetVersions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(VersionsActions.resetVersions),
            switchMap(({ versions }) => {
                return of(VersionsActions.resetVersionsSuccess({ versions }));
            })
        );
    });

    updatedDesigns = createEffect(() => {
        return this.actions$.pipe(
            ofType(VersionsActions.onUpdatedDesigns),
            switchMap(({ versions }) => {
                return of(VersionsActions.onUpdatedDesignsSuccess({ versions: versions }));
            })
        );
    });

    saveNewVersionFromPicker$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(VersionsActions.saveNewVersion),
            concatLatestFrom(() => [
                this.versionsService.newVersionPlaceholder$,
                this.brandService.localizations$,
                this.versionsService.versions$
            ]),
            switchMap(
                ([
                    { newVersionName, autoTranslate },
                    newVersionPlaceholder,
                    localizations,
                    versions
                ]) => {
                    const localization = localizations.find(
                        ({ id }) => id === newVersionPlaceholder!.localization.id
                    );
                    if (!localization) {
                        return of(
                            VersionsActions.createVersionFailure({
                                error: new Error(
                                    `Localization '${newVersionPlaceholder?.localization.id}' not found`
                                )
                            })
                        );
                    }
                    const [valid, errorMessage] = validateNewVersion(newVersionName, versions);
                    if (!valid) {
                        return of(
                            VersionsActions.createVersionFailure({ error: new Error(errorMessage) })
                        );
                    }
                    return of(
                        VersionsActions.createVersion({
                            localization: localization,
                            newVersionName: newVersionName,
                            autoTranslate
                        })
                    );
                }
            )
        );
    });

    createVersion$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(VersionsActions.createVersion),
            concatLatestFrom(() => [
                this.versionsService.creativesetId$,
                this.versionsService.defaultVersion$,
                this.brandService.localizations$
            ]),
            switchMap(
                ([
                    { localization, newVersionName, autoTranslate },
                    creativesetId,
                    defaultVersion,
                    localizations
                ]) => {
                    const newVersions: IVersion[] = [
                        {
                            id: '',
                            properties: [],
                            targetUrl: localization.targetUrl,
                            name: newVersionName,
                            localization
                        }
                    ];
                    const translationMap = this.getTextsFromVersion(defaultVersion.properties);
                    if (autoTranslate && translationMap.length) {
                        if (!localization) {
                            throw new Error('Target culture was not found');
                        }
                        const sourceCulture = localizations.find(
                            ({ id }) => id === defaultVersion.localization.id
                        );
                        if (!sourceCulture) {
                            throw new Error('Source culture was not found');
                        }

                        const texts = translationMap.map(t => t.text);
                        return this.bannerlingoDataService
                            .translateTexts(texts, localization, sourceCulture)
                            .pipe(
                                switchMap(translations => {
                                    newVersions[0].properties = this.mapTranslationsToVersionProperties(
                                        translations,
                                        translationMap
                                    );
                                    return of({ creativesetId, newVersions });
                                })
                            );
                    }

                    return of({ creativesetId, newVersions });
                }
            ),
            switchMap(({ newVersions, creativesetId }) => {
                return this.versionsDataService.createVersions(creativesetId, newVersions).pipe(
                    map(result => {
                        return VersionsActions.createVersionSuccess({
                            versions: result.versions,
                            creatives: result.creatives
                        });
                    }),
                    catchError(error => of(VersionsActions.createVersionFailure({ error })))
                );
            })
        );
    });

    deleteVersions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(VersionsActions.deleteVersions),
            concatLatestFrom(() => this.versionsService.creativesetId$),
            switchMap(([{ versions }, creativesetId]) => {
                return this.versionsDataService.deleteVersions(creativesetId, versions).pipe(
                    map(result =>
                        VersionsActions.deleteVersionsSuccess({ versionsIds: result?.ids || [] })
                    ),
                    catchError(error => of(VersionsActions.deleteVersionsFailure({ error })))
                );
            })
        );
    });

    updateVersion$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(VersionsActions.updateVersions),
            concatLatestFrom(() => [this.versionsService.creativesetId$]),
            switchMap(([{ versions: versionsToUpdate }, creativesetId]) => {
                const newVersionsToUpdate = cloneDeep(versionsToUpdate);

                const { versions, dirtyCreatives } = prepareVersionsForUpdate(
                    newVersionsToUpdate,
                    this.creativesetDataService.creativeset
                );

                return this.versionsDataService
                    .updateVersions(creativesetId, versions, dirtyCreatives)
                    .pipe(
                        map(updatedVersion =>
                            VersionsActions.updateVersionsSuccess({
                                versions: updatedVersion
                            })
                        ),
                        catchError(error => of(VersionsActions.updateVersionsFailure({ error })))
                    );
            })
        );
    });

    updateVersionsAndCreatives$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(VersionsActions.updateVersionsAndCreatives),
            concatLatestFrom(() => [
                this.versionsService.versions$,
                this.versionsService.defaultVersion$
            ]),
            switchMap(([{ versions, creatives }, allVersions, defaultVersion]) => {
                const updatedVersions = versions;
                const updatedDesigns = this.getDesigns(creatives);
                if (!updatedVersions.length || !updatedDesigns.length) {
                    return EMPTY;
                }
                return this.creativesetDataService
                    .updateDesignsInCreativeset(
                        allVersions,
                        updatedDesigns,
                        updatedVersions,
                        defaultVersion
                    )
                    .pipe(
                        map(response => {
                            if (isKnownErrorResponse(response)) {
                                return VersionsActions.updateVersionsFailure({
                                    error: response
                                });
                            }
                            if (!response) {
                                return VersionsActions.updateVersionsFailure({
                                    error: new Error('Creativeset not in response')
                                });
                            }

                            return VersionsActions.updateVersionsSuccess({
                                versions: response.versions
                            });
                        }),
                        catchError(error => of(VersionsActions.updateVersionsFailure({ error })))
                    );
            })
        );
    });

    setDefaultVersion$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(VersionsActions.setDefaultVersion),
            concatLatestFrom(() => this.versionsService.creativesetId$),
            switchMap(([{ version }, creativesetId]) => {
                return this.versionsDataService.setDefaultVersion(creativesetId, version).pipe(
                    map(result =>
                        VersionsActions.setDefaultVersionSuccess({
                            version: result
                        })
                    ),
                    catchError(error => of(VersionsActions.setDefaultVersionFailure({ error })))
                );
            })
        );
    });

    versionError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(
                    VersionsActions.deleteVersionsFailure,
                    VersionsActions.setDefaultVersionFailure,
                    VersionsActions.createVersionFailure,
                    VersionsActions.updateVersionsFailure
                ),
                tap(({ error, type }) => {
                    this.uiNotificationService.open(`Could not update versions`, {
                        type: 'error',
                        placement: 'top',
                        autoCloseDelay: 5000
                    });

                    if (isApolloError(error)) {
                        const primaryError = getPrimaryGraphQlError(
                            error.graphQLErrors,
                            error.networkError
                        );
                        throw new Error(`${type}: ${primaryError.message}`);
                    }

                    throw new Error(`${type}: ${error}`);
                })
            );
        },
        { dispatch: false }
    );

    removeVersionPropFromSelectedVersions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(VersionsActions.removeVersionPropertiesFromSelectedVersions),
            concatLatestFrom(() => this.filtersService.selectedVersionIds$),
            switchMap(([{ propertyIdsToRemove }, selectedVersionIds]) => {
                return of(
                    VersionsActions.removeVersionPropertiesFromVersions({
                        propertyIdsToRemove,
                        versionIds: [selectedVersionIds[0]]
                    })
                );
            })
        );
    });

    addVersionPropertiesToSelected$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(VersionsActions.addVersionPropertiesToSelected),
            concatLatestFrom(() => this.filtersService.selectedVersionIds$),
            switchMap(([{ versionProperties }, selectedVersionIds]) => {
                return of(
                    VersionsActions.addVersionProperties({
                        versionProperties,
                        versionId: selectedVersionIds[0]
                    })
                );
            })
        );
    });

    addVersionPropertiesToDefault$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(VersionsActions.addVersionPropertiesToDefault),
            concatLatestFrom(() => this.versionsService.defaultVersion$),
            switchMap(([{ versionProperties }, defaultVersion]) => {
                return of(
                    VersionsActions.addVersionProperties({
                        versionProperties,
                        versionId: defaultVersion.id
                    })
                );
            })
        );
    });

    removeVersionPropFromAllVersions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(VersionsActions.removeVersionPropertiesFromAllVersions),
            concatLatestFrom(() => this.versionsService.versionIds$),
            switchMap(([{ propertyIdsToRemove }, versionIds]) => {
                return of(
                    VersionsActions.removeVersionPropertiesFromVersions({
                        propertyIdsToRemove,
                        versionIds
                    })
                );
            })
        );
    });

    private mapTranslationsToVersionProperties(
        translations: string[],
        translationMap: TranslatedTextToVersionProperty[]
    ): IVersionProperty<IVersionedText>[] {
        const newVersionProperties: IVersionProperty<IVersionedText>[] = [];
        for (let i = 0; i < translationMap.length; i++) {
            const { versionProperty } = translationMap[i];
            const newText = translations[i];
            const newStyles: ITextSpan[] = getNewStylesForTranslatedText(
                versionProperty.value,
                newText
            );

            newVersionProperties.push({
                ...versionProperty,
                value: {
                    ...versionProperty.value,
                    text: newText,
                    styles: newStyles
                }
            });
        }
        return newVersionProperties;
    }

    private getTextsFromVersion(properties: IVersionProperty[]): TranslatedTextToVersionProperty[] {
        return properties
            .filter(isVersionedText)
            .filter(prop => prop.value.text.length > 0)
            .map(property => ({
                text: property.value.text,
                versionProperty: property
            }));
    }

    private getDesigns(creatives: ICreative[]): IDesign[] {
        return creatives
            .map(({ design }) => design)
            .filter((design): design is IDesign => !!design)
            .reduce<IDesign[]>((acc, curr) => [...acc.filter(({ id }) => id !== curr.id), curr], []);
    }
}
