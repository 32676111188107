import { IElementProperty } from '@domain/creativeset/element';
import { IVersionProperty } from '@domain/creativeset/version';
import { FeedType, IFeed, IFeedData, IFeedDataDto, IFeedDataKeyValueItem } from '@domain/feed';
import { OneOfElementDataNodes } from '@domain/nodes';
import { IText, ITextVariable } from '@domain/text';
import { IWidgetCustomProperty, IWidgetElementProperty } from '@domain/widget';
import { isTextNode, isWidgetNode } from '../../nodes/helpers';
import { deserializeFeedString } from '../../serialization/property-value-serializer';
import { isVariableSpan } from '../rich-text/text-nodes';

export function normalizeFeedPath(path: string): string {
    return path.replace(/\s+/g, ' ').trim();
}

export function normalizeFeedData(feedData: IFeedDataDto): IFeedData {
    const normalizedFeedData: IFeedData = {
        data: [],
        intervalInSeconds: feedData.intervalInSeconds
    };

    for (const item of feedData.data) {
        const feedValue: IFeedDataKeyValueItem = {};

        for (const key of Object.keys(item)) {
            const path = normalizeFeedPath(key);
            const value = item[key].value;
            const targetUrl = item[key].targetUrl;
            feedValue[path] = {
                value: value === null ? undefined : value,
                targetUrl: targetUrl === null ? undefined : targetUrl
            };
        }

        normalizedFeedData.data.push(feedValue);
    }

    return normalizedFeedData;
}

export function encodeFeedPath(path: string): string {
    return path.replace(/\s/g, '%20');
}

export function decodeFeedPath(path: string): string {
    return path.replace(/%20/g, ' ');
}

export function createFeed(id: string, path = '', type: FeedType = 'text'): IFeed {
    const feed: IFeed = {
        id: id,
        path: encodeFeedPath(path ?? ''),
        step: {
            occurrence: 'loop',
            size: 1,
            start: 1
        },
        fallback: '',
        type: type
    };
    return feed;
}

export function createVariable(id: string, path = ''): ITextVariable {
    return { ...createFeed(id, path, 'text'), type: 'text' };
}

export function isCustomFeedProperty(customProperty: IWidgetCustomProperty): boolean {
    return customProperty.unit === 'feed';
}

export function isFeedValue(value: unknown): value is IFeed {
    if (!value || typeof value !== 'object') {
        return false;
    }

    if ('id' in value && 'path' in value && 'step' in value) {
        return true;
    }

    return false;
}

export function isVersionedFeed(
    property: IVersionProperty | IElementProperty | IWidgetElementProperty | IWidgetCustomProperty
): property is IVersionProperty<IFeed> {
    if (isFeedValue(property.value)) {
        return true;
    }

    return false;
}

export function hasTextFeed(element: { __dirtyContent?: IText; content: IText }): boolean {
    const content = element.__dirtyContent?.spans || element.content.spans;
    for (const span of content) {
        if (isVariableSpan(span)) {
            return true;
        }
    }
    return false;
}

export function hasWidgetFeed(element: { customProperties: IWidgetCustomProperty[] }): boolean {
    for (const property of element.customProperties) {
        try {
            if (isCustomFeedProperty(property)) {
                const feed = deserializeFeedString(property.value) as IFeed | undefined;
                if (feed?.id) {
                    return true;
                }
            }
        } catch {
            throw new Error(
                `Failed to parse widget property ${property.unit} - "${property.name}" value "${property.value}"`
            );
        }
    }
    return false;
}

export function hasFeededContent(element: OneOfElementDataNodes): boolean {
    if (element.feed) {
        return true;
    }

    if (isTextNode(element)) {
        const hasFeed = hasTextFeed(element);
        if (hasFeed) {
            return true;
        }
    }

    if (isWidgetNode(element)) {
        const hasFeed = hasWidgetFeed(element);
        if (hasFeed) {
            return true;
        }
    }

    return false;
}

// extracts all feed ids from a given element. Text & widgets can contain multiple feeds.
export function getFeedIds(element: OneOfElementDataNodes): string[] {
    if (element.feed) {
        return [element.feed.id];
    }
    const feedIds = new Set<string>();

    if (isTextNode(element)) {
        const content = element.__dirtyContent?.spans || element.content.spans;
        for (const span of content) {
            if (isVariableSpan(span) && span.style.variable) {
                feedIds.add(span.style.variable.id);
            }
        }
    }

    if (isWidgetNode(element)) {
        for (const property of element.customProperties) {
            try {
                if (isCustomFeedProperty(property)) {
                    const feed = deserializeFeedString(property.value);
                    if (feed?.id) {
                        feedIds.add(feed.id);
                    }
                }
            } catch {
                throw new Error(
                    `Failed to parse widget property ${property.unit} - "${property.name}" value "${property.value}"`
                );
            }
        }
    }

    return [...feedIds];
}

export function isFeedpathProperty(property: Omit<IElementProperty, 'id'>): boolean {
    return property.name.indexOf('feedPath') > -1;
}
