<div class="side-panel ui-scrollbar">
    <size-add-tabs (selectedTabChange)="selectTab($event)">
        <div tab1>
            <ng-container *ngTemplateOutlet="sizesContentTemplate" />
        </div>
        <div tab2>
            <ng-container *ngTemplateOutlet="psdContentTemplate" />
        </div>
    </size-add-tabs>
</div>

<ng-template #sizesContentTemplate>
    @if (sizeCollectionEnabled()) {
        <size-add-custom-input (customSizeAdded)="addSizeToCustomSizesCollection($event)" />
        <size-add-collection-select />
        <size-add-collection-overview />
        <size-add-collection-list />
    } @else {
        <size-add-custom-input (customSizeAdded)="addCustomSize($event)" />
        <size-add-overview
            label="Sizes selected"
            [currentSelected]="addedSizes().length"
            (toggleAll)="toggleAllSizes()" />
        <div
            id="size-list"
            class="size-lists">
            @for (sizeCategory of sizeCategories(); track sizeCategory.label) {
                @if (sizeCategory.sizes.length > 0) {
                    <size-list
                        [label]="sizeCategory.label"
                        [type]="sizeCategory.type"
                        [stickyHeight]="sizeCategory.stickyHeight"
                        [collapsed]="!!sizeCategory.collapsed"
                        [sizes]="sizeCategory.sizes"
                        [addMultipleSizes]="true"
                        (sizeSelected)="selectSize($event)"
                        (sizesChanged)="selectedSizes($event, sizeCategory)" />
                }
            }
        </div>
    }
</ng-template>

<ng-template #psdContentTemplate>
    @if (selectedTab === Tabs.PSD_IMPORT) {
        <psd-list />
    }
</ng-template>

@switch (selectedTab) {
    @case (Tabs.SIZE_ADD) {
        <size-add-preview
            [sizes]="addedSizes()"
            (onCancel)="close()" />
    }
    @case (Tabs.PSD_IMPORT) {
        <div class="psd-preview-container">
            <psd-import-preview (onCancel)="close()" />
            <psd-fix-font-panel />
        </div>
    }
}
