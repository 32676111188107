import { ILineColumnPosition, ILineColumnPositionDirection, ITextPosition } from '@domain/rich-text';
import { IRichText } from '@domain/rich-text/rich-text.header';
import {
    CharacterOffset,
    CharacterPositionEndpoint,
    ICharacterPosition,
    IRichTextSelectionService,
    ITextSelection,
    TextDirectionResolvement
} from '@domain/rich-text/rich-text.selection.header';
import {
    CharacterPropertyKeys,
    ICharacterProperties,
    IContentLine,
    ISpanProperties,
    IWordSpan,
    OneOfContentSpans,
    OneOfEditableSpans,
    ResolvedTextDirection,
    SpanType,
    TextDirection
} from '@domain/text';
import { __parent, parent } from '@studio/utils/di';
import { getStringByExcludingZeroWidthJoints } from '@studio/utils/dom-utils';
import { mod } from '@studio/utils/utils';
import { toRGBA } from '../../color.utils';
import {
    deserializeTextStyle,
    serializeTextStyle
} from '../../serialization/property-value-serializer';
import { RichText } from './rich-text';
import { ARABIC_DIACRTIC_RANGE_MAP } from './rich-text.bidi-chars';
import { RichTextEditorService } from './rich-text.editor';
import { TextSelection } from './rich-text.selection.text';
import { isSpanMergeable_m } from './rich-text.span.utils';
import { isContentSpan, isNewlineLikeCharacter, isSpaceLikeCharacter } from './text-nodes';

export const SELECTION_FOCUSED_BACKGROUND_COLOR = '#b8d5ff';

export class RichTextSelectionService implements IRichTextSelectionService {
    selection: ITextSelection | undefined;
    verticalFocusColumn: number | undefined = 0;
    mouseDownPosition?: ILineColumnPositionDirection;
    currentMousePosition: ILineColumnPositionDirection;
    lineToSelectionLineElementMap = new Map<number, HTMLDivElement>();
    caretElement: HTMLDivElement;
    caretAnimationIsVisible = false;
    startSelectionCharacterPosition: ICharacterPosition;
    endSelectionCharacterPosition: ICharacterPosition;
    caretIntervalReference: number;

    private jumpToNextTextNode = false;

    constructor(@parent() private editor: RichTextEditorService) {}

    get text(): IRichText {
        return this.editor.text;
    }

    isElementSelected(): boolean {
        if (!this.selection) {
            return true;
        }
        return false;
    }

    unselect(): void {
        this.selection = undefined;
    }

    updateSelection(updateCurrentStyle = true): void {
        if (!this.selection) {
            for (const selectionLineElement of this.lineToSelectionLineElementMap.values()) {
                selectionLineElement.innerHTML = '';
            }
            return;
        }

        if (updateCurrentStyle && !this.editor.keyboardBindings.isBackwardErasure) {
            this.editor.currentStyle = this.getFirstStyleFromSelection();
            this.editor.currentProperties = this.getFirstPropertiesFromSelection();
        }

        this.text.emit('hidevariablesettings');
        this.clearCaretAndTextSelection();
        const start = this.selection.start;
        const end = this.selection.end;

        if (this.selection.isCollapsed) {
            this.startCaretInterval();
        } else {
            this.text.selectionSpanElements_m = [];
            let encounteredStartSpan = false;
            outer: for (let lineIndex = 0; lineIndex < this.text.textLines_m.length; lineIndex++) {
                const textLine = this.text.textLines_m[lineIndex];
                let column = 0;

                for (const span of textLine.spans) {
                    if (span.type === SpanType.End) {
                        continue;
                    }
                    const columnLength = span.content.length;
                    const isStartLine = lineIndex === start.line;
                    const isEndLine = lineIndex === end.line;
                    const isStartSpan =
                        isStartLine &&
                        column <= start.column &&
                        start.column < column + span.content.length;
                    const isEndSpan =
                        isEndLine && column <= end.column && end.column <= column + span.content.length;
                    const spanDir = span.dir as TextDirection.Ltr | TextDirection.Rtl;
                    if (isStartSpan && isEndSpan) {
                        const startSpanColumn = start.column - column;
                        const endSpanColumn = end.column - column;
                        const startTextPosition = this.getTextPosition(
                            startSpanColumn,
                            spanDir,
                            span.__viewElement!
                        )!;
                        const endTextPosition = this.getTextPosition(
                            endSpanColumn,
                            spanDir,
                            span.__viewElement!
                        )!;
                        this.renderSelectionLine(lineIndex, startTextPosition, endTextPosition);
                        break outer;
                    }
                    if (isStartSpan) {
                        const spanColumn = start.column - column;
                        const startTextPosition = this.getTextPosition(
                            spanColumn,
                            spanDir,
                            span.__viewElement!
                        )!;
                        const endTextPosition = this.getTextPosition(
                            span.content.length,
                            spanDir,
                            span.__viewElement!
                        )!;
                        this.renderSelectionLine(lineIndex, startTextPosition, endTextPosition);
                        encounteredStartSpan = true;
                        column += columnLength;
                        continue;
                    }
                    if (isEndSpan && span.type !== SpanType.Variable) {
                        const spanColumn = end.column - column;
                        const startTextPosition = this.getTextPosition(
                            0,
                            spanDir,
                            span.__viewElement!
                        )!;
                        const endTextPosition = this.getTextPosition(
                            spanColumn,
                            spanDir,
                            span.__viewElement!
                        )!;
                        this.renderSelectionLine(lineIndex, startTextPosition, endTextPosition);
                        break outer;
                    }
                    if (encounteredStartSpan) {
                        const startTextPosition = this.getTextPosition(
                            0,
                            spanDir,
                            span.__viewElement!
                        )!;
                        const endTextPosition = this.getTextPosition(
                            span.content.length,
                            spanDir,
                            span.__viewElement!
                        )!;
                        this.renderSelectionLine(lineIndex, startTextPosition, endTextPosition);
                    }
                    column += columnLength;
                }
            }
        }
    }

    selectText(
        anchor: ILineColumnPositionDirection,
        focus: ILineColumnPositionDirection,
        saveFocusColumn = true,
        emitSelectionChange = true,
        updateCurrentStyle = true
    ): void {
        const selection = new TextSelection(anchor, focus);
        this.selection = selection;
        this.updateSelection(updateCurrentStyle);

        // Save vertical focus column, it's used later for arrow key navigations.
        if (saveFocusColumn) {
            this.verticalFocusColumn = focus.column;
        }
        if (emitSelectionChange) {
            this.editor.onTextSelectionChange$?.next(this.selection);
        }
    }

    selectAllText(quiet = false): void {
        const start = {
            line: 0,
            column: 0,
            dir: this.text.textLines_m[0].spans[0].dir as ResolvedTextDirection
        };
        const lastLine = this.editor.text.textLines_m[this.editor.text.textLines_m.length - 1];
        const lastDir = lastLine.spans[lastLine.spans.length - 1].dir as ResolvedTextDirection;
        this.selectText(
            start,
            {
                line: this.editor.text.textLines_m.length - 1,
                column: lastLine.characterWidth,
                dir: lastDir
            },
            /* saveFocusColumn */ true,
            /* emitSelectionChange */ !quiet
        );
        this.editor.keyboardBindings.textarea?.focus();
    }

    selectWord(): void {
        const mouseDownPosition = this.mouseDownPosition!;
        const lineElement = this.editor.text.lineToTextLineElementMap_m.get(mouseDownPosition.line)!;
        const [startColumn, endColumn] = this.getWordTextRange(
            mouseDownPosition.column,
            getStringByExcludingZeroWidthJoints(lineElement)
        );
        this.selectText(
            this.setDirectionInPosition({ line: mouseDownPosition.line, column: startColumn }),
            this.setDirectionInPosition({ line: mouseDownPosition.line, column: endColumn })
        );
        this.currentMousePosition = this.setDirectionInPosition({
            line: mouseDownPosition.line,
            column: endColumn
        });
    }

    selectCurrentWordSpan(): void {
        if (!this.selection || !this.selection.isCollapsed) {
            return;
        }
        const startSelection = this.selection.start;
        let startSpan: IWordSpan | undefined;
        let startPosition: ILineColumnPosition | undefined;
        let endPosition: ILineColumnPosition | undefined;
        let crossedSelectionPosition = false;
        let line = 0;
        outer: for (const textLine of this.text.textLines_m) {
            let column = 0;
            for (const span of textLine.spans) {
                if (span.type !== SpanType.Word) {
                    if (crossedSelectionPosition) {
                        break outer;
                    }
                    startPosition = undefined;
                    startSpan = undefined;
                    column += span.content.length;
                    continue;
                }
                if (!startSpan) {
                    startPosition = { line, column };
                    startSpan = span;
                }
                if (isSpanMergeable_m(span, startSpan)) {
                    endPosition = { line, column: column + span.content.length };
                } else {
                    if (crossedSelectionPosition) {
                        break outer;
                    }
                    startPosition = { line, column };
                    endPosition = { line, column: column + span.content.length };
                    startSpan = span;
                }
                column += span.content.length;
                if (line >= startSelection.line && column >= startSelection.column) {
                    crossedSelectionPosition = true;
                }
            }
            line++;
        }
        if (startPosition && endPosition) {
            this.selectText(
                this.setDirectionInPosition(startPosition),
                this.setDirectionInPosition(endPosition)
            );
        }
    }

    getWordTextRange(column: number, lineText: string): [number, number] {
        // Select text backwards if the column is in the last column.
        if (column === lineText.length && column !== 0) {
            column--;
        }
        let startColumn = column;
        let endColumn = column;
        const ch = lineText.charCodeAt(column);
        if (isSpaceLikeCharacter(ch)) {
            while (isSpaceLikeCharacter(lineText.charCodeAt(startColumn)) && startColumn >= 0) {
                startColumn--;
            }
            while (
                isSpaceLikeCharacter(lineText.charCodeAt(endColumn)) &&
                endColumn !== lineText.length
            ) {
                endColumn++;
            }
        } else {
            while (!isSpaceLikeCharacter(lineText.charCodeAt(startColumn)) && startColumn >= 0) {
                startColumn--;
            }
            while (
                !isSpaceLikeCharacter(lineText.charCodeAt(endColumn)) &&
                endColumn !== lineText.length
            ) {
                endColumn++;
            }
        }
        return [startColumn + 1, endColumn];
    }

    setCaret(
        position: ILineColumnPositionDirection,
        saveFocusColumn = true,
        updateCurrentStyle = true
    ): void {
        this.selectText(position, position, saveFocusColumn, true, updateCurrentStyle);
    }

    getCurrentCaretPosition(): ILineColumnPositionDirection | undefined {
        if (!this.selection) {
            return undefined;
        }
        return Object.assign({}, this.selection.start);
    }

    getStartOfLinePosition(): ILineColumnPositionDirection {
        const line = this.selection!.focus.line;
        return this.setDirectionInPosition({ line, column: 0 });
    }

    getEndOfLinePosition(): ILineColumnPositionDirection {
        const line = this.selection!.focus.line;
        let column: number;
        if (line === this.editor.text.textLines_m.length - 1) {
            column = this.editor.text.textLines_m[line].characterWidth;
        } else {
            column = this.editor.text.textLines_m[line].characterWidth - 1;
        }
        return this.setDirectionInPosition({ line, column });
    }

    getParagraphStartPosition(): ILineColumnPositionDirection {
        let line = this.editor.text.textLines_m.length - 1;
        const focus = this.selection!.focus;
        let reachedFocus = false;
        for (const textLine of this.editor.text.textLines_m.slice().reverse()) {
            if ((reachedFocus || focus.line === 0) && line === 0) {
                return this.setDirectionInPosition({ line: 0, column: 0 });
            }
            if (line >= focus.line) {
                line--;
                if (line === focus.line) {
                    reachedFocus = true;
                }
                continue;
            }
            if (textLine.endsWithNewline) {
                if (focus.line === line + 1 && focus.column === 0) {
                    line--;
                    continue;
                }
                return this.setDirectionInPosition({ line: line + 1, column: 0 });
            }
            line--;
        }
        throw new Error('Could not find paragraph start position.');
    }

    getParagraphEndPosition(): ILineColumnPositionDirection {
        let line = 0;
        const focus = this.selection!.focus;
        for (const textLine of this.editor.text.textLines_m) {
            if (line < focus.line) {
                line++;
                continue;
            }
            if (textLine.endsWithNewline || textLine.isLastLine) {
                let column: number;
                if (textLine.isLastLine) {
                    column = textLine.characterWidth;
                } else {
                    column = textLine.characterWidth - 1;
                    if (focus.line === line && focus.column === column) {
                        line++;
                        continue;
                    }
                }
                return this.setDirectionInPosition({ line, column });
            }
            line++;
        }
        throw new Error('Could not find paragraph end position.');
    }

    getEndPosition(): ILineColumnPositionDirection {
        const lastLine = this.editor.text.textLines_m.length - 1;
        const lastColumn = this.editor.text.textLines_m[lastLine].characterWidth;
        return this.setDirectionInPosition({
            line: lastLine,
            column: lastColumn
        });
    }

    getPreviousWordPosition(): ILineColumnPositionDirection {
        const focus = this.selection!.focus;
        const previousPosition = this.getPositionByOffset(focus, -1);
        const previousSpan = this.getContentSpanByPosition(previousPosition);
        const previousSpanPosition: ILineColumnPositionDirection = this.setDirectionInPosition({
            line: previousSpan.line!,
            column: previousSpan.column!
        });
        if (previousSpan.type === SpanType.Space) {
            const previousPreviousDifferentSpan = this.getPreviousContentSpan(previousSpan);
            return {
                line: previousPreviousDifferentSpan.line!,
                column: previousPreviousDifferentSpan.column!,
                dir: previousPreviousDifferentSpan.dir as ResolvedTextDirection
            };
        }
        return previousSpanPosition;
    }

    getNextWordPosition(): ILineColumnPositionDirection {
        const focus = this.selection!.focus;
        const nextPosition = this.getPositionByOffset(focus, 1);
        const nextSpan = this.getContentSpanByPosition(nextPosition);
        const nextSpanPosition: ILineColumnPositionDirection = {
            line: nextSpan.line!,
            column: nextSpan.column! + nextSpan.content.length,
            dir: nextSpan.dir as ResolvedTextDirection
        };
        if (nextSpan.type === SpanType.Space) {
            const nextNextDifferentSpan = this.getNextContentSpan(nextSpan);
            return {
                line: nextNextDifferentSpan.line!,
                column: nextNextDifferentSpan.column! + nextNextDifferentSpan.content.length,
                dir: nextNextDifferentSpan.dir as ResolvedTextDirection
            };
        }
        return nextSpanPosition;
    }

    reselectText(): void {
        const startCaretPosition = this.getLineColumnPositionFromCharacterPosition(
            '',
            this.startSelectionCharacterPosition
        );
        const endCaretPosition = this.getLineColumnPositionFromCharacterPosition(
            '',
            this.endSelectionCharacterPosition
        );
        this.editor.selection.selectText(
            startCaretPosition,
            endCaretPosition,
            /* saveFocusColumn */ true,
            /* emitSelectionChange */ false
        );
    }

    storeSelectionCharacterPositions(): void {
        this.startSelectionCharacterPosition = this.getCharacterPositionFromCaretPosition(
            this.selection!.start,
            this.text.textLines_m
        );
        this.endSelectionCharacterPosition = this.getCharacterPositionFromCaretPosition(
            this.selection!.end,
            this.text.textLines_m
        );
    }

    getCharacterPositionFromCaretPosition(
        caretPosition: ILineColumnPosition,
        textLines?: IContentLine[]
    ): ICharacterPosition {
        const _textLines = textLines || this.text.textLines_m;

        // Can occur when inserting/replacing text content with feed variables
        if (caretPosition.line > _textLines.length - 1) {
            caretPosition.line = _textLines.length - 1;
            caretPosition.column = _textLines[caretPosition.line].characterWidth;
        }

        let position = 0;
        for (let i = 0; i < _textLines.length; i++) {
            const textLine = _textLines[i];
            if (i === caretPosition.line) {
                position += caretPosition.column;
                return {
                    characters: position,
                    endpoint:
                        caretPosition.column === 0
                            ? CharacterPositionEndpoint.End
                            : CharacterPositionEndpoint.Start
                };
            }
            position += textLine.characterWidth;
        }
        throw new Error('Could not get character position from caret position.');
    }

    getLineColumnPositionFromCharacterPosition(
        textOffset: string,
        characterPosition: ICharacterPosition,
        textLines?: IContentLine[]
    ): ILineColumnPositionDirection {
        const _textLines = textLines || this.text.textLines_m;
        let column = characterPosition.characters;
        let lineColumnPosition: ILineColumnPosition | undefined;
        outer: for (let i = 0; i < _textLines.length; i++) {
            const textLine = _textLines[i];
            const characterWidth = textLine.characterWidth;
            if (column === 0) {
                lineColumnPosition = {
                    line: i,
                    column
                };
                break outer;
            } else if (characterWidth > column) {
                lineColumnPosition = {
                    line: i,
                    column
                };
                break outer;
            } else if (column === characterWidth) {
                if (characterPosition.endpoint === CharacterPositionEndpoint.Start) {
                    lineColumnPosition = {
                        line: i,
                        column
                    };
                } else {
                    lineColumnPosition = {
                        line: i + 1,
                        column: 0
                    };
                }
                break outer;
            }
            column -= characterWidth;
        }
        if (!lineColumnPosition) {
            throw new Error('Could not find caret position.');
        }
        const characterOffsets = this.getCharacterOffsetsFromText(textOffset);
        for (const offset of characterOffsets) {
            switch (offset) {
                case CharacterOffset.Character:
                    if (
                        lineColumnPosition.column + 1 >
                        _textLines[lineColumnPosition.line].characterWidth
                    ) {
                        lineColumnPosition.line++;
                        lineColumnPosition.column = 1;
                    } else {
                        lineColumnPosition.column++;
                    }
                    break;
                case CharacterOffset.Newline:
                    lineColumnPosition.line++;
                    if (
                        this.text.style.maxRows > 0 &&
                        lineColumnPosition.line >= this.text.style.maxRows
                    ) {
                        lineColumnPosition.line = this.text.style.maxRows - 1;
                    } else {
                        lineColumnPosition.column = 0;
                    }
                    break;
                default:
                    throw new Error('Unknown character offset.');
            }
        }
        return this.setDirectionInPosition(lineColumnPosition);
    }

    setDirectionInPosition(position: ILineColumnPosition): ILineColumnPositionDirection {
        for (let line = 0; line < this.text.textLines_m.length; line++) {
            if (line !== position.line) {
                continue;
            }
            const textLine = this.text.textLines_m[line];
            let column = 0;
            for (let i = 0; i < textLine.spans.length; i++) {
                const span = textLine.spans[i];
                if (span.type === SpanType.End) {
                    return { ...position, dir: TextDirection.Ltr };
                }
                const lastColumn = column + span.content.length;
                if (column >= position.column || position.column <= lastColumn) {
                    return { ...position, dir: span.dir as ResolvedTextDirection };
                }
                column = lastColumn;
            }
        }
        throw new Error(`Could not set dir in position {${position.line},${position.column}}.`);
    }

    private getContentSpanByPosition(position: ILineColumnPosition): OneOfContentSpans {
        let line = 0;
        let column = 0;
        for (const textLine of this.editor.text.textLines_m) {
            column = 0;
            if (line !== position.line) {
                line++;
                continue;
            }
            for (const span of textLine.spans) {
                if (isContentSpan(span)) {
                    if (position.column <= column + span.content.length) {
                        return span;
                    }
                    column += span.content.length;
                }
            }
            line++;
        }
        throw new Error('Cannot get span type.');
    }

    private getPositionByOffset(position: ILineColumnPosition, offset: number): ILineColumnPosition {
        if (offset === 0) {
            return position;
        }
        if (offset < 0) {
            let line = this.editor.text.textLines_m.length - 1;
            let reachedPosition = false;
            let remainderColumns = 0;
            for (const textLine of this.editor.text.textLines_m.slice().reverse()) {
                if (reachedPosition) {
                    remainderColumns -= 1; // Remove extra column in the end of line
                    if (textLine.endsWithNewline) {
                        remainderColumns += 1; // Adds an extra remainder to skip newlines.
                    }
                    if (textLine.characterWidth >= remainderColumns) {
                        return { line, column: textLine.characterWidth - remainderColumns };
                    }
                    remainderColumns -= textLine.characterWidth;
                }
                if (line === position.line) {
                    if (position.column + offset >= 0) {
                        return { line, column: position.column + offset };
                    }
                    reachedPosition = true;
                    remainderColumns = -offset;
                }
                line--;
            }
            return { line: 0, column: 0 };
        } else {
            let line = 0;
            let reachedPosition = false;
            let remainderColumns = 0;
            for (const textLine of this.editor.text.textLines_m) {
                if (reachedPosition) {
                    remainderColumns -= 1; // Remove extra column in the end of line
                    if (remainderColumns <= textLine.characterWidth) {
                        return { line, column: remainderColumns };
                    }
                    remainderColumns -= textLine.characterWidth;
                }
                if (line === position.line) {
                    const lineWidth = textLine.endsWithNewline
                        ? textLine.characterWidth - 1
                        : textLine.characterWidth;
                    if (position.column + offset <= lineWidth) {
                        return { line, column: position.column + offset };
                    }
                    reachedPosition = true;
                    remainderColumns = offset;
                }
                line++;
            }
            const lastLine = this.editor.text.textLines_m.length - 1;
            return { line: lastLine, column: this.editor.text.textLines_m[lastLine].characterWidth };
        }
    }

    private getCharacterOffsetsFromText(text: string): CharacterOffset[] {
        const charOffsets: CharacterOffset[] = [];
        for (let i = 0; i < text.length; i++) {
            const ch = text.charCodeAt(i);
            if (isNewlineLikeCharacter(ch)) {
                charOffsets.push(CharacterOffset.Newline);
            } else {
                charOffsets.push(CharacterOffset.Character);
            }
        }
        return charOffsets;
    }

    private getPreviousContentSpan(span: OneOfContentSpans): OneOfContentSpans {
        let line = this.editor.text.textLines_m.length - 1;
        let reachedSpan = false;
        for (const textLine of this.editor.text.textLines_m.slice().reverse()) {
            let column = textLine.characterWidth;
            for (const _span of textLine.spans.slice().reverse()) {
                if (isContentSpan(_span)) {
                    if (reachedSpan) {
                        if (_span.type === SpanType.Newline) {
                            return _span;
                        }
                        if (_span.type !== span.type) {
                            return _span;
                        }
                        continue;
                    }
                    if (line === span.line) {
                        if (span.column! >= column - _span.content.length && span.column! <= column) {
                            if (line === 0 && column - _span.content.length === 0) {
                                return _span;
                            }
                            reachedSpan = true;
                            continue;
                        }
                    }
                    column -= _span.content.length;
                }
            }
            line--;
        }
        throw new Error('Could find previous span.');
    }

    private getNextContentSpan(span: OneOfContentSpans): OneOfContentSpans {
        let line = 0;
        let reachedSpan = false;
        for (const textLine of this.editor.text.textLines_m) {
            let column = 0;
            let lastSpan: OneOfContentSpans | undefined;
            for (const _span of textLine.spans) {
                if (reachedSpan) {
                    if (_span.type === SpanType.End) {
                        return lastSpan!;
                    }
                }
                if (isContentSpan(_span)) {
                    if (reachedSpan) {
                        if (_span.type === SpanType.Newline) {
                            return _span;
                        }
                        if (_span.type !== span.type) {
                            return _span;
                        }
                        continue;
                    }
                    lastSpan = _span;
                    if (line === span.line) {
                        if (span.column! >= column - _span.content.length && span.column! <= column) {
                            reachedSpan = true;
                            continue;
                        }
                    }
                    column += _span.content.length;
                }
            }
            line++;
        }
        throw new Error('Could find previous span.');
    }

    private _getCharacterByCaretPosition(position: ILineColumnPosition): number | undefined {
        const textLine = this.text.textLines_m[position.line];
        let traversedColumns = 0;
        for (const span of textLine.spans) {
            const caret = span.content.length + traversedColumns;
            if (position.column > caret) {
                traversedColumns += span.content.length;
            } else if (position.column <= caret) {
                return (
                    (span as OneOfContentSpans).content.charCodeAt(
                        position.column - traversedColumns
                    ) || undefined
                );
            }
        }
    }

    moveCaretForward(caret: ILineColumnPositionDirection): ILineColumnPositionDirection | undefined {
        if (!this.selection) {
            return;
        }
        let caretPosition: ILineColumnPositionDirection | undefined = Object.assign(
            {},
            caret || this.selection.end
        );
        const textLine = this.editor.text.textLines_m[caretPosition.line];
        const textPosition = this.editor.selection.getTextPosition(
            caretPosition.column + 1,
            caret.dir,
            this.editor.text.lineToTextLineElementMap_m.get(caretPosition.line)!
        )!;
        if (textLine.isLastLine && caretPosition.column >= textLine.characterWidth) {
            // Don't do anything
        } else if (caretPosition.column === this.getLastNavigationColumn(caretPosition.line)) {
            caretPosition.line++;
            caretPosition.column = 0;
        } else if (
            textPosition &&
            this.editor.text.isOfNodeSpanType_m(textPosition.node, SpanType.Variable)
        ) {
            caretPosition.column += getStringByExcludingZeroWidthJoints(textPosition.node).length;
        } else {
            if (this.getTextDirectionFromPosition(caretPosition) === TextDirection.Both) {
                const resolvedDirection = this.getTextDirectionFromPosition(
                    caretPosition,
                    TextDirectionResolvement.Forwards
                ) as ResolvedTextDirection;
                if (this.selection.dir !== resolvedDirection) {
                    caretPosition.dir = resolvedDirection;
                } else {
                    caretPosition.column++;
                }
            } else {
                caretPosition.column++;
            }
        }
        const char = this._getCharacterByCaretPosition(caretPosition);
        if (char && RichText.isInCharacterRange_m(char, ARABIC_DIACRTIC_RANGE_MAP)) {
            caretPosition = this.moveCaretForward(caretPosition);
        }
        return caretPosition;
    }

    moveCaretBackward(caret: ILineColumnPositionDirection): ILineColumnPositionDirection | undefined {
        let caretPosition: ILineColumnPositionDirection | undefined = Object.assign({}, caret);
        const lineElement = this.text.lineToTextLineElementMap_m.get(caretPosition.line)!;
        const textPosition = this.editor.selection.getTextPosition(
            caretPosition.column - 1,
            caret.dir,
            this.text.lineToTextLineElementMap_m.get(caretPosition.line)!
        )!;

        const isVariableSpan = this.text.isOfNodeSpanType_m(textPosition.node, SpanType.Variable);

        if (caretPosition.line === 0 && caretPosition.column === 0) {
            // Don't do anything
        } else if (caretPosition.column === 0) {
            caretPosition.line--;
            caretPosition.column = this.editor.selection.getLastNavigationColumn(caretPosition.line);
        } else if (isVariableSpan) {
            let aggregateColumn = 0;
            outer: for (let i = 0; i < lineElement.childNodes.length; i++) {
                const bidiContainer = lineElement.childNodes[i];
                for (let k = 0; k < bidiContainer.childNodes.length; k++) {
                    const span = bidiContainer.childNodes[k];
                    for (let j = 0; j < span.childNodes.length; j++) {
                        if (span.childNodes[j] === textPosition.node) {
                            break outer;
                        } else {
                            aggregateColumn += getStringByExcludingZeroWidthJoints(
                                span.childNodes[j]
                            ).length;
                        }
                    }
                }
            }

            if (textPosition.offset + aggregateColumn === caretPosition.column) {
                caretPosition.column -= getStringByExcludingZeroWidthJoints(textPosition.node).length;
                if (isVariableSpan && caretPosition.column > 0) {
                    caretPosition.column--;
                }
            } else if (caretPosition.column > aggregateColumn) {
                caretPosition.column--;
            }
        } else {
            if (this.getTextDirectionFromPosition(caretPosition) === TextDirection.Both) {
                const resolvedDirection = this.getTextDirectionFromPosition(
                    caretPosition,
                    TextDirectionResolvement.Backwards
                ) as ResolvedTextDirection;
                if (this.selection!.dir !== resolvedDirection) {
                    caretPosition.dir = resolvedDirection;
                } else {
                    caretPosition.column--;
                }
            } else {
                caretPosition.column--;
            }
        }

        const char = this._getCharacterByCaretPosition(caretPosition);
        if (char && RichText.isInCharacterRange_m(char, ARABIC_DIACRTIC_RANGE_MAP)) {
            caretPosition = this.moveCaretBackward(caretPosition);
        }
        return caretPosition;
    }

    isDirectionalConflictingPosition(position: ILineColumnPosition): boolean {
        return this.getTextDirectionFromPosition(position) === TextDirection.Both;
    }

    getTextDirectionFromPosition(
        position: ILineColumnPosition,
        resolvement?: TextDirectionResolvement
    ): ResolvedTextDirection | TextDirection.Both {
        for (let line = 0; line < this.text.textLines_m.length; line++) {
            const textLine = this.text.textLines_m[line];
            if (line !== position.line) {
                continue;
            }
            let column = 0;
            const lastIndex = textLine.spans.length;
            for (let i = 0; i < lastIndex; i++) {
                const currentSpan = textLine.spans[i] as OneOfContentSpans;
                if (
                    position.column >= column &&
                    position.column <= column + currentSpan.content.length
                ) {
                    const currentBidiDirection = this.getBidiDirectionFromSpan(currentSpan);
                    const prevSpan = textLine.spans[i - 1] as OneOfContentSpans;
                    const nextSpan = textLine.spans[i + 1] as OneOfContentSpans;
                    if (
                        position.column === column &&
                        prevSpan &&
                        this.getBidiDirectionFromSpan(prevSpan) !== currentBidiDirection
                    ) {
                        if (resolvement) {
                            if (resolvement === TextDirectionResolvement.Forwards) {
                                return currentSpan.dir as ResolvedTextDirection;
                            } else {
                                return prevSpan.dir as ResolvedTextDirection;
                            }
                        }
                        return TextDirection.Both;
                    }
                    if (
                        position.column === column + currentSpan.content.length &&
                        nextSpan &&
                        this.getBidiDirectionFromSpan(nextSpan) !== currentBidiDirection
                    ) {
                        if (resolvement) {
                            if (resolvement === TextDirectionResolvement.Forwards) {
                                return nextSpan.dir as ResolvedTextDirection;
                            } else {
                                return currentSpan.dir as ResolvedTextDirection;
                            }
                        }
                        return TextDirection.Both;
                    }
                    return currentBidiDirection;
                }
                column += currentSpan.content.length;
            }
            line++;
        }
        throw new Error(
            `Could not find bidi-direction in position {${position.line},${position.column}}.`
        );
    }

    getOppositeConflictingPosition(
        position: ILineColumnPositionDirection
    ): ILineColumnPositionDirection {
        for (let line = 0; line < this.text.textLines_m.length; line++) {
            const textLine = this.text.textLines_m[line];
            if (line !== position.line) {
                continue;
            }
            let column = 0;
            let sourceDir: ResolvedTextDirection | undefined;
            for (let i = 0; i < textLine.spans.length; i++) {
                const currentSpan = textLine.spans[i] as OneOfContentSpans;
                const lastColumn = column + currentSpan.content.length;
                if (position.column === column && position.dir === currentSpan.dir) {
                    sourceDir = currentSpan.dir;
                    if (sourceDir === TextDirection.Ltr) {
                        return { line, column, dir: TextDirection.Rtl };
                    } else {
                        return { line, column, dir: TextDirection.Ltr };
                    }
                }
                if (position.column === lastColumn && position.dir === currentSpan.dir) {
                    sourceDir = currentSpan.dir;
                    if (sourceDir === TextDirection.Ltr) {
                        for (let j = i + 1; j < textLine.spans.length; j++) {
                            const targetSpan = textLine.spans[j] as OneOfEditableSpans;
                            if (targetSpan.type === SpanType.End) {
                                return { line, column, dir: TextDirection.Rtl };
                            }
                            const targetDir = this.getBidiDirectionFromSpan(targetSpan);
                            if (sourceDir === targetDir) {
                                return { line, column, dir: TextDirection.Rtl };
                            }
                            column += targetSpan.content.length;
                        }
                    } else {
                        column += currentSpan.content.length;
                        for (let j = i + 1; j < textLine.spans.length; j++) {
                            const targetSpan = textLine.spans[j] as OneOfEditableSpans;
                            if (targetSpan.type === SpanType.End) {
                                return { line, column, dir: TextDirection.Ltr };
                            }
                            const targetDir = this.getBidiDirectionFromSpan(targetSpan);
                            if (sourceDir === targetDir) {
                                return { line, column, dir: TextDirection.Ltr };
                            }
                            column += targetSpan.content.length;
                        }
                    }
                    break;
                }
                column = lastColumn;
            }
        }
        throw new Error(`Position is not conflicting {${position.line},${position.column}}.`);
    }

    private getBidiDirectionFromSpan(span: OneOfContentSpans): ResolvedTextDirection {
        switch (span.dir) {
            case TextDirection.Rtl:
            case TextDirection.Ltr:
                return span.dir;
            default:
                throw new Error('Bidi-direction is not resolved');
        }
    }

    decrementCaretPosition(caretPosition: ILineColumnPositionDirection): ILineColumnPositionDirection {
        const copyCaretPosition = { ...caretPosition };
        if (copyCaretPosition.line === 0 && copyCaretPosition.column === 0) {
            // Don't do anything
        } else if (copyCaretPosition.column === 0) {
            copyCaretPosition.line--;
            copyCaretPosition.column =
                this.editor.text.textLines_m[copyCaretPosition.line].characterWidth - 1;
        } else {
            const previousLineIndex = copyCaretPosition.line - 1;
            const previousLine = this.editor.text.textLines_m[copyCaretPosition.line - 1];
            const position = this.getTextPosition(
                copyCaretPosition.column,
                copyCaretPosition.dir,
                this.editor.text.lineToTextLineElementMap_m.get(copyCaretPosition.line)!
            )!;
            if (this.editor.text.isOfNodeSpanType_m(position.node, SpanType.Variable)) {
                copyCaretPosition.column -= getStringByExcludingZeroWidthJoints(position.node).length;
            } else {
                copyCaretPosition.column--;

                // The caret should jump to previous line only if the previous line didn't end with newline
                if (previousLine && !previousLine.endsWithNewline && copyCaretPosition.column === 0) {
                    copyCaretPosition.line = previousLineIndex;
                    copyCaretPosition.column = previousLine.characterWidth;
                }
            }
        }
        return copyCaretPosition;
    }

    selectWordAtCaretPosition({ line, column }: ILineColumnPosition): void {
        const lineElement = this.text.lineToTextLineElementMap_m.get(line);
        if (lineElement && lineElement.textContent) {
            const [startColumn, endColumn] = this.editor.selection.getWordTextRange(
                column,
                lineElement.textContent
            );
            this.editor.selection.selectText(
                this.setDirectionInPosition({ line, column: startColumn }),
                this.setDirectionInPosition({ line, column: endColumn })
            );
        }
    }

    startCaretInterval(): void {
        clearInterval(this.caretIntervalReference);
        // TODO: this should be injected via env
        if (window.bfstudio?.environment.stage !== 'test') {
            this.caretIntervalReference = window.setInterval(this.placeCaret, 400);
        }
        this.editor.selection.caretAnimationIsVisible = true;
        this.placeCaret();
    }

    private placeCaret = (): void => {
        if (this.editor.keyboardBindings.inCompositionMode) {
            this.editor.selection.hideCaret();
            return;
        }
        if (!this.selection || !this.selection.isCollapsed) {
            this.editor.selection.hideCaret();
            return;
        }
        if (
            document.activeElement?.tagName === 'INPUT' ||
            document.activeElement?.tagName === 'BUTTON'
        ) {
            this.editor.selection.hideCaret();
            return;
        }
        const { line, column, dir } = this.selection.start;
        const textLineElement =
            this.text.lineToTextLineElementMap_m.get(line)! ||
            this.text.lineToTextLineElementMap_m.get(0)!;
        const position = this.getTextPosition(column, dir, textLineElement);
        if (!position) {
            return;
        }

        const selectionRange = new Range();
        if (this.text.isOfNodeSpanType_m(position.node, SpanType.Variable)) {
            const pos = Math.min(position.offset, column);
            selectionRange.setStart(position.node, pos);
            selectionRange.setEnd(position.node, pos);
        } else {
            selectionRange.setStart(position.node, position.offset);
            selectionRange.setEnd(position.node, position.offset);
        }
        // Safari is awfully buggy, it returns wrong bounding rect from a collapsed range created from
        // the Range object. To get around this bug we have to select the range first and get the range
        // from selection.
        const selection = window.getSelection()!;
        if (selection.rangeCount > 0) {
            selection.removeAllRanges();
        }
        selection.addRange(selectionRange);

        if (selection.rangeCount === 0) {
            return;
        }

        const caretBoundingRect = selection.getRangeAt(0).getBoundingClientRect();
        if (caretBoundingRect) {
            const rootElementBoundingRect = this.text.rootElement_m.getBoundingClientRect();
            this.editor.selection.caretElement.style.visibility = this.editor.selection
                .caretAnimationIsVisible
                ? 'visible'
                : 'hidden';
            this.editor.selection.caretAnimationIsVisible =
                !this.editor.selection.caretAnimationIsVisible;

            let top: number;
            let left: number;
            let height: number;
            const zoom = this.text.zoom_m;
            const modRotation = this.text.viewElement_m?.rotationZ
                ? mod(-this.text.viewElement_m.rotationZ, 2 * Math.PI)
                : 0;

            // Imagine the following rectangles being rotated:
            //
            //  -------------------
            // |       |           |
            // |      _|top        |
            // |     | |           |
            // |     |_|___________|
            // |                   |
            // |  inner            | outer
            // |                   |
            //  -------------------
            //
            // When it is rotated it generates a bounding client rects that intersects the rectangles at
            // some points. These line intersections are used for calculating the top and left. And it's a
            // matter of simple trigonometry and solving linear equation system to figure out top and left.
            //
            //   y_outer = k_outer * x_outer + m_outer (line equation for outer side)
            //   y_inner = k_inner * x_inner + m_inner (line equation for inner side)
            //
            const outer = rootElementBoundingRect;
            const inner = caretBoundingRect;
            const outerHeight = (this.text.viewElement_m?.height || 0) * zoom;
            const innerWidth = 1 * zoom; // Assume caret is 1px always.
            if (modRotation === 0) {
                left = (inner.left - outer.left) / zoom;
                top = (inner.top - outer.top) / zoom;
                height = inner.height / zoom;
            } else if (modRotation < Math.PI / 2) {
                // Solving outer linear equation
                const outerAngle = Math.PI / 2 - modRotation;
                const outerK = Math.tan(outerAngle);
                const outerLeftX = outer.left;
                const outerBottomX = outerLeftX + outerHeight * Math.cos(outerAngle);
                const outerBottomY = outer.bottom;
                const outerM = outerBottomY - outerK * outerBottomX;
                const outerLeftY = outerK * outerLeftX + outerM;

                // Solving inner linear equation
                const innerK = -1 / outerK;
                const innerLeftX = inner.left;
                const innerAngle = modRotation;
                const innerTopX = innerLeftX + 1 * Math.cos(innerAngle);
                const innerTopY = inner.top;
                const innerM = innerTopY - innerK * innerTopX;
                const innerLeftY = innerK * innerLeftX + innerM;

                // Solving their intersection point
                const intersectionX = (outerM - innerM) / (innerK - outerK);
                const intersectionY = outerK * intersectionX + outerM;

                // Calculate left and top
                left =
                    Math.sqrt((innerLeftY - intersectionY) ** 2 + (innerLeftX - intersectionX) ** 2) /
                    zoom;
                top =
                    Math.sqrt((outerLeftY - intersectionY) ** 2 + (outerLeftX - intersectionX) ** 2) /
                    zoom;
                if (intersectionX < outerLeftX) {
                    top =
                        -Math.sqrt(
                            (outerLeftY - intersectionY) ** 2 + (outerLeftX - intersectionX) ** 2
                        ) / zoom;
                } else {
                    top =
                        Math.sqrt(
                            (outerLeftY - intersectionY) ** 2 + (outerLeftX - intersectionX) ** 2
                        ) / zoom;
                }
                const innerEastY = inner.bottom - innerWidth * Math.sin(innerAngle);
                const innerEastX = inner.right;
                height =
                    Math.sqrt((innerTopY - innerEastY) ** 2 + (innerTopX - innerEastX) ** 2) / zoom;
            } else if (modRotation === Math.PI / 2) {
                left = (outer.bottom - inner.bottom) / zoom;
                top = (inner.left - outer.left) / zoom;
                height = inner.width / zoom;
            } else if (modRotation < Math.PI) {
                // Solving outer linear equation
                const outerAngle = modRotation - Math.PI / 2;
                const outerK = -Math.tan(outerAngle);
                const outerRightX = outer.right;
                const outerBottomX = outerRightX - outerHeight * Math.cos(outerAngle);
                const outerBottomY = outer.bottom;
                const outerM = outerBottomY - outerK * outerBottomX;

                // Solving inner linear equation
                const innerAngle = Math.PI - modRotation;
                const innerK = -1 / outerK;
                const innerLeftX = inner.left;
                const innerBottomX = innerLeftX + innerWidth * Math.cos(innerAngle);
                const innerBottomY = inner.bottom;
                const innerM = innerBottomY - innerK * innerBottomX;

                // Solving their intersection point
                const intersectionX = (outerM - innerM) / (innerK - outerK);
                const intersectionY = innerK * intersectionX + innerM;

                // Calculate left and top
                left =
                    Math.sqrt(
                        (innerBottomY - intersectionY) ** 2 + (innerBottomX - intersectionX) ** 2
                    ) / zoom;
                if (intersectionX < outerBottomX) {
                    top =
                        -Math.sqrt(
                            (outerBottomY - intersectionY) ** 2 + (outerBottomX - intersectionX) ** 2
                        ) / zoom;
                } else {
                    top =
                        Math.sqrt(
                            (outerBottomY - intersectionY) ** 2 + (outerBottomX - intersectionX) ** 2
                        ) / zoom;
                }
                const innerRightX = inner.right;
                const innerRightY = inner.top - innerWidth * Math.sin(innerAngle);
                height =
                    Math.sqrt((innerBottomY - innerRightY) ** 2 + (innerBottomX - innerRightX) ** 2) /
                    zoom;
            } else if (modRotation === Math.PI) {
                left = (outer.right - inner.right) / zoom;
                top = (outer.bottom - inner.bottom) / zoom;
                height = inner.height / zoom;
            } else if (modRotation < 1.5 * Math.PI) {
                // Solving outer linear equation
                const outerK = -Math.tan(modRotation + Math.PI / 2);
                const outerTopY = outer.top;
                const outerRightX = outer.right;
                const outerRightY = outerTopY + outerHeight * Math.cos(modRotation - Math.PI);
                const outerM = outerRightY - outerK * outerRightX;

                // Solving inner linear equation
                const innerAngle = modRotation - Math.PI;
                const innerK = -1 / outerK;
                const innerBottomY = inner.bottom;
                const innerRightY = innerBottomY - innerWidth * Math.cos(innerAngle);
                const innerRightX = inner.right;
                const innerM = innerRightY - innerK * innerRightX;

                // Solving their intersection point
                const intersectionX = (outerM - innerM) / (innerK - outerK);
                const intersectionY = innerK * intersectionX + innerM;

                // Calculate left and top
                left =
                    Math.sqrt((innerRightY - intersectionY) ** 2 + (innerRightX - intersectionX) ** 2) /
                    zoom;
                top =
                    Math.sqrt((outerRightY - intersectionY) ** 2 + (outerRightX - intersectionX) ** 2) /
                    zoom;
                if (intersectionX > outerRightX) {
                    top =
                        -Math.sqrt(
                            (outerRightY - intersectionY) ** 2 + (outerRightX - intersectionX) ** 2
                        ) / zoom;
                } else {
                    top =
                        Math.sqrt(
                            (outerRightY - intersectionY) ** 2 + (outerRightX - intersectionX) ** 2
                        ) / zoom;
                }
                const innerTopX = inner.left + innerWidth * Math.sin(innerAngle);
                const innerTopY = inner.top;
                height =
                    Math.sqrt((innerRightY - innerTopY) ** 2 + (innerRightX - innerTopX) ** 2) / zoom;
            } else if (modRotation === 1.5 * Math.PI) {
                left = (inner.top - outer.top) / zoom;
                top = (outer.right - inner.right) / zoom;
                height = inner.width / zoom;
            } else {
                // Solving outer linear equation
                const angle = modRotation - 1.5 * Math.PI;
                const outerK = -Math.tan(modRotation + Math.PI / 2);
                const outerLeftX = outer.left;
                const outerTopY = outer.top;
                const outerTopX = outerLeftX + outerHeight * Math.cos(angle);
                const outerM = outerTopY - outerK * outerTopX;

                // Solving inner linear equation
                const innerK = -1 / outerK;
                const innerRightX = inner.right;
                const innerTopY = inner.top;
                const innerTopX = innerRightX - innerWidth * Math.sin(angle);
                const innerM = innerTopY - innerK * innerTopX;

                // Solving their intersection point
                const intersectionX = (outerM - innerM) / (innerK - outerK);
                const intersectionY = innerK * intersectionX + innerM;

                // Calculate left and top
                left =
                    Math.sqrt((innerTopY - intersectionY) ** 2 + (innerTopX - intersectionX) ** 2) /
                    zoom;
                if (intersectionX > outerTopX) {
                    top =
                        -Math.sqrt(
                            (outerTopY - intersectionY) ** 2 + (outerTopX - intersectionX) ** 2
                        ) / zoom;
                } else {
                    top =
                        Math.sqrt((outerTopY - intersectionY) ** 2 + (outerTopX - intersectionX) ** 2) /
                        zoom;
                }
                const innerLeftX = inner.left;
                const innerLeftY = inner.bottom - innerWidth * Math.sin(angle);
                height =
                    Math.sqrt((innerLeftY - innerTopY) ** 2 + (innerLeftX - innerTopX) ** 2) / zoom;
            }
            this.editor.selection.caretElement.style.top = `${Math.round(top!)}px`;
            this.editor.selection.caretElement.style.left = `${Math.round(left!)}px`;
            this.editor.selection.caretElement.style.height = `${Math.round(height!)}px`;
            const textColor = this.editor.currentStyle.textColor || this.text.style.textColor;
            this.editor.selection.caretElement.style.borderLeft = `1px solid ${
                textColor ? toRGBA(textColor) : '#000'
            }`;

            // Make sure the textarea stays inside the textelements.
            this.editor.keyboardBindings.textarea.style.top = `${
                rootElementBoundingRect.top + Math.min(top!, this.text.style.height! - 1)
            }px`;
            this.editor.keyboardBindings.textarea.style.left = `${
                rootElementBoundingRect.left + Math.min(left!, this.text.style.width! - 1)
            }px`;
        }
        selection.removeAllRanges();

        // Awful Safari bug #2. We cannot type any text on the textarea, unless we have made
        // a range selection.
        this.editor.keyboardBindings.textarea.setSelectionRange(0, 0);
        this.editor.keyboardBindings.textarea.focus();
    };

    lastTextPosition: ITextPosition | undefined;
    getTextPosition(
        column: number,
        dir: TextDirection.Ltr | TextDirection.Rtl,
        node: Node,
        aggregateColumn: { value: number } = { value: 0 }
    ): ITextPosition | undefined {
        for (let i = 0; i < node.childNodes.length; i++) {
            const childNode = node.childNodes[i];
            if (childNode.nodeType === Node.TEXT_NODE) {
                if (this.jumpToNextTextNode) {
                    this.jumpToNextTextNode = false;
                    return {
                        node: childNode,
                        offset: 0,
                        dir
                    };
                }
                const textLength = getStringByExcludingZeroWidthJoints(childNode).length;
                if (aggregateColumn.value + textLength > column) {
                    // Don't select parts of variable nodes
                    if (
                        this.text.getSpanTypeString_m(
                            (childNode.parentNode as HTMLElement).dataset.type!
                        ) === SpanType.Variable
                    ) {
                        return {
                            node: childNode,
                            offset: textLength,
                            dir
                        };
                    }
                    const aggregateColumnValue = column - aggregateColumn.value;
                    let offset: number;
                    if (aggregateColumnValue === textLength) {
                        offset = textLength;
                    } else if (aggregateColumnValue === 0) {
                        offset = 0;
                    } else {
                        offset =
                            column -
                            aggregateColumn.value +
                            (childNode.textContent!.includes('\u200D') ? 1 : 0);
                    }
                    return {
                        node: childNode,
                        offset,
                        dir
                    };
                }
                aggregateColumn.value += textLength;
                if (aggregateColumn.value === column) {
                    if (
                        dir !==
                        ((childNode.parentElement as HTMLSpanElement).dir as
                            | TextDirection.Ltr
                            | TextDirection.Rtl)
                    ) {
                        this.jumpToNextTextNode = true;
                        continue;
                    }
                    return {
                        node: childNode,
                        offset: childNode.textContent!.includes('\u200C') ? textLength + 1 : textLength,
                        dir
                    };
                }
            } else {
                const lastTextPos = this.lastTextPosition;
                this.lastTextPosition = undefined;
                const textPosition = this.getTextPosition(column, dir, childNode, aggregateColumn);
                if (
                    lastTextPos &&
                    textPosition &&
                    this.text.isOfNodeSpanType_m(textPosition.node, SpanType.Variable)
                ) {
                    return document.body.contains(lastTextPos.node) ? lastTextPos : textPosition;
                }
                if (textPosition) {
                    return textPosition;
                }
            }
        }
        return this.lastTextPosition;
    }

    renderCaret(): void {
        this.caretElement = document.createElement('div');
        this.caretElement.id = 'caret-element';
        this.caretElement.style.width = '0px';
        this.caretElement.style.display = 'inline-block';
        this.caretElement.style.position = 'absolute';
        this.caretElement.style.height = '100%';
        this.caretElement.style.visibility = 'hidden';
        this.caretElement.style.pointerEvents = 'none';
        this.caretElement.style.borderLeft = `1px solid #000`;
        this.text.rootElement_m.appendChild(this.caretElement);
    }

    hideCaret(): void {
        this.caretAnimationIsVisible = false;
        if (this.caretElement) {
            this.caretElement.style.visibility = 'hidden';
        }
    }

    clearCaretAndTextSelection(): void {
        this.clearSelection();
        this.hideCaret();
    }

    getLastNavigationColumn(line: number): number {
        const textLine = this.text.textLines_m[line];
        if (textLine.endsWithNewline) {
            return textLine.characterWidth - 1;
        } else {
            return textLine.characterWidth;
        }
    }

    getTextSelection(): ITextSelection | undefined {
        if (!this.selection) {
            return;
        }
        return { ...this.selection };
    }

    private getFirstStyleFromSelection(): Partial<ICharacterProperties> {
        let style: Partial<ICharacterProperties> | undefined;
        this.editor.forEachSpanInSelection(span => {
            if (style) {
                return;
            }
            if (isContentSpan(span)) {
                style = {};
                for (const prop in span.style) {
                    const property = prop as CharacterPropertyKeys;
                    let spanStyleValue: ICharacterProperties[CharacterPropertyKeys];
                    if (property === 'fontSize' && span.style[property]) {
                        spanStyleValue = span.style[property] as number;
                    } else {
                        spanStyleValue =
                            span.style[property] !== undefined
                                ? span.style[property]
                                : this.text.style[property];
                    }
                    style[property as string] = deserializeTextStyle(
                        property,
                        serializeTextStyle(property, spanStyleValue)!
                    );
                }
            }
        });
        return style || {};
    }

    private getFirstPropertiesFromSelection(): ISpanProperties {
        let properties: ISpanProperties | undefined;
        this.editor.forEachSpanInSelection(span => {
            if (properties) {
                return;
            }
            if (isContentSpan(span)) {
                properties = { attributes: span.attributes, styleIds: span.styleIds };
            }
        });
        return properties || {};
    }

    // private getRenderedTextPositionFromFormatted(position: ITextPosition): ITextPosition {
    //     let offset = position.offset;
    //     const textNode = position.node;
    //     const textContent = textNode.textContent!;
    //     const rawTextNodeLength = textContent.length;
    //     const textNodeLength = getStringByExcludingZeroWidthJoints(textNode).length;
    //     if (offset === textNodeLength) {
    //         offset = rawTextNodeLength;
    //     }
    //     else if (offset !== 0 && textContent.charCodeAt(0) === CharacterCode.ZeroWidthJoiner) {
    //         offset++;
    //     }
    //     return { node: position.node, offset };
    // }

    private renderSelectionLine(line: number, start: ITextPosition, end: ITextPosition): void {
        const selectionRange = new Range();
        if (start.node === end.node && start.offset === end.offset) {
            return;
        }
        if (this.text.isOfNodeSpanType_m(start.node, SpanType.Variable)) {
            selectionRange.setStart(start.node, 0);
        } else {
            // const renderedTextPosition = this.getRenderedTextPositionFromFormatted(start);
            selectionRange.setStart(start.node, start.offset);
        }
        if (this.text.isOfNodeSpanType_m(end.node, SpanType.Variable)) {
            selectionRange.setEnd(end.node, end.node.textContent!.length);
        } else {
            // const renderedTextPosition = this.getRenderedTextPositionFromFormatted(end);
            selectionRange.setEnd(end.node, end.offset);
        }
        const selectionRangeBoundingRect = selectionRange.getBoundingClientRect();
        if (selectionRangeBoundingRect) {
            const textElementBoundingRect = this.text.textElement_m.getBoundingClientRect();
            const selectionLineElement = this.editor.selection.lineToSelectionLineElementMap.get(line)!;
            const selectionLineBoundingRect = selectionLineElement.getBoundingClientRect();
            const spanElement = document.createElement('div');
            const rotation = -(this.text.viewElement_m?.rotationZ ?? 0);
            const zoom = this.text.zoom_m;
            const outerWidth = this.text.textElement_m.offsetWidth * zoom;
            const outerHeight = this.text.textElement_m.offsetHeight * zoom;
            const lineHeight = this.text.textLines_m[line].lineHeight * zoom;
            let left: number;
            let width: number;

            const modRotation = mod(rotation, 2 * Math.PI);
            if (modRotation === 0) {
                left = (selectionRangeBoundingRect.left - selectionLineBoundingRect.left) / zoom;
                width = selectionRangeBoundingRect.width / zoom;
            } else if (modRotation < Math.PI / 2) {
                const cos = Math.cos(modRotation);
                const sin = Math.sin(modRotation);

                const innerLeftX = selectionRangeBoundingRect.left;
                const innerLeftY = selectionRangeBoundingRect.bottom - cos * lineHeight;
                const innerK = -Math.tan(modRotation - Math.PI / 2);
                const innerM = innerLeftY - innerK * innerLeftX;

                const outerLeftX = textElementBoundingRect.left;
                const outerLeftY = textElementBoundingRect.top + sin * outerWidth;
                const outerK = -1 / innerK;
                const outerM = outerLeftY - outerK * outerLeftX;

                const intersectionX = (outerM - innerM) / (innerK - outerK);
                const intersectionY = innerK * intersectionX + innerM;

                // let reddot = document.getElementById('reddot');
                // if (!reddot) {
                //     reddot = document.createElement('div');
                //     reddot.id = 'reddot';
                //     reddot.style.background = 'red';
                //     reddot.style.position = 'absolute';
                //     document.body.appendChild(reddot);
                // }
                // reddot.style.left = x_intersection + 'px';
                // reddot.style.top = y_intersection + 'px';
                // reddot.style.width = '2px';
                // reddot.style.height = '2px';

                const innerTopX =
                    selectionRangeBoundingRect.left +
                    selectionRangeBoundingRect.width -
                    sin * lineHeight;
                const innerTopY = selectionRangeBoundingRect.top;

                // let gloue = document.getElementById('gloue');
                // if (!gloue) {
                //     gloue = document.createElement('div');
                //     gloue.id = 'gloue';
                //     gloue.style.background = 'red';
                //     gloue.style.position = 'absolute';
                //     document.body.appendChild(gloue);
                // }
                // gloue.style.left = x_outer_left + 'px';
                // gloue.style.top = y_outer_left + 'px';
                // gloue.style.width = '2px';
                // gloue.style.height = '2px';

                left =
                    Math.sqrt((intersectionY - outerLeftY) ** 2 + (intersectionX - outerLeftX) ** 2) /
                    zoom;
                width = Math.sqrt((innerLeftY - innerTopY) ** 2 + (innerLeftX - innerTopX) ** 2) / zoom;
            } else if (modRotation === Math.PI / 2) {
                left = (selectionLineBoundingRect.bottom - selectionRangeBoundingRect.bottom) / zoom;
                width = selectionRangeBoundingRect.height / zoom;
            } else if (modRotation < Math.PI) {
                const innerBottomX =
                    selectionRangeBoundingRect.right - Math.cos(modRotation - Math.PI / 2) * lineHeight;
                const innerBottomY = selectionRangeBoundingRect.bottom;
                const innerK = -Math.tan(modRotation);
                const innerM = innerBottomY - innerK * innerBottomX;

                const outerBottomX =
                    textElementBoundingRect.left + Math.cos(Math.PI - modRotation) * outerWidth;
                const outerBottomY = textElementBoundingRect.bottom;
                const outerK = -1 / innerK;
                const outerM = outerBottomY - outerK * outerBottomX;

                const intersectionX = (outerM - innerM) / (innerK - outerK);
                const intersectionY = innerK * intersectionX + innerM;

                const innerLeftX = selectionRangeBoundingRect.left;
                const innerLeftY =
                    selectionRangeBoundingRect.top + Math.cos(Math.PI - modRotation) * lineHeight;

                left =
                    Math.sqrt(
                        (intersectionY - innerBottomY) ** 2 + (intersectionX - innerBottomX) ** 2
                    ) / zoom;
                width =
                    Math.sqrt((innerLeftY - innerBottomY) ** 2 + (innerLeftX - innerBottomX) ** 2) /
                    zoom;
            } else if (modRotation === Math.PI) {
                left = (selectionLineBoundingRect.right - selectionRangeBoundingRect.right) / zoom;
                width = selectionRangeBoundingRect.width / zoom;
            } else if (modRotation < 1.5 * Math.PI) {
                const innerRightX = selectionRangeBoundingRect.right;
                const innerRightY =
                    selectionRangeBoundingRect.top + Math.sin(1.5 * Math.PI - modRotation) * lineHeight;
                const innerK = 1 / Math.tan(modRotation);
                const innerM = innerRightY - innerK * innerRightX;

                const outerRightX = textElementBoundingRect.right;
                const outerRightY =
                    textElementBoundingRect.top + Math.sin(1.5 * Math.PI - modRotation) * outerHeight;
                const outerK = -1 / innerK;
                const outerM = outerRightY - outerK * outerRightX;

                const intersectionX = (outerM - innerM) / (innerK - outerK);
                const intersectionY = innerK * intersectionX + innerM;

                const innerBottomX =
                    selectionRangeBoundingRect.left + Math.sin(modRotation - Math.PI) * lineHeight;
                const innerBottomY = selectionRangeBoundingRect.bottom;

                left =
                    Math.sqrt((intersectionY - outerRightY) ** 2 + (intersectionX - outerRightX) ** 2) /
                    zoom;
                width =
                    Math.sqrt((innerRightY - innerBottomY) ** 2 + (innerRightX - innerBottomX) ** 2) /
                    zoom;
            } else if (modRotation === 1.5 * Math.PI) {
                left = (selectionRangeBoundingRect.top - selectionLineBoundingRect.top) / zoom;
                width = selectionRangeBoundingRect.height / zoom;
            } else {
                const innerTopX =
                    selectionRangeBoundingRect.left + Math.sin(2 * Math.PI - modRotation) * lineHeight;
                const innerTopY = selectionRangeBoundingRect.top;
                const innerK = 1 / Math.tan(modRotation);
                const innerM = innerTopY - innerK * innerTopX;

                const outerTopX =
                    textElementBoundingRect.left + Math.sin(2 * Math.PI - modRotation) * outerHeight;
                const outerTopY = textElementBoundingRect.top;
                const outerK = -1 / innerK;
                const outerM = outerTopY - outerK * outerTopX;

                const intersectionX = (outerM - innerM) / (innerK - outerK);
                const intersectionY = innerK * intersectionX + innerM;

                const innerRightX = selectionRangeBoundingRect.right;
                const innerRightY =
                    selectionRangeBoundingRect.bottom -
                    Math.cos(2 * Math.PI - modRotation) * lineHeight;

                left =
                    Math.sqrt((intersectionY - outerTopY) ** 2 + (intersectionX - outerTopX) ** 2) /
                    zoom;
                width =
                    Math.sqrt((innerTopY - innerRightY) ** 2 + (innerTopX - innerRightX) ** 2) / zoom;
            }
            width = Math.ceil(width) + 1;
            spanElement.style.left = `${left}px`;
            spanElement.style.width = `${width}px`;
            spanElement.style.display = 'block';
            spanElement.style.height = 'calc(100% + 1px)';
            spanElement.style.position = 'absolute';
            spanElement.style.backgroundColor = SELECTION_FOCUSED_BACKGROUND_COLOR;
            selectionLineElement.appendChild(spanElement);
            this.text.selectionSpanElements_m.push(spanElement);
        }
    }

    clearSelection(): void {
        for (const lineElement of this.editor.selection.lineToSelectionLineElementMap.values()) {
            lineElement.innerHTML = '';
        }
    }
}

__parent(RichTextSelectionService, 'text', 0);
