import {
    CharacterPropertyKeys,
    ICharacterProperties,
    ISpanAttributes,
    IStyleIdMap,
    IWordSpan,
    OneOfContentSpans,
    OneOfEditableSpans,
    OneOfRenderedSpans,
    PreviousStyleIdType,
    SpanType
} from '@domain/text';
import { cloneDeep } from '@studio/utils/clone';
import { deepEqual, omit } from '@studio/utils/utils';
import {
    copySpan,
    copyStyle,
    hasSamePreviousStyleIds,
    hasSameStyleIds,
    hasSameStyleProperty,
    isWordSpan
} from './text-nodes';

type StyleIds = {
    styleId: string | undefined;
    styleIds: IStyleIdMap | undefined;
    previousStyleIds: (string | PreviousStyleIdType)[] | undefined;
    previousStyleIdToHistoryIndexMap: Map<string, number> | undefined;
};

export function createWordSpanFromCharacter(
    char: number,
    style: Partial<ICharacterProperties>,
    attributes: ISpanAttributes = {},
    ids: Partial<StyleIds> = {}
): IWordSpan {
    return {
        type: SpanType.Word,
        content: String.fromCharCode(char),
        style: copyStyle(style),
        top: 0,
        left: 0,
        width: 0,
        height: 0,
        lineHeight: 0,
        attributes: cloneDeep(attributes),
        styleIds: ids.styleIds || {},
        styleId: ids.styleId,
        __previousStyleIds: [...(ids.previousStyleIds || [])],
        __previousStyleIdToHistoryIndexMap: new Map<string, number>(
            ids.previousStyleIdToHistoryIndexMap || []
        )
    };
}

export function isSpanMergeable_m(source: OneOfRenderedSpans, target: OneOfEditableSpans): boolean {
    if (source.type === SpanType.Newline || source.type === SpanType.End) {
        return false;
    }
    if (source.type !== target.type) {
        return false;
    }
    if (!hasSameStyleIds(source.styleIds, target.styleIds)) {
        return false;
    }
    if (!hasSamePreviousStyleIds(source.__previousStyleIds, target.__previousStyleIds)) {
        return false;
    }
    return hasSpanSameStyleAndAttributes_m(source, target);
}

export function hasSpanSameStyleAndAttributes_m(
    source: OneOfContentSpans,
    target: OneOfContentSpans
): boolean {
    const sourceStyle = omit(source.style, '__fontFamilyId');
    const targetStyle = omit(target.style, '__fontFamilyId');
    if (Object.keys(sourceStyle).length !== Object.keys(targetStyle).length) {
        return false;
    }
    for (const property in sourceStyle) {
        if (!hasSameStyleProperty(property as CharacterPropertyKeys, sourceStyle, targetStyle)) {
            return false;
        }
    }
    const sourceAttributes = omit(source.attributes, 'shouldRenderNumber');
    const targetAttributes = omit(target.attributes, 'shouldRenderNumber');
    if (Object.keys(sourceAttributes).length !== Object.keys(targetAttributes).length) {
        return false;
    }
    for (const attribute in sourceAttributes) {
        if (!deepEqual(source.attributes[attribute], target.attributes[attribute])) {
            return false;
        }
    }
    return true;
}

export function explodeSpans(spans: OneOfEditableSpans[]): OneOfEditableSpans[] {
    const explodedSpans: OneOfEditableSpans[] = [];

    for (const span of spans) {
        // explode word spans into words per character
        if (isWordSpan(span)) {
            const wordSpans: IWordSpan[] = [...span.content].map(char =>
                createWordSpanFromCharacter(char.charCodeAt(0), span.style)
            );
            explodedSpans.push(...wordSpans);
        } else {
            explodedSpans.push(copySpan(span));
        }
    }

    return explodedSpans;
}

export function mergeSpans(spans: OneOfEditableSpans[]): OneOfEditableSpans[] {
    const mergedSpans: OneOfEditableSpans[] = [];
    let lastSpan: OneOfEditableSpans | undefined;
    for (const span of spans) {
        if (!lastSpan) {
            lastSpan = span;
            continue;
        }

        if (isSpanMergeable_m(lastSpan, span)) {
            lastSpan.content += span.content;
        } else {
            mergedSpans.push(lastSpan);
            lastSpan = span;
        }
    }

    if (lastSpan) {
        mergedSpans.push(lastSpan);
    }

    return mergedSpans;
}
