import { IBoundingBox, IPosition, ISize } from '@domain/dimension';
import {
    ICreativeDataNode,
    IGroupElementDataNode,
    ITimelineNode,
    OneOfDataNodes,
    OneOfGroupDataNodes
} from '@domain/nodes';
import { forEachParentNode, isCreativeNode, isGroupDataNode, toFlatNodeList } from './helpers';
import { ElementSelection } from './selection';

export const MAX_NESTED_GROUPS = 7;

/** @@remove STUDIO:START */
export function cloneNodes(nodes: OneOfDataNodes[]): OneOfDataNodes[] {
    return nodes.map(node => {
        if (isGroupDataNode(node)) {
            return node.copy(true);
        }
        return node;
    });
}
/** @@remove STUDIO:END */

export function isLockedAndDirectlySelected(
    node: OneOfDataNodes,
    selection: ElementSelection
): boolean {
    const isSelectionEmpty = selection.nodes.length === 0;
    const isNodeDirectlySelected = selection.nodes.includes(node);
    const isAnyAncestorLockedAndDirectlySelected = getLockedAncestors(node).some(ancestor =>
        selection.nodes.includes(ancestor)
    );
    if (!isSelectionEmpty && (isNodeDirectlySelected || isAnyAncestorLockedAndDirectlySelected)) {
        return isLocked(node);
    } else {
        return false;
    }
}

export function isLocked(node: OneOfDataNodes): boolean {
    return node.locked || hasLockedAncestor(node);
}

export function hasLockedAncestor(node: OneOfDataNodes): boolean {
    let hasLockedParent = false;

    forEachParentNode(node, parent => {
        if (parent.locked) {
            hasLockedParent = true;
            return true;
        }
    });

    return hasLockedParent;
}

export function getLockedAncestors(node: OneOfDataNodes): OneOfDataNodes[] {
    const lockedParents: OneOfDataNodes[] = [];
    forEachParentNode(node, parent => {
        if (parent.locked) {
            lockedParents.push(parent);
        }
    });

    return lockedParents;
}

export function hasHiddenAncestor(node: OneOfDataNodes): boolean {
    let hasHiddenParent = false;

    forEachParentNode(node, parent => {
        if (parent.hidden) {
            hasHiddenParent = true;
            return true;
        }
    });

    return hasHiddenParent;
}

/**
 * Filter out nodes that are part of an included group
 */
export function filterAncestorNodes(nodeList: OneOfDataNodes[]): OneOfDataNodes[] {
    return nodeList.filter(node => !node.__parentNode || !nodeList.includes(node.__parentNode));
}

/**
 * Looks for the first expanded ancestor of node
 */
export function findExpandedAncestor(
    node: OneOfDataNodes,
    nodeList: ITimelineNode[]
): IGroupElementDataNode | undefined {
    let ancestor: IGroupElementDataNode | undefined;

    forEachParentNode(node, parent => {
        const isParentExpanded = nodeList.some(
            tl => tl.node.__parentNode?.id === parent.id && !tl.collapsed
        );
        if (isGroupDataNode(parent) && isParentExpanded) {
            ancestor = parent;
            return true;
        }
    });

    return ancestor;
}

/**
 * Checks if parent of node is selected
 */
export function isParentNodeSelected(node: OneOfDataNodes, selection: ElementSelection): boolean {
    return selection.nodes.some(n => isGroupDataNode(n) && node.__parentNode?.id === n.id);
}

export function hasSelectionOfAllNodesInGroup(selection: ElementSelection): boolean {
    const { elements, nodes } = selection;

    return nodes.every(node => {
        elements.every(element => {
            if (isGroupDataNode(node)) {
                return node.findNodeById_m(element.id);
            }
            return element.id === node.id;
        });
    });
}

export function getBoundingBoxOfGroup(node: IGroupElementDataNode): IBoundingBox {
    let x1;
    let y1;
    let x2;
    let y2;
    node.elements.forEach(element => {
        if (x1 === undefined || element.x < x1) {
            x1 = element.x;
        }
        if (y1 === undefined || element.y < y1) {
            y1 = element.y;
        }
        if (x2 === undefined || element.x + element.width > x2) {
            x2 = element.x + element.width;
        }
        if (y2 === undefined || element.y + element.height > y2) {
            y2 = element.y + element.height;
        }
    });

    return {
        x: x1,
        y: y1,
        width: x2 - x1,
        height: y2 - y1
    };
}

export function getSizeAndPositionOfNode(node: OneOfDataNodes): ISize & IPosition {
    if (isGroupDataNode(node)) {
        const box = getBoundingBoxOfGroup(node);
        const { x, y, width, height } = box;
        return { x, y, width, height };
    } else {
        const { x, y, width, height } = node;
        return { x, y, width, height };
    }
}

export function getCommonParent(
    nodes: OneOfDataNodes[],
    creativeDocument: ICreativeDataNode
): IGroupElementDataNode | ICreativeDataNode {
    const creativeNodes = toFlatNodeList(creativeDocument).reverse();
    const parentGroup = creativeNodes.find(node => nodes.some(n => n.id === node.id))?.__parentNode;

    const nodeInRoot = nodes.some(n => !n.__parentNode);

    const commonParent = nodeInRoot ? creativeDocument : parentGroup || creativeDocument;

    return commonParent;
}

export function canCreateGroup(nodes: OneOfDataNodes[], creativeDocument: ICreativeDataNode): boolean {
    const descendantLevels = getSelectionDepth(nodes);
    const commonParent = getCommonParent(nodes, creativeDocument);
    const nestingLevel = isCreativeNode(commonParent) ? 0 : getNestingLevel(commonParent);

    return nestingLevel + descendantLevels < MAX_NESTED_GROUPS;
}

export function canMoveSelectionIntoGroup(
    nodes: OneOfDataNodes[],
    target: OneOfGroupDataNodes
): boolean {
    if (isCreativeNode(target)) {
        return true;
    }
    const selectionDepth = getSelectionDepth(nodes);
    const targetLevel = getNestingLevel(target);
    return selectionDepth + targetLevel <= MAX_NESTED_GROUPS;
}

export function getNestingLevel(node: OneOfDataNodes): number {
    let parent: OneOfGroupDataNodes | undefined = node.__parentNode;
    let parentAmount = isGroupDataNode(node) ? 1 : 0;

    while (parent && isGroupDataNode(parent) && parent !== parent.__parentNode) {
        parent = parent.__parentNode;
        parentAmount++;
    }

    return parentAmount;
}

export function getSelectionDepth(nodes: OneOfDataNodes[]): number {
    if (nodes.length === 0) {
        return 0;
    }
    const depths = nodes.map(node => (isGroupDataNode(node) ? getGroupDepth(node) : 0));
    return Math.max(...depths);
}

export function getGroupDepth(group: OneOfGroupDataNodes, isRoot = true): number {
    const childGroupsDepths = group.nodes.map(node => {
        if (isGroupDataNode(node) || isCreativeNode(node)) {
            return 1 + getGroupDepth(node, false);
        } else {
            return 0;
        }
    });
    const maxChildDepth = Math.max(...childGroupsDepths);
    return isRoot ? maxChildDepth + 1 : maxChildDepth;
}
