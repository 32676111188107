@if (group) {
    @if (!selectedElement && group.elements.length > 1) {
        <group-input-header
            [groupLength]="group.elements.length"
            [expanded]="expanded"
            (toggleExpand)="toggleExpand($event)"></group-input-header>
    }

    @let inputsDataValue = inputsData();
    @for (inputData of inputsDataValue; track $index; let index = $index) {
        @let groupElement = selectedElement ?? group.elements[index];

        <div
            [id]="'target-' + inputData.renderId"
            ui-popover-target
            #stylingPopoverTarget="ui-popover-target"
            class="versioned-styling__wrapper">
            <ng-template #leadingIcon>
                @if (!selectedElement && group.elements.length > 1 && !expanded) {
                    <group-option-number [number]="group.elements.length" />
                } @else {
                    <ui-svg-icon [icon]="(groupElement | iconForElement) ?? ''" />
                }
            </ng-template>
            <mat-input
                [attr.data-test-id]="'version-input-' + groupElement.id"
                [leadingIcon]="leadingIcon"
                [value]="inputData.text"
                [type]="'textarea'"
                [label]="version | versionToLabel: (expanded ? groupElement.name : undefined)"
                [showStyledText]="true"
                [styledContent]="inputData.styledContent"
                [highlightedAreas]="inputData.highlightedAreas"
                [isStyledContentFocused]="focusedElementId() === groupElement.id"
                (inputFocus)="highlightElement(groupElement.id, version.id)"
                (inputMouseOver)="highlightElement(groupElement.id, version.id)"
                (selectedText)="onSelectedText(inputData, $event, groupElement.id)" />
        </div>
    }
}
