import { mergeFontFamilies } from '@creative/font-families.utils';
import { deserializeFontFamilies } from '@data/deserialization/font-families';
import { IFontFamily } from '@domain/font-families';
import { IAsyncState } from '@domain/store/async';
import { createReducer, on } from '@ngrx/store';
import { ExternalFontFamilyDto } from '@studio/domain/api/font-manager.types';
import { creativeSetShowcaseActions } from '../../creativeset-showcase/state/creativeset-showcase.actions';
import * as FontFamiliesActions from './font-families.actions';

export const FONT_FAMILIES_FEATURE_KEY = 'fontFamilies';

export interface FontFamiliesPartialState {
    readonly [FONT_FAMILIES_FEATURE_KEY]: FontFamiliesState;
}

export interface FontFamiliesState extends IAsyncState {
    fontFamiliesFromBrand: IFontFamily[];
    fontFamiliesFromCreativeSet: IFontFamily[];

    externalFontsResult?: ExternalFontFamilyDto[];
    importedExternalFonts?: IFontFamily[];
}

export const initialState: FontFamiliesState = {
    loaded: false,
    fontFamiliesFromBrand: [],
    fontFamiliesFromCreativeSet: [],
    externalFontsResult: undefined,
    importedExternalFonts: undefined
};

export const reducer = createReducer<FontFamiliesState>(
    initialState,

    on(
        FontFamiliesActions.loadFontFamiliesOfBrand,
        FontFamiliesActions.searchExternalFonts,
        FontFamiliesActions.importExternalFonts,
        (state): FontFamiliesState => ({
            ...state,
            loaded: false,
            error: undefined
        })
    ),

    on(
        FontFamiliesActions.loadFontFamiliesOfBrandSuccess,
        FontFamiliesActions.searchExternalFontsSuccess,
        FontFamiliesActions.importExternalFontsSuccess,
        (state): FontFamiliesState => ({
            ...state,
            loaded: true,
            error: undefined
        })
    ),
    on(
        FontFamiliesActions.loadFontFamiliesOfBrandSuccess,
        (state, { fontFamilies }): FontFamiliesState => ({
            ...state,
            fontFamiliesFromBrand: fontFamilies
        })
    ),
    on(
        FontFamiliesActions.searchExternalFontsSuccess,
        (state, { response }): FontFamiliesState => ({
            ...state,
            externalFontsResult: response.items
        })
    ),
    on(FontFamiliesActions.importExternalFontsSuccess, (state, { response }): FontFamiliesState => {
        const parsedFontFamilies = deserializeFontFamilies(response.fontFamilies);
        return {
            ...state,
            fontFamiliesFromBrand: [...state.fontFamiliesFromBrand, ...parsedFontFamilies],
            importedExternalFonts: parsedFontFamilies
        };
    }),

    on(
        FontFamiliesActions.loadFontFamiliesOfBrandFailure,
        FontFamiliesActions.searchExternalFontsFailure,
        FontFamiliesActions.importExternalFontsFailure,
        (state, { error }): FontFamiliesState => ({
            ...state,
            loaded: true,
            error
        })
    ),

    on(
        FontFamiliesActions.setFontFamiliesOfCreativeSet,
        (state, { fontFamilies }): FontFamiliesState => ({
            ...state,
            loaded: true,
            error: undefined,
            fontFamiliesFromCreativeSet: fontFamilies
        })
    ),

    on(
        creativeSetShowcaseActions.loadShowcaseCreativeSetSuccess,
        (state, { fonts }): FontFamiliesState => ({
            ...state,
            loaded: true,
            error: undefined,
            fontFamiliesFromCreativeSet: fonts
        })
    ),

    on(
        FontFamiliesActions.addFontFamilies,
        (state, { fontFamilies }): FontFamiliesState => ({
            ...state,
            fontFamiliesFromCreativeSet: mergeFontFamilies(
                state.fontFamiliesFromCreativeSet,
                fontFamilies
            )
        })
    ),
    on(FontFamiliesActions.resetExternalFonts, state => ({
        ...state,
        externalFontsResult: undefined
    })),
    on(FontFamiliesActions.resetImportedFonts, state => ({
        ...state,
        importedExternalFonts: undefined
    }))
);
