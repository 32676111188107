import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UIModule } from '@bannerflow/ui';
import { FeatureFlagsDirective, StudioFeatureFlags } from '@studio/feature-flags';
import { Observable } from 'rxjs';
import { CommentsOverviewComponent } from '../../../shared/components/comments-overview/comments-overview.component';
import { CurrentUsersComponent } from '../../../shared/components/current-users/current-users.component';
import { FeatureToggleComponent } from '../../../shared/components/feature-toggle/feature-toggle.component';
import { HelpMenuComponent } from '../../../shared/components/help-menu/help-menu.component';
import { MarkDoneButtonComponent } from '../../../shared/components/mark-done-button/mark-done-button.component';
import { ToggleEnvironmentComponent } from '../../../shared/components/toggle-environment/toggle-environment.component';
import { TopbarContextMenuComponent } from '../../../shared/components/topbar-context-menu/topbar-context-menu.component';
import { VersionPickerComponent } from '../../../shared/components/version-picker/version-picker.component';
import { TruncateSpanComponent } from '../../../shared/directives';
import { EnvironmentService } from '../../../shared/services/environment.service';
import { HotkeyBetterService } from '../../../shared/services/hotkeys/hotkey.better.service';
import { SizePickerComponent as SizePicker2Component } from '../../../shared/size-picker/size-picker.component';
import { StudioTopbarComponent } from '../../../shared/studio-topbar/studio-topbar.component';
import { UserService } from '../../../shared/user/state/user.service';
import { TranslationMenuComponent } from '../translation-menu/translation-menu.component';
import { SizePickerComponent } from './size-picker/size-picker.component';

@Component({
    standalone: true,
    imports: [
        CommentsOverviewComponent,
        CommonModule,
        CurrentUsersComponent,
        FeatureFlagsDirective,
        FeatureToggleComponent,
        HelpMenuComponent,
        MarkDoneButtonComponent,
        MarkDoneButtonComponent,
        SizePickerComponent,
        SizePicker2Component,
        StudioTopbarComponent,
        ToggleEnvironmentComponent,
        TopbarContextMenuComponent,
        TranslationMenuComponent,
        TruncateSpanComponent,
        UIModule,
        VersionPickerComponent
    ],
    selector: 'translation-topbar',
    templateUrl: './translation-topbar.component.html',
    styleUrls: ['./translation-topbar.component.scss']
})
export class TranslationTopbarComponent implements OnDestroy {
    @Input() creativesetName: string;
    @Input() creativesetId: string;
    @Output() layoutChange = new EventEmitter<'left' | 'right' | undefined>();
    inShowcaseMode$: Observable<boolean>;
    isEmployee$ = this.userService.isEmployee$;

    StudioFeatureFlags = StudioFeatureFlags;

    @HostListener('document:keydown', ['$event'])
    onKeydown(event: KeyboardEvent): void {
        // Prevent default behavior for Cmd + ArrowLeft and Cmd + ArrowRight on MAC
        // needed for LeftAlignedPanel and RightAlignedPanel shortcuts
        if ((event.key === 'ArrowLeft' || event.key === 'ArrowRight') && event.metaKey) {
            event.preventDefault();
        }
    }

    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private environmentService: EnvironmentService,
        private hotkeyBetterService: HotkeyBetterService,
        private userService: UserService
    ) {
        this.inShowcaseMode$ = this.environmentService.inShowcaseMode$;
        this.startHotkeyListeners();
    }

    changeLayoutDirection(layout?: 'left' | 'right'): void {
        this.layoutChange.emit(layout);
    }

    navigateToMV(): void {
        this.router.navigate(['../'], {
            queryParamsHandling: 'merge',
            relativeTo: this.activatedRoute
        });
    }

    ngOnDestroy(): void {
        this.stopHotkeyListeners();
    }

    // shortcuts support
    private leftAlignedPanel = (): void => {
        this.changeLayoutDirection('left');
    };
    private rightAlignedPanel = (): void => {
        this.changeLayoutDirection('right');
    };
    private exitTranslationPage = (): void => {
        const stylePopover = document.getElementById('style-popover');
        if (!stylePopover) {
            this.navigateToMV();
        }
    };

    private startHotkeyListeners(): void {
        this.hotkeyBetterService.on('LeftAlignedPanel', this.leftAlignedPanel);
        this.hotkeyBetterService.on('RightAlignedPanel', this.rightAlignedPanel);
        this.hotkeyBetterService.on('ExitTranslationPage', this.exitTranslationPage);
    }

    private stopHotkeyListeners(): void {
        this.hotkeyBetterService.off('LeftAlignedPanel', this.leftAlignedPanel);
        this.hotkeyBetterService.off('RightAlignedPanel', this.rightAlignedPanel);
        this.hotkeyBetterService.on('ExitTranslationPage', this.exitTranslationPage);
    }
}
