<div class="app">
    <ui-loader *ngIf="!loaded"></ui-loader>
    <router-outlet></router-outlet>
</div>
<canvas
    id="canvas-icon-drawer"
    width="32"
    height="32"
    style="opacity: 0; pointer-events: none"></canvas>
<!-- This element is used to feature detect CSS image-set used in our custom cursors. DON'T TOUCH !!! -->
<div
    id="detect-image-set"
    style="
        background-image: -webkit-image-set(url(//) 1x, url(//) 2x);
        background-image: image-set(url(//) 1x, url(//) 2x);
    "></div>
