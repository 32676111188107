import { Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UIModule } from '@bannerflow/ui';
import { ISizeCollection } from '@studio/domain/components/size-list.types';
import { SizeAddStateService } from '../state/size-add.service';
import { CollectionNamePipe } from '../pipes/collection-name.pipe';
import { DefaultCollectionsEnum } from '@studio/domain/state/size-add.types';

@Component({
    standalone: true,
    imports: [UIModule, CollectionNamePipe],
    selector: 'size-add-collection-select',
    templateUrl: './size-add-collection-select.component.html',
    styleUrl: './size-add-collection-select.component.scss'
})
export class SizeAddCollectionSelectComponent {
    private sizeAddStateService = inject(SizeAddStateService);

    private _collections = toSignal(this.sizeAddStateService.collections$, { initialValue: [] });

    selectedCollectionsIds = toSignal(this.sizeAddStateService.selectedCollectionsIds$, {
        initialValue: []
    });
    collections = computed(() => this.computeCollections());

    defaultCollections: string[] = [
        DefaultCollectionsEnum.CustomSizes,
        DefaultCollectionsEnum.OrphanSizes,
        DefaultCollectionsEnum.SocialSizes
    ];

    onSelectedCollectionChange(newValue: string[]): void {
        this.sizeAddStateService.setSelectedCollections(newValue);
    }

    private computeCollections(): Array<ISizeCollection & { selected: boolean }> {
        const collections = this._collections();
        const selectedCollectionsIds = this.selectedCollectionsIds();

        return collections.map(collection => ({
            ...collection,
            selected: !!selectedCollectionsIds.includes(collection.id)
        }));
    }
}
