import { isVersionedFeed } from '@creative/elements/feed/feeds.utils';
import { isVersionedText, isVersionedWidgetText } from '@creative/elements/rich-text/text-nodes';
import { VersionDtoV2, VersionPropertyDto } from '@domain/api/generated/sapi';
import { IVersion, IVersionProperty } from '@domain/creativeset/version';
import { validateAndFixVersionedFeed } from './versionedFeed-semantic-validation';
import { serializeVersionedFeed } from './versionedFeed-serializer';
import { validateAndFixVersionedText } from './versionedText-semantic-validation';
import { serializeVersionedText } from './versionedText-serializer';
import { serializeWidgetText } from './widgetText-serializer';

export function serializeVersions(
    versions: IVersion[],
    sentinelService?: { trackPageAction: (message: string, data: unknown) => void }
): VersionDtoV2[] {
    const serializedVersions: VersionDtoV2[] = [];
    for (const version of versions) {
        const serializedVersion = serializeVersion(version, sentinelService);
        serializedVersions.push(serializedVersion);
    }

    return serializedVersions;
}

export function serializeVersion(
    version: IVersion,
    sentinelService?: { trackPageAction: (message: string, data: unknown) => void }
): VersionDtoV2 {
    const serializedVersionProperties = serializeVersionProperties(version.properties, sentinelService);
    return {
        id: version.id,
        name: version.name,
        targetUrl: version.targetUrl,
        localization: {
            id: version.localization.id
        },
        properties: serializedVersionProperties
    };
}

export function serializeVersionProperties(
    versionProperties: IVersionProperty[],
    sentinelService?: { trackPageAction: (message: string, data: unknown) => void }
): VersionPropertyDto[] {
    const serializedVersionProperties: VersionPropertyDto[] = [];
    for (const versionProperty of versionProperties) {
        const serializedVersionProperty = serializeVersionProperty(versionProperty, sentinelService);
        serializedVersionProperties.push(serializedVersionProperty);
    }
    return serializedVersionProperties;
}

export function serializeVersionProperty(
    versionProperty: IVersionProperty,
    sentinelService?: { trackPageAction: (message: string, data: unknown) => void }
): VersionPropertyDto {
    if (isVersionedFeed(versionProperty)) {
        try {
            const fixedValue = validateAndFixVersionedFeed(versionProperty.value);
            versionProperty.value = fixedValue;
        } catch (error) {
            if (error instanceof Error) {
                sentinelService?.trackPageAction('VersionedFeedSemanticValidationFail', {
                    versionPropertyId: versionProperty.id,
                    value: JSON.stringify(versionProperty.value),
                    errorMessage: error.message
                });
            }
        }

        return {
            name: 'feed',
            id: versionProperty.id,
            value: serializeVersionedFeed(versionProperty)
        };
    }
    if (isVersionedWidgetText(versionProperty)) {
        return {
            name: 'widgetText',
            id: versionProperty.id,
            value: serializeWidgetText(versionProperty)
        };
    }
    if (isVersionedText(versionProperty)) {
        try {
            const fixedValue = validateAndFixVersionedText(versionProperty.value);
            versionProperty.value = fixedValue;
        } catch (error) {
            if (error instanceof Error) {
                sentinelService?.trackPageAction('VersionedTextSemanticValidationFail', {
                    versionPropertyId: versionProperty.id,
                    value: JSON.stringify(versionProperty.value),
                    errorMessage: error.message
                });
            }
        }
        return {
            name: 'content',
            id: versionProperty.id,
            value: serializeVersionedText(versionProperty)
        };
    }
    throw new Error('Invalid versionProperty type');
}
