import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnDestroy, inject } from '@angular/core';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { UIModule } from '@bannerflow/ui';
import { Observable, map } from 'rxjs';
import { HotkeyBetterService } from '../../../../shared/services/hotkeys/hotkey.better.service';
import { UserSettingsService } from '../../../../shared/user-settings/state/user-settings.service';

@Component({
    selector: 'panel-tabs',
    templateUrl: './panel-tabs.component.html',
    styleUrls: ['./panel-tabs.component.scss'],
    imports: [UIModule, MatTabsModule, NgIf, AsyncPipe],
    standalone: true
})
export class PanelTabsComponent implements OnDestroy {
    selectedTabIndex$: Observable<number>;

    private userSettingsService = inject(UserSettingsService);
    private hotkeyBetterService = inject(HotkeyBetterService);

    constructor() {
        this.startHotkeyListeners();

        this.selectedTabIndex$ = this.userSettingsService.translationPageTab$.pipe(
            map(lastTab => {
                switch (lastTab) {
                    case 'styling':
                        return 1;
                    case 'content':
                    default:
                        return 0;
                }
            })
        );
    }

    onSelectedTabChange(event: MatTabChangeEvent): void {
        switch (event.index) {
            case 1:
                this.openStylingPanel();
                return;
            case 0:
            default:
                this.openContentPanel();
                return;
        }
    }

    ngOnDestroy(): void {
        this.stopHotkeyListeners();
    }

    // shortcuts support
    private openContentPanel = (): void => {
        this.userSettingsService.toggleTranslationPageTab('content');
    };
    private openStylingPanel = (): void => {
        this.userSettingsService.toggleTranslationPageTab('styling');
    };

    private startHotkeyListeners(): void {
        this.hotkeyBetterService.on('OpenContentPanel', this.openContentPanel);
        this.hotkeyBetterService.on('OpenStylingPanel', this.openStylingPanel);
    }

    private stopHotkeyListeners(): void {
        this.hotkeyBetterService.off('OpenContentPanel', this.openContentPanel);
        this.hotkeyBetterService.off('OpenStylingPanel', this.openStylingPanel);
    }
}
