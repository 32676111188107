import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    inject,
    ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UIDialogDirective } from '@bannerflow/ui';
import { CreativeSize } from '@domain/creativeset';
import { FeatureFlagsDirective, StudioFeatureFlags } from '@studio/feature-flags';
import { Breakpoint } from '@studio/utils/breakpoints';
import { CreativesetDataService } from '../../../shared/creativeset/creativeset.data.service';
import { MediaDirective } from '../../../shared/directives/media.directive';
import { PermissionsDirective } from '../../../shared/directives/permissions.directive';
import { FiltersService } from '../../../shared/filters/state/filters.service';
import { EditCreativeService } from '../services/edit-creative.service';
import { FilterListSizesComponent } from './filter-list-sizes/filter-list-sizes.component';
import { QuickFiltersComponent } from './quick-filters/quick-filters.component';
import { FilterListStatusComponent } from './status/filter-list-status.component';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        FilterListSizesComponent,
        FilterListStatusComponent,
        PermissionsDirective,
        FeatureFlagsDirective,
        QuickFiltersComponent,
        MediaDirective
    ],
    selector: 'filter-list',
    templateUrl: './filter-list.component.html',
    styleUrls: ['./filter-list.component.scss']
})
export class FilterListComponent implements AfterViewInit {
    private changeDetectorRef = inject(ChangeDetectorRef);
    private creativeSetDataService = inject(CreativesetDataService);
    private editCreativeService = inject(EditCreativeService);
    private filtersService = inject(FiltersService);
    private destroyRef = inject(DestroyRef);

    @ViewChild('dialog') dialog: UIDialogDirective;

    sizes: CreativeSize[];

    Breakpoint = Breakpoint;
    StudioFeatureFlags = StudioFeatureFlags;

    constructor() {
        this.creativeSetDataService.sizes$.pipe(takeUntilDestroyed()).subscribe(sizes => {
            this.sizes = sizes;
        });
    }

    ngAfterViewInit(): void {
        this.editCreativeService.updateView$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            const sizeIds = this.creativeSetDataService.creativeset.sizes.map(({ id }) => id);
            this.filtersService.setAllSizes(sizeIds);
            this.changeDetectorRef.detectChanges();
        });

        this.editCreativeService.deletion.change$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                const sizeIds = this.creativeSetDataService.creativeset.sizes.map(({ id }) => id);
                this.filtersService.setAllSizes(sizeIds);
                this.filtersService.clearSizesFilter();
            });
    }
}
